import { createStyles, UnstyledButton, UnstyledButtonProps } from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { Flex, IconFn, RpiText } from "@rpi/openapi-core";
import React from "react";
import { NavRouteLink, NavRouteLinkProps, RoutePathsKeys } from "../../hooks/useNavigateRoute";

interface NavLinkProps2 {
  name?: string;
  icon?: IconFn;
}

const useNavLinkStyles = createStyles((theme) => {
  return {
    root: {
      display: "block",
      width: "100%",
      paddingLeft: 27,
      paddingRight: 32,
      paddingTop: 16,
      paddingBottom: 16,
      height: 50,
      color: theme.colors.sky[5],
      backgroundColor: "transparent",
      borderLeft: `5px solid ${theme.colors.brand[5]}`,
      transition: "color 125ms, border 125ms",
      "&:hover": {
        color: theme.colors.mellow[5],
        borderColor: theme.colors.mellow[5],
      },
    },
    active: {
      color: theme.colors.mellow[5],
      borderColor: theme.colors.mellow[5],
    },
    link: {
      textDecoration: "none",
    },
  };
});

export function NavLink<T extends RoutePathsKeys>({
  name,
  icon: iconConifg,

  sx,

  ...props
}: Pick<PolymorphicComponentProps<"button", UnstyledButtonProps>, "sx"> & NavRouteLinkProps<T> & NavLinkProps2) {
  const { classes, cx } = useNavLinkStyles();

  const icon = React.useMemo(() => {
    if (!iconConifg) return;
    return React.createElement(iconConifg, { size: 20, color: "currentColor" });
  }, [iconConifg]);

  return (
    <NavRouteLink
      className={classes.link}
      {...props}
      children={({ isActive }) => (
        <UnstyledButton className={cx(classes.root, isActive && classes.active)} sx={sx}>
          <Flex.Row align="center" gap={16}>
            {icon}
            <RpiText type="p2" weight="bold" children={name} />
          </Flex.Row>
        </UnstyledButton>
      )}
    />
  );
}
