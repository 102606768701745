import { GraphQL, GraphQLPagedQueryData, GraphQLQueryData } from "@rpi/openapi-api";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { formatCurrency } from "../../utils/misc.util";

const openApiProductColumn = createEntityColumn<GraphQL.OpenapiProductResponse, GraphQL.OpenapiProductSearchFields>();

export const OpenApiProductColumn = {
  name: openApiProductColumn<"id" | "name">({
    header: "Name",
    accessor: "name",
    sortKey: GraphQL.OpenapiProductSearchFields.Name,
    cell: ({ row }) => {
      return <CellRoute route={["open-api-product", { id: row.id }]} children={row.name} />;
    },
    size: 350,
  }),
  openApiSku: openApiProductColumn<"openapiSku">({
    header: "Open API Sku",
    accessor: "openapiSku",
    sortKey: GraphQL.OpenapiProductSearchFields.OpenapiSku,
    size: 200,
  }),
  uosSku: openApiProductColumn<"uosSku">({
    header: "UOS Sku",
    accessor: "uosSku",
    sortKey: GraphQL.OpenapiProductSearchFields.UosSku,
    size: 200,
  }),
  uosMediaOption: openApiProductColumn<"uosMediaOption">({
    header: "UOS Media Option",
    accessor: "uosMediaOption",
    sortKey: GraphQL.OpenapiProductSearchFields.UosMediaOption,
    size: 180,
  }),
  uosOrientation: openApiProductColumn<"uosOrientation">({
    header: "UOS Orientation",
    accessor: "uosOrientation",
    sortKey: GraphQL.OpenapiProductSearchFields.UosOrientation,
    size: 163,
  }),
  description: openApiProductColumn<"description">({
    header: "Description",
    accessor: "description",
    sortKey: GraphQL.OpenapiProductSearchFields.Description,
    size: 300,
  }),
};

export const openApiProductColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.OpenApiProductsQuery>> = [
  OpenApiProductColumn.name,
  OpenApiProductColumn.openApiSku,
  OpenApiProductColumn.uosSku,
  OpenApiProductColumn.uosOrientation,
  OpenApiProductColumn.uosMediaOption,
];

const openApiProductPricingColumn = createEntityColumn<GraphQL.OpenapiProductPricing>();

export const OpenApiProductPricingColumn = {
  basePrice: openApiProductPricingColumn<"basePrice" | "currency">({
    header: "Base price",
    accessor: "basePrice",
    cell: ({ row }) => formatCurrency(row.currency as GraphQL.SupportedCurrency, row.basePrice),
    size: 300,
    align: "right",
  }),
  perPageCost: openApiProductPricingColumn<"perPageCost" | "currency">({
    header: "Page cost",
    accessor: "perPageCost",
    cell: ({ row }) => formatCurrency(row.currency as GraphQL.SupportedCurrency, row.perPageCost),
    size: 300,
    align: "right",
  }),
};

export const openApiProductPricingColumns: RpiTableColumns<
  NonNullable<GraphQLQueryData<GraphQL.OpenApiProductQuery>["productPricing"]>[number]
> = [OpenApiProductPricingColumn.basePrice, OpenApiProductPricingColumn.perPageCost];
