import React from "react";
import { useAuthUser, UseAuthUserResult } from "../../hooks/useAuthUser";
import { useDevMode } from "../../hooks/useDevMode";
import { getRowSelectionColumn } from "./modules/RpiTableRowSelection";
import { getSubRowColumn } from "./modules/RpiTableSubRow";
import { RpiTableBody } from "./RpiTableBody";
import { RpiTableHead } from "./RpiTableHead";
import { RpiTableProvider, RpiTableProviderProps } from "./RpiTableProvider";
import { RpiTableVariant, useRpiTableStyles } from "./RpiTableStyles";
import { RpiTableWrapper, RpiTableWrapperProps } from "./RpiTableWrapper";

// TODO: Not working?
// export type RpiTableV2Column<T extends object> = {
//   [K in keyof T]: {
//     header: React.ReactNode;
//     accessor: K;
//     cell?: (value: { value: T[K]; row: T }) => React.ReactNode;
//   };
// }[keyof T];

export interface RpiTableColumnConfig {
  id?: string;
  header: React.ReactNode | (() => any);
  tooltip?: string;
  size?: number;
  minSize?: number;
  maxSize?: number;
  align?: "left" | "right" | "center";
  noPadding?: boolean;
}

export interface RpiTableColumn<T, S = string> extends RpiTableColumnConfig {
  accessor?: keyof T;
  cell?: (data: { row: T }) => any;
  sortKey?: S | null;
}

export type RpiTableColumns<T> = RpiTableColumn<T>[] | ((user: UseAuthUserResult) => Array<RpiTableColumn<T> | false>);

export type RpiTableAdditionalColumn<T> = RpiTableColumn<T> & { index?: number };

export type RpiTableAdditionalColumns<T> =
  | RpiTableAdditionalColumn<T>[]
  | ((user: UseAuthUserResult) => Array<RpiTableAdditionalColumn<T> | false>);

export interface RpiTableProps<T> extends Omit<RpiTableProviderProps<T>, "children"> {
  columns: RpiTableColumns<T>;
  data?: T[] | null;
  perPage?: number;
  variant?: RpiTableVariant;
  isLoading?: boolean;
  error?: any;
  emptyMessage?: RpiTableWrapperProps["emptyMessage"];
  additionalColumns?: RpiTableAdditionalColumns<T>;
}

// TODO: Move to @rpi/openapi-core
export function RpiTable<T extends object>({
  columns: initialColumns,
  data = [],
  variant = "normal",
  perPage,
  isLoading,
  error,
  emptyMessage,
  additionalColumns: initialAdditionalColumns,

  ...providerProps
}: RpiTableProps<T>) {
  const { isDevMode, DevPreview } = useDevMode();
  const user = useAuthUser();

  const { classes, cx } = useRpiTableStyles({ variant });

  const generateColums = React.useCallback(
    (cols: RpiTableColumns<T> | RpiTableAdditionalColumns<T>): RpiTableColumn<T>[] | RpiTableAdditionalColumn<T>[] => {
      if (typeof cols === "function") {
        return [...cols(user)].filter((column) => !!column) as RpiTableColumn<T>[] | RpiTableAdditionalColumn<T>[];
      }
      return [...cols];
    },
    [user]
  );

  const defaultColumns: RpiTableColumn<T>[] = React.useMemo(() => {
    const generatedColumns = generateColums(initialColumns) as RpiTableColumn<T>[];

    if (initialAdditionalColumns) {
      const additionalColumns = generateColums(initialAdditionalColumns) as RpiTableAdditionalColumn<T>[];
      additionalColumns.forEach((additionalColumn) => {
        const { index, ...column } = additionalColumn;
        if (typeof index === "number") {
          generatedColumns.splice(index, 0, column);
        } else {
          generatedColumns.push(column);
        }
      });
    }

    return generatedColumns;
  }, [initialColumns, initialAdditionalColumns, user]);

  const columns: RpiTableColumn<T>[] = React.useMemo(() => {
    if (!providerProps.getRowId || (!providerProps.withRowSelection && !providerProps.withSubRow)) {
      return defaultColumns;
    }

    const updatedColumns = defaultColumns.slice();

    if (providerProps.withRowSelection) {
      updatedColumns.unshift(getRowSelectionColumn<T>());
    }

    if (providerProps.withSubRow) {
      updatedColumns.unshift(getSubRowColumn<T>());
    }

    return updatedColumns;
  }, [defaultColumns, providerProps, user]);

  const tableHead = React.useMemo(() => <RpiTableHead columns={columns} />, [columns]);
  const tableBody = React.useMemo(
    () => <RpiTableBody columns={columns} data={data || []} perPage={perPage} />,
    [data, columns, perPage]
  );

  if (isDevMode) return <DevPreview data={data} />;

  return (
    <RpiTableWrapper dataSize={data?.length} isLoading={isLoading} error={error} emptyMessage={emptyMessage}>
      <RpiTableProvider {...providerProps}>
        <table className={cx(classes.table, classes.variant)}>
          {tableHead}
          {tableBody}
        </table>
      </RpiTableProvider>
    </RpiTableWrapper>
  );
}
