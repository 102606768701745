import React from "react";
import { RpiTableRowSelectionProvider, RpiTableRowSelectionProviderProps } from "./modules/RpiTableRowSelection";
import { RpiTableSortByProvider, RpiTableSortByProviderProps } from "./modules/RpiTableSortBy";
import { RpiTableSubRowProvider, RpiTableSubRowProviderProps } from "./modules/RpiTableSubRow";
import { RpiTable } from "./RpiTable";

interface RpiTableContextProps<T> {
  getRowId?: (row: T) => string;
}

export const RpiTableContext = React.createContext<RpiTableContextProps<any> | null>(null);

export function useRpiTable<T>() {
  const context = React.useContext(RpiTableContext) as RpiTableContextProps<T> | null;
  if (!context) {
    throw new Error(`${useRpiTable.name} must be used within ${RpiTableProvider.name}`);
  }

  return context;
}

export interface RpiTableProviderProps<T> extends RpiTableContextProps<T> {
  children?: React.ReactNode;
  withSortBy?: Omit<RpiTableSortByProviderProps, "children">;
  withRowSelection?: Omit<RpiTableRowSelectionProviderProps<T>, "children">;
  withSubRow?: Omit<RpiTableSubRowProviderProps<T>, "children">;
}

export function RpiTableProvider<T extends object>({
  children,
  getRowId,
  withSortBy,
  withRowSelection,
  withSubRow,
}: RpiTableProviderProps<T>): JSX.Element {
  // TODO: any
  const providers = React.useMemo(() => {
    return [
      {
        element: RpiTableSortByProvider,
        props: withSortBy,
      },
      {
        element: RpiTableRowSelectionProvider,
        props: withRowSelection,
      },
      {
        element: RpiTableSubRowProvider,
        props: withSubRow,
      },
    ].reduce((previous, { element, props }: any) => {
      if (!props) return previous;
      return React.createElement<T>(element, { ...props, children: previous });
    }, children);
  }, [children, withSortBy, withRowSelection, withSubRow]);

  React.useEffect(() => {
    if (!getRowId) {
      if (withRowSelection || withSubRow) {
        console.warn(`${RpiTable.name} requires "getRowId" to use "withRowSelection" and "withSubRow".`);
      }
    }
  }, [getRowId, withRowSelection, withSubRow]);

  return <RpiTableContext.Provider value={{ getRowId }} children={providers} />;
}
