export const PublicUrl = {
  DOCS_FIRST_REQUEST: `${process.env.REACT_APP_DOCS_URL}/documentation/first-request`,
  DOCS_FAQS: `${process.env.REACT_APP_DOCS_URL}/support-main#faqs`,
  TERMS_AND_CONDITIONS: `${process.env.REACT_APP_DOCS_URL}/terms_and_conditions`,
  PRIVACY_POLICY: `https://www.rpiprint.com/compliance`,
};

export const InternalUrl = {
  PDF_PROCESSING_URL: `${process.env.REACT_APP_PDF_PROCESSING_INTERNAL_URL}`,
};

export const PublicEmail = {
  SUPPORT: process.env.REACT_APP_SUPPORT_EMAIL,
};
