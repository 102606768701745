import { GraphQL, GraphQLPagedQueryData } from "@rpi/openapi-api";
import { CellDate } from "../../components/cells/CellDate";
import { CellRoute } from "../../components/cells/CellLink";
import { CellStatus } from "../../components/cells/CellStatus";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";

const printAssetColumn = createEntityColumn<GraphQL.PrintAssetResponse, GraphQL.PrintAssetSearchFields>();

export const PrintAssetColumn = {
  uploadId: printAssetColumn<"id" | "printAssetUploadId">({
    header: "Upload ID",
    accessor: "printAssetUploadId",
    sortKey: GraphQL.PrintAssetSearchFields.CustomerPublicId,
    cell: ({ row }) => {
      if (!row.printAssetUploadId) return;
      return <CellRoute route={["print-asset", { id: row.id }]} children={row.printAssetUploadId} />;
    },
    size: 350,
  }),
  customer: printAssetColumn<"customer">({
    header: "Customer",
    accessor: "customer",
    sortKey: GraphQL.PrintAssetSearchFields.CustomerPublicId,
    cell: ({ row }) => {
      if (!row.customer?.publicId) return;
      return <CellRoute route={["customer", { publicId: row.customer.publicId }]} children={row.customer.publicId} />;
    },
    size: 200,
  }),
  status: printAssetColumn<"status">({
    header: "Status",
    accessor: "status",
    sortKey: GraphQL.PrintAssetSearchFields.Status,
    cell: ({ row }) => <CellStatus.PrintAsset status={row.status} />,
    size: 150,
    align: "right",
  }),
  updatedAt: printAssetColumn<"updatedAt">({
    header: "Updated",
    accessor: "updatedAt",
    sortKey: GraphQL.PrintAssetSearchFields.UpdatedAt,
    cell: ({ row }) => <CellDate date={row.updatedAt} />,
    size: 200,
  }),
};

export const printAssetColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.PrintAssetsQuery>> = (user) => [
  PrintAssetColumn.uploadId,
  user.is.SUPER_ADMIN && PrintAssetColumn.customer,
  PrintAssetColumn.status,
  PrintAssetColumn.updatedAt,
];
