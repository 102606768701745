import { useForm } from "@mantine/form";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { RpiButton, RpiDrawerNumberInput, RpiDrawerSelectInput, RpiDrawerTextInput } from "@rpi/openapi-core";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { createDrawerOverlay, RpiDrawer, RpiDrawerForm } from "../../rpi-core/drawer/RpiDrawer";
import { formatCurrency } from "../../utils/misc.util";
import { shouldRetry } from "../../utils/query.util";

export const OrderRefundView = ({ opened, onClose }: { opened: boolean; onClose: () => void }) => {
  const { id: stringId } = useParams();
  const id = React.useMemo(() => stringId && Number.parseInt(stringId), []);

  const [closing, setClosing] = React.useState(false);

  const handleClose = React.useCallback(
    (delay?: boolean) => {
      if (closing) return;

      if (delay) {
        setClosing(true);
        return setTimeout(() => {
          onClose();
          setClosing(false);
        }, 1000);
      }
      return onClose();
    },
    [onClose, closing]
  );

  useEffect(() => {
    return () => {
      setClosing(false);
    };
  }, []);

  React.useEffect(() => {
    if (!stringId) handleClose();
  }, [stringId]);

  const { data, isLoading, error } = GraphQL.useOrderQuery(
    { id: id || -1 },
    {
      enabled: !!stringId && opened,
      refetchOnMount: true,
      retry: shouldRetry(handleClose),
      cacheTime: 0,
    }
  );
  const order = React.useMemo(() => data?.order, [data]);

  return (
    <RpiDrawer title="Refund order" isLoading={isLoading} error={error} opened={opened} onClose={handleClose}>
      {order && <RefundOrder order={order} onClose={() => handleClose(true)} />}
    </RpiDrawer>
  );
};

interface RefundOrderProps {
  order: GraphQLQueryData<GraphQL.OrderQuery>;
  onClose: () => void;
}

const RefundOrder: React.FC<RefundOrderProps> = ({ order, onClose }) => {
  const { mutateAsync: refundOrder, reset, ...mutation } = GraphQL.useAdminRefundOrderMutation<{ message: string }>();
  const orderTotal = React.useMemo(() => order.pricing?.orderTotal || 0, [order.pricing]);
  const shippingTotal = React.useMemo(() => order.pricing?.shippingTotal || 0, [order.pricing]);
  const remainingRefund = React.useMemo(() => order.remainingPossibleRefund || 0, [order.remainingPossibleRefund]);

  const shippingOption = {
    label: "Shipping only",
    value: GraphQL.BraintreeRefundStrategy.ShippingOnly,
  };
  const selectOptions = [
    {
      label: "Partial refund",
      value: GraphQL.BraintreeRefundStrategy.OrderPartialValue,
    },
    {
      label: "Partial percentage refund",
      value: GraphQL.BraintreeRefundStrategy.OrderPercentageZeroToOne,
    },
  ];

  let defaultSelectedStrategy = GraphQL.BraintreeRefundStrategy.OrderPartialValue;
  if (order.canDoFullRefund) {
    selectOptions.push({
      label: "Full refund",
      value: GraphQL.BraintreeRefundStrategy.OrderFullValue,
    });
    defaultSelectedStrategy = GraphQL.BraintreeRefundStrategy.OrderFullValue;
  }
  if (shippingTotal < remainingRefund) {
    selectOptions.push(shippingOption);
  }

  const [refundStrategy, setRefundStrategy] = React.useState<GraphQL.BraintreeRefundStrategy>(defaultSelectedStrategy);

  const form = useForm<{
    partialValue: number;
    percentage: number;
  }>({
    initialValues: {
      partialValue: order.remainingPossibleRefund || 0,
      percentage: (remainingRefund / orderTotal) * 100,
    },
    validate: {},
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async ({ partialValue, percentage }: typeof form.values) => {
      if (!order.customer?.publicId) {
        throw new Error("Missing customer public id.");
      }

      switch (refundStrategy) {
        case GraphQL.BraintreeRefundStrategy.OrderFullValue:
          await refundOrder({
            customerPublicId: order.customer.publicId,
            input: {
              refundStrategy,
              customerOrderId: order.customerOrderId,
            },
          });
          break;

        case GraphQL.BraintreeRefundStrategy.OrderPartialValue:
          await refundOrder({
            customerPublicId: order.customer.publicId,
            input: {
              refundStrategy,
              refundStrategyValue: typeof partialValue === "string" ? Number.parseFloat(partialValue) : partialValue,
              customerOrderId: order.customerOrderId,
            },
          });
          break;

        case GraphQL.BraintreeRefundStrategy.OrderPercentageZeroToOne:
          await refundOrder({
            customerPublicId: order.customer.publicId,
            input: {
              refundStrategy,
              refundStrategyValue: (typeof percentage === "string" ? Number.parseFloat(percentage) : percentage) / 100,
              customerOrderId: order.customerOrderId,
            },
          });
          break;

        case GraphQL.BraintreeRefundStrategy.ShippingOnly:
          await refundOrder({
            customerPublicId: order.customer.publicId,
            input: {
              refundStrategy,
              customerOrderId: order.customerOrderId,
            },
          });
          break;
      }

      onClose();
    },
    [refundOrder, order, onClose, refundStrategy]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Refunding order...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to refund order...",
          description: "Please try again later.",
        },
        success: {
          message: "Order refunded!",
        },
      })(mutation),
    [mutation]
  );

  const refundAmount = React.useMemo(() => {
    switch (refundStrategy) {
      case GraphQL.BraintreeRefundStrategy.OrderFullValue:
        return remainingRefund;

      case GraphQL.BraintreeRefundStrategy.OrderPartialValue:
        return form.values.partialValue;

      case GraphQL.BraintreeRefundStrategy.OrderPercentageZeroToOne:
        return Math.round(orderTotal * (form.values.percentage || 0)) / 100;

      case GraphQL.BraintreeRefundStrategy.ShippingOnly:
        return shippingTotal;
      default:
        return 0;
    }
  }, [orderTotal, form.values, refundStrategy]);

  return (
    <RpiDrawerForm overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerTextInput disabled label="Customer Order ID" value={order.customerOrderId || ""} />
      <RpiDrawerSelectInput
        label="Strategy"
        data={selectOptions}
        value={refundStrategy}
        onChange={(value) => setRefundStrategy(value as GraphQL.BraintreeRefundStrategy)}
      />

      {refundStrategy === GraphQL.BraintreeRefundStrategy.OrderPartialValue && (
        <RpiDrawerNumberInput
          required
          label={`Amount (${order.currency})`}
          min={0}
          max={remainingRefund}
          step="0.01"
          {...form.getInputProps("partialValue")}
        />
      )}

      {refundStrategy === GraphQL.BraintreeRefundStrategy.OrderPercentageZeroToOne && (
        <RpiDrawerNumberInput
          required
          label="Percentage (%)"
          min={0}
          max={(remainingRefund / orderTotal) * 100}
          step="0.01"
          {...form.getInputProps("percentage")}
        />
      )}

      <RpiButton
        type="submit"
        variant="mellow"
        mt={24}
        width="full"
        children={`Refund ${formatCurrency(order.currency, refundAmount || 0)}`}
      />
    </RpiDrawerForm>
  );
};
