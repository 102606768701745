import { useForm } from "@mantine/form";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { RpiCheckbox, RpiDrawerNumberInput, RpiDrawerTextInput } from "@rpi/openapi-core";
import React from "react";
import { useParams } from "react-router-dom";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../../rpi-core/drawer/RpiDrawer";
import { shouldRetry } from "../../../utils/query.util";
import { validateEmail } from "../../../utils/validation.util";

export const CustomerEditView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const { customerPublicId } = useParams();

    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    React.useEffect(() => {
      if (!customerPublicId) handleClose();
    }, [customerPublicId]);

    const { data, isLoading, error } = GraphQL.useCustomerQuery(
      { customerPublicId: customerPublicId || "" },
      {
        enabled: !!customerPublicId && opened,
        refetchOnMount: true,
        retry: shouldRetry(handleClose),
        cacheTime: 0,
      }
    );
    const customer = React.useMemo(() => data?.customer, [data]);

    return (
      <RpiDrawer title="Edit customer" isLoading={isLoading} error={error} opened={opened} onClose={handleClose}>
        {customer && <EditUser customer={customer} onClose={() => handleClose(true)} />}
      </RpiDrawer>
    );
  }
);

type EditUserForm = Omit<NonNullable<GraphQL.AdminEditCustomerMutationVariables["input"]>, "ordersLimit"> &
  NonNullable<GraphQL.AdminEditCustomerMutationVariables["input"]>["ordersLimit"];

interface EditUserProps {
  customer: GraphQLQueryData<GraphQL.CustomerQuery>;
  onClose: () => void;
}

const EditUser: React.FC<EditUserProps> = ({ customer, onClose }) => {
  const { mutateAsync: editCustomer, reset, ...mutation } = GraphQL.useAdminEditCustomerMutation<{ message: string }>();

  const form = useForm<EditUserForm>({
    initialValues: {
      publicId: customer.publicId || "",
      email: customer.email || "",
      emailUpdates: customer.emailUpdates,
      maxOrders: customer.settings?.ordersLimit?.maxOrders || 0,
      limitQueueSize: customer.settings?.ordersLimit?.limitQueueSize || 0,
      timeZone: "", // TODO
    },
    validate: {
      email: validateEmail,
    },
  });

  React.useEffect(() => {
    if (form.values.maxOrders > form.values.limitQueueSize) {
      form.setFieldValue("maxOrders", form.values.limitQueueSize);
    }
  }, [form.values]);

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async ({ maxOrders, limitQueueSize, ...rest }: typeof form.values) => {
      await editCustomer({ input: { ...rest, ordersLimit: { maxOrders, limitQueueSize } } });
      onClose();
    },
    [editCustomer, customer, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Editing customer...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to edit customer...",
          description: "Please try again later.",
        },
        success: {
          message: "Customer edited!",
        },
      })(mutation),
    [mutation]
  );

  return (
    <RpiDrawerForm button="Save changes" overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerTextInput disabled label="Public ID" {...form.getInputProps("publicId")} />
      <RpiDrawerTextInput label="Contact email" {...form.getInputProps("email")} />
      <RpiDrawerNumberInput
        label="Daily order count"
        min={0}
        max={form.values.limitQueueSize}
        {...form.getInputProps("maxOrders")}
      />
      <RpiDrawerNumberInput
        label="Over-limit order count"
        min={0}
        max={10000}
        {...form.getInputProps("limitQueueSize")}
      />
      <RpiCheckbox mt={8} label="Email notifications" {...form.getInputProps("emailUpdates")} />
    </RpiDrawerForm>
  );
};
