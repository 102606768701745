import { sleep } from "./misc.util";
import { isFunction } from "./type.util";

type RetryCallback<TData, TArgs extends any[]> = (...args: TArgs) => TData | Promise<TData>;

interface RetryOptions {
  retry: number | ((retryCount: number, error: any) => boolean | Promise<boolean>);
  retryDelay?: number;
}

export function retry<TData, TArgs extends any[]>(callback: RetryCallback<TData, TArgs>, options: RetryOptions) {
  return async (...args: TArgs) => {
    let retryCount = 0;
    let retry = false;

    do {
      try {
        return await Promise.resolve(callback(...args));
      } catch (error) {
        if (isFunction(options.retry)) {
          retry = await options.retry(retryCount, error);
        } else {
          retry = retryCount < options.retry - 1;
        }

        if (!retry) throw error;

        if (options.retryDelay) await sleep(options.retryDelay);

        retryCount++;
      }
    } while (retry);
  };
}
