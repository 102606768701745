import { GraphQL } from "@rpi/openapi-api";
import { Flex, RpiButton } from "@rpi/openapi-core";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { RpiPagination, RpiPaginationRow } from "../../../components/RpiPagination";
import { RpiSearchFilter } from "../../../components/search-filter/RpiSearchFilter";
import { customerColumns } from "../../../configuration/columns/customer.column";
import { customerFilters } from "../../../configuration/filters/customer.filter";
import { useNavigateRoute } from "../../../hooks/useNavigateRoute";
import { useRpiPagination } from "../../../hooks/usePagination";
import { RpiPerPage } from "../../../hooks/useRpiPerPage";
import { RpiTable } from "../../../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../../../utils/graphql.util";

export const CustomersView: React.FC = () => {
  const navigateRoute = useNavigateRoute();
  const outlet = useOutlet();

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.AdminSearchCustomersQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.AdminSearchCustomersQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.AdminSearchCustomersQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const { data, isLoading, error, refetch } = GraphQL.useAdminSearchCustomersQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.adminSearchCustomers.totalCount),
    }
  );
  const customers = React.useMemo(() => data?.adminSearchCustomers.data, [data]);

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  return (
    <AppPage path={["Customers"]} onReload={refetch}>
      {outlet}

      <AppPage.Section>
        <Flex.Row justify="space-between" align="flex-end">
          <RpiSearchFilter
            placeholder="Search by Public ID (or Email)"
            filters={customerFilters}
            onChange={setSearchInputs}
            onSearch={setSearchGeneral}
          />

          <RpiButton
            width="fit"
            variant="brand-outline"
            size="small"
            children="Add new customer"
            trailingIcon
            onClick={() => navigateRoute(["customer:create"])}
          />
        </Flex.Row>

        <RpiTable
          emptyMessage={
            searchInputs?.length || searchGeneral ? "No customers found." : "Your customers will appear here."
          }
          isLoading={isLoading}
          error={error}
          perPage={pagination.perPage}
          data={customers}
          columns={customerColumns}
          withSortBy={{ onChange: setSortInput }}
        />

        <RpiPaginationRow>
          <RpiPagination pagination={pagination} />
          <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
        </RpiPaginationRow>
      </AppPage.Section>
    </AppPage>
  );
};
