import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { GraphQL } from "@rpi/openapi-api";
import { IconV2, RpiButton, RpiCheckbox, RpiDrawerSelectInput, RpiDrawerTextInput } from "@rpi/openapi-core";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { getBraintreeDeviceData } from "../../../hooks/useBraintree";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../../rpi-core/drawer/RpiDrawer";
import { countrySelectData } from "../../../utils/country.util";
import { shouldRetry } from "../../../utils/query.util";

export const PaymentMethodEditView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const { token } = useParams();
    const user = useAuthUser();

    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    React.useEffect(() => {
      if (!token) handleClose();
    }, [token]);

    const { data, isLoading, error } = GraphQL.useListPaymentMethodsQuery(
      {
        customerPublicId: user.id!,
      },
      {
        enabled: !!user.id && opened,
        refetchOnMount: true,
        retry: shouldRetry(handleClose),
        cacheTime: 0,
      }
    );
    const paymentMethod = React.useMemo(() => {
      if (!data) return;

      const paymentMethod = data.listPaymentMethods.find((pm) => pm.token === token);
      if (!paymentMethod) {
        handleClose();
        return;
      }

      return paymentMethod;
    }, [data, handleClose]);

    return (
      <RpiDrawer title="Edit payment method" isLoading={isLoading} error={error} opened={opened} onClose={handleClose}>
        {paymentMethod && <EditPaymentMethod paymentMethod={paymentMethod} onClose={() => handleClose(true)} />}
      </RpiDrawer>
    );
  }
);

interface EditPaymentMethodProps {
  paymentMethod: GraphQL.BraintreePaymentMethodResponse;
  onClose: () => void;
}

const EditPaymentMethod: React.FC<EditPaymentMethodProps> = ({ paymentMethod, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [debouncedCountrySearch] = useDebouncedValue(countrySearch, 250);

  const {
    mutateAsync: editPaymentMethod,
    reset,
    ...mutation
  } = GraphQL.useEditPaymentMethodMutation<{ message: string }>();

  const form = useForm<Omit<GraphQL.BraintreePaymentMethodEditInput, "deviceDataFromClient">>({
    initialValues: {
      token: paymentMethod.token || "",
      cardholderName: paymentMethod.cardholderName,
      streetAddress: paymentMethod.address || "",
      city: paymentMethod.city,
      state: paymentMethod.state,
      country: paymentMethod.countryIso2,
      makeDefault: paymentMethod.isDefault,
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      setIsLoading(true);
      const deviceData = await getBraintreeDeviceData().catch((error) => {
        console.error(error);
        return undefined;
      });

      await editPaymentMethod({ input: { ...input, deviceDataFromClient: deviceData } });
      setIsLoading(false);
      onClose();
    },
    [editPaymentMethod, paymentMethod, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Editing payment method...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to edit payment method...",
          description: "Please try again later.",
        },
        success: {
          message: "Payment method edited!",
        },
      })(mutation),
    [mutation]
  );

  const filteredCountrySelectData = React.useMemo(() => {
    if (!debouncedCountrySearch || debouncedCountrySearch.length === 0) return countrySelectData;
    const lowercaseDebounced = debouncedCountrySearch.toLowerCase();
    return countrySelectData.filter(({ label }) => label.toLowerCase().includes(lowercaseDebounced));
  }, [debouncedCountrySearch]);

  return (
    <RpiDrawerForm overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerTextInput label="Name on card" {...form.getInputProps("cardholderName")} />
      <RpiDrawerTextInput required label="Address" {...form.getInputProps("streetAddress")} />
      <RpiDrawerTextInput label="Town/City" {...form.getInputProps("city")} />
      {form.values?.country === "US" && <RpiDrawerTextInput label="State" {...form.getInputProps("state")} />}
      <RpiDrawerSelectInput
        label="Country"
        data={filteredCountrySelectData}
        {...form.getInputProps("country")}
        searchable
        onSearch={setCountrySearch}
      />

      <RpiCheckbox
        label="Default payment method"
        defaultChecked={form.values.makeDefault}
        mt={24}
        variant="radio"
        {...form.getInputProps("makeDefault")}
      />

      <RpiButton
        type="submit"
        variant="mellow"
        width="full"
        mt={24}
        icon={IconV2.LockSolid}
        isLoading={isLoading}
        children="Save changes"
      />
    </RpiDrawerForm>
  );
};
