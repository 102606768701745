export namespace RpiFilterV2 {
  namespace Config {
    export interface Base<TField> {
      type: "text" | "number" | "select" | "dropdown" | "date";
      label: string;
      field: TField;
    }

    export interface Text<TField> extends Base<TField> {
      type: "text";
    }

    export interface Select<TField> extends Base<TField> {
      type: "select";
      data: Array<string | { value: string; label?: string }>;
    }

    export interface Dropdown<TField> extends Base<TField> {
      type: "dropdown";
      data: Array<string | { value: string; label: string }>;
      onSearch?: (value: string) => void;
      isLoading?: boolean;
    }

    export interface Date<TField> extends Base<TField> {
      type: "date";
    }
  }

  export type Config<TField> =
    | Config.Text<TField>
    | Config.Select<TField>
    | Config.Dropdown<TField>
    | Config.Date<TField>;

  export const createConfigs = <TFields, T = TFields[]>(array: { [I in keyof T]: Config<T[I]> }) => array;
}
