import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";

const orderItemColumn = createEntityColumn<GraphQL.OrderItemResponse>();

export const OrderItemColumn = {
  id: orderItemColumn<"id" | "orderId">({
    header: "ID",
    accessor: "id",
    cell: ({ row }) => <CellRoute route={["order:item", { orderId: row.orderId, itemId: row.id }]} children={row.id} />,
    minSize: 100,
    size: 150,
  }),
  quantity: orderItemColumn<"quantity">({
    header: "Quantity",
    accessor: "quantity",
    size: 120,
  }),
  orderItemStatus: orderItemColumn<"orderItemStatus">({
    header: "Status",
    accessor: "orderItemStatus",
    minSize: 100,
    size: 250,
  }),
  openapiProduct: orderItemColumn<"openapiProduct">({
    header: "Open Api Product",
    accessor: "openapiProduct",
    cell: ({ row }) => {
      if (!row.openapiProduct?.id) return;
      return (
        <CellRoute
          route={["open-api-product", { id: row.openapiProduct.id }]}
          children={row.openapiProduct.name || row.openapiProduct.id}
        />
      );
    },
    minSize: 200,
    size: 300,
  }),
  customerProduct: orderItemColumn<"customerProduct">({
    header: "Product",
    accessor: "customerProduct",
    cell: ({ row }) => {
      if (!row.customerProduct?.id) return;

      return (
        <CellRoute
          route={["customer-product", { id: row.customerProduct.id }]}
          children={row.customerProduct.name || row.customerProduct.id}
        />
      );
    },
    minSize: 200,
    size: 300,
  }),
};

export const orderItemColumns: RpiTableColumns<
  NonNullable<GraphQLQueryData<GraphQL.OrderQuery>["orderItems"]>[number]
> = (user) => [
  OrderItemColumn.id,
  OrderItemColumn.quantity,
  OrderItemColumn.orderItemStatus,
  OrderItemColumn.customerProduct,
  user.is.SUPER_ADMIN && OrderItemColumn.openapiProduct,
];
