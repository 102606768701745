import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { Rest } from "@rpi/openapi-api";
import { Flex, RpiButton, RpiMultilineInput, RpiText, RpiTextInput } from "@rpi/openapi-core";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { validateEmail, validateLength } from "../../utils/validation.util";

export const ShareIdeaPage: React.FC = () => {
  const { mutateAsync: createShareIdea, ...mutation } = useMutation({
    mutationFn: Rest.createShareIdea,
  });

  const form = useForm<Rest.ShareIdeaRequest>({
    initialValues: {
      contactEmail: "",
      contactName: "",
      message: "",
    },
    validate: {
      contactEmail: validateEmail,
      contactName: validateLength("Name", { min: 1, max: 64 }),
      message: validateLength("Message", { min: 5, max: 2048 }),
    },
  });

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      return await Rest.createShareIdea(input)
        .then(() => {
          showNotification({
            title: `Idea shared.`,
            message: "",
            color: "green",
          });
          form.reset();
        })
        .catch((error) => {
          showNotification({
            title: "Failed to send idea.",
            message: error.message,
            color: "red",
          });
          form.reset();
        });
    },
    [createShareIdea]
  );

  return (
    <Flex.Column
      justify="center"
      align="center"
      fullWidth
      p={80}
      sx={(theme) => ({ background: theme.colors.brand[5] })}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex.Column align="flex-start" fullWidth sx={{ maxWidth: 640 }}>
          <RpiText
            type="h2"
            weight="bold"
            mb={24}
            sx={(theme) => ({ color: theme.colors.sky[5], fontSize: 64, lineHeight: "64px" })}
            children="Share your idea"
          />
          <RpiText
            type="p1"
            weight="regular"
            mb={36}
            sx={(theme) => ({ color: theme.colors.sky[5] })}
            children="Don't see a product you want? Fill out the form and send us what you would like to see in our product offering."
          />
          <Flex.Column gap={16} fullWidth mb={16}>
            <Flex.Row fullWidth gap={16}>
              <RpiTextInput fullWidth placeholder="Name" {...form.getInputProps("contactName")} />
              <RpiTextInput fullWidth placeholder="Email" {...form.getInputProps("contactEmail")} />
            </Flex.Row>
            <RpiMultilineInput fullWidth placeholder="Message" rows={3} {...form.getInputProps("message")} />
          </Flex.Column>

          <RpiButton type="submit" variant="mellow" trailingIcon isLoading={mutation.isLoading} children="Submit" />
        </Flex.Column>
      </form>
    </Flex.Column>
  );
};
