import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { useParams } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { PdfPreview } from "../../components/preview/pdf/PdfPreview";
import { RpiSingleTable } from "../../components/tables/RpiSingleTable";
import { orderItemRows } from "../../configuration/rows/order.row";
import { getRoutePath } from "../../hooks/useNavigateRoute";

export const OrderItemView: React.FC = () => {
  const { orderId, itemId } = useParams();

  const { data, isLoading, error, refetch } = GraphQL.useOrderItemQuery({
    id: Number.parseInt(itemId!),
  });
  const orderItem = React.useMemo(() => data?.orderItem, [data]);

  return (
    <AppPage
      path={[
        { name: "Orders", to: getRoutePath("orders") },
        orderId && { name: orderId, to: getRoutePath("order", { id: Number.parseInt(orderId) }) },
        "Items",
        itemId,
      ]}
      onReload={refetch}
    >
      <AppPage.Section title="Data">
        <RpiSingleTable isLoading={isLoading} error={error} data={orderItem} rows={orderItemRows} />
      </AppPage.Section>

      {orderItem?.printAsset?.preflightOutputFiles && (
        // TODO add proper preview from asset-service
        <AppPage.Section mt={32} title="PDF Preview" description="by pdf.js">
          <PdfPreview
            coverUrl={orderItem.printAsset.preflightOutputFiles.cover?.srcUrl}
            gutsUrl={orderItem.printAsset.preflightOutputFiles.guts?.srcUrl}
            gutsBleeds={orderItem?.openapiProduct?.productSpecification?.processingSettings.guts_page_bleeds}
          />
        </AppPage.Section>
      )}
    </AppPage>
  );
};
