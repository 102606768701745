import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import React from "react";
import { useParams } from "react-router-dom";
import { createRpiDrawerView, RpiDrawer, RpiDrawerProps } from "../../../rpi-core/drawer/RpiDrawer";
import { RpiDrawerField } from "../../../rpi-core/drawer/RpiDrawerField";
import { formatCurrency } from "../../../utils/misc.util";
import { shouldRetry } from "../../../utils/query.util";

export const OpenApiProductView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const { id: stringId } = useParams();
    const id = React.useMemo(() => stringId && Number.parseInt(stringId), []);

    React.useEffect(() => {
      if (!stringId) onClose();
    }, [stringId]);

    const { data, isLoading, error } = GraphQL.useOpenApiProductQuery(
      { id: id || -1 },
      {
        enabled: !!stringId && opened,
        refetchOnMount: true,
        retry: shouldRetry(onClose),
      }
    );
    const openApiProduct = React.useMemo(() => data?.openapiProduct, [data]);

    return (
      <RpiDrawer title="Open API Product details" isLoading={isLoading} error={error} opened={opened} onClose={onClose}>
        {openApiProduct && <OpenApiProductInfo openApiProduct={openApiProduct} />}
      </RpiDrawer>
    );
  }
);

interface OpenApiProductInfoProps {
  openApiProduct: GraphQLQueryData<GraphQL.OpenApiProductQuery>;
}

const OpenApiProductInfo: React.FC<OpenApiProductInfoProps> = ({ openApiProduct }) => {
  const { basePrice, pageCost } = React.useMemo(() => {
    if (!openApiProduct.productPricing || openApiProduct.productPricing.length === 0) {
      return {};
    }

    // TODO: Selectable currency?
    const pricing = openApiProduct.productPricing[0];
    return {
      basePrice: formatCurrency(pricing.currency as GraphQL.SupportedCurrency, pricing.basePrice),
      pageCost: formatCurrency(pricing.currency as GraphQL.SupportedCurrency, pricing.perPageCost),
    };
  }, [openApiProduct.productPricing]);

  return (
    <>
      <RpiDrawerField label="Name" children={openApiProduct.name} />
      <RpiDrawerField label="Description" children={openApiProduct.description} />
      <RpiDrawerField label="Base Price" children={basePrice} />
      <RpiDrawerField label="Page Cost" children={pageCost} />
      <RpiDrawerField label="Open API Sku" children={openApiProduct.openapiSku} />
      <RpiDrawerField label="UOS Sku" children={openApiProduct.uosSku} />
      <RpiDrawerField label="UOS Orientation" children={openApiProduct.uosOrientation} />
      <RpiDrawerField label="UOS Media Option" children={openApiProduct.uosMediaOption} />
    </>
  );
};
