import { CognitoUserAmplify } from "@aws-amplify/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { getUserId, getUserRole } from "../utils/auth.util";

export interface UseAuthUserResult extends Pick<ReturnType<typeof useAuthenticator>, "signOut"> {
  cognitoUser: CognitoUserAmplify;
  id?: string;
  role?: GraphQL.DashboardUserRole;
  hasRole: (userRole: GraphQL.DashboardUserRole) => boolean;
  is: {
    USER: boolean;
    SUPER_ADMIN: boolean;
    CUSTOMER_ADMIN: boolean;
  };
}

export const useAuthUser = (): UseAuthUserResult => {
  const { user: cognitoUser, signOut } = useAuthenticator((context) => [context.user]);

  const id = React.useMemo(() => getUserId(cognitoUser), [cognitoUser]);

  const role = React.useMemo(() => getUserRole(cognitoUser), [cognitoUser]);

  const hasRole = React.useCallback((userRole: GraphQL.DashboardUserRole) => userRole === role, [role]);

  const is: UseAuthUserResult["is"] = React.useMemo(
    () => ({
      USER: hasRole(GraphQL.DashboardUserRole.User),
      SUPER_ADMIN: hasRole(GraphQL.DashboardUserRole.SuperAdmin),
      CUSTOMER_ADMIN: hasRole(GraphQL.DashboardUserRole.CustomerAdmin),
    }),
    [hasRole]
  );

  return React.useMemo(
    () => ({
      cognitoUser,
      id,
      role,
      hasRole,
      is,
      signOut,
    }),
    [cognitoUser, signOut, id, role, hasRole, is]
  );
};
