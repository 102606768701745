import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { CellDate } from "../../components/cells/CellDate";
import { CellJson } from "../../components/cells/CellJson";
import { CellLink, CellRoute } from "../../components/cells/CellLink";
import { CellStatus } from "../../components/cells/CellStatus";
import { RpiSingleTableRows } from "../../components/tables/RpiSingleTable";
import { formatCurrency } from "../../utils/misc.util";

export const orderRows: RpiSingleTableRows<GraphQLQueryData<GraphQL.OrderQuery>> = (user) => [
  user.is.SUPER_ADMIN && {
    header: "Customer",
    accessor: (data) => data.customer?.publicId,
  },
  {
    header: "Customer Order ID",
    accessor: "customerOrderId",
  },
  {
    header: "Status",
    accessor: (data) => <CellStatus.PublicOrder status={data.publicOrderStatus} />,
  },
  {
    header: "Parent Order ID (if this is a reprint)",
    accessor: (data) => {
      if (!data.reprintParentOrderId) return;
      return <CellRoute route={["order", { id: data.reprintParentOrderId }]} children={data.reprintParentOrderId} />;
    },
  },
  {
    header: "Created At",
    accessor: (data) => <CellDate date={data.createdAt} />,
  },
  {
    header: "Updated At",
    accessor: (data) => <CellDate date={data.updatedAt} />,
  },
  {
    header: "Submitted At",
    accessor: (data) => <CellDate date={data.submittedAt} />,
  },
  {
    header: "Payment Mode",
    accessor: "paymentMode",
  },
  {
    header: "Payment Status",
    accessor: (data) => <CellStatus.Payment status={data.paymentStatus} />,
  },
  {
    header: "Payment",
    accessor: (data) => {
      if (!data.salePayment) return;
      return <CellRoute route={["payment", { id: data.salePayment.id }]} children={data.salePayment.id} />;
    },
  },
  {
    header: "Currency",
    accessor: "currency",
  },
  {
    header: "Last Status Message",
    accessor: "lastStatusMessage",
  },
  user.is.SUPER_ADMIN && {
    header: "Validation Result",
    accessor: (data) => <CellJson data={data.validationResult || {}} />,
  },
];

export const refundOrderRows: RpiSingleTableRows<NonNullable<GraphQLQueryData<GraphQL.PaymentQuery>["refundedOrder"]>> =
  [
    {
      header: "Order ID",
      accessor: (data) => {
        return <CellRoute route={["order", { id: data.id }]} children={data.customerOrderId} />;
      },
    },
    {
      header: "Status",
      accessor: (data) => <CellStatus.PublicOrder status={data.publicOrderStatus} />,
    },
    {
      header: "Created At",
      accessor: (data) => <CellDate date={data.createdAt} />,
    },
    {
      header: "Updated At",
      accessor: (data) => <CellDate date={data.updatedAt} />,
    },
    {
      header: "Submitted At",
      accessor: (data) => <CellDate date={data.submittedAt} />,
    },
    {
      header: "Order total",
      accessor: (data) => formatCurrency(data.currency, data.pricing?.orderTotal || 0),
    },
    // TODO uncomment when backend returns single order response
    // {
    //   header: "Remaining available refund",
    //   accessor: (data) => formatCurrency(data.currency, data.remainingPossibleRefund || 0),
    // },
    {
      header: "Last Status Message",
      accessor: "lastStatusMessage",
    },
  ];

// Order Item
export const orderItemRows: RpiSingleTableRows<GraphQLQueryData<GraphQL.OrderItemQuery>> = (user) => [
  {
    header: "Quantity",
    accessor: "quantity",
  },
  {
    header: "Page Count",
    accessor: "pagecount",
  },
  {
    header: "Status",
    accessor: "orderItemStatus",
  },
  {
    header: "Print Asset",
    accessor: ({ printAsset }) =>
      printAsset && <CellRoute route={["print-asset", { id: printAsset.id }]} children={printAsset.id} />,
  },
  {
    header: "Product",
    accessor: ({ customerProduct }) =>
      customerProduct && (
        <CellRoute
          route={["customer-product", { id: customerProduct.id }]}
          children={customerProduct.name || customerProduct.id}
        />
      ),
  },
  {
    header: "Pricing Algorithm",
    accessor: "pricingAlgorithm",
  },
  {
    header: "Cover URI",
    accessor: ({ coverUri }) => coverUri && <CellLink sx={{ wordWrap: "break-word" }} to={coverUri} external />,
  },
  {
    header: "Guts URI",
    accessor: ({ gutsUri }) => gutsUri && <CellLink sx={{ wordWrap: "break-word" }} to={gutsUri} external />,
  },
  {
    header: "Image URI",
    accessor: ({ imageUri }) => imageUri && <CellLink sx={{ wordWrap: "break-word" }} to={imageUri} external />,
  },
  user.is.SUPER_ADMIN && {
    header: "Validation Result",
    accessor: (data) => <CellJson data={data.validationResult || {}} />,
  },
  user.is.SUPER_ADMIN && {
    header: "Pricing Details",
    accessor: (data) => <CellJson data={data.pricingDetails || {}} />,
  },
];

export const orderDestinationRows: RpiSingleTableRows<
  NonNullable<GraphQLQueryData<GraphQL.OrderQuery>["destination"]>
> = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Shipping method code",
    accessor: (data) =>
      data.shippingMethodCode && data.shippingMethodCode !== "undefined" ? data.shippingMethodCode : "",
  },
  {
    header: "Shipping details",
    accessor: (data) => <CellJson data={data.shippingDetails || {}} />,
  },
];

export const orderDestinationAddressRows: RpiSingleTableRows<
  NonNullable<NonNullable<GraphQLQueryData<GraphQL.OrderQuery>["destination"]>["address"]>
> = [
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Company",
    accessor: "company",
  },
  {
    header: "Address 1",
    accessor: "address1",
  },
  {
    header: "Address 2",
    accessor: "address2",
  },
  {
    header: "Address 3",
    accessor: "address3",
  },
  {
    header: "Postal",
    accessor: "postalCode",
  },
  {
    header: "City",
    accessor: "city",
  },
  {
    header: "Region ISO code",
    accessor: "regionIso",
  },
  {
    header: "County ISO code",
    accessor: "countryIso",
  },
  {
    header: "Phone",
    accessor: "phone",
  },
  {
    header: "Email",
    accessor: "email",
  },
];
