import { createStyles, Drawer, DrawerProps, Loader, useMantineTheme } from "@mantine/core";
import { isGraphQLError } from "@rpi/openapi-api";
import { Flex, IconV2, RpiButton, RpiIconButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { flushSync } from "react-dom";
import { To } from "react-router-dom";
import { useDevMode } from "../../hooks/useDevMode";
import { useNavigateRoute } from "../../hooks/useNavigateRoute";
import { optObj } from "../../utils/misc.util";

export interface RpiDrawerProps extends Omit<DrawerProps, "classNames" | "id"> {
  isLoading?: boolean;
  error?: any;
  overlay?: Omit<RpiDrawerOverlayProps, "overlay">;
}

const useRpiDrawerStyles = createStyles(() => {
  return {
    overlay: {
      opacity: `1 !important`,
      backgroundColor: "rgba(92, 92, 92, 0.67) !important",
    },
    drawer: {
      width: 362,
      padding: "0px !important",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      alignItems: "flex-start",
      width: "100%",
      padding: "32px 16px !important",
      margin: 0,
    },
    title: {
      marginRight: 0,
      width: "100%",
    },
    closeButton: {},
  };
});

export const RpiDrawer: React.FC<RpiDrawerProps> = ({
  title,
  onClose,
  isLoading,
  error,
  overlay,
  children,
  ...props
}) => {
  const devMode = useDevMode();
  const { classes } = useRpiDrawerStyles();

  const childrenRender = React.useMemo(() => {
    if (overlay) return;

    if (error) {
      return (
        <RpiDrawerOverlay
          overlay={false}
          type="error"
          message={isGraphQLError(error) ? error.message : "Failed to load."}
        />
      );
    }

    if (isLoading) {
      return <RpiDrawerOverlay overlay={false} type="loading" />;
    }

    if (devMode.isDevMode) return <devMode.DevPreview fromChildren={children} />;

    return children;
  }, [isLoading, error, overlay, children, devMode]);

  return (
    <Drawer
      classNames={classes}
      position="right"
      withCloseButton={false}
      onClose={onClose}
      title={
        <Flex.Row gap={56} fullWidth justify="space-between" align="flex-start">
          <RpiText
            type="h2"
            weight="bold"
            color={(theme) => theme.colors.brand[5]}
            sx={{ lineHeight: "28.75px" }}
            children={title || ""}
          />
          <RpiIconButton sx={{ outline: "none !important" }} size={14} icon={IconV2.X} onClick={onClose} />
        </Flex.Row>
      }
      {...props}
    >
      {!overlay && (
        <Flex.Column px={16} pb={32} gap={16} sx={{ flex: 1, overflowY: "auto" }} children={childrenRender} />
      )}
      {overlay && <RpiDrawerOverlay overlay={true} {...overlay} />}
    </Drawer>
  );
};

export interface RpiDrawerOverlayProps {
  type: "loading" | "success" | "error";
  message?: string;
  description?: string;
  overlay?: boolean;
}

export const RpiDrawerOverlay: React.FC<RpiDrawerOverlayProps> = ({ type, message, description, overlay = true }) => {
  const { colors } = useMantineTheme();

  const icon = React.useMemo(() => {
    switch (type) {
      case "loading":
        return <Loader color={colors.calm[5]} size={100} />;
      case "success":
        return <IconV2.Success color={colors.calm[5]} size={{ height: 100 }} />;
      case "error":
        return <IconV2.ErrorOutline color={colors.spark[5]} size={{ height: 100 }} />;
    }
  }, [type]);

  return (
    <Flex.Column
      justify="center"
      align="center"
      gap={8}
      px={16}
      py={32}
      sx={[
        (theme) => ({
          background: theme.colors.sky[5],
          width: "100%",
          height: "100%",
        }),
        optObj(overlay, {
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 100,
        }),
      ]}
    >
      <Flex mb={16} children={icon} />

      {message && (
        <RpiText
          type="p2"
          weight="regular"
          color={(theme) => theme.colors.brand[5]}
          sx={{ textAlign: "center" }}
          children={message}
        />
      )}

      {description && (
        <RpiText
          type="error"
          weight="regular"
          color={(theme) => theme.colors.brand[4]}
          sx={{ textAlign: "center" }}
          children={description}
        />
      )}
    </Flex.Column>
  );
};

export function createDrawerOverlay<
  T extends {
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
  }
>(data: Partial<Record<"loading" | "error" | "success", Omit<RpiDrawerOverlayProps, "type">>>) {
  return (state: T): Omit<RpiDrawerOverlayProps, "overlay"> | undefined => {
    if (state.isLoading && data.loading) return { type: "loading", ...data.loading };
    if (state.isError && data.error) return { type: "error", ...data.error };
    if (state.isSuccess && data.success) return { type: "success", ...data.success };
    return undefined;
  };
}

export interface RpiDrawerFormProps
  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  button?: string;
  overlay?: Omit<RpiDrawerOverlayProps, "overlay">;
  disabled?: boolean;
}

export const RpiDrawerForm = React.forwardRef<HTMLFormElement, RpiDrawerFormProps>(
  ({ children, button, overlay, disabled, ...props }, ref) => {
    if (overlay) return <RpiDrawerOverlay overlay {...overlay} />;

    return (
      <form ref={ref} {...props}>
        <Flex.Column gap={8}>
          {children}
          {button && (
            <RpiButton
              type="submit"
              variant="mellow"
              disabled={disabled}
              mt={24}
              width="full"
              trailingIcon
              children={button}
            />
          )}
        </Flex.Column>
      </form>
    );
  }
);

export function createRpiDrawerView<TParams extends object = { opened: boolean; onClose: () => void }>(
  drawer: React.FC<TParams>
): React.FC<Omit<TParams, "opened" | "onClose"> & { redirect?: To }> {
  return ({ redirect = "../", ...props }) => {
    const [opened, setOpened] = React.useState(false);
    const navigateRoute = useNavigateRoute();

    React.useEffect(() => setOpened(true), []);

    const handleClose = React.useCallback(() => {
      flushSync(() => {
        setOpened(false);
      });

      setTimeout(() => {
        navigateRoute(redirect, { state: undefined });
      }, 125);
    }, []);

    return React.createElement(drawer, { opened, onClose: handleClose, ...props } as TParams);
  };
}
