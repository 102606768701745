import { CognitoUserAmplify } from "@aws-amplify/ui";
import { GraphQL } from "@rpi/openapi-api";
import { useMatches } from "react-router-dom";

export const getUserRole = (cognitoUser: CognitoUserAmplify): GraphQL.DashboardUserRole => {
  const cognitoRole = cognitoUser?.attributes?.["custom:Role"];
  if (!cognitoRole) return GraphQL.DashboardUserRole.User;

  if (/user/gi.test(cognitoRole)) return GraphQL.DashboardUserRole.User;
  if (/customer(_|\s)*admin/gi.test(cognitoRole)) return GraphQL.DashboardUserRole.CustomerAdmin;
  if (/super(_|\s)*admin/gi.test(cognitoRole)) return GraphQL.DashboardUserRole.SuperAdmin;

  return GraphQL.DashboardUserRole.User;
};

export const getUserId = (cognitoUser: CognitoUserAmplify): string | undefined => {
  return cognitoUser?.attributes?.["custom:RPI-OpenAPI-UserId"];
};

export const hasRequiredRole = (
  requiredRole: GraphQL.DashboardUserRole | GraphQL.DashboardUserRole[],
  cognitoUser: CognitoUserAmplify
): boolean => {
  const isRoleArray = Array.isArray(requiredRole);
  if (isRoleArray && requiredRole.length === 0) return true;

  const userRole = getUserRole(cognitoUser);
  if (!userRole) return false;

  if (isRoleArray) return requiredRole.includes(userRole);
  return requiredRole === userRole;
};

export type MatchHandle =
  | {
      requiredRole?: GraphQL.DashboardUserRole | GraphQL.DashboardUserRole[];
    }
  | undefined;

export const canViewPage = (matches: ReturnType<typeof useMatches>, cognitoUser: CognitoUserAmplify): boolean => {
  for (const match of matches) {
    const handle = match.handle as MatchHandle;
    if (handle?.requiredRole && !hasRequiredRole(handle?.requiredRole, cognitoUser)) {
      return false;
    }
  }

  return true;
};
