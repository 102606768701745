import { useDebouncedValue } from "@mantine/hooks";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { RpiFilterV2 } from "../../components/search-filter/RpiSearchFilterTypes";
import { useAuthUser } from "../../hooks/useAuthUser";
import { optArray } from "../../utils/misc.util";

export const useUploadedFilesFilter = () => {
  const user = useAuthUser();

  const [customersSearch, setCustomersSearch] = React.useState("");
  const [debouncedCustomersSearch] = useDebouncedValue(customersSearch, 250);

  const { data, isLoading } = GraphQL.useAdminListCustomersQuery(
    { input: debouncedCustomersSearch },
    { enabled: user.is.SUPER_ADMIN }
  );
  const customers = React.useMemo(() => (data?.adminListCustomers || []).map((customer) => customer.publicId), [data]);

  return React.useMemo(() => {
    return RpiFilterV2.createConfigs<GraphQL.UploadedFileSearchFields>([
      ...optArray<RpiFilterV2.Config<GraphQL.UploadedFileSearchFields>>(user.is.SUPER_ADMIN, [
        {
          type: "dropdown",
          label: "Customer",
          field: GraphQL.UploadedFileSearchFields.CustomerPublicId,
          data: customers,
          onSearch: setCustomersSearch,
          isLoading: isLoading,
        },
      ]),
      {
        type: "select",
        label: "Status",
        field: GraphQL.UploadedFileSearchFields.Status,
        data: Object.values(GraphQL.UploadedFileStatus),
      },
      {
        type: "text",
        label: "Collection",
        field: GraphQL.UploadedFileSearchFields.CollectionId,
      },
      {
        type: "text",
        label: "Filename",
        field: GraphQL.UploadedFileSearchFields.FileName,
      },
      {
        type: "text",
        label: "Content type",
        field: GraphQL.UploadedFileSearchFields.FileType,
      },
      {
        type: "date",
        label: "Created at",
        field: GraphQL.UploadedFileSearchFields.CreatedAt,
      },
      {
        type: "date",
        label: "Updated at",
        field: GraphQL.UploadedFileSearchFields.UpdatedAt,
      },
    ]);
  }, [user.is, customers, isLoading]);
};
