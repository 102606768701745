import { createStyles } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { ConfirmationModal } from "./ConfirmationModal";
import { TaxReviewModal } from "./TaxReviewModal";

export const useRpiModalStyles = createStyles((theme) => {
  return {
    overlay: {
      opacity: `1 !important`,
      backgroundColor: "rgba(92, 92, 92, 0.67) !important",
    },
    title: {
      ...theme.other.fontTypes.h2,
      fontWeight: theme.other.fontWeights.bold,
      color: theme.colors.brand[5],
    },
    modal: {
      padding: "24px !important",
      borderRadius: 24,
    },
    header: {
      marginBottom: 16,
    },
    close: {},
  };
});

export const modals = {
  confirmation: ConfirmationModal,
  taxReview: TaxReviewModal,
};

interface RpiModalsProviderProps {
  children: React.ReactNode;
}

export const RpiModalsProvider: React.FC<RpiModalsProviderProps> = ({ children }) => {
  const { classes } = useRpiModalStyles();
  return (
    <ModalsProvider
      modals={modals}
      modalProps={{ classNames: classes, withCloseButton: false, centered: true }}
      children={children}
    />
  );
};
