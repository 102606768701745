import { useForm } from "@mantine/form";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { RpiDrawerTextInput } from "@rpi/openapi-core";
import React from "react";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../rpi-core/drawer/RpiDrawer";
import { shouldRetry } from "../../utils/query.util";

export const SettingsEditAccountView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    const { data, isLoading, error } = GraphQL.useDashboardUserCurrentQuery(
      {},
      {
        enabled: opened,
        refetchOnMount: true,
        retry: shouldRetry(handleClose),
        cacheTime: 0,
      }
    );
    const dashboardUser = React.useMemo(() => data?.dashboardUserCurrent, [data]);

    return (
      <RpiDrawer title="Edit account" isLoading={isLoading} error={error} opened={opened} onClose={handleClose}>
        {dashboardUser && <EditAccount dashboardUser={dashboardUser} onClose={() => handleClose(true)} />}
      </RpiDrawer>
    );
  }
);

interface EditAccountProps {
  dashboardUser: GraphQLQueryData<GraphQL.DashboardUserCurrentQuery>;
  onClose: () => void;
}

const EditAccount: React.FC<EditAccountProps> = ({ dashboardUser, onClose }) => {
  const {
    mutateAsync: editDashboardUser,
    reset,
    ...mutation
  } = GraphQL.useEditDashboardUserMutation<{ message: string }>();

  const form = useForm<GraphQL.EditDashboardUserMutationVariables["input"]>({
    initialValues: {
      firstName: dashboardUser.firstName || "",
      lastName: dashboardUser.lastName || "",
      username: dashboardUser.username || "",
      role: dashboardUser.role || GraphQL.DashboardUserRole.User,
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      await editDashboardUser({ id: dashboardUser.id, input });
      onClose();
    },
    [editDashboardUser, dashboardUser, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Editing account...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to edit account...",
          description: "Please try again later.",
        },
        success: {
          message: "Account edited!",
        },
      })(mutation),
    [mutation]
  );

  return (
    <RpiDrawerForm button="Save changes" overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerTextInput label="First Name" {...form.getInputProps("firstName")} />
      <RpiDrawerTextInput label="Last Name" {...form.getInputProps("lastName")} />
    </RpiDrawerForm>
  );
};
