import { Flex } from "@rpi/openapi-core";
import React from "react";
import { useRpiTableSubRow } from "./modules/RpiTableSubRow";
import { RpiTableColumn } from "./RpiTable";
import { justifyFromAlign } from "./RpiTableHelpers";
import { useRpiTable } from "./RpiTableProvider";

interface RpiTableBodyProps<T> {
  columns: RpiTableColumn<T>[];
  data: T[];
  perPage?: number;
}

export function RpiTableBody<T>({ columns, data, perPage }: RpiTableBodyProps<T>): React.ReactElement {
  const emptyRows = React.useMemo(() => {
    if (!perPage || data.length > perPage) return;

    const count = perPage - data.length;
    if (count <= 0) return;

    const emptyCells = Array(columns.length + 1)
      .fill(0)
      .map((_, i) => <td key={`rpi-table-empty-row-td-${i}`} />);

    return Array(count)
      .fill(0)
      .map((_, i) => <tr key={`rpi-table-empty-row-${i}`} children={emptyCells} />);
  }, [columns.length, data.length, perPage]);

  return (
    <tbody>
      {data.map((row, i) => (
        <RpiTableRow key={`rpi-table-row-${i}`} columns={columns} row={row} />
      ))}
      {emptyRows}
    </tbody>
  );
}

interface RpiTableRowProps<T> {
  columns: RpiTableColumn<T>[];
  row: T;
}

export function RpiTableRow<T>({ row, columns }: RpiTableRowProps<T>): React.ReactElement {
  const { getRowId } = useRpiTable<T>();
  const withSubRow = useRpiTableSubRow<T>();

  const subRowRender = React.useMemo(() => {
    if (!withSubRow || !getRowId) return;

    const showSubRow = withSubRow.subRowSelection?.[getRowId(row)];
    if (!showSubRow) return;

    const subRowRender = withSubRow.renderSubRow(row);
    if (!subRowRender) return;

    return (
      <tr>
        <td className="subRow" colSpan={columns.length + 1} children={subRowRender} />
      </tr>
    );
  }, [columns, row, getRowId, withSubRow]);

  return (
    <>
      <tr>
        {columns.map((column, i) => (
          <RpiTableCell key={`rpi-table-td-${i}`} row={row} column={column} />
        ))}
        <td />
      </tr>
      {subRowRender}
    </>
  );
}

interface RpiTableCellProps<T> {
  column: RpiTableColumn<T>;
  row: T;
}

function RpiTableCell<T>({ row, column }: RpiTableCellProps<T>): React.ReactElement {
  const cellRender = React.useMemo(() => {
    if (!column.accessor && !column.cell) return "";
    const value = column.accessor ? row[column.accessor] : undefined;
    const render = column.cell ? React.createElement(column.cell, { row }) : (value as React.ReactNode);

    if (column.align && column.align !== "left") {
      return (
        <Flex.Row justify={justifyFromAlign(column.align)} sx={{ minWidth: 0 }} gap={4}>
          <div className="ellipsis" children={render} />
        </Flex.Row>
      );
    }

    return render;
  }, [row, column]);

  return (
    <td
      style={{
        ...(column.align && { textAlign: column.align }),
        ...(column.noPadding && { padding: "0px" }),
      }}
      children={cellRender}
    />
  );
}
