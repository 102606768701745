import { GraphQL, GraphQLPagedQueryData, GraphQLQueryData } from "@rpi/openapi-api";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { dashboardUserRoleLabel } from "../../utils/graphql.util";
import { CellDate } from "../../components/cells/CellDate";

const dashboardUserColumn = createEntityColumn<GraphQL.DashboardUserResponse, GraphQL.DashboardUserSearchFields>();

export const DashboardUserColumn = {
  username: dashboardUserColumn<"username" | "id">({
    header: "Username",
    accessor: "username",
    sortKey: GraphQL.DashboardUserSearchFields.Username,
    cell: ({ row }) => {
      return <CellRoute route={["dashboard-user", { id: row.id }]} children={row.username} />;
    },
    size: 200,
  }),
  customer: dashboardUserColumn<"customerPublicId">({
    header: "Customer",
    accessor: "customerPublicId",
    sortKey: GraphQL.DashboardUserSearchFields.CustomerPublicId,
    cell: ({ row }) => {
      if (!row.customerPublicId) return;
      return <CellRoute route={["customer", { publicId: row.customerPublicId }]} children={row.customerPublicId} />;
    },
    size: 200,
  }),
  email: dashboardUserColumn<"email">({
    header: "Email",
    accessor: "email",
    sortKey: GraphQL.DashboardUserSearchFields.Email,
    size: 300,
  }),
  role: dashboardUserColumn<"role">({
    header: "Role",
    accessor: "role",
    sortKey: GraphQL.DashboardUserSearchFields.Role,
    cell: ({ row }) => dashboardUserRoleLabel(row.role),
    size: 150,
  }),
  status: dashboardUserColumn<"disabled">({
    header: "Status",
    accessor: "disabled",
    sortKey: GraphQL.DashboardUserSearchFields.Disabled,
    cell: ({ row }) => (row.disabled ? "Inactive" : "Active"),
  }),
  createdAt: dashboardUserColumn<"createdAt">({
    header: "Created At",
    accessor: "createdAt",
    sortKey: GraphQL.DashboardUserSearchFields.CreatedAt,
    cell: ({ row }) => <CellDate date={row.createdAt} />,
    size: 170,
  }),
};

export const dashboardUserColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.DashboardUsersQuery>> = (user) => [
  DashboardUserColumn.username,
  user.is.SUPER_ADMIN && DashboardUserColumn.customer,
  DashboardUserColumn.email,
  DashboardUserColumn.role,
  DashboardUserColumn.status,
  DashboardUserColumn.createdAt,
];

export const customerDashboardUserColumns: RpiTableColumns<
  NonNullable<NonNullable<GraphQLQueryData<GraphQL.CustomerQuery>["dashboardUsers"]>[number]>
> = [
  DashboardUserColumn.username,
  DashboardUserColumn.email,
  DashboardUserColumn.role,
  DashboardUserColumn.status,
  DashboardUserColumn.createdAt,
];
