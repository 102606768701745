import { useMantineTheme } from "@mantine/core";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import React from "react";
import Chart from "react-apexcharts";

interface UseDailyMetricsGraphReturnType {
  graph: Omit<React.ComponentProps<typeof Chart>, "width" | "height">;
  data: GraphQLQueryData<GraphQL.DailyMetricsQuery>;
}

export const useDailyMetricsGraph = (variables: GraphQL.DailyMetricsQueryVariables): UseDailyMetricsGraphReturnType => {
  const theme = useMantineTheme();

  const { data } = GraphQL.useDailyMetricsQuery(variables);
  const dailyMetrics = React.useMemo(() => data?.dailyMetrics, [data]);

  const series = React.useMemo(
    () => [
      {
        name: "Orders",
        type: "line",
        data: (dailyMetrics?.days || []).map((dailyMetric) => dailyMetric.numCreatedOrders),
        color: theme.colors.spark[5],
      },
    ],
    [theme, dailyMetrics?.days]
  );

  const options = React.useMemo(
    () => ({
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 1,
      },
      fill: {
        opacity: 1,
      },
      grid: {
        borderColor: theme.colors.sky[8],
        padding: {
          top: -22,
          right: 8,
        },
      },
      markers: {
        size: 2,
        strokeWidth: 0,
        hover: {
          size: 2,
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: (dailyMetrics?.days || []).map((m) => m.createdDay),
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          offsetX: -8,
          style: {
            fontFamily: "sofia-pro",
            fontSize: "14px",
            fontWeight: 400,
            colors: [theme.colors.text[3]],
          },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          show: true,
          format: "MM/dd/yyyy",
        },
      },
    }),
    [theme, dailyMetrics?.days]
  );

  return React.useMemo(() => ({ graph: { series, options }, data: dailyMetrics }), [series, options, dailyMetrics]);
};
