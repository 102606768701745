import { DefaultMantineColor, MantineTheme, useMantineTheme } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";

type CellStatusColors = Record<
  "INACTIVE" | "PENDING" | "PROCESSING" | "COMPLETED" | "ERROR",
  (theme: MantineTheme) => { background: DefaultMantineColor; foreground: DefaultMantineColor }
>;

type CellStatusConfig<T extends string> = Record<
  T,
  {
    name?: string;
    color: CellStatusColors["INACTIVE"];
  }
>;

type CellStatusType<T extends string> = React.FC<{ status: CellStatusBaseProps<T>["status"] }>;

interface CellStatusBaseProps<T extends string> {
  status?: T | null;
  configs: CellStatusConfig<T>;
}

function CellStatusBase<T extends string>({ status, configs }: CellStatusBaseProps<T>): React.ReactElement {
  const theme = useMantineTheme();

  const config = status && configs[status];
  if (!status || !config) return <></>;

  const color = config.color(theme);

  return (
    <Flex.Row
      align="center"
      px={8}
      sx={{
        borderRadius: 11,
        background: color.background,
        maxWidth: "fit-content",
        height: 20,
        flexWrap: "nowrap",
      }}
    >
      <RpiText
        type="p3"
        weight="light"
        color={color.foreground}
        className="text-ellipsis"
        sx={{ textTransform: "capitalize" }}
        pb={2} /* Font has top padding */
        children={config.name || status.toLowerCase()}
      />
    </Flex.Row>
  );
}

const StatusColor: CellStatusColors = {
  INACTIVE: (theme) => ({ background: theme.colors.calm[5], foreground: theme.colors.text[5] }),
  PENDING: (theme) => ({ background: theme.colors.mellow[5], foreground: theme.colors.text[5] }),
  PROCESSING: (theme) => ({ background: theme.colors.brand[5], foreground: theme.colors.sky[5] }),
  COMPLETED: (theme) => ({ background: theme.colors.amethyst[5], foreground: theme.colors.sky[5] }),
  ERROR: (theme) => ({ background: theme.colors.spark[5], foreground: theme.colors.sky[5] }),
};

/* Order */
export const orderStatusConfig: CellStatusConfig<GraphQL.OrderStatus> = {
  [GraphQL.OrderStatus.AssetsProcessed]: {
    name: "Assets Processed",
    color: StatusColor.PROCESSING,
  },
  [GraphQL.OrderStatus.CancellationAttempted]: {
    name: "Cancellation Attempted",
    color: StatusColor.PENDING,
  },
  [GraphQL.OrderStatus.Cancelled]: {
    color: StatusColor.COMPLETED,
  },
  [GraphQL.OrderStatus.CancellationRequestSentToPrinter]: {
    name: "Cancellation Request Sent To Printer",
    color: StatusColor.COMPLETED,
  },
  [GraphQL.OrderStatus.CancelledBeforeSubmission]: {
    name: "Cancellation Before Submission",
    color: StatusColor.COMPLETED,
  },
  [GraphQL.OrderStatus.Created]: {
    color: StatusColor.INACTIVE,
  },
  [GraphQL.OrderStatus.Exception]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.OrderStatus.Failed]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.OrderStatus.PricingCompleted]: {
    name: "Pricing Completed",
    color: StatusColor.PROCESSING,
  },
  [GraphQL.OrderStatus.Received]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.OrderStatus.ReceivedOverLimit]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.OrderStatus.Rejected]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.OrderStatus.Shipped]: {
    color: StatusColor.COMPLETED,
  },
  [GraphQL.OrderStatus.Submitted]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.OrderStatus.Submitting]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.OrderStatus.WaitingForSubmission]: {
    name: "Waiting For Submission",
    color: StatusColor.PENDING,
  },
  [GraphQL.OrderStatus.WaitingInHoldingBin]: {
    name: "Waiting In Holding Bin",
    color: StatusColor.PENDING,
  },
  [GraphQL.OrderStatus.WaitingForTaxes]: {
    name: "Waiting For Taxes",
    color: StatusColor.PENDING,
  },
};

const CellStatusOrder: CellStatusType<GraphQL.OrderStatus> = ({ status }) => (
  <CellStatusBase status={status} configs={orderStatusConfig} />
);

/* Order Item */
export const publicOrderStatusConfig: CellStatusConfig<GraphQL.PublicOrderStatus> = {
  [GraphQL.PublicOrderStatus.Received]: {
    color: StatusColor.INACTIVE,
  },
  [GraphQL.PublicOrderStatus.Validating]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.PublicOrderStatus.ValidHoldingBin]: {
    name: "Holding Bin",
    color: StatusColor.PROCESSING,
  },
  [GraphQL.PublicOrderStatus.ValidAwaitingPayment]: {
    name: "Awaiting Payment",
    color: StatusColor.PROCESSING,
  },
  [GraphQL.PublicOrderStatus.Printing]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.PublicOrderStatus.Shipped]: {
    color: StatusColor.COMPLETED,
  },
  [GraphQL.PublicOrderStatus.Failed]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.PublicOrderStatus.Cancelled]: {
    color: StatusColor.PENDING,
  },
  [GraphQL.PublicOrderStatus.CancellationRequested]: {
    name: "Cancellation Requested",
    color: StatusColor.PENDING,
  },
  [GraphQL.PublicOrderStatus.Exception]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.PublicOrderStatus.Queued]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.PublicOrderStatus.Rejected]: {
    color: StatusColor.ERROR,
  },
};

const CellStatusPublicOrder: CellStatusType<GraphQL.PublicOrderStatus> = ({ status }) => (
  <CellStatusBase status={status} configs={publicOrderStatusConfig} />
);

/* Print Asset */
export const printAssetStatusConfig: CellStatusConfig<GraphQL.PrintAssetStatus> = {
  [GraphQL.PrintAssetStatus.Invalid]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.PrintAssetStatus.AwaitingInput]: {
    name: "Awaiting Input",
    color: StatusColor.PENDING,
  },
  [GraphQL.PrintAssetStatus.Enqueued]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.PrintAssetStatus.Processing]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.PrintAssetStatus.Success]: {
    color: StatusColor.COMPLETED,
  },
  [GraphQL.PrintAssetStatus.Failure]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.PrintAssetStatus.Purged]: {
    color: StatusColor.COMPLETED,
  },
};

const CellStatusPrintAsset: CellStatusType<GraphQL.PrintAssetStatus> = ({ status }) => (
  <CellStatusBase status={status} configs={printAssetStatusConfig} />
);

/* Payment */
export const paymentStatusConfig: CellStatusConfig<GraphQL.PaymentStatus> = {
  [GraphQL.PaymentStatus.ExternallyPaid]: {
    name: "Externally Paid",
    color: StatusColor.PROCESSING,
  },
  [GraphQL.PaymentStatus.Paid]: {
    color: StatusColor.INACTIVE,
  },
  [GraphQL.PaymentStatus.Unpaid]: {
    color: StatusColor.ERROR,
  },
  [GraphQL.PaymentStatus.NoPaymentStaffOrder]: {
    color: StatusColor.INACTIVE,
  },
};

const CellStatusPayment: CellStatusType<GraphQL.PaymentStatus> = ({ status }) => (
  <CellStatusBase status={status} configs={paymentStatusConfig} />
);

/* Uploaded File */
export const uploadedFileStatusConfig: CellStatusConfig<GraphQL.UploadedFileStatus> = {
  [GraphQL.UploadedFileStatus.Started]: {
    color: StatusColor.PROCESSING,
  },
  [GraphQL.UploadedFileStatus.Finished]: {
    color: StatusColor.COMPLETED,
  },
  [GraphQL.UploadedFileStatus.Failure]: {
    color: StatusColor.ERROR,
  },
};

const CellStatusUploadedFile: CellStatusType<GraphQL.UploadedFileStatus> = ({ status }) => (
  <CellStatusBase status={status} configs={uploadedFileStatusConfig} />
);

export const CellStatus = {
  Order: CellStatusOrder,
  PublicOrder: CellStatusPublicOrder,
  PrintAsset: CellStatusPrintAsset,
  Payment: CellStatusPayment,
  UploadedFile: CellStatusUploadedFile,
};
