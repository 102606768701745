import { CustomSelect, CustomSelectItemProps, CustomSelectWrapper } from "./SelectUtils";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { Group, Text } from "@mantine/core";

type SelectOpenapiProvidedShippingClassificationType = GraphQL.OpenapiProvidedShippingClassification;

const SelectOpenapiProvidedShippingClassificationItem = React.forwardRef<
  HTMLDivElement,
  CustomSelectItemProps<SelectOpenapiProvidedShippingClassificationType>
>(({ label, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap spacing="xs">
      <Text size="sm" children={label} />
    </Group>
  </div>
));

export const SelectOpenapiProvidedShippingClassification: CustomSelectWrapper<
  SelectOpenapiProvidedShippingClassificationType
> = ({ getValue, ...props }) => {
  return (
    <CustomSelect
      label="Change Shipping"
      placeholder="Select shipping classification"
      nothingFound="No Shipping classifications"
      maxDropdownHeight={200}
      itemComponent={SelectOpenapiProvidedShippingClassificationItem}
      getItemProps={(role) => ({
        label: role,
        value: getValue ? getValue(role) : role,
        element: role,
      })}
      data={Object.values(GraphQL.OpenapiProvidedShippingClassification)}
      {...props}
    />
  );
};
