import { Box, createStyles, Popover } from "@mantine/core";
import { useClickOutside, useDebouncedState } from "@mantine/hooks";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, IconV2, RpiIconButton, RpiTextInput } from "@rpi/openapi-core";
import React from "react";
import { relativeTime } from "../../utils/misc.util";
import { PickPartial } from "../../utils/type.util";
import { RpiSearchFilterDisplay } from "./RpiSearchFilterDisplay";
import { RpiSearchFilterDropdown } from "./RpiSearchFilterDropdown";
import { RpiSearchDateFilterProps } from "./RpiSearchFilterInput";
import { RpiFilterV2 } from "./RpiSearchFilterTypes";

const useRpiSearchFilterStyles = createStyles((theme) => {
  return {
    dropdown: {
      display: "flex",
      borderRadius: 25,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.colors.calm[5],
      backgroundColor: theme.colors.sky[5],
      padding: 0,
      margin: 0,
      boxShadow: theme.other.boxShadows.buttonHover,
    },
    dropdownBody: {
      flex: 1,
      position: "relative",
    },
    inputWrapper: {
      height: 48,
      borderRadius: 24,
    },
    inputIcon: {
      paddingLeft: 22,
    },
    inputAction: {
      paddingRight: 22,
    },
    inputInput: {
      padding: "14px 8px !important",
      width: 234,
    },
  };
});

export interface RpiSearchFilterInput<TField> {
  field: TField;
  condition: GraphQL.SearchCondition;
  firstValue?: string;
  secondValue?: string;
  values?: string[];
}

export interface ExtendedRpiSearchFilterInput<TField> extends RpiSearchFilterInput<TField> {
  label: RpiFilterV2.Config<TField>["label"];
  type: RpiFilterV2.Config<TField>["type"];
}

export type RpiSearchFilterParser<TField> = (data: ReturnType<typeof parseInput>) => RpiSearchFilterInput<TField>[];

export interface RpiSearchFilterProps<TField> {
  filters: RpiFilterV2.Config<TField>[];
  dateRangeField?: TField;
  placeholder?: string;
  initialInputs?: RpiSearchFilterInput<TField>[];
  parser?: RpiSearchFilterParser<TField>;
  onChange?: (inputs: RpiSearchFilterInput<TField>[]) => void;
  onSearch?: (value: string) => void;
}

export function RpiSearchFilter<TField>({
  filters,
  dateRangeField,
  placeholder,
  parser,
  initialInputs,
  onChange,
  onSearch,
}: RpiSearchFilterProps<TField>): React.ReactElement {
  const { classes } = useRpiSearchFilterStyles();

  let convertedInitialInput: RpiSearchFilterInput<TField>[];
  if (initialInputs) {
    convertedInitialInput = initialInputs.map((input) => convertInput(input));
  } else {
    convertedInitialInput = [];
  }

  const [inputs, setInputs] = React.useState<ExtendedRpiSearchFilterInput<TField>[]>(
    convertedInitialInput as ExtendedRpiSearchFilterInput<TField>[]
  );
  const [dateRangeInput, setDateRangeInput] = React.useState<RpiSearchDateFilterProps<TField>["input"]>();

  const [searchValue, setSearchValue] = useDebouncedState<string>("", 250);
  const searchInputs = React.useMemo(() => {
    if (!parser || !searchValue) return null;
    return parser(parseInput(searchValue));
  }, [searchValue, parser]);

  const [opened, setOpened] = React.useState(false);

  const [dropdownRef, setDropdown] = React.useState<any>(null);
  const [controlRef, setControl] = React.useState<any>(null);

  useClickOutside(() => setOpened(false), null, [controlRef, dropdownRef]);

  React.useEffect(() => {
    if (!onChange) return;

    const convertedInputs = inputs.map((input) => convertInput(input));

    if (dateRangeInput) {
      convertedInputs.push(convertInput(dateRangeInput.input));
    }

    if (searchInputs && searchInputs.length > 0) {
      convertedInputs.push(...searchInputs);
    }

    onChange(convertedInputs);
  }, [inputs, dateRangeInput, searchInputs]);

  React.useEffect(() => {
    if (!onSearch) return;

    onSearch(searchValue);
  }, [onSearch, searchValue]);

  return (
    <Flex.Row align="flex-start" gap={16} sx={{ position: "relative", height: "fit-content" }}>
      <Popover
        classNames={{ dropdown: classes.dropdown }}
        opened={opened}
        onChange={setOpened}
        width={321}
        position="bottom"
        offset={4}
        closeOnClickOutside={false}
        closeOnEscape={true}
      >
        <Popover.Target>
          <RpiTextInput
            classNames={{
              wrapper: classes.inputWrapper,
              icon: classes.inputIcon,
              action: classes.inputAction,
              input: classes.inputInput,
            }}
            placeholder={placeholder || "Search"}
            icon={IconV2.Search}
            onChange={(e) => setSearchValue(e.target.value)}
            action={
              <RpiIconButton
                customRef={setControl}
                icon={IconV2.Filter}
                height={16}
                onClick={() => setOpened((o) => !o)}
              />
            }
          />
        </Popover.Target>

        <Popover.Dropdown>
          <Box ref={setDropdown} className={classes.dropdownBody}>
            <RpiSearchFilterDropdown
              filters={filters}
              inputs={inputs}
              onChange={(inputs) => {
                setInputs(inputs.sort((a, b) => a.label?.localeCompare(b.label)));
                setOpened(false);
              }}
              onClear={() => {
                setInputs([]);
                setOpened(false);
              }}
              {...(dateRangeField && {
                dateFilter: {
                  field: dateRangeField,
                  input: dateRangeInput,
                  setInput: setDateRangeInput,
                },
              })}
            />
          </Box>
        </Popover.Dropdown>
      </Popover>

      <Flex.Row sx={{ height: "fit-content", flex: 1, flexWrap: "wrap", columnGap: 8, rowGap: 8 }}>
        {inputs.map((input, i) => (
          <RpiSearchFilterDisplay
            key={`input-${i}`}
            onDelete={() =>
              setInputs((inputs) => {
                const copy = [...inputs];
                copy.splice(i, 1);
                return copy;
              })
            }
            input={input}
          />
        ))}
      </Flex.Row>
    </Flex.Row>
  );
}

function convertInput<TField>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  label,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  ...input
}: PickPartial<ExtendedRpiSearchFilterInput<TField>, "label" | "type">): RpiSearchFilterInput<TField> {
  return input;
}

const parseInput = (input: string) => {
  const hasTime = /^(.+\s+|\s*)\d+(h|d)\s*$/g.test(input);
  const split = input.trim().split(/\s+/);
  const relativeTime = hasTime ? split.pop() : null;

  return {
    date: parseRelativeTime(relativeTime),
    query: split.length === 1 && split[0].length === 0 ? [] : split,
  };
};

const parseRelativeTime = (input: string | null | undefined) => {
  if (!input) return null;

  const [amountString, type] = input.split(/(h|d)/);
  if (!amountString || !type) return null;

  const amount = Number.parseInt(amountString);
  if (!amount) return null;

  const hours = -1 * amount * (type === "d" ? 24 : 1);

  const date = new Date();

  try {
    return {
      from: relativeTime(hours, date).toISOString(),
      to: new Date().toISOString(),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};
