import { Rest } from "@rpi/openapi-api";
import { Flex } from "@rpi/openapi-core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { DeliveryTimelinePage } from "./DeliveryTimelinePage";
import { LandingPage } from "./LandingPage";
import { PricingPage } from "./PricingPage";
import { ProductListing } from "./ProductListing";
import { ShareIdeaPage } from "./ShareIdeaPage";
import { ShippingRatesPage } from "./ShippingRatesPage";
import { Environment } from "../../env/Environment";

export const LANDING_PAGE_MAX_WIDTH = 1124;

export const IndexView: React.FC = () => {
  const { data: priceListInfoData } = useQuery(["price-list-info"], Rest.getPriceListInfo);
  const priceListInfo = React.useMemo(() => priceListInfoData?.data, [priceListInfoData]);

  return (
    <Flex.Column sx={{ width: "100%", position: "relative", overflow: "hidden" }}>
      <LandingPage />

      <div id="product-listing">
        <ProductListing />
      </div>

      <div id="pricing">
        <PricingPage products={priceListInfo?.products} />
      </div>

      <div id="delivery-timeline">
        <DeliveryTimelinePage />
      </div>

      <div id="shipping-rates">
        <ShippingRatesPage shipping={priceListInfo?.shipping} />
      </div>

      {!Environment.isSandbox && (
        <div id="share-idea">
          <ShareIdeaPage />
        </div>
      )}
    </Flex.Column>
  );
};
