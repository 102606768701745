import { SegmentedControl } from "@mantine/core";
import { Calendar, RangeCalendar } from "@mantine/dates";
import { GraphQL } from "@rpi/openapi-api";
import {
  Flex,
  IconV2,
  RpiButton,
  RpiCheckbox,
  RpiDrawerSelectInput,
  RpiDrawerTextInput,
  RpiIconButton,
  RpiText,
} from "@rpi/openapi-core";
import React from "react";
import { relativeTime } from "../../utils/misc.util";
import { isString } from "../../utils/type.util";
import { ExtendedRpiSearchFilterInput } from "./RpiSearchFilter";
import { RpiFilterV2 } from "./RpiSearchFilterTypes";

interface RpiSearchFilterInputProps<TField> {
  filter: RpiFilterV2.Config<TField>;
  // Inputs for selected field
  inputs: ExtendedRpiSearchFilterInput<TField>[];
  onChange: (inputs: ExtendedRpiSearchFilterInput<TField>[]) => void;
}

export function RpiSearchFilterCreate<TField>({
  filter,
  inputs,
  onChange,
}: RpiSearchFilterInputProps<TField>): React.ReactElement {
  return React.useMemo(() => {
    switch (filter.type) {
      case "text":
        return <TextFilter filter={filter} inputs={inputs} onChange={onChange} />;
      case "select":
        return <SelectFilter filter={filter} inputs={inputs} onChange={onChange} />;
      case "dropdown":
        return <DropdownFilter filter={filter} inputs={inputs} onChange={onChange} />;
      case "date":
        return <DateFilter filter={filter} inputs={inputs} onChange={onChange} />;
    }
  }, [filter, inputs, onChange]);
}

interface SaveButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onClick, disabled }) => (
  <RpiButton
    variant="mellow"
    size="small"
    children="Apply"
    mt={8}
    mb={1}
    width="full"
    onClick={onClick}
    disabled={disabled}
    sx={{
      borderRadius: 13,
      paddingTop: "12px !important",
      paddingBottom: "12px !important",
      height: "auto",
    }}
  />
);

export function TextFilter<TField>({
  filter,
  inputs,
  onChange,
}: RpiSearchFilterInputProps<TField>): React.ReactElement {
  const [condition, setCondition] = React.useState<GraphQL.SearchCondition>(GraphQL.SearchCondition.Contains);
  const [value, setValue] = React.useState<string>();

  if (filter.type !== "text") return <></>;

  return (
    <Flex.Column gap={8} px={8}>
      <SegmentedControl
        styles={(theme) => ({
          root: {
            background: theme.colors.calm[3],
            borderRadius: 6,
          },
          label: {
            ...theme.other.fontTypes.p3,
            fontWeight: theme.other.fontWeights.regular,
            color: theme.colors.brand[5],
          },
          labelActive: {
            color: theme.colors.text[5],
          },
        })}
        fullWidth
        value={condition}
        onChange={(condition) => setCondition(condition as GraphQL.SearchCondition)}
        data={[
          { label: "Contains", value: GraphQL.SearchCondition.Contains },
          { label: "Equals", value: GraphQL.SearchCondition.Equals },
          { label: "Not Equals", value: GraphQL.SearchCondition.NotEquals },
        ]}
      />
      <RpiDrawerTextInput label={filter.label} value={value || ""} onChange={(e) => setValue(e.target.value)} />

      <SaveButton
        disabled={!value}
        onClick={() =>
          onChange([
            ...inputs,
            {
              field: filter.field,
              condition,
              firstValue: value,
              label: filter.label,
              type: filter.type,
            },
          ])
        }
      />
    </Flex.Column>
  );
}

export function SelectFilter<TField>({
  filter,
  inputs,
  onChange,
}: RpiSearchFilterInputProps<TField>): React.ReactElement {
  const [selected, setSelected] = React.useState(() => {
    const data: Record<string, boolean> = {};
    inputs.forEach((input) => {
      input.values?.forEach((value) => (data[value] = true));
    });
    return data;
  });

  if (filter.type !== "select") return <></>;

  return (
    <Flex.Column px={8} gap={8} sx={{ flex: 1, overflow: "hidden", position: "relative" }}>
      <Flex.Column gap={8} sx={{ flex: 1, overflowY: "auto", overflowX: "hidden", position: "relative" }}>
        {filter.data.map((element, i) => {
          let value: string, label: string;
          if (typeof element === "string") {
            value = element;
            label = element;
          } else {
            value = element.value;
            label = element.label || element.value;
          }

          return (
            <RpiCheckbox
              key={`select-filter-item-${i}-${value}`}
              checked={!!selected[value]}
              onChange={(e) => setSelected((selected) => ({ ...selected, [value]: !!e.target.checked }))}
              label={label}
              styles={{
                root: {
                  maxWidth: 245,
                },
                labelWrapper: {
                  display: "flex",
                  width: "100%",
                },
                label: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
            />
          );
        })}
      </Flex.Column>

      <SaveButton
        onClick={() => {
          const values: string[] = [];
          Object.keys(selected).forEach((key) => {
            if (selected[key]) values.push(key);
          });

          onChange(
            values.length === 0
              ? []
              : [
                  {
                    field: filter.field,
                    condition: GraphQL.SearchCondition.In,
                    values,
                    label: filter.label,
                    type: filter.type,
                  },
                ]
          );
        }}
      />
    </Flex.Column>
  );
}

export function DropdownFilter<TField>({
  filter,
  inputs,
  onChange,
}: RpiSearchFilterInputProps<TField>): React.ReactElement {
  const [condition, setCondition] = React.useState<GraphQL.SearchCondition>(GraphQL.SearchCondition.Equals);
  const [value, setValue] = React.useState<string>();

  if (filter.type !== "dropdown") return <></>;

  const data = React.useMemo(() => {
    return filter.data.map((value) => {
      if (isString(value)) return { label: value, value };
      return value;
    });
  }, [filter.data]);

  return (
    <Flex.Column px={8} gap={8}>
      <SegmentedControl
        styles={(theme) => ({
          root: {
            background: theme.colors.calm[3],
            borderRadius: 6,
          },
          label: {
            ...theme.other.fontTypes.p3,
            fontWeight: theme.other.fontWeights.regular,
            color: theme.colors.brand[5],
          },
          labelActive: {
            color: theme.colors.text[5],
          },
        })}
        fullWidth
        value={condition}
        onChange={(condition) => setCondition(condition as GraphQL.SearchCondition)}
        data={[
          { label: "Equals", value: GraphQL.SearchCondition.Equals },
          { label: "Not Equals", value: GraphQL.SearchCondition.NotEquals },
        ]}
      />
      <RpiDrawerSelectInput
        label={filter.label}
        value={value || ""}
        onChange={(value) => setValue(value as string)}
        data={data}
        searchable={!!filter.onSearch}
        onSearch={filter.onSearch}
        isLoading={filter.isLoading}
      />

      <SaveButton
        disabled={!value}
        onClick={() =>
          onChange([
            ...inputs,
            {
              field: filter.field,
              condition,
              firstValue: value,
              label: filter.label,
              type: filter.type,
            },
          ])
        }
      />
    </Flex.Column>
  );
}

export function DateFilter<TField>({
  filter,
  inputs,
  onChange,
}: RpiSearchFilterInputProps<TField>): React.ReactElement {
  const [condition, setCondition] = React.useState<GraphQL.SearchCondition>(GraphQL.SearchCondition.Equals);
  const [date, setDate] = React.useState<[Date | null, Date | null]>([null, null]);

  if (filter.type !== "date") return <></>;

  return (
    <Flex.Column gap={8} px={8}>
      <SegmentedControl
        styles={(theme) => ({
          root: {
            background: theme.colors.calm[3],
            borderRadius: 6,
          },
          label: {
            ...theme.other.fontTypes.p3,
            fontWeight: theme.other.fontWeights.regular,
            color: theme.colors.brand[5],
          },
          labelActive: {
            color: theme.colors.text[5],
          },
        })}
        fullWidth
        value={condition}
        onChange={(condition) => setCondition(condition as GraphQL.SearchCondition)}
        data={[
          { label: "Equals", value: GraphQL.SearchCondition.Equals },
          { label: "Not Equals", value: GraphQL.SearchCondition.NotEquals },
          { label: "Between", value: GraphQL.SearchCondition.Between },
        ]}
      />

      {condition === GraphQL.SearchCondition.Between ? (
        <RangeCalendar initialMonth={date?.[0] || undefined} value={date} onChange={setDate} />
      ) : (
        <Calendar initialMonth={date?.[0] || undefined} value={date?.[0]} onChange={(date) => setDate([date, null])} />
      )}

      <SaveButton
        disabled={!date?.[0] || (condition === GraphQL.SearchCondition.Between && !date[1])}
        onClick={() => {
          const input: ExtendedRpiSearchFilterInput<TField> = {
            field: filter.field,
            condition,
            label: filter.label,
            type: filter.type,
          };

          if (date[0]) {
            input["firstValue"] = date[0].toISOString();
          }

          if (date[1]) {
            input["secondValue"] = date[1].toISOString();
          }

          onChange([...inputs, input]);
        }}
      />
    </Flex.Column>
  );
}

const dateFilterOptions: Array<{ label: string; time: { amount: number; type: "d" | "h" } }> = [
  {
    label: "Last 24h",
    time: {
      amount: 24,
      type: "h",
    },
  },
  {
    label: "Last 48h",
    time: {
      amount: 48,
      type: "h",
    },
  },
  {
    label: "Last 7d",
    time: {
      amount: 7,
      type: "d",
    },
  },
  {
    label: "Last 14d",
    time: {
      amount: 14,
      type: "d",
    },
  },
];

export interface RpiSearchDateFilterProps<TField> {
  field: TField;
  input?: {
    index: number;
    input: ExtendedRpiSearchFilterInput<TField>;
  };
  setInput: React.Dispatch<React.SetStateAction<RpiSearchDateFilterProps<TField>["input"]>>;
}

export function RpiSearchDateFilter<TField>({
  field,
  input,
  setInput,
}: RpiSearchDateFilterProps<TField>): React.ReactElement {
  const [opened, setOpened] = React.useState(false);

  return (
    <Flex.Column px={20} pt={8} mb={6}>
      <Flex.Row fullWidth sx={{ cursor: "pointer" }} justify="space-between" onClick={() => setOpened(!opened)}>
        <RpiText
          type="p3"
          weight="regular"
          sx={{
            lineHeight: "16.8px",
            userSelect: "none",
          }}
          children="Date Range"
        />

        <RpiIconButton
          width={12}
          color="currentColor"
          sx={{ color: "inherit" }}
          iconProps={{ style: { transform: opened ? "rotate(180deg)" : undefined } }}
          icon={IconV2.ArrowDown}
        />
      </Flex.Row>

      {opened && (
        <Flex.Column gap={16} mt={16}>
          {dateFilterOptions.map(({ label, time }, i) => {
            return (
              <RpiCheckbox
                key={`date-filter-option-${i}`}
                label={label}
                checked={input?.index === i}
                onChange={(e) => {
                  if (!e.target.checked) return setInput(undefined);
                  const date = new Date();
                  const hours = -1 * time.amount * (time.type === "d" ? 24 : 1);

                  setInput({
                    index: i,
                    input: {
                      field,
                      condition: GraphQL.SearchCondition.Between,
                      firstValue: relativeTime(hours, date).toISOString(),
                      secondValue: date.toISOString(),
                      label: "Date Range",
                      type: "date",
                    },
                  });
                }}
              />
            );
          })}
        </Flex.Column>
      )}
    </Flex.Column>
  );
}
