import { IconV2, RpiIconButton } from "@rpi/openapi-core";
import React from "react";
import { RpiTableColumn } from "../RpiTable";
import { useRpiTable } from "../RpiTableProvider";

export interface RpiTableSubRowContextProps<T> {
  subRowSelection?: Record<string, boolean | undefined>;
  setSubRowSelection: React.Dispatch<React.SetStateAction<RpiTableSubRowContextProps<T>["subRowSelection"]>>;
  renderSubRow: (row: T) => React.ReactNode;
  hasSubRow?: (row: T) => boolean;
}

export const RpiTableSubRowContext = React.createContext<RpiTableSubRowContextProps<any> | null>(null);

export function useRpiTableSubRow<T>() {
  return React.useContext(RpiTableSubRowContext) as RpiTableSubRowContextProps<T> | null;
}

export interface RpiTableSubRowProviderProps<T> {
  children: React.ReactNode;
  defaultValue?: RpiTableSubRowContextProps<T>["subRowSelection"];
  onChange?: (subRowSelection: RpiTableSubRowContextProps<T>["subRowSelection"]) => any;
  renderSubRow: RpiTableSubRowContextProps<T>["renderSubRow"];
  hasSubRow?: RpiTableSubRowContextProps<T>["hasSubRow"];
}

export function RpiTableSubRowProvider<T>({
  children,
  defaultValue,
  onChange,
  ...props
}: RpiTableSubRowProviderProps<T>) {
  const [subRowSelection, setSubRowSelection] = React.useState<RpiTableSubRowContextProps<T>["subRowSelection"]>(
    () => defaultValue
  );

  React.useEffect(() => {
    onChange && onChange(subRowSelection);
  }, [subRowSelection]);

  return (
    <RpiTableSubRowContext.Provider value={{ subRowSelection, setSubRowSelection, ...props }} children={children} />
  );
}

export function getSubRowColumn<T>(): RpiTableColumn<T> {
  return {
    id: "#sub-row-select",
    header: "",
    cell: ({ row }) => {
      const { getRowId } = useRpiTable<T>();
      const withSubRow = useRpiTableSubRow<T>();

      if (!withSubRow || !row || !getRowId || (withSubRow.hasSubRow && !withSubRow.hasSubRow(row))) {
        return <></>;
      }

      const rowId = getRowId(row);
      const checked = !!withSubRow.subRowSelection?.[rowId];

      return (
        <RpiIconButton
          icon={IconV2.ArrowDown}
          size={12}
          color="currentcolor"
          iconProps={{ style: checked ? {} : { transform: "rotate(-90deg)" } }}
          onClick={() => {
            withSubRow.setSubRowSelection((selection) => ({ ...selection, [rowId]: !checked }));
          }}
        />
      );
    },
    maxSize: 34,
    minSize: 34,
    size: 34,
    align: "center",
    noPadding: true,
  };
}
