import { Box, Skeleton } from "@mantine/core";
import { Flex, Grid } from "@rpi/openapi-core";
import React from "react";

interface ProductOptionCardProps {
  src?: string;
  size?: "sm" | "lg";

  variants?: Array<{
    name: React.ReactNode;
    description: React.ReactNode[];
    bullet?: boolean;
    actionNode?: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
  }>;
}

export const ProductOptionCard: React.FC<ProductOptionCardProps> = ({ src, size = "sm", variants }) => {
  const imgDimensions = React.useMemo(() => {
    switch (size) {
      case "sm":
        return { width: 364, height: 215 };

      case "lg":
        return { width: 1123, height: 215 };
    }
  }, [size]);

  return (
    <Flex.Column gap={16} sx={{ position: "relative", width: imgDimensions.width }}>
      {!src ? (
        <Skeleton width={imgDimensions.width} height={imgDimensions.height} radius={24} />
      ) : (
        <Box
          component="img"
          width={imgDimensions.width}
          height={imgDimensions.height}
          sx={{ borderRadius: "24px" }}
          src={src}
        />
      )}

      <Flex.Column
        gap={16}
        children={variants?.map(({ name, description, bullet, actionNode, onClick, disabled }, i) => {
          const body = (
            <Flex.Column
              pl={16}
              sx={(theme) => ({ ...theme.other.fontTypes.p2, fontWeight: theme.other.fontWeights.regular })}
            >
              <Box sx={(theme) => ({ fontWeight: theme.other.fontWeights.bold })} children={name} />
              <Grid
                columns={bullet ? "min-content 1fr" : "1fr"}
                mx={bullet ? 8 : 0}
                children={description.map((text, i) => (
                  <React.Fragment key={`product-option-description-${i}`}>
                    {bullet && <Box mr={8} children="•" />}
                    <span children={text} />
                  </React.Fragment>
                ))}
              />
            </Flex.Column>
          );

          return (
            <React.Fragment
              key={`product-option-variant-${name}-${i}`}
              children={
                actionNode ? (
                  <Flex.Row
                    pl={16}
                    align="flex-start"
                    onClick={onClick}
                    sx={{ cursor: onClick ? (disabled ? "not-allowed" : "pointer") : "default" }}
                  >
                    {actionNode}
                    {body}
                  </Flex.Row>
                ) : (
                  body
                )
              }
            />
          );
        })}
      />
    </Flex.Column>
  );
};
