import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";
import { RpiRouteLink } from "../../hooks/useNavigateRoute";
import { createRpiDrawerView, RpiDrawer, RpiDrawerProps } from "../../rpi-core/drawer/RpiDrawer";
import { RpiDrawerField } from "../../rpi-core/drawer/RpiDrawerField";
import { shouldRetry } from "../../utils/query.util";

export const CustomerProductView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const { id: stringId } = useParams();
    const id = React.useMemo(() => stringId && Number.parseInt(stringId), []);

    React.useEffect(() => {
      if (!stringId) onClose();
    }, [stringId]);

    const { data, isLoading, error } = GraphQL.useCustomerProductQuery(
      { id: id || -1 },
      {
        enabled: !!stringId && opened,
        refetchOnMount: true,
        retry: shouldRetry(onClose),
      }
    );
    const customerProduct = React.useMemo(() => data?.customerProduct, [data]);

    return (
      <RpiDrawer title="Product details" isLoading={isLoading} error={error} opened={opened} onClose={onClose}>
        {customerProduct && <ProductInfo customerProduct={customerProduct} />}
      </RpiDrawer>
    );
  }
);

interface ProductInfoProps {
  customerProduct: GraphQLQueryData<GraphQL.CustomerProductQuery>;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ customerProduct }) => {
  const user = useAuthUser();

  return (
    <>
      <RpiDrawerField label="Your Name" children={customerProduct.name} />
      <RpiDrawerField label="Description" children={customerProduct.description} />
      <RpiDrawerField label="Your SKU" children={customerProduct.customerSku} />
      {user.is.SUPER_ADMIN && (
        <>
          <RpiDrawerField label="Open API Product">
            <RpiRouteLink
              variant="inherit"
              route={["open-api-product", { id: customerProduct.openapiProductId }]}
              children={customerProduct.openapiProductId}
            />
          </RpiDrawerField>

          <RpiDrawerField label="Customer">
            <RpiRouteLink
              variant="inherit"
              route={["customer", { publicId: customerProduct.customerPublicId }]}
              children={customerProduct.customerPublicId}
            />
          </RpiDrawerField>
        </>
      )}
    </>
  );
};
