import { GraphQL } from "@rpi/openapi-api";
import { RpiFilterV2 } from "../../components/search-filter/RpiSearchFilterTypes";

export const openApiProductFilters = RpiFilterV2.createConfigs<GraphQL.OpenapiProductSearchFields>([
  {
    type: "text",
    label: "Name",
    field: GraphQL.OpenapiProductSearchFields.Name,
  },
  {
    type: "text",
    label: "UOS Sku",
    field: GraphQL.OpenapiProductSearchFields.UosSku,
  },
  {
    type: "text",
    label: "Open API Sku",
    field: GraphQL.OpenapiProductSearchFields.OpenapiSku,
  },
  {
    type: "text",
    label: "UOS Media Option",
    field: GraphQL.OpenapiProductSearchFields.UosMediaOption,
  },
  {
    type: "text",
    label: "UOS Orientation",
    field: GraphQL.OpenapiProductSearchFields.UosOrientation,
  },
  {
    type: "text",
    label: "Description",
    field: GraphQL.OpenapiProductSearchFields.Description,
  },
]);
