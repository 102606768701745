import { GraphQL } from "@rpi/openapi-api";
import { Flex, RpiButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { RpiPagination, RpiPaginationRow } from "../../components/RpiPagination";
import { RpiSearchFilter } from "../../components/search-filter/RpiSearchFilter";
import { apiCredentialColumns } from "../../configuration/columns/apiCredential.column";
import { useApiCredentialFilters } from "../../configuration/filters/apiCredential.filter";
import { useAuthUser } from "../../hooks/useAuthUser";
import { useNavigateRoute } from "../../hooks/useNavigateRoute";
import { useRpiPagination } from "../../hooks/usePagination";
import { RpiPerPage } from "../../hooks/useRpiPerPage";
import { RpiTable } from "../../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../../utils/graphql.util";

export const ApiCredentialsView: React.FC = () => {
  const user = useAuthUser();
  const navigateRoute = useNavigateRoute();
  const outlet = useOutlet();

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.ApiCredentialsQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.ApiCredentialsQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.ApiCredentialsQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const apiCredentialFilters = useApiCredentialFilters();

  const { data, isLoading, error, refetch } = GraphQL.useApiCredentialsQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.apiCredentials.totalCount),
    }
  );
  const apiCredentials = React.useMemo(() => data?.apiCredentials.data, [data]);

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  return (
    <AppPage path={["API Credentials"]} onReload={refetch}>
      {outlet}

      {!user.is.SUPER_ADMIN && (
        <Flex.Row align="center" gap={8} mb={16}>
          <RpiText
            type="h3"
            weight="regular"
            color={(theme) => theme.colors.text[5]}
            sx={{ flexShrink: 0 }}
            children="Wehbook Secret:"
          />
          <Flex.Row
            gap={8}
            px={8}
            sx={(theme) => ({
              background: theme.colors.sky[7],
              borderRadius: 4,
            })}
          >
            <RpiText
              type="p3"
              weight="regular"
              sx={{ fontFamily: "monospace" }}
              children={apiCredentials?.[0]?.webhookSecret}
            />
          </Flex.Row>
        </Flex.Row>
      )}

      <AppPage.Section>
        <RpiSearchFilter
          placeholder="Search by Api Key"
          filters={apiCredentialFilters}
          onChange={setSearchInputs}
          onSearch={setSearchGeneral}
        />

        <RpiTable
          emptyMessage={
            searchInputs?.length || searchGeneral
              ? "No API credentials found."
              : "Your API credentials will appear here."
          }
          isLoading={isLoading}
          error={error}
          perPage={pagination.perPage}
          data={apiCredentials}
          columns={apiCredentialColumns}
          withSortBy={{ onChange: setSortInput }}
        />

        <RpiPaginationRow>
          <RpiPagination pagination={pagination} />
          <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
        </RpiPaginationRow>
      </AppPage.Section>

      {(user.is.USER || user.is.CUSTOMER_ADMIN) && (
        <RpiButton
          mt={32}
          width="fit"
          variant="brand-outline"
          size="small"
          children="Create credential"
          trailingIcon
          onClick={() => navigateRoute(["api-credential:create"])}
        />
      )}
    </AppPage>
  );
};
