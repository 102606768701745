import { Box } from "@mantine/core";
import React from "react";
import { DashboardGraphCard } from "./DashboardGraphCard";
import { DashboardInfoCard } from "./DashboardInfoCard";
import { DashboardTableCard } from "./DashboardTableCard";

interface DashboardCardBaseProps {
  size: "small" | "medium" | "large";
  children?: React.ReactNode;
}

export const DashboardCardBase: React.FC<DashboardCardBaseProps> = ({ size, children }) => {
  return (
    <Box
      sx={(theme) => ({
        borderRadius: 16,
        boxSizing: "border-box",
        position: "relative",
        boxShadow: theme.other.boxShadows.button,
        background: theme.colors.sky[5],
        ...(size === "small"
          ? {
              width: 305,
              height: 191,
              padding: 16,
            }
          : size === "medium"
          ? {
              width: 466,
              height: 308,
              padding: "24px 20px",
            }
          : {
              width: 944,
              minHeight: 483,
              padding: "16px 8px",
            }),
      })}
      children={children}
    />
  );
};

export const DashboardCard = {
  Info: DashboardInfoCard,
  Graph: DashboardGraphCard,
  Table: DashboardTableCard,
};
