import { Menu } from "@mantine/core";
import { Flex, IconV2, RpiIconButton } from "@rpi/openapi-core";
import React from "react";
import { RpiTableAdditionalColumn, RpiTableColumnConfig } from "./RpiTable";

interface RpiTableRowMenuProps {
  options: Array<{
    label: string;
    onClick?: () => void;
    type?: "error";
  }>;
}

export const RpiTableRowMenu: React.FC<RpiTableRowMenuProps> = ({ options }) => {
  return (
    <Menu
      width={64}
      position="bottom-start"
      offset={0}
      styles={(theme) => ({
        dropdown: {
          borderRadius: 12,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.colors.sky[6],
          boxShadow: theme.other.boxShadows.large,
          padding: "3px !important",
          marginLeft: 12,
        },
        item: {
          ...theme.other.fontTypes.p3,
          fontWeight: theme.other.fontWeights.regular,
          color: theme.colors.brand[5],
          width: "100%",
          padding: "0px !important",
          borderRadius: 8,
          height: 32,
          "&:hover, &:active": {
            color: theme.colors.brand[5],
            background: theme.colors.calm[4],
          },
        },
        itemLabel: {
          textAlign: "center",
        },
      })}
    >
      <Menu.Target>
        <RpiTableRowMenuTarget />
      </Menu.Target>

      <Menu.Dropdown>
        {options.map((option, i) => {
          return (
            <Menu.Item
              key={`menu-option-${i}`}
              onClick={option.onClick}
              children={option.label}
              {...(option.type === "error" && {
                sx: (theme) => ({
                  color: theme.colors.spark[6],
                  "&:hover, &:active": {
                    color: theme.colors.spark[6],
                    background: theme.colors.spark[1],
                  },
                }),
              })}
            />
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};

const RpiTableRowMenuTarget = React.forwardRef((props, ref) => {
  return (
    <Flex.Column justify="center" py={1}>
      <RpiIconButton
        customRef={ref}
        height={20}
        color="currentColor"
        sx={(theme) => ({ color: theme.colors.brand[5] })}
        icon={IconV2.VerticalDots}
        {...props}
      />
    </Flex.Column>
  );
});

export const rowMenuDefaultOptions: RpiTableColumnConfig = {
  header: "",
  maxSize: 34,
  minSize: 34,
  size: 34,
  align: "center",
  noPadding: true,
};

export function createRpiTableRowMenu<T>(
  options: (...args: Parameters<NonNullable<RpiTableAdditionalColumn<T>["cell"]>>) => RpiTableRowMenuProps["options"],
  index = 0
): RpiTableAdditionalColumn<T> {
  return {
    index,
    id: "#menu",
    cell: (...args) => <RpiTableRowMenu options={options(...args)} />,
    ...rowMenuDefaultOptions,
  };
}
