import Braintree from "braintree-web";
import React from "react";

export type BraintreeFields = "number" | "cvv" | "expirationDate" | "postalCode";

export const useBraintree = () => {
  const [client, setClient] = React.useState<Braintree.Client>();
  const [hostedFields, setHostedFields] = React.useState<Braintree.HostedFields>();
  const [dataCollector, setDataCollector] = React.useState<Braintree.DataCollector>();

  const [fields, setFields] = React.useState<
    Record<
      BraintreeFields,
      {
        container?: HTMLElement;
        isFocused?: boolean;
        isEmpty?: boolean;
        isPotentiallyValid?: boolean;
        isValid?: boolean;
      }
    >
  >({
    number: { isEmpty: true },
    cvv: { isEmpty: true },
    expirationDate: { isEmpty: true },
    postalCode: { isEmpty: true },
  });

  const numberRef = React.useRef<HTMLDivElement>();
  const cvvRef = React.useRef<HTMLDivElement>();
  const expDateRef = React.useRef<HTMLDivElement>();
  const postalCodeRef = React.useRef<HTMLDivElement>();

  const cleanRefs = React.useCallback(() => {
    const removeRefChildren = (ref: React.MutableRefObject<HTMLDivElement | undefined>) => {
      if (!ref.current) return;
      ref.current.innerHTML = "";
    };

    [numberRef, cvvRef, expDateRef, postalCodeRef].forEach((ref) => removeRefChildren(ref));
  }, []);

  const initBraintree = React.useCallback(async () => {
    const client = await Braintree.client.create({
      authorization: process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY!,
    });

    cleanRefs();

    const hostedFields = await Braintree.hostedFields.create({
      client,
      styles: {
        input: {
          "font-family": "monospace, monospace",
          "font-size": "16px",
          "font-weight": 300,
          color: "#050203",
          outline: "none",
          background: "transparent",
          border: "none",
          margin: 0,
          width: "100%",
          padding: "8px",
          borderRadius: "6px",
        },
      },
      fields: {
        number: { container: numberRef.current },
        cvv: { container: cvvRef.current },
        expirationDate: { container: expDateRef.current },
        postalCode: { container: postalCodeRef.current },
      },
    });

    const dataCollector = await Braintree.dataCollector.create({ client });

    setClient(client);
    setHostedFields(hostedFields);
    setDataCollector(dataCollector);
    setFields(hostedFields.getState().fields as any);

    hostedFields.on("blur", ({ fields }) => setFields(fields as any));
    hostedFields.on("cardTypeChange", ({ fields }) => setFields(fields as any));
    hostedFields.on("empty", ({ fields }) => setFields(fields as any));
    hostedFields.on("focus", ({ fields }) => setFields(fields as any));
    hostedFields.on("notEmpty", ({ fields }) => setFields(fields as any));
    hostedFields.on("validityChange", ({ fields }) => setFields(fields as any));
  }, []);

  React.useEffect(() => {
    initBraintree().catch(console.error);

    return cleanRefs;
  }, []);

  const getPayload = React.useCallback(async () => {
    if (!hostedFields) {
      console.error("No braintree hosted fields");
      throw "Internal processing error";
    }

    if (!dataCollector) {
      console.error("No braintree data collector");
      throw "Internal processing error";
    }

    try {
      const [payload, deviceData] = await Promise.all([hostedFields.tokenize(), dataCollector.getDeviceData()]);
      return { payload, deviceData };
    } catch (error: any) {
      console.error(error);
      throw parseBraintreeError(error);
    }
  }, [hostedFields, dataCollector]);

  return {
    fields: {
      number: {
        ref: numberRef,
        ...fields.number,
      },
      cvv: {
        ref: cvvRef,
        ...fields.cvv,
      },
      expirationDate: {
        ref: expDateRef,
        ...fields.expirationDate,
      },
      postalCode: {
        ref: postalCodeRef,
        ...fields.postalCode,
      },
    },
    client,
    hostedFields,
    dataCollector,
    getPayload,
  };
};

const emptyErrors: Record<BraintreeFields, string> = {
  number: "Please input card number",
  cvv: "Please input cvc",
  expirationDate: "Please input expiration date",
  postalCode: "Please input postal code",
};

const invalidErrors: Record<BraintreeFields, string> = {
  number: "Invalid card number",
  cvv: "The security for this card is invalid. Verify the code and submit the payment again.",
  expirationDate: "The security for this card is invalid. Verify the code and submit the payment again.",
  postalCode: "Invalid postal code",
};

const parseBraintreeError = (error: any): Partial<Record<BraintreeFields, string>> | string => {
  if (error?.code === "HOSTED_FIELDS_FIELDS_EMPTY") {
    return emptyErrors;
  }

  if (error?.code === "HOSTED_FIELDS_FIELDS_INVALID") {
    return ((error?.details?.invalidFieldKeys || []) as BraintreeFields[]).reduce((prev, current) => {
      return {
        ...prev,
        [current]: invalidErrors[current],
      };
    }, {});
  }

  return "Internal Error";
};

export const getBraintreeDeviceData = async () => {
  const client = await Braintree.client.create({
    authorization: process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY!,
  });

  const dataCollector = await Braintree.dataCollector.create({ client });

  return (await dataCollector.getDeviceData()) as string;
};
