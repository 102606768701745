import React from "react";

interface useLoadingCallbackResult {
  isLoading: boolean;
  error?: any;
  loadingCallback: (callback: () => void) => void;
  lc: <T extends (...args: any) => any>(callback: T) => (...args: Parameters<T>) => Promise<void>;
}

export const useLoadingCallback = (): useLoadingCallbackResult => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(); // Todo: Handle error for each callback

  const loadingCounter = React.useRef<number>(0);

  const loadingCallback: useLoadingCallbackResult["loadingCallback"] = React.useCallback(async (callback) => {
    loadingCounter.current++;
    setIsLoading(!!loadingCounter.current);
    try {
      await Promise.resolve(callback());
    } catch (error) {
      setError(error);
    }
    loadingCounter.current--;
    setIsLoading(!!loadingCounter.current);
  }, []);

  const lc: useLoadingCallbackResult["lc"] = React.useCallback((callback) => {
    return async (...args) => {
      loadingCounter.current++;
      setIsLoading(!!loadingCounter.current);
      try {
        await Promise.resolve(callback(...args));
      } catch (error) {
        setError(error);
      }
      loadingCounter.current--;
      setIsLoading(!!loadingCounter.current);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      setIsLoading(false);
      setError(undefined);
    };
  }, []);

  return {
    isLoading: !!isLoading,
    error,
    loadingCallback,
    lc,
  };
};
