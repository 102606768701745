import { Flex, RpiButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Error404ViewProps {
  /** Is root page. */
  root?: boolean;
  /** Hide redirect button. */
  hideButton?: boolean;
}

export const Error404View: React.FC<Error404ViewProps> = ({ root = true, hideButton }) => {
  const navigate = useNavigate();

  return (
    <Flex.Column
      gap={16}
      justify="center"
      align="center"
      sx={(theme) => ({ width: "100%", height: root ? "100vh" : "100%", color: theme.colors.brand[5] })}
    >
      <RpiText type="d1" weight="bold" children="404" />
      <RpiText type="d2" weight="roman" children="Page Not Found" />
      {!hideButton && (
        <RpiButton
          variant="brand-outline"
          size="large"
          width="fit"
          mt={16}
          onClick={() => navigate(-1)}
          children="Go Back"
        />
      )}
    </Flex.Column>
  );
};
