import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, RpiButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { RpiPagination, RpiPaginationRow } from "../../../components/RpiPagination";
import { RpiSearchFilter } from "../../../components/search-filter/RpiSearchFilter";
import { dashboardUserColumns } from "../../../configuration/columns/dashboardUser.colum";
import { useDashboardUserFilters } from "../../../configuration/filters/dashboardUser.filter";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { useNavigateRoute } from "../../../hooks/useNavigateRoute";
import { useRpiPagination } from "../../../hooks/usePagination";
import { RpiPerPage } from "../../../hooks/useRpiPerPage";
import { RpiTable } from "../../../rpi-core/table/RpiTable";
import { createRpiTableRowMenu } from "../../../rpi-core/table/RpiTableRowMenu";
import { SearchGeneral, SearchInputs, SearchSort } from "../../../utils/graphql.util";
import { optArray } from "../../../utils/misc.util";

export const DashboardUsersView: React.FC = () => {
  const user = useAuthUser();
  const navigateRoute = useNavigateRoute();
  const outlet = useOutlet();

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.DashboardUsersQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.DashboardUsersQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.DashboardUsersQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const dashboardUserFilters = useDashboardUserFilters();

  const { data, isLoading, error, refetch } = GraphQL.useDashboardUsersQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.dashboardUsers.totalCount),
    }
  );
  const dashboardUsers = React.useMemo(() => data?.dashboardUsers.data, [data]);

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  const { mutateAsync: deleteUser, ...deleteUserMutation } = GraphQL.useDeleteDashboardUserMutation();

  const modals = useModals();
  const handleDeleteDashboardUser = React.useCallback(
    (dashboardUser: Pick<GraphQL.DashboardUserResponse, "id" | "username">) => {
      if (deleteUserMutation.isLoading) return;

      return modals.openConfirmModal({
        title: "Delete dashboard user",
        centered: true,
        children: <RpiText type="p2" children={`Are you sure you want to delete "${dashboardUser.username}"?`} />,
        labels: { confirm: "Delete", cancel: "Cancel" },
        confirmProps: { color: "red" },
        onConfirm: async () => {
          return await deleteUser({ id: dashboardUser.id })
            .then(() => {
              showNotification({
                title: `Deleted ${dashboardUser.username}.`,
                message: "",
                color: "green",
              });
              refetch();
            })
            .catch((error) => {
              showNotification({
                title: "Failed to delete dashboard user.",
                message: error.message,
                color: "red",
              });
            });
        },
      });
    },
    [deleteUser, refetch, deleteUserMutation]
  );

  const canModify = user.is.CUSTOMER_ADMIN || user.is.SUPER_ADMIN;

  return (
    <AppPage path={["Dashboard Users"]} onReload={refetch}>
      {outlet}

      <AppPage.Section>
        <Flex.Row justify="space-between" align="flex-end">
          <RpiSearchFilter
            placeholder="Search by Username (or Email)"
            filters={dashboardUserFilters}
            onChange={setSearchInputs}
            onSearch={setSearchGeneral}
          />

          {canModify && (
            <RpiButton
              width="fit"
              variant="brand-outline"
              size="small"
              children="Add new user"
              trailingIcon
              onClick={() => navigateRoute(["dashboard-user:create"])}
            />
          )}
        </Flex.Row>

        <RpiTable
          emptyMessage={
            searchInputs?.length || searchGeneral
              ? "No dashboard users found."
              : "Your dashboard users will appear here."
          }
          isLoading={isLoading}
          error={error}
          perPage={pagination.perPage}
          data={dashboardUsers}
          columns={dashboardUserColumns}
          additionalColumns={[
            createRpiTableRowMenu<Pick<GraphQL.DashboardUserResponse, "id" | "username">>(({ row }) => [
              {
                label: "View",
                onClick: () => navigateRoute(["dashboard-user", { id: row.id }]),
              },
              ...optArray(canModify, [
                {
                  label: "Edit",
                  onClick: () => navigateRoute(["dashboard-user:edit", { id: row.id }]),
                },
              ]),
              ...optArray(canModify && user.id !== row.username, [
                {
                  label: "Delete",
                  onClick: () => handleDeleteDashboardUser({ id: row.id, username: row.username }),
                  type: "error",
                },
              ]),
            ]),
          ]}
          withSortBy={{ onChange: setSortInput }}
        />

        <RpiPaginationRow>
          <RpiPagination pagination={pagination} />
          <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
        </RpiPaginationRow>
      </AppPage.Section>
    </AppPage>
  );
};
