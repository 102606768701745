import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { CellJson } from "../../components/cells/CellJson";

const printFileColumn = createEntityColumn<GraphQL.BlurbCorePrintFileResponse>();

export const BlurbCorePrintFileColumn = {
  id: printFileColumn<"id">({
    header: "ID",
    accessor: "id",
    minSize: 100,
    size: 150,
  }),
  printAssetId: printFileColumn<"printAssetId">({
    header: "Print Asset ID",
    accessor: "printAssetId",
    size: 200,
  }),
  preprintOperationKey: printFileColumn<"preprintOperationKey">({
    header: "Preprint Operation Key",
    accessor: "preprintOperationKey",
    size: 260,
  }),
  status: printFileColumn<"status">({
    header: "Status",
    accessor: "status",
    size: 260,
  }),
  startedAt: printFileColumn<"startedAt">({
    header: "Started At",
    accessor: "startedAt",
    size: 350,
  }),
  completedAt: printFileColumn<"completedAt">({
    header: "Completed At",
    accessor: "completedAt",
    size: 200,
  }),
  printReadyFiles: printFileColumn<"printReadyFiles">({
    header: "Print Ready Files",
    accessor: "printReadyFiles",
    cell: ({ row }) => <CellJson data={row.printReadyFiles} />,
    size: 220,
  }),
  blurbLogo: printFileColumn<"blurbLogo">({
    header: "Blurb Logo",
    accessor: "blurbLogo",
    size: 170,
  }),
  isbn: printFileColumn<"isbn">({
    header: "ISBN",
    accessor: "isbn",
    size: 170,
  }),
  renderSpread: printFileColumn<"renderSpread">({
    header: "Render Spread",
    accessor: "renderSpread",
    size: 170,
  }),
  disableCropMarks: printFileColumn<"disableCropMarks">({
    header: "Disable Crop Marks",
    accessor: "disableCropMarks",
    size: 170,
  }),
  renderAsIngram: printFileColumn<"renderAsIngram">({
    header: "Render As Ingram",
    accessor: "renderAsIngram",
    size: 170,
  }),
};

export const blurbCorePrintFileColumns: RpiTableColumns<
  NonNullable<GraphQLQueryData<GraphQL.PrintAssetQuery>["blurbCorePrintFiles"]>[number]
> = [
  BlurbCorePrintFileColumn.id,
  BlurbCorePrintFileColumn.status,
  BlurbCorePrintFileColumn.preprintOperationKey,
  BlurbCorePrintFileColumn.startedAt,
  BlurbCorePrintFileColumn.completedAt,
  BlurbCorePrintFileColumn.printReadyFiles,
  BlurbCorePrintFileColumn.blurbLogo,
  BlurbCorePrintFileColumn.isbn,
  BlurbCorePrintFileColumn.renderSpread,
  BlurbCorePrintFileColumn.disableCropMarks,
  BlurbCorePrintFileColumn.renderAsIngram,
];
