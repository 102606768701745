import { AxiosError } from "axios";

// Type Guards
export function isNumber(value: unknown): value is number {
  return typeof value === "number" && !Number.isNaN(value);
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isObject(value: unknown): value is object {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
}

export function isFunction<TArgs extends any[] = any[], TReturn = any>(
  value: unknown
): value is (...args: TArgs) => TReturn {
  return typeof value === "function";
}

export function isEmptyString(value: unknown): value is "" {
  return isString(value) && value.length === 0;
}

export function isArrayOfType<T>(value: unknown, isType: (element: unknown) => element is T): value is T[] {
  return Array.isArray(value) && value.every((element) => isType(element));
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

export function filterNullableArray<T>(array: Array<T | null | undefined>): T[] {
  return array.filter((value) => !isNull(value) && !isUndefined(value)) as T[];
}

export function isAxiosError(error: unknown): error is AxiosError {
  return error instanceof AxiosError;
}

// Helpers
export type NullablePartial<T, OmitKeys extends keyof T = never> = {
  [K in keyof T]?: K extends OmitKeys ? T[K] : T[K] | undefined | null;
};

export type PickEntityKeys<T, K extends keyof T> = {
  [P in K]: NonNullable<T[P]> extends object ? DeepPartial<T[P]> : T[P];
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type DeepNonNullable<T> = T extends object
  ? {
      [P in keyof T]-?: DeepNonNullable<T[P]>;
    }
  : NonNullable<T>;

export type RequiredProperty<T, K extends keyof T> = T & {
  [P in K]-?: Exclude<T[P], undefined | null>;
};

export type PickPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
