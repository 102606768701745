import { GraphQL, Rest } from "@rpi/openapi-api";
import { Flex, IconV2 } from "@rpi/openapi-core";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { RpiPagination, RpiPaginationRow } from "../../../components/RpiPagination";
import { RpiSearchFilter } from "../../../components/search-filter/RpiSearchFilter";
import { useRpiPagination } from "../../../hooks/usePagination";
import { RpiPerPage } from "../../../hooks/useRpiPerPage";
import { RpiTable } from "../../../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../../../utils/graphql.util";
import { useCustomersCommunicationsFilter } from "../../../configuration/filters/customersCommunications.filter";
import { customerCommunicationColumns } from "../../../configuration/columns/customersCommunications.column";
import { Button } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import { downloadBlob } from "../../../utils/file.util";

export async function exportCommunicationsCSV(body: GraphQL.CustomerCommunicationSearch) {
  const session = await Auth.currentSession();
  const jwtToken = session.getAccessToken().getJwtToken();
  return Rest.exportCommunicationsCSV(body, jwtToken);
}

export const CommunicationsView: React.FC = () => {
  const outlet = useOutlet();

  const [sortInput, setSortInput] =
    React.useState<SearchSort<GraphQL.AdminSearchCustomerCommunicationsQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<
    SearchInputs<GraphQL.AdminSearchCustomerCommunicationsQueryVariables>
  >([]);
  const [searchGeneral, setSearchGeneral] =
    React.useState<SearchGeneral<GraphQL.AdminSearchCustomerCommunicationsQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const customersCommunicationsFilter = useCustomersCommunicationsFilter();

  const exportCommunicationsCSVMutation = useMutation({
    mutationFn: exportCommunicationsCSV,
    onSuccess: (response) => {
      downloadBlob(response.data, "communications-export.csv");
    },
  });

  const search = {
    inputs: searchInputs,
    sort: sortInput,
    generalInput: searchGeneral,
  };
  const { data, isLoading, error, refetch } = GraphQL.useAdminSearchCustomerCommunicationsQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: search,
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.adminSearchCustomerCommunications.totalCount),
    }
  );
  const customersCommunications = React.useMemo(() => data?.adminSearchCustomerCommunications.data, [data]);

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  return (
    <AppPage path={["Communications"]} onReload={refetch}>
      {outlet}

      <AppPage.Section>
        <Flex.Row justify="space-between" align="flex-end">
          <RpiSearchFilter
            placeholder="Search by ID"
            filters={customersCommunicationsFilter}
            onChange={setSearchInputs}
            onSearch={setSearchGeneral}
          />
          <Button
            size={"xs"}
            leftIcon={<IconV2.FileDownload />}
            children={"Export to CSV"}
            onClick={() => exportCommunicationsCSVMutation.mutate(search)}
          />
        </Flex.Row>

        <RpiTable
          emptyMessage={
            searchInputs?.length || searchGeneral ? "No communications found." : "Communications will appear here."
          }
          isLoading={isLoading}
          error={error}
          perPage={pagination.perPage}
          data={customersCommunications}
          columns={customerCommunicationColumns}
          withSortBy={{ onChange: setSortInput }}
        />

        <RpiPaginationRow>
          <RpiPagination pagination={pagination} />
          <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
        </RpiPaginationRow>
      </AppPage.Section>
    </AppPage>
  );
};
