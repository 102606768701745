import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { useParams } from "react-router-dom";
import { getRoutePath } from "../../../hooks/useNavigateRoute";
import { AppPage } from "../../../components/AppPage";
import { RpiSingleTable } from "../../../components/tables/RpiSingleTable";
import { customerCommunicationRows } from "../../../configuration/rows/customerCommunication.row";
import { CellJson } from "../../../components/cells/CellJson";

export const CommunicationView: React.FC = () => {
  const { id } = useParams();

  const { data, isLoading, error, refetch } = GraphQL.useCustomerCommunicationQuery({
    id: Number.parseInt(id!),
  });
  const customerCommunication = React.useMemo(() => data?.customerCommunication, [data]);

  return (
    <AppPage
      path={[
        {
          name: "Communications",
          to: getRoutePath("communications"),
        },
        id,
      ]}
      onReload={refetch}
    >
      <AppPage.Section title="Data">
        <RpiSingleTable
          isLoading={isLoading}
          error={error}
          data={customerCommunication}
          rows={customerCommunicationRows}
        />
      </AppPage.Section>

      <AppPage.Section title="Content" mt={32}>
        <CellJson data={customerCommunication?.contents} collapsed={2} />
      </AppPage.Section>
    </AppPage>
  );
};
