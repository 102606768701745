import React from "react";
import ReactJson, { ReactJsonViewProps } from "react-json-view";

interface CellJsonProps extends Omit<ReactJsonViewProps, "src"> {
  data?: object;
}

export const CellJson: React.FC<CellJsonProps> = ({ data, ...props }) => {
  if (!data) return <></>;
  return <ReactJson src={data} name={null} displayDataTypes={false} {...props} />;
};
