import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { RpiDrawerSelectInput, RpiDrawerTextInput } from "@rpi/openapi-core";
import React from "react";
import { useLocation } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../rpi-core/drawer/RpiDrawer";
import { optObj } from "../../utils/misc.util";
import { shouldRetry } from "../../utils/query.util";
import { isNumber, isString } from "../../utils/type.util";

export const CustomerProductCreateView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const { state } = useLocation();

    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    const { data, isLoading, error } = GraphQL.useListOpenApiProductsQuery(
      { hideAlreadyAssociatedProducts: true },
      {
        enabled: opened,
        refetchOnMount: true,
        retry: shouldRetry(handleClose),
        cacheTime: 0,
      }
    );
    const openApiProducts = React.useMemo(() => data?.listOpenApiProducts, [data]);
    const selectedOpenApiProduct = React.useMemo(() => {
      const sku = state?.sku;
      if (!sku || !isString(sku) || !openApiProducts) return;
      return openApiProducts.find((product) => product.openapiSku === sku);
    }, [openApiProducts, state]);

    return (
      <RpiDrawer
        title="Add new product"
        overlay={optObj(openApiProducts && openApiProducts.length === 0, {
          type: "error",
          message: "No available Open API product.",
          description: "All Open API product already have associated products.",
        })}
        isLoading={isLoading}
        error={error}
        opened={opened}
        onClose={handleClose}
      >
        {openApiProducts && (
          <CreateProduct
            openApiProducts={openApiProducts}
            selectedOpenApiProduct={selectedOpenApiProduct}
            onClose={() => handleClose(true)}
          />
        )}
      </RpiDrawer>
    );
  }
);

interface CreateProductProps {
  openApiProducts: NonNullable<GraphQLQueryData<GraphQL.ListOpenApiProductsQuery>>;
  selectedOpenApiProduct?: NonNullable<GraphQLQueryData<GraphQL.ListOpenApiProductsQuery>>[number];
  onClose: () => void;
}

const CreateProduct: React.FC<CreateProductProps> = ({ openApiProducts, selectedOpenApiProduct, onClose }) => {
  const [customersSearch, setCustomersSearch] = React.useState("");
  const [debouncedCustomersSearch] = useDebouncedValue(customersSearch, 250);

  const user = useAuthUser();

  const { data, ...customersQuery } = GraphQL.useAdminListCustomersQuery(
    { input: debouncedCustomersSearch },
    { enabled: user.is.SUPER_ADMIN }
  );
  const customers = React.useMemo(() => data?.adminListCustomers, [data]);

  const {
    mutateAsync: createCustomerProduct,
    reset,
    ...mutation
  } = GraphQL.useCreateCustomerProductMutation<{ message: string }>();

  const form = useForm<GraphQL.CreateCustomerProductMutationVariables["input"]>({
    initialValues: {
      customerPublicId: user.id!,
      customerSku: selectedOpenApiProduct?.openapiSku || "",
      name: selectedOpenApiProduct?.name || "",
      description: "",
      openapiProductId: selectedOpenApiProduct && isNumber(selectedOpenApiProduct?.id) ? selectedOpenApiProduct.id : -1,
    },
    validate: {
      openapiProductId: (value) => {
        if (value === -1) {
          return "You must select open API product";
        }
      },
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      await createCustomerProduct({ input });
      onClose();
    },
    [createCustomerProduct, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Creating product...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to create product...",
          description: "Please try again later.",
        },
        success: {
          message: "Product created!",
        },
      })(mutation),
    [mutation]
  );

  const customersSelectData = React.useMemo(
    () => (customers || []).map((customer) => ({ label: customer.publicId, value: customer.publicId })),
    [customers]
  );

  const openApiProductsSelectData = React.useMemo(
    () => (openApiProducts || []).map((product) => ({ label: product.name, value: product.id })),
    [customers]
  );

  return (
    <RpiDrawerForm button="Save" overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      {user.is.SUPER_ADMIN && (
        <RpiDrawerSelectInput
          required
          label="Customer"
          data={customersSelectData}
          {...form.getInputProps("customerPublicId")}
          searchable
          onSearch={setCustomersSearch}
          isLoading={customersQuery.isLoading}
        />
      )}

      <RpiDrawerTextInput required label="Your SKU" {...form.getInputProps("customerSku")} />
      <RpiDrawerTextInput required label="Your Product Name" {...form.getInputProps("name")} />
      <RpiDrawerTextInput label="Description" {...form.getInputProps("description")} />

      <RpiDrawerSelectInput
        required
        label="Open API Product"
        data={openApiProductsSelectData}
        {...form.getInputProps("openapiProductId")}
      />
    </RpiDrawerForm>
  );
};
