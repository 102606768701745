import { Group, Text } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { CustomSelect, CustomSelectItemProps, CustomSelectWrapper } from "./SelectUtils";

type SelectApiCredentialType = Pick<GraphQL.ApiCredentialsResponse, "apiKey" | "description" | "sharedSecret">;

const SelectApiCredentialItem = React.forwardRef<HTMLDivElement, CustomSelectItemProps<SelectApiCredentialType>>(
  ({ element, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm" children={element.apiKey} />
          {element.description && <Text size="xs" color="dimmed" children={element.description} />}
        </div>
      </Group>
    </div>
  )
);

export const SelectApiCredential: CustomSelectWrapper<SelectApiCredentialType> = ({ getValue, ...props }) => {
  return (
    <CustomSelect
      label="Api Credential"
      placeholder="Select api credential"
      nothingFound="No Api Keys"
      maxDropdownHeight={200}
      itemComponent={SelectApiCredentialItem}
      getItemProps={(apiCredential) => ({
        label: apiCredential.apiKey,
        value: getValue ? getValue(apiCredential) : apiCredential.apiKey,
        element: apiCredential,
      })}
      {...props}
    />
  );
};
