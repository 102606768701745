import { Box, Loader, LoadingOverlay } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, RpiButton, RpiMultilineInput, RpiText } from "@rpi/openapi-core";
import React from "react";
import { customerTaxDocumentsColumns } from "../../configuration/columns/upload.column";
import { RpiTable } from "../../rpi-core/table/RpiTable";
import { shouldRetry } from "../../utils/query.util";
import { isUndefined } from "../../utils/type.util";

export type TaxReviewModalProps = {
  customerPublicId: string;
};

export const TaxReviewModal = ({ context, id, innerProps }: ContextModalProps<TaxReviewModalProps>) => {
  const [notes, setNotes] = React.useState("");

  const { data, isLoading, error } = GraphQL.useCustomerQuery(
    { customerPublicId: innerProps.customerPublicId },
    {
      refetchOnMount: true,
      retry: shouldRetry(() => context.closeModal(id)),
    }
  );
  const customer = React.useMemo(() => data?.customer, [data]);

  const mutation = GraphQL.useAdminTaxReviewMutation<{ message: string }>({
    onSuccess: () => {
      context.closeModal(id);

      showNotification({
        title: `Tax review submited for customer "${innerProps.customerPublicId}".`,
        message: "",
        color: "green",
      });
    },
    onError: (error) => {
      showNotification({
        title: `Failed to submit tax review for customer "${innerProps.customerPublicId}".`,
        message: error.message || "Unknown error.",
        color: "red",
      });
    },
  });

  const sendReview = React.useCallback(
    (approved: boolean) => {
      return mutation.mutate({
        input: {
          customerPublicId: innerProps.customerPublicId,
          approved,
          notes,
        },
      });
    },
    [mutation.mutate, innerProps, notes]
  );

  if (isLoading || error) {
    return <LoadingOverlay loader={<Loader color={error ? "spark" : "brand"} size="xl" />} visible={true} />;
  }

  return (
    <Flex.Column gap={32} sx={(theme) => ({ color: theme.colors.brand[5] })}>
      <RpiText>
        Current status: {!isUndefined(customer?.chargeTax) && customer!.chargeTax ? "Not tax exempt" : "Tax exempt"}
      </RpiText>

      <Box
        sx={{
          maxHeight: "40vh",
          overflowY: "auto",
        }}
      >
        <RpiTable
          emptyMessage="No files found."
          isLoading={isLoading}
          error={error}
          data={customer?.taxDocuments || []}
          columns={customerTaxDocumentsColumns}
        />
      </Box>

      <Flex.Column gap={16}>
        <RpiText type="h3" weight="medium" children="Notes" />

        <RpiMultilineInput
          fullWidth
          placeholder="Message"
          rows={3}
          styles={{
            wrapper: {
              borderRadius: 6,
            },
          }}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Flex.Column>

      <Flex.Row gap={32} justify="space-between">
        <RpiButton
          variant="brand-outline"
          width="fit"
          isLoading={mutation.isLoading}
          onClick={() => context.closeModal(id)}
          children="Cancel"
        />

        <Flex.Row gap={16} justify="flex-end">
          <RpiButton
            variant="spark"
            width={200}
            isLoading={mutation.isLoading}
            onClick={() => sendReview(false)}
            children="Deny"
          />
          <RpiButton
            variant="mellow"
            width={200}
            isLoading={mutation.isLoading}
            onClick={() => sendReview(true)}
            children="Approve"
          />
        </Flex.Row>
      </Flex.Row>
    </Flex.Column>
  );
};
