import { Box } from "@mantine/core";
import { GraphQL, GraphQLQueryData, Rest } from "@rpi/openapi-api";
import { Flex, Grid, RpiButton, RpiCheckbox, RpiLink, RpiSlider, RpiText } from "@rpi/openapi-core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { OverflowX } from "../../components/OverflowX";
import { ProductOptionCard } from "../../components/ProductOptionCard";
import { RpiPagination } from "../../components/RpiPagination";
import { RpiSearchFilter } from "../../components/search-filter/RpiSearchFilter";
import { customerProductColumns } from "../../configuration/columns/customerProduct.column";
import { useCustomerProductFilters } from "../../configuration/filters/customerProduct.filter";
import { useAuthUser } from "../../hooks/useAuthUser";
import { useNavigateRoute } from "../../hooks/useNavigateRoute";
import { useRpiPagination } from "../../hooks/usePagination";
import { RpiTable } from "../../rpi-core/table/RpiTable";
import { createRpiTableRowMenu } from "../../rpi-core/table/RpiTableRowMenu";
import { SearchGeneral, SearchInputs, SearchSort } from "../../utils/graphql.util";
import { PublicEmail } from "../../utils/link.util";
import { MailTo } from "../../utils/mail.util";
import { formatCurrency, optArray } from "../../utils/misc.util";
import { isNumber } from "../../utils/type.util";

export const CustomerProductsView: React.FC = () => {
  const user = useAuthUser();
  const navigateRoute = useNavigateRoute();
  const outlet = useOutlet();

  const canModify = user.is.CUSTOMER_ADMIN || user.is.SUPER_ADMIN;

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.CustomerProductsQueryVariables>>({
    by: GraphQL.CustomerProductSearchField.Id,
    sortDirection: GraphQL.SortDirection.Desc,
  });
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.CustomerProductsQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.CustomerProductsQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const customerProductFilters = useCustomerProductFilters();

  const { data, isLoading, error, refetch } = GraphQL.useCustomerProductsQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.customerProducts.totalCount),
    }
  );
  const customerProducts = React.useMemo(() => data?.customerProducts.data, [data]);

  const { data: openApiProductsData, ...openApiProductsQuery } = GraphQL.useListOpenApiProductsQuery(
    { hideAlreadyAssociatedProducts: true },
    { enabled: canModify }
  );
  const openApiProducts = React.useMemo(() => openApiProductsData?.listOpenApiProducts, [openApiProductsData]);

  const { data: customerData } = GraphQL.useCustomerQuery({ customerPublicId: user.id! });
  const customer = React.useMemo(() => customerData?.customer, [customerData]);

  React.useEffect(() => {
    if (outlet === null) {
      refetch();
      openApiProductsQuery.refetch();
    }
  }, [outlet]);

  const maximumOrdersMessage = React.useMemo(() => {
    if (!customer) return;

    const { maxOrders } = customer?.settings?.ordersLimit || {};

    return (
      <>
        {!isNumber(maxOrders)
          ? ""
          : maxOrders > 0
          ? `You can place up to ${maxOrders} orders in a day. `
          : "You can place unlimited amount of orders in a day. "}
        {!isNumber(maxOrders)
          ? "If you want to set order limit"
          : maxOrders > 0
          ? "If you want to increase this number"
          : "If you want to limit this number"}
        ,
        <br />
        please reach out to RPI CS team{" "}
        <RpiLink
          variant="inherit"
          href={MailTo({ email: PublicEmail.SUPPORT, subject: `Increase order limit for "${customer.publicId}"` })}
          children={PublicEmail.SUPPORT}
        />
        .
      </>
    );
  }, [customer]);

  return (
    <AppPage path={["Products"]} onReload={refetch}>
      {outlet}

      <AppPage.Section>
        <RpiSearchFilter
          placeholder="Search by SKU"
          filters={customerProductFilters}
          onChange={setSearchInputs}
          onSearch={setSearchGeneral}
        />

        <RpiTable
          emptyMessage={
            searchInputs?.length || searchGeneral ? "No products found." : "Your products will appear here."
          }
          isLoading={isLoading}
          error={error}
          perPage={pagination.perPage}
          data={customerProducts}
          columns={customerProductColumns}
          additionalColumns={[
            createRpiTableRowMenu<Pick<GraphQL.CustomerProductResponse, "id">>(({ row }) => [
              {
                label: "View",
                onClick: () => navigateRoute(["customer-product", { id: row.id }]),
              },
              ...optArray(canModify, [
                {
                  label: "Edit",
                  onClick: () => navigateRoute(["customer-product:edit", { id: row.id }]),
                },
              ]),
            ]),
          ]}
          withSortBy={{
            defaultValue: {
              by: GraphQL.CustomerProductSearchField.Id,
              sortDirection: GraphQL.SortDirection.Desc,
            },
            onChange: setSortInput,
          }}
        />

        <Flex.Row fullWidth justify="space-between">
          <RpiText
            type="p3"
            weight="regular"
            color={(theme) => theme.colors.brand[5]}
            children={maximumOrdersMessage}
          />
          <RpiPagination centered={false} pagination={pagination} />
        </Flex.Row>
      </AppPage.Section>

      {canModify && <AddNewProduct openApiProducts={openApiProducts} />}
    </AppPage>
  );
};

interface AddNewProductProps {
  openApiProducts?: GraphQLQueryData<GraphQL.ListOpenApiProductsQuery>;
}

const AddNewProduct: React.FC<AddNewProductProps> = ({ openApiProducts }) => {
  const navigateRoute = useNavigateRoute();

  const [selectedSize, setSelectedSize] = React.useState<Rest.ProductListingResponse["sizes"][string][number]>();
  const [selectedCover, setSelectedCover] = React.useState<Rest.ProductListingResponse["covers"][number]>();
  const [selectedPaper, setSelectedPaper] = React.useState<Rest.ProductListingResponse["papers"][number]>();

  const [pageQuantity, setPageQuantity] = React.useState(20);

  const { data: priceListInfoData } = useQuery(["price-list-info"], Rest.getPriceListInfo);
  const priceListInfo = React.useMemo(() => priceListInfoData?.data, [priceListInfoData]);

  const { data: productListingData } = useQuery(["product-listing"], Rest.getProductListing, {
    onSuccess: ({ sizes, covers, papers }) => {
      setSelectedSize((selected) => (selected ? selected : sizes[Object.keys(sizes)[0]][0]));
      setSelectedCover((selected) => (selected ? selected : covers[0]));
      setSelectedPaper((selected) => (selected ? selected : papers[0]));
    },
  });
  const { sizes, covers, papers } = React.useMemo(
    () => (productListingData || {}) as Rest.ProductListingResponse,
    [productListingData]
  );

  const selectedPriceListing = React.useMemo(() => {
    if (!productListingData || !selectedSize || !selectedCover || !selectedPaper) return;

    const sku = productListingData.pricing_sku_mapping.find(
      (map) =>
        map.size === selectedSize.price_key &&
        map.cover === selectedCover.price_key &&
        map.paper === selectedPaper?.price_key
    )?.sku;

    if (!sku) return;
    return priceListInfo?.products.find((product) => product.openapiSku === sku);
  }, [productListingData, priceListInfo, selectedSize, selectedCover, selectedPaper]);

  const isProductAssociated = React.useMemo(() => {
    if (!openApiProducts) return false;
    if (!selectedPriceListing?.openapiSku) return false;
    return !openApiProducts.find((oap) => oap.openapiSku === selectedPriceListing.openapiSku);
  }, [openApiProducts, selectedPriceListing]);

  const selectedPrice = React.useMemo(() => {
    if (!selectedPriceListing) return 0;

    const extraPages =
      pageQuantity < selectedPriceListing.minPageCount
        ? selectedPriceListing.minPageCount
        : pageQuantity - selectedPriceListing.minPageCount;

    return selectedPriceListing.basePriceUsd + extraPages * selectedPriceListing.pageCostUsd;
  }, [selectedPriceListing, pageQuantity]);

  React.useEffect(() => {
    if (selectedPriceListing && pageQuantity < selectedPriceListing.minPageCount) {
      setPageQuantity(selectedPriceListing.minPageCount);
    }
  }, [selectedPriceListing, pageQuantity]);

  return (
    <AppPage.Section mt={40} title="Add a new product" isLoading={!productListingData || !priceListInfo}>
      {productListingData && priceListInfo && (
        <Flex.Column gap={32} sx={(theme) => ({ color: theme.colors.brand[5], flex: 1, position: "relative" })}>
          <Flex.Column gap={24}>
            <RpiText type="h3" weight="bold" children="1. Choose a book size" />
            <OverflowX>
              <Flex.Row
                gap={16}
                children={
                  sizes &&
                  Object.keys(sizes).map((key, i) => {
                    const options = sizes[key];

                    return (
                      <ProductOptionCard
                        key={`size-option-${i}`}
                        src={`/assets/images/book-sizes/${key}_size.png`}
                        variants={options.map((option) => ({
                          name: option.inches,
                          description: [option.centimeters],
                          onClick: () => setSelectedSize(option),
                          actionNode: (
                            <RpiCheckbox
                              mt={7}
                              checked={selectedSize?.price_key === option.price_key}
                              readOnly
                              variant="radio"
                            />
                          ),
                        }))}
                      />
                    );
                  })
                }
              />
            </OverflowX>
          </Flex.Column>

          <Flex.Column gap={24}>
            <RpiText type="h3" weight="bold" children="2. Choose a cover for your book" />
            <OverflowX>
              <Flex.Row
                gap={16}
                children={covers?.map((cover, i) => {
                  const isDisabled = cover.price_key === "SC" && selectedSize?.price_key === "12x12";
                  const isSelected = selectedCover?.price_key === cover.price_key;

                  if (isDisabled && isSelected) setSelectedCover(undefined);

                  return (
                    <ProductOptionCard
                      key={`cover-option-${i}`}
                      src={`/assets/images/book-covers/${cover.price_key}_cover.png`}
                      variants={[
                        {
                          name: cover.name,
                          description: cover.description_lines,
                          bullet: true,
                          disabled: isDisabled,
                          onClick: !isDisabled ? () => setSelectedCover(cover) : undefined,
                          actionNode: (
                            <RpiCheckbox mt={7} checked={isSelected} readOnly variant="radio" disabled={isDisabled} />
                          ),
                        },
                      ]}
                    />
                  );
                })}
              />
            </OverflowX>
          </Flex.Column>

          <Flex.Column gap={24} fullWidth>
            <RpiText type="h3" weight="bold" children="3. Choose the paper type" />
            <OverflowX>
              <Flex.Row
                fullWidth
                gap={16}
                children={papers?.map((paper, i) => (
                  <ProductOptionCard
                    key={`paper-option-${i}`}
                    src={`/assets/images/book-papers/${paper.price_key}_paper.png`}
                    size={papers?.length > 1 ? "sm" : "lg"}
                    variants={[
                      {
                        name: paper.name,
                        description: paper.description_lines,
                        bullet: true,
                        onClick: () => setSelectedPaper(paper),
                        actionNode: (
                          <RpiCheckbox
                            mt={7}
                            checked={selectedPaper?.price_key === paper.price_key}
                            readOnly
                            variant="radio"
                          />
                        ),
                      },
                    ]}
                  />
                ))}
              />
            </OverflowX>
          </Flex.Column>

          <Grid
            px={28}
            py={32}
            gap={24}
            columns="1fr 1fr"
            sx={(theme) => ({
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.colors.brand[5],
              borderRadius: 24,
              maxWidth: 1123,
              width: "100%",
            })}
          >
            <RpiText type="h2" weight="bold" sx={{ gridColumn: "span 2" }} children="Product summary" />

            <div>
              <RpiText type="p3" weight="light" children="Photo book size" />
              <RpiText
                type="p1"
                weight="regular"
                sx={{ minHeight: 24 }}
                children={selectedSize && `${selectedSize.inches} | ${selectedSize.centimeters}`}
              />
            </div>

            <Flex.Column justify="space-between" sx={{ gridRow: "span 2" }}>
              <RpiText type="p2" weight="regular" children="Choose page quantity" />

              <RpiSlider
                mb={16}
                min={selectedPriceListing ? selectedPriceListing.minPageCount : 20}
                max={380}
                marks={[
                  { value: selectedPriceListing ? selectedPriceListing.minPageCount : 20, label: 20 },
                  { value: 380, label: 380 },
                ]}
                value={pageQuantity}
                onChange={setPageQuantity}
              />
            </Flex.Column>

            <div>
              <RpiText type="p3" weight="light" children="Cover" />
              <RpiText type="p1" weight="regular" sx={{ minHeight: 24 }} children={selectedCover?.name} />
            </div>

            <div>
              <RpiText type="p3" weight="light" children="Paper type" />
              <RpiText type="p1" weight="regular" sx={{ minHeight: 24 }} children={selectedPaper?.name} />
            </div>

            <Flex.Column gap={24}>
              <RpiText
                type="h1"
                weight="bold"
                color={(theme) => theme.colors.spark[5]}
                children={`From US ${formatCurrency(GraphQL.SupportedCurrency.Usd, selectedPrice)}`}
              />
              <RpiText type="p3" weight="regular" children="Cost per book does not include taxes and shipping." />

              <Flex.Row gap={16} align="baseline">
                <RpiButton
                  variant="mellow"
                  trailingIcon
                  width="fit"
                  disabled={!selectedSize || !selectedCover || !selectedPaper || !selectedPrice || isProductAssociated}
                  children="Add product"
                  onClick={() =>
                    navigateRoute(["customer-product:create"], { state: { sku: selectedPriceListing?.openapiSku } })
                  }
                />

                {isProductAssociated && (
                  <RpiText
                    type="p3"
                    weight="regular"
                    color={(theme) => theme.colors.spark[5]}
                    children="This product was already added."
                  />
                )}
              </Flex.Row>
            </Flex.Column>
          </Grid>
        </Flex.Column>
      )}
    </AppPage.Section>
  );
};
