import { Box } from "@mantine/core";
import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";
import { isNumber } from "../../utils/type.util";
import { ExtendedRpiSearchFilterInput, RpiSearchFilterProps } from "./RpiSearchFilter";
import { RpiSearchDateFilter, RpiSearchDateFilterProps, RpiSearchFilterCreate } from "./RpiSearchFilterInput";

interface RpiSearchFilterDropdownProps<TField> {
  filters: RpiSearchFilterProps<TField>["filters"];
  dateFilter?: RpiSearchDateFilterProps<TField>;
  inputs: ExtendedRpiSearchFilterInput<TField>[];
  onChange: (inputs: ExtendedRpiSearchFilterInput<TField>[]) => void;
  onClear: () => void;
}

export function RpiSearchFilterDropdown<T>({
  filters,
  dateFilter,
  inputs,
  onChange,
  onClear,
}: RpiSearchFilterDropdownProps<T>): React.ReactElement {
  const [selected, setSelected] = React.useState<number>();

  const isSelected = React.useMemo(() => isNumber(selected), [selected]);

  const inputFields = React.useMemo(() => {
    if (!isSelected) return;

    const selectedInputs: ExtendedRpiSearchFilterInput<T>[] = [];
    const restInputs: ExtendedRpiSearchFilterInput<T>[] = [];

    inputs.forEach((input) => {
      if (input.field === filters[selected!].field) selectedInputs.push(input);
      else restInputs.push(input);
    });

    return (
      <RpiSearchFilterCreate
        filter={filters[selected!]}
        inputs={selectedInputs}
        onChange={(updatedInputs) => onChange([...restInputs, ...updatedInputs])}
      />
    );
  }, [isSelected, inputs, onChange, filters]);

  return (
    <Flex.Column gap={8} py={14} sx={(theme) => ({ color: theme.colors.brand[5] })}>
      <Flex.Row px={20} fullWidth justify="space-between" mb={6}>
        <Flex.Row gap={4}>
          <RpiText type="p3" weight="regular" children="Filter by" />
          {isSelected && <RpiText type="p3" weight="bold" children={filters[selected!].label} />}
        </Flex.Row>

        <RpiText
          type="p3"
          weight="regular"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={onClear}
          children="Clear"
        />
      </Flex.Row>

      <Box px={8}>
        <Box sx={(theme) => ({ width: "100%", height: 1, background: theme.colors.calm[5] })} />
      </Box>

      <Flex.Column
        mx={4}
        sx={isSelected && filters[selected!].type !== "date" ? { maxHeight: 250, overflowY: "auto" } : undefined}
      >
        {isSelected
          ? inputFields
          : filters.map((filter, i) => {
              return (
                <RpiText
                  key={`filter-value-${i}`}
                  px={16}
                  py={8}
                  type="p3"
                  weight="regular"
                  sx={(theme) => ({
                    cursor: "pointer",
                    borderRadius: 8,
                    "&:hover": {
                      background: theme.colors.calm[4],
                    },
                  })}
                  children={filter.label}
                  onClick={() => setSelected(i)}
                />
              );
            })}
      </Flex.Column>

      {!isSelected && dateFilter && (
        <>
          <Box px={8}>
            <Box sx={(theme) => ({ width: "100%", height: 1, background: theme.colors.calm[5] })} />
          </Box>

          <RpiSearchDateFilter {...dateFilter} />
        </>
      )}
    </Flex.Column>
  );
}
