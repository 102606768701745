import { useForm } from "@mantine/form";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { RpiCheckbox, RpiDrawerPasswordInput } from "@rpi/openapi-core";
import React from "react";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../rpi-core/drawer/RpiDrawer";
import { shouldRetry } from "../../utils/query.util";

export const SettingsEditAccountPasswordView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    const { data, isLoading, error } = GraphQL.useDashboardUserCurrentQuery(
      {},
      {
        enabled: opened,
        refetchOnMount: true,
        retry: shouldRetry(handleClose),
        cacheTime: 0,
      }
    );
    const dashboardUser = React.useMemo(() => data?.dashboardUserCurrent, [data]);

    return (
      <RpiDrawer title="Edit password" isLoading={isLoading} error={error} opened={opened} onClose={handleClose}>
        {dashboardUser && <EditPassword dashboardUser={dashboardUser} onClose={() => handleClose(true)} />}
      </RpiDrawer>
    );
  }
);

interface EditPasswordProps {
  dashboardUser: GraphQLQueryData<GraphQL.DashboardUserCurrentQuery>;
  onClose: () => void;
}

const EditPassword: React.FC<EditPasswordProps> = ({ dashboardUser, onClose }) => {
  const form = useForm<
    GraphQL.ChangeDashboardUserPasswordMutationVariables["input"] & { currentPassword: string; confirmPassword: string }
  >({
    initialValues: {
      newPassword: "",
      requireReset: false,
      username: dashboardUser.username || "",

      currentPassword: "",
      confirmPassword: "",
    },

    validate({ newPassword, currentPassword, confirmPassword }) {
      // TODO: Current password validation

      if (newPassword === currentPassword) {
        return {
          newPassword: "Password is the same as the current password.",
        };
      }

      if (newPassword !== confirmPassword) {
        return {
          confirmPassword: "The password confirmation does not match.",
        };
      }

      return {};
    },
  });

  const {
    mutateAsync: changePassword,
    reset,
    ...mutation
  } = GraphQL.useChangeDashboardUserPasswordMutation<{ message: string }>({
    onError: (error) => {
      form.setFieldError("confirmPassword", error?.message || "Invalid password");
      setTimeout(reset, 1000);
    },
    onSuccess: () => {
      onClose();
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async ({ currentPassword, confirmPassword, ...input }: typeof form.values) => {
      await changePassword({ id: dashboardUser.id, input });
    },
    [changePassword, dashboardUser, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Editing password...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to edit password...",
          description: "Please try again later.",
        },
        success: {
          message: "Password edited!",
        },
      })(mutation),
    [mutation]
  );

  return (
    <RpiDrawerForm button="Save changes" overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerPasswordInput required label="Current password" {...form.getInputProps("currentPassword")} />
      <RpiDrawerPasswordInput required label="New password" {...form.getInputProps("newPassword")} />
      <RpiDrawerPasswordInput required label="Confirm new password" {...form.getInputProps("confirmPassword")} />

      <RpiCheckbox label="Require reset" {...form.getInputProps("requireReset")} />
    </RpiDrawerForm>
  );
};
