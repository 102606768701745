const ENV = process.env.REACT_APP_ENV;

export const Environment = {
  value: ENV,
  name: {
    dev: "dev",
    test: "test",
    sand: "sandbox",
    prod: "production",
    "prod-eu": "production",
  }[ENV],

  isDev: ENV === "dev",
  isTest: ENV === "test",
  isSandbox: ENV === "sand",
  isProduction: ENV === "prod" || ENV === "prod-eu",
};
