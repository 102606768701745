import { GraphQL } from "@rpi/openapi-api";

export const DASHBOARD_PATH = "/dashboard";
export const ADMIN_DASHBOARD_PATH = `${DASHBOARD_PATH}/admin`;

// prettier-ignore
export const routePaths = {
  "auth": "/auth",
  "auth:sign-in": "/auth/sign-in",
  "auth:sign-up": "/auth/sign-up",
  "auth:reset-password": "/auth/reset-password",

  "home": DASHBOARD_PATH,

  "settings": `${DASHBOARD_PATH}/settings`,
  "settings:edit-account": `${DASHBOARD_PATH}/settings/edit-account`,
  "settings:edit-password": `${DASHBOARD_PATH}/settings/edit-password`,

  "customer-products": `${DASHBOARD_PATH}/customer-products`,
  "customer-product": ({ id }: Pick<GraphQL.CustomerProductResponse, "id">) => `${DASHBOARD_PATH}/customer-products/${id}`,
  "customer-product:edit": ({ id }: Pick<GraphQL.CustomerProductResponse, "id">) => `${DASHBOARD_PATH}/customer-products/${id}/edit`,
  "customer-product:create": `${DASHBOARD_PATH}/customer-products/create`,

  "communications": `${ADMIN_DASHBOARD_PATH}/communications`,
  "communication": ({ id }: Pick<GraphQL.CustomerCommunicationResponse, "id">) => `${ADMIN_DASHBOARD_PATH}/communications/${id}`,

  "print-assets": `${DASHBOARD_PATH}/print-assets`,
  "print-asset": ({ id }: Pick<GraphQL.PrintAssetResponse, "id">) => `${DASHBOARD_PATH}/print-assets/${id}`,

  "orders": `${DASHBOARD_PATH}/orders`,
  "orders-attention": `${DASHBOARD_PATH}/orders?attention=true`,
  "orders:purchase": `${DASHBOARD_PATH}/orders/purchase`,
  "orders:purchase:confirmation": ({ braintreeTransactionId }: Pick<GraphQL.PaymentSingleResponse, "braintreeTransactionId">) => `${DASHBOARD_PATH}/orders/purchase/confirmation/${braintreeTransactionId}`,
  "orders:purchase:payment-method:edit": ({ token }: Pick<GraphQL.BraintreePaymentMethodResponse, "token">) => `${DASHBOARD_PATH}/orders/purchase/payment-methods/${token}/edit`,
  "orders:purchase:payment-method:create": `${DASHBOARD_PATH}/orders/purchase/payment-methods/create`,
  "order": ({ id }: Pick<GraphQL.OrderSingleResponse, "id">) => `${DASHBOARD_PATH}/orders/${id}`,
  "order:item": ({ orderId, itemId }: { orderId: GraphQL.OrderSingleResponse ["id"], itemId: GraphQL.OrderItemResponse["id"]}) => `${DASHBOARD_PATH}/orders/${orderId}/items/${itemId}`,

  "api-credentials": `${DASHBOARD_PATH}/api-credentials`,
  "api-credential:create": `${DASHBOARD_PATH}/api-credentials/create`,
  "api-examples": `${DASHBOARD_PATH}/api-examples`,

  "customers": `${ADMIN_DASHBOARD_PATH}/customers`,
  "customer": ({ publicId }: Pick<GraphQL.CustomerResponse, "publicId">) => `${ADMIN_DASHBOARD_PATH}/customers/${publicId}`,
  "customer:edit": ({ publicId }: Pick<GraphQL.CustomerResponse, "publicId">) => `${ADMIN_DASHBOARD_PATH}/customers/${publicId}/edit`,
  "customer:create": `${ADMIN_DASHBOARD_PATH}/customers/create`,

  "open-api-products": `${ADMIN_DASHBOARD_PATH}/open-api-products`,
  "open-api-product": ({ id }: Pick<GraphQL.OpenapiProductResponse, "id">) => `${ADMIN_DASHBOARD_PATH}/open-api-products/${id}`,

  "dashboard-users": `${ADMIN_DASHBOARD_PATH}/dashboard-users`,
  "dashboard-user": ({ id }: Pick<GraphQL.DashboardUserResponse, "id">) => `${ADMIN_DASHBOARD_PATH}/dashboard-users/${id}`,
  "dashboard-user:edit": ({ id }: Pick<GraphQL.DashboardUserResponse, "id">) => `${ADMIN_DASHBOARD_PATH}/dashboard-users/${id}/edit`,
  "dashboard-user:create": `${ADMIN_DASHBOARD_PATH}/dashboard-users/create`,

  "billing": `${ADMIN_DASHBOARD_PATH}/billing`,
  "payment-method:edit": ({ token }: Pick<GraphQL.BraintreePaymentMethodResponse, "token">) => `${ADMIN_DASHBOARD_PATH}/billing/payment-methods/${token}/edit`,
  "payment-method:create": `${ADMIN_DASHBOARD_PATH}/billing/payment-methods/create`,
  "billing:file-upload": `${ADMIN_DASHBOARD_PATH}/billing/file-upload`,

  "uploads": `${ADMIN_DASHBOARD_PATH}/uploads`,
  "uploads:upload": `${ADMIN_DASHBOARD_PATH}/uploads/upload`,
  "uploads:collection": ({ collectionId, customerPublicId }: Pick<GraphQL.UploadedFileResponse, "collectionId"> & { customerPublicId: GraphQL.CustomerResponse["publicId"]}) => (
    `${ADMIN_DASHBOARD_PATH}/uploads/customers/${customerPublicId}/collections/${collectionId}`
  ),

  "payment": ({ id }: Pick<GraphQL.PaymentResponse, "id">) => `${ADMIN_DASHBOARD_PATH}/payments/${id}`,
  
  "configuration": `${ADMIN_DASHBOARD_PATH}/configuration`,
} as const;
