import { createBrowserRouter } from "react-router-dom";
import { AppLayout } from "./layouts/AppLayout";
import { DashboardLayout } from "./layouts/dashboard/DashboardLayout";
import { BillingView } from "./views/admin/billing/BillingView";
import { PaymentMethodCreateView } from "./views/admin/billing/PaymentMethodCreateView";
import { PaymentMethodEditView } from "./views/admin/billing/PaymentMethodEditView";
import { PaymentView } from "./views/admin/billing/PaymentView";
import { ConfigurationView } from "./views/admin/configuration/ConfigurationView";
import { CustomerCreateView } from "./views/admin/customers/CustomerCreateView";
import { CustomerEditView } from "./views/admin/customers/CustomerEditView";
import { CustomersView } from "./views/admin/customers/CustomersView";
import { CustomerView } from "./views/admin/customers/CustomerView";
import { DashboardUserCreateView } from "./views/admin/dashboard-users/DashboardUserCreateView";
import { DashboardUserEditView } from "./views/admin/dashboard-users/DashboardUserEditView";
import { DashboardUsersView } from "./views/admin/dashboard-users/DashboardUsersView";
import { DashboardUserView } from "./views/admin/dashboard-users/DashboardUserView";
import { OpenApiProductsView } from "./views/admin/open-api-products/OpenApiProductsView";
import { OpenApiProductView } from "./views/admin/open-api-products/OpenApiProductView";
import { ApiCredentialCreateView } from "./views/api-credentials/ApiCredentialCreateView";
import { ApiCredentialsView } from "./views/api-credentials/ApiCredentialsView";
import { ApiExamplesView } from "./views/ApiExamplesView";
import { AuthView } from "./views/AuthView";
import { CustomerProductCreateView } from "./views/customer-products/CustomerProductCreateView";
import { CustomerProductEditView } from "./views/customer-products/CustomerProductEditView";
import { CustomerProductsView } from "./views/customer-products/CustomerProductsView";
import { CustomerProductView } from "./views/customer-products/CustomerProductView";
import { Error404View } from "./views/error/Error404View";
import { HomeView } from "./views/HomeView";
import { IndexView } from "./views/index/IndexView";
import { OrderItemView } from "./views/orders/OrderItemView";
import { OrdersView } from "./views/orders/OrdersView";
import { OrderView } from "./views/orders/OrderView";
import { PurchaseOrdersConfirmationView } from "./views/orders/PurchaseOrdersConfirmationView";
import { PurchaseOrdersView } from "./views/orders/PurchaseOrdersView";
import { PrintAssetsView } from "./views/print-asset/PrintAssetsView";
import { PrintAssetView } from "./views/print-asset/PrintAssetView";
import { SettingsEditAccountPasswordView } from "./views/settings/SettingsEditAccountPasswordView";
import { SettingsEditAccountView } from "./views/settings/SettingsEditAccountView";
import { SettingsView } from "./views/settings/SettingsView";
import { DesignLayout } from "./views/temporary/DesignLayout";
import { DesignV2View } from "./views/temporary/DesignV2View";
import { DesignView } from "./views/temporary/DesignView";
import { CommunicationsView } from "./views/admin/communications/CommunicationsView";
import { CommunicationView } from "./views/admin/communications/CommunicationView";
import { GraphQL } from "@rpi/openapi-api";
import { UploadsView } from "./views/admin/uploads/UploadsView";
import { UploadFilesView } from "./views/admin/uploads/UploadFilesView";
import { UploadsCollectionView } from "./views/admin/uploads/UploadsCollectionView";
import { TaxFileUploadView } from "./views/admin/billing/TaxFileUploadView";

export const DASHBOARD_PATH = "/dashboard";
const ADMIN_PATH = "admin";
export const ADMIN_DASHBOARD_PATH = `${DASHBOARD_PATH}/${ADMIN_PATH}`;

export const router = createBrowserRouter([
  {
    path: DASHBOARD_PATH,
    element: <AppLayout.Private />,
    errorElement: <Error404View />,
    children: [
      {
        path: "",
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <HomeView />,
          },
          {
            path: "settings",
            element: <SettingsView />,
            children: [
              {
                path: "edit-account",
                element: <SettingsEditAccountView />,
              },
              {
                path: "edit-password",
                element: <SettingsEditAccountPasswordView />,
              },
            ],
          },
          {
            path: "customer-products",
            element: <CustomerProductsView />,
            children: [
              {
                path: "create",
                element: <CustomerProductCreateView />,
              },
              {
                path: ":id",
                element: <CustomerProductView />,
              },
              {
                path: ":id/edit",
                element: <CustomerProductEditView />,
              },
            ],
          },
          {
            path: "print-assets",
            children: [
              {
                index: true,
                element: <PrintAssetsView />,
              },
              {
                path: ":id",
                element: <PrintAssetView />,
              },
            ],
          },
          {
            path: "orders",
            children: [
              {
                index: true,
                element: <OrdersView />,
              },
              {
                path: ":id",
                element: <OrderView />,
              },
              {
                path: ":orderId/items/:itemId",
                element: <OrderItemView />,
              },
              {
                path: "purchase",
                handle: {
                  requiredRole: [GraphQL.DashboardUserRole.CustomerAdmin, GraphQL.DashboardUserRole.User],
                },
                element: <PurchaseOrdersView />,
                children: [
                  {
                    path: "payment-methods",
                    children: [
                      {
                        path: "create",
                        element: <PaymentMethodCreateView redirect="../../" />,
                      },
                      {
                        path: ":token/edit",
                        element: <PaymentMethodEditView redirect="../../" />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "purchase/confirmation/:braintreeTransactionId",
                element: <PurchaseOrdersConfirmationView />,
              },
            ],
          },
          {
            path: "api-credentials",
            element: <ApiCredentialsView />,
            children: [
              {
                path: "create",
                handle: {
                  requiredRole: [GraphQL.DashboardUserRole.CustomerAdmin, GraphQL.DashboardUserRole.User],
                },
                element: <ApiCredentialCreateView />,
              },
            ],
          },
          {
            path: "api-examples",
            element: <ApiExamplesView />,
          },
          {
            path: ADMIN_PATH,
            handle: {
              requiredRole: [GraphQL.DashboardUserRole.SuperAdmin, GraphQL.DashboardUserRole.CustomerAdmin],
            },
            children: [
              {
                path: "customers",
                element: <CustomersView />,
                handle: {
                  requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
                },
                children: [
                  {
                    path: "create",
                    element: <CustomerCreateView />,
                  },
                ],
              },
              {
                path: "customers/:customerPublicId",
                element: <CustomerView />,
                handle: {
                  requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
                },
                children: [
                  {
                    path: "edit",
                    element: <CustomerEditView />,
                  },
                ],
              },
              {
                path: "communications",
                handle: {
                  requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
                },
                children: [
                  {
                    index: true,
                    element: <CommunicationsView />,
                  },
                  {
                    path: ":id",
                    element: <CommunicationView />,
                  },
                ],
              },
              {
                path: "open-api-products",
                handle: {
                  requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
                },
                element: <OpenApiProductsView />,
                children: [
                  {
                    path: ":id",
                    element: <OpenApiProductView />,
                  },
                ],
              },
              {
                path: "dashboard-users",
                element: <DashboardUsersView />,
                children: [
                  {
                    path: "create",
                    element: <DashboardUserCreateView />,
                  },
                  {
                    path: ":id",
                    element: <DashboardUserView />,
                  },
                  {
                    path: ":id/edit",
                    element: <DashboardUserEditView />,
                  },
                ],
              },
              {
                path: "billing",
                element: <BillingView />,
                children: [
                  {
                    path: "payment-methods",
                    handle: {
                      requiredRole: [GraphQL.DashboardUserRole.CustomerAdmin, GraphQL.DashboardUserRole.User],
                    },
                    children: [
                      {
                        path: "create",
                        element: <PaymentMethodCreateView redirect="../../" />,
                      },
                      {
                        path: ":token/edit",
                        element: <PaymentMethodEditView redirect="../../" />,
                      },
                    ],
                  },
                  {
                    path: "file-upload",
                    handle: {
                      requiredRole: [GraphQL.DashboardUserRole.CustomerAdmin],
                    },
                    element: <TaxFileUploadView />,
                  },
                ],
              },
              {
                path: "uploads",
                element: <UploadsView />,
                handle: {
                  requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
                },
                children: [
                  {
                    path: "upload",
                    element: <UploadFilesView />,
                  },
                ],
              },
              {
                path: "uploads",
                handle: {
                  requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
                },
                children: [
                  {
                    path: "customers/:customerPublicId/collections/:collectionId",
                    element: <UploadsCollectionView />,
                  },
                ],
              },
              {
                path: "payments",
                children: [
                  {
                    path: ":id",
                    element: <PaymentView />,
                  },
                ],
              },
              {
                path: "configuration",
                handle: {
                  requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
                },
                children: [
                  {
                    index: true,
                    element: <ConfigurationView />,
                  },
                ],
              },
            ],
          },
          {
            path: "*",
            element: <Error404View root={false} hideButton />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <AppLayout.Public />,
    errorElement: <Error404View />,
    children: [
      {
        index: true,
        element: <IndexView />,
      },
      {
        path: "auth",
        children: [
          {
            index: true,
            element: <AuthView />,
          },
          {
            path: "sign-in",
            element: <AuthView initialState="signIn" />,
          },
          {
            path: "sign-up",
            element: <AuthView initialState="signUp" />,
          },
          {
            path: "reset-password",
            element: <AuthView initialState="resetPassword" />,
          },
        ],
      },
      {
        path: "design",
        element: <DesignLayout />,
        children: [
          {
            index: true,
            element: <DesignView />,
          },
          {
            path: "v2",
            element: <DesignV2View />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Error404View />,
  },
]);
