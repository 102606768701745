import { Rest } from "@rpi/openapi-api";
import { Flex, RpiText } from "@rpi/openapi-core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { ProductOptionCard } from "../../components/ProductOptionCard";
import { LANDING_PAGE_MAX_WIDTH } from "./IndexView";

export const ProductListing: React.FC = () => {
  const { data: productListingData } = useQuery(["product-listing"], Rest.getProductListing);
  const { sizes, covers, papers } = React.useMemo(
    () => (productListingData || {}) as Rest.ProductListingResponse,
    [productListingData]
  );

  return (
    <Flex.Column
      py={40}
      px={80}
      align="center"
      fullWidth
      sx={(theme) => ({ color: theme.colors.brand[5], overflow: "hidden" })}
    >
      <Flex.Column gap={32} fullWidth sx={{ maxWidth: LANDING_PAGE_MAX_WIDTH }}>
        <RpiText type="d2" weight="bold" children="Product Listing" />
        <RpiText type="h2" weight="bold" children="Professional-quality photo books" />

        <Flex.Column gap={24} fullWidth>
          <RpiText type="h3" weight="bold" children="Book size" />
          <Flex.Row
            fullWidth
            gap={16}
            sx={{ overflowX: "auto" }}
            children={
              sizes
                ? Object.keys(sizes).map((key, i) => {
                    const options = sizes[key];

                    return (
                      <ProductOptionCard
                        key={`size-option-${i}`}
                        src={`/assets/images/book-sizes/${key}_size.png`}
                        variants={options.map((option) => ({
                          name: option.inches,
                          description: [option.centimeters],
                        }))}
                      />
                    );
                  })
                : Array(3)
                    .fill(0)
                    .map((_, i) => <ProductOptionCard key={`size-option-${i}-loading`} />)
            }
          />
        </Flex.Column>

        <Flex.Column gap={24} fullWidth>
          <RpiText type="h3" weight="bold" children="Perfect Paper" />
          <Flex.Column
            fullWidth
            gap={16}
            sx={{ overflowX: "auto" }}
            children={
              papers ? (
                papers.map((paper, i) => (
                  <ProductOptionCard
                    key={`paper-option-${i}`}
                    src={`/assets/images/book-papers/${paper.price_key}_paper.png`}
                    size={papers?.length > 1 ? "sm" : "lg"}
                    variants={[
                      {
                        name: paper.name,
                        description: paper.description_lines,
                        bullet: true,
                      },
                    ]}
                  />
                ))
              ) : (
                <ProductOptionCard size="lg" />
              )
            }
          />
        </Flex.Column>

        <Flex.Column gap={24} fullWidth>
          <RpiText type="h3" weight="bold" children="Solid Cover" />
          <Flex.Row
            fullWidth
            gap={16}
            sx={{ overflowX: "auto" }}
            children={
              covers
                ? covers?.map((cover, i) => (
                    <ProductOptionCard
                      key={`cover-option-${i}`}
                      src={`/assets/images/book-covers/${cover.price_key}_cover.png`}
                      variants={[
                        {
                          name: cover.name,
                          description: cover.description_lines,
                          bullet: true,
                        },
                      ]}
                    />
                  ))
                : Array(3)
                    .fill(0)
                    .map((_, i) => <ProductOptionCard key={`cover-option-${i}-loading`} />)
            }
          />
        </Flex.Column>
      </Flex.Column>
    </Flex.Column>
  );
};
