import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { CellDate } from "../../components/cells/CellDate";
import { CellJson } from "../../components/cells/CellJson";

export type CustomShipmentResponse<T> = T & {
  parsedRawShipmentData?: any;
};

const shipmentColumn = createEntityColumn<CustomShipmentResponse<GraphQL.ShipmentResponse>>();

export const ShipmentColumn = {
  id: shipmentColumn<"id">({
    header: "ID",
    accessor: "id",
    minSize: 100,
    size: 150,
  }),
  isFinalShipment: shipmentColumn<"isFinalShipment">({
    header: "Is Final Shipment",
    accessor: "isFinalShipment",
    size: 200,
  }),
  masterTrackingNumber: shipmentColumn<"masterTrackingNumber">({
    header: "Master tracking number",
    accessor: "masterTrackingNumber",
    size: 260,
  }),
  secondaryTrackingNumber: shipmentColumn<"secondaryTrackingNumber">({
    header: "Secondary tracking number",
    accessor: "secondaryTrackingNumber",
    size: 260,
  }),
  rawShipmentData: shipmentColumn<"rawShipmentData">({
    header: "Raw data",
    accessor: "rawShipmentData",
    cell: ({ row }) => <CellJson data={row.rawShipmentData} />,
    size: 350,
  }),
  shippedDate: shipmentColumn<"shippedDate">({
    header: "Shipped date",
    accessor: "shippedDate",
    cell: ({ row }) => <CellDate date={row.shippedDate} />,
    size: 200,
  }),
  shippingMethodCode: shipmentColumn<"shippingMethodCode">({
    header: "ShippingMethodCode",
    accessor: "shippingMethodCode",
    minSize: 100,
    size: 150,
  }),
  status: shipmentColumn<"status">({
    header: "Status",
    accessor: "status",
    minSize: 100,
  }),
  trackingNumber: shipmentColumn<"trackingNumber">({
    header: "Tracking Number",
    accessor: "trackingNumber",
    size: 220,
  }),
  uosShipmentId: shipmentColumn<"uosShipmentId">({
    header: "uosShipmentId",
    accessor: "uosShipmentId",
    minSize: 100,
    size: 150,
  }),
  shipMethod: shipmentColumn<"shippingMethodCode">({
    header: "Shipping Method",
    accessor: "shippingMethodCode",
    cell: ({ row }) => row.shippingMethodCode,
    size: 170,
  }),
};

export const shipmentColumns: RpiTableColumns<
  CustomShipmentResponse<NonNullable<GraphQLQueryData<GraphQL.OrderQuery>["shipments"]>[number]>
> = [
  ShipmentColumn.id,
  ShipmentColumn.shipMethod,
  ShipmentColumn.status,
  ShipmentColumn.isFinalShipment,
  ShipmentColumn.masterTrackingNumber,
  ShipmentColumn.trackingNumber,
  ShipmentColumn.secondaryTrackingNumber,
  ShipmentColumn.shippedDate,
];
