import { Loader } from "@mantine/core";
import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";

interface RouteLoadingViewProps {
  /** Is root page. */
  root?: boolean;
}

export const RouteLoadingView: React.FC<RouteLoadingViewProps> = ({ root = true }) => {
  return (
    <Flex.Row
      gap={32}
      justify="center"
      align="center"
      sx={(theme) => ({ width: "100%", height: root ? "100vh" : "100%", color: theme.colors.brand[5] })}
    >
      <Loader size="lg" />
      <RpiText type="d2" weight="roman" children="Loading..." />
    </Flex.Row>
  );
};
