import { Box, Tooltip } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import { Flex } from "@rpi/openapi-core";
import React from "react";
import { ArrowDown, ArrowUp, InfoCircle } from "tabler-icons-react";
import { isNumber } from "../../utils/type.util";
import { useRpiTableSortBy } from "./modules/RpiTableSortBy";
import { RpiTableColumn } from "./RpiTable";
import { justifyFromAlign } from "./RpiTableHelpers";

interface RpiTableHeadProps<T> {
  columns: RpiTableColumn<T>[];
}

export function RpiTableHead<T>({ columns }: RpiTableHeadProps<T>): React.ReactElement {
  return (
    <thead>
      <tr>
        {columns.map((column, i) => (
          <RpiTableTh key={`rpi-table-th-${i}`} column={column} />
        ))}
        <th />
      </tr>
    </thead>
  );
}

interface RpiTableThProps<T> {
  column: RpiTableColumn<T>;
}

export function RpiTableTh<T>({ column }: RpiTableThProps<T>): React.ReactElement {
  const withSortBy = useRpiTableSortBy();
  const [width, setWidth] = React.useState<number>(() =>
    isNumber(column.minSize) && isNumber(column.maxSize) && column.minSize === column.maxSize
      ? column.minSize!
      : column.size || 100
  );

  const [isResizing, setIsResizing] = React.useState(false);

  const sortByKey = column.sortKey;
  const isSortable = !!(withSortBy && sortByKey);

  const content = React.useMemo(() => {
    const headerRender = (
      <div
        className="ellipsis"
        children={typeof column.header === "function" ? React.createElement(column.header) : column.header}
      />
    );

    if (!isSortable && !column.tooltip && (!column.align || column.align === "left")) return headerRender;

    const sortByRender =
      withSortBy !== null &&
      withSortBy.sortBy?.by &&
      withSortBy.sortBy.by === sortByKey &&
      React.createElement(withSortBy.sortBy.sortDirection === GraphQL.SortDirection.Asc ? ArrowUp : ArrowDown, {
        size: 16,
        style: { flexShrink: 0 },
      });

    return (
      <Flex.Row
        justify={column.align ? justifyFromAlign(column.align) : "flex-start"}
        align="center"
        sx={{ minWidth: 0 }}
        gap={4}
      >
        {headerRender}
        {column.tooltip && (
          <Tooltip label={column.tooltip} withArrow multiline width={column.tooltip.length > 25 ? 256 : "auto"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoCircle size={16} />
            </Box>
          </Tooltip>
        )}
        {sortByRender}
      </Flex.Row>
    );
  }, [column, withSortBy, isSortable]);

  const resizer = React.useMemo(() => {
    if (isNumber(column.minSize) && isNumber(column.maxSize) && column.minSize === column.maxSize) return;

    return (
      <Resizer
        width={width}
        onChange={(newWidth) => {
          // TODO: Table default configuration
          const minSize = column.minSize || 100;
          const maxSize = column.maxSize;

          if (newWidth < minSize) newWidth = minSize;
          else if (maxSize !== undefined && newWidth > maxSize) newWidth = maxSize;

          setWidth(newWidth);
        }}
        setIsResizing={(isResizing) => {
          if (isResizing) return setIsResizing(isResizing);
          setTimeout(() => setIsResizing(isResizing), 0);
        }}
      />
    );
  }, [column, width]);

  const handleSortBy = React.useCallback(() => {
    if (!withSortBy || !isSortable) return;

    let newSortBy: GraphQL.SortInput | undefined;
    if (withSortBy.sortBy?.by === sortByKey) {
      if (withSortBy.sortBy.sortDirection === GraphQL.SortDirection.Desc) {
        newSortBy = {
          by: sortByKey,
          sortDirection: GraphQL.SortDirection.Asc,
        };
      }
    } else {
      newSortBy = {
        by: sortByKey,
        sortDirection: GraphQL.SortDirection.Desc,
      };
    }

    withSortBy.setSortBy(() => newSortBy);
  }, [withSortBy, isSortable, sortByKey]);

  return (
    <th
      style={{
        width,
        ...(isSortable && { cursor: "pointer" }),
        ...(column.align && { textAlign: column.align }),
        ...(column.noPadding && { padding: "0px" }),
      }}
      onClick={!isResizing && isSortable ? handleSortBy : undefined}
    >
      {content}
      {resizer}
    </th>
  );
}

interface ResizerProps {
  width: number;
  onChange: (width: number) => void;
  setIsResizing: (isResizing: boolean) => void;
}

const Resizer: React.FC<ResizerProps> = ({ width, onChange, setIsResizing }) => {
  const [isListening, setIsListening] = React.useState<boolean>(false);
  const [position, setPosition] = React.useState<{ x: number; width: number } | null>(null);

  const handleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (!position) return;
      const newWidth = position.width + (e.pageX - position.x);
      onChange(newWidth < 0 ? 0 : newWidth);
    },
    [position, onChange]
  );

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const position = {
        x: e.pageX,
        width,
      };

      setPosition(position);
      setIsResizing(true);
    },
    [width, setIsResizing]
  );

  const handleMouseUp = React.useCallback(() => {
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("mousemove", handleMouseMove);

    setPosition(null);
    setIsListening(false);
    setIsResizing(false);
  }, [handleMouseMove, setIsResizing]);

  React.useEffect(() => {
    if (position) {
      if (!isListening) {
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("mousemove", handleMouseMove);
        setIsListening(true);
      }
    }
  }, [isListening, position, handleMouseMove, handleMouseUp]);

  return <div className="resizer" onMouseDown={handleMouseDown} onClick={(e) => e.stopPropagation()} />;
};
