import React from "react";
import { fallbackNumber } from "../utils/misc.util";
import { useRpiPerPage, UseRpiPerPageProps, UseRpiPerPageResult } from "./useRpiPerPage";

export interface UseRpiPaginationProps extends UseRpiPerPageProps {
  initialPage?: number;
  initialTotal?: number;
}

export interface UseRpiPaginationResult extends UseRpiPerPageResult {
  totalCount: number;
  setTotalCount: (total?: number | null) => void;
  page: number;
  pages: number;
  perPage: number;
  offset: number;
  setPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  firstPage: () => void;
  lastPage: () => void;
}

export function useRpiPagination({
  initialPage,
  initialTotal,

  ...perPageProps
}: UseRpiPaginationProps): UseRpiPaginationResult {
  const { perPage, setPerPage } = useRpiPerPage(perPageProps);

  const [totalCount, setTotalCount] = React.useState<number>(() => fallbackNumber(initialTotal, 0));
  const [page, setPage] = React.useState<number>(() => fallbackNumber(initialPage, 0));

  const offset = React.useMemo(() => page * perPage, [page, perPage]);
  const pages = React.useMemo(() => Math.ceil(totalCount / perPage), [totalCount, perPage]);

  const updatePage = (newPage: number) => {
    setPage(() => {
      if (newPage < 0) return 0;
      else if (newPage >= pages) return pages > 0 ? pages - 1 : 0;
      return newPage;
    });
  };

  React.useEffect(() => {
    if (page >= pages) {
      updatePage(0);
    }
  }, [page, pages]);

  React.useEffect(() => {
    return () => {
      // setTotalCount(0);
      setPage(0);
    };
  }, []);

  return {
    totalCount,
    setTotalCount: (total) => setTotalCount(total || 0),
    page,
    pages,
    offset,
    setPage: updatePage,
    nextPage: () => updatePage(page + 1),
    previousPage: () => updatePage(page - 1),
    firstPage: () => updatePage(0),
    lastPage: () => updatePage(pages),

    perPage,
    setPerPage,
  };
}
