import { Group, Text } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { CustomSelect, CustomSelectItemProps, CustomSelectWrapper } from "./SelectUtils";

type SelectCustomerProductType = Pick<GraphQL.CustomerProductResponse, "customerSku" | "name" | "description">;

const SelectCustomerProductItem = React.forwardRef<HTMLDivElement, CustomSelectItemProps<SelectCustomerProductType>>(
  ({ element, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Group noWrap spacing="xs">
            <Text size="sm" children={element.name} />
            <Text size="sm" color="dimmed" children={`(${element.customerSku})`} />
          </Group>
          {element.description && <Text size="xs" color="dimmed" children={element.description} />}
        </div>
      </Group>
    </div>
  )
);

export const SelectCustomerProduct: CustomSelectWrapper<SelectCustomerProductType> = ({ ...props }) => {
  return (
    <CustomSelect
      label="Product"
      placeholder="Select product"
      nothingFound="No Products"
      maxDropdownHeight={200}
      itemComponent={SelectCustomerProductItem}
      getItemProps={(customerProduct) => ({
        value: customerProduct.customerSku,
        label: customerProduct.name || customerProduct.customerSku,
        element: customerProduct,
      })}
      {...props}
    />
  );
};
