import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";
import Chart from "react-apexcharts";
import { DashboardCardBase } from "./DashboardCard";

interface DashboardGraphCardProps extends Omit<React.ComponentProps<typeof Chart>, "width" | "height"> {
  title: string;
  description?: string;
  className?: string;
}

export const DashboardGraphCard: React.FC<DashboardGraphCardProps> = ({ title, description, className, ...props }) => {
  return (
    <DashboardCardBase size="medium">
      <Flex.Column gap={24} sx={{ position: "relative", height: "100%" }}>
        <Flex.Column gap={8} sx={(theme) => ({ color: theme.colors.brand[5] })}>
          <RpiText type="p2" weight="bold" children={title} />
          {description && <RpiText type="p3" weight="light" children={description} />}
        </Flex.Column>

        <Flex.Column sx={{ flex: 1, position: "relative" }} className={className}>
          <Chart width="100%" height="100%" {...props} />
        </Flex.Column>
      </Flex.Column>
    </DashboardCardBase>
  );
};
