import { Box, Loader, UnstyledButtonProps } from "@mantine/core";
import { Flex, IconFn, RpiButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { RouteLink, RouteLinkProps, RoutePathsKeys } from "../../hooks/useNavigateRoute";
import { DashboardCardBase } from "./DashboardCard";
import { PolymorphicComponentProps } from "@mantine/utils";

type DashboardCardLink<T extends RoutePathsKeys> = { showTrailingIcon?: boolean } & (
  | RouteLinkProps<T>
  | PolymorphicComponentProps<"a", UnstyledButtonProps>
);

interface DashboardStatsCardProps<T extends RoutePathsKeys> {
  title?: string;
  description?: string;
  links?: Array<DashboardCardLink<T>> | DashboardCardLink<T>;
  icon: IconFn;
  isLoading?: boolean;
  children: React.ReactNode;
}

function createDashboardLink<T extends RoutePathsKeys>(link: DashboardCardLink<T>) {
  if (!link) return;

  if ("to" in link || "route" in link) {
    return (
      <RpiButton
        component={RouteLink}
        variant="spark-ghost"
        trailingIcon={link.showTrailingIcon}
        width="fit"
        p={0}
        sx={{ height: 32 }}
        {...link}
      />
    );
  }

  return (
    <RpiButton
      component="a"
      variant="spark-ghost"
      width="fit"
      trailingIcon={link.showTrailingIcon}
      p={0}
      sx={{ height: 32 }}
      {...link}
    />
  );
}

export function DashboardInfoCard<T extends RoutePathsKeys>({
  title,
  description,
  links,
  icon,
  isLoading,
  children,
}: DashboardStatsCardProps<T>) {
  let linkRender;
  if (links) {
    if (Array.isArray(links) && links.length > 0) {
      linkRender = links?.map((link) => createDashboardLink(link));
    } else {
      linkRender = createDashboardLink(links as DashboardCardLink<T>);
    }
  }

  return (
    <DashboardCardBase size="small">
      <Flex.Column justify="space-between" sx={{ height: "100%" }}>
        <Flex.Column gap={16}>
          <Box mt={4} children={React.createElement(icon, { size: 30 })} />
          <Flex.Column gap={8} sx={(theme) => ({ color: theme.colors.brand[5] })}>
            {title && <RpiText type="p3" weight="light" children={title} />}

            <Flex.Column>
              <Box sx={{ minHeight: 30 }}>
                {!isLoading && (
                  <RpiText type="h2" weight="bold" sx={{ lineHeight: "normal !important" }} children={children} />
                )}
                {isLoading && (
                  <Flex.Row align="center" sx={{ height: 30 }}>
                    <Loader variant="dots" height={24} />
                  </Flex.Row>
                )}
              </Box>

              {description && <RpiText type="p2" weight="regular" children={description} />}
            </Flex.Column>
          </Flex.Column>
        </Flex.Column>

        <>{linkRender}</>
      </Flex.Column>
    </DashboardCardBase>
  );
}
