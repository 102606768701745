import { RpiLink } from "@rpi/openapi-core";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { ExtendedLinkProps, RoutePathsKeys, getRoutePath } from "../../hooks/useNavigateRoute";
import { isString } from "../../utils/type.util";

type CellLinkProps = Omit<React.ComponentProps<typeof RpiLink<"a">>, "component" | "href"> &
  LinkProps & { external?: boolean };

export const CellLink: React.FC<CellLinkProps> = ({ children, ...props }) => {
  const childrenRender = React.useMemo(() => {
    if (children) return children;
    return isString(props.to) ? props.to : props.to.pathname;
  }, [children, props.to]);

  if (props.external) {
    const { to, external, ...rest } = props;
    return (
      <RpiLink
        variant="inherit"
        sx={(theme) => ({ color: theme.colors.brand[5], textDecoration: "underline" })}
        href={isString(to) ? to : to.pathname}
        children={childrenRender}
        {...rest}
      />
    );
  }

  return (
    <RpiLink
      component={Link}
      variant="inherit"
      sx={(theme) => ({ color: theme.colors.brand[5], textDecoration: "underline" })}
      children={childrenRender}
      {...props}
    />
  );
};

export type CellRouteProps<T extends RoutePathsKeys> = ExtendedLinkProps<T, CellLinkProps>;

export function CellRoute<T extends RoutePathsKeys>(props: CellRouteProps<T>): React.ReactElement {
  if ("route" in props) {
    const { route, ...rest } = props;
    return <CellLink to={getRoutePath(...route)} {...rest} />;
  }

  return <CellLink {...props} />;
}
