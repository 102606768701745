import { createStyles } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Flex, Grid, IconV2, RpiButton, RpiIconButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { isFunction } from "../utils/type.util";

const defaultContentTypes: string[] = [
  "application/pdf",
  "image/jpeg",
  "image/gif",
  "image/png",
  "text/xml",
  "text/html",
  "text/markdown",
  "application/oxps",
];

const useFilesInputStyles = createStyles<"input" | "root" | "files" | "file" | "fileTitle", { activeDrag?: boolean }>(
  (theme, { activeDrag }) => {
    return {
      root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        alignItems: "center",
        width: "100%",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.colors.calm[activeDrag ? 6 : 5],
        padding: 16,
        borderRadius: 6,
        background: theme.colors.calm[activeDrag ? 5 : 4],
        color: theme.colors.brand[5],
        overflowX: "hidden",
      },
      input: {
        display: "none",
      },
      files: {
        position: "relative",
        maxWidth: "100%",
        width: "100%",
      },
      file: {
        maxWidth: "100%",
        width: "100%",
        cursor: "default",
        userSelect: "none",
      },
      fileTitle: {
        height: 30,
        borderRadius: 6,
        background: theme.colors.brand[5],
        color: theme.colors.sky[5],
        maxWidth: "100%",
        width: "100%",

        "& > *": {
          color: `${theme.colors.sky[5]} !important`,
        },
      },
    };
  }
);

type FileInputInputs<TProps> = (setProps: (props: Partial<TProps>) => void, defaultProps?: TProps) => React.ReactNode;

type FilesInput<TProps, TFile> = {
  onChange: (files: TFile[]) => void;
  defaultProps?: TProps;
  defaultOpen?: boolean;
  contentTypes?: string[];
} & (TProps extends undefined
  ? {
      inputs?: void;
    }
  : {
      inputs: FileInputInputs<TProps>;
    });

export function FilesInput<
  TProps extends Record<string, any> | undefined,
  TFile extends { file: File } & Omit<TProps, "file">
>({
  onChange,
  defaultProps,
  defaultOpen = true,
  inputs,
  contentTypes = defaultContentTypes,
}: FilesInput<TProps, TFile>) {
  const [activeDrag, setActiveDrag] = React.useState(false);
  const [files, intSetFiles] = React.useState<TFile[]>([]);

  const { classes } = useFilesInputStyles({ activeDrag });

  const inputRef = React.useRef<HTMLInputElement>();

  const setFiles = React.useCallback(
    (newFiles: TFile[] | ((files: TFile[]) => TFile[])) => {
      const updatedFiles = isFunction(newFiles) ? newFiles(files) : newFiles;
      intSetFiles(updatedFiles);
      onChange(updatedFiles);
    },
    [onChange]
  );

  const handleFiles = React.useCallback(
    (fileList: FileList) => {
      const filtered = Array.from(fileList)
        .filter((file) => {
          const isAllowed = contentTypes.includes(file.type);
          if (!isAllowed) {
            showNotification({
              title: "Unsupported file type.",
              message: `"${file.name}" is of type "${file.type}".`,
              color: "red",
            });
          }
          return isAllowed;
        })
        .map((file) => ({ file, ...(defaultProps || {}) } as TFile));

      setFiles([...files, ...filtered]);
    },
    [files, contentTypes]
  );

  const handleDeleteFile = React.useCallback(
    (index: number) => {
      setFiles((files) => {
        if (index < 0 || index >= files.length) return files;

        const clone = [...files];
        clone.splice(index, 1);
        return clone;
      });
    },
    [setFiles]
  );

  const handleDrag = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setActiveDrag(true);
    } else if (e.type === "dragleave") {
      setActiveDrag(false);
    }
  }, []);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (e.target.files) handleFiles(e.target.files);

      if (inputRef.current) inputRef.current.value = "";
    },
    [handleFiles]
  );

  const handleDrop = React.useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setActiveDrag(false);

      if (e.dataTransfer.files) {
        handleFiles(e.dataTransfer.files);
      }
    },
    [handleFiles]
  );

  const accept = React.useMemo(() => contentTypes.join(","), [contentTypes]);

  return (
    <div
      className={classes.root}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input
        ref={inputRef as any}
        className={classes.input}
        type="file"
        multiple
        accept={accept}
        onChange={handleChange}
      />

      <RpiText type="p3" weight="bold" children="Drag files here or" />

      {Boolean(files?.length) && (
        <Flex.Column
          align="center"
          gap={8}
          className={classes.files}
          children={files?.map((file, i) => {
            return (
              <FileInput
                key={`file-input-${i}`}
                file={file}
                inputs={inputs!(
                  (props) =>
                    setFiles((files) => {
                      const copy = [...files];
                      copy[i] = { ...copy[i], ...props };
                      return copy;
                    }),
                  defaultProps
                )}
                classes={classes}
                defaultOpen={defaultOpen}
                onDelete={() => handleDeleteFile(i)}
              />
            );
          })}
        />
      )}

      <RpiButton
        variant="brand-outline"
        width="fit"
        onClick={() => inputRef.current?.click()}
        children="Browse files"
      />
    </div>
  );
}

interface FileInputProps<TFile> {
  file: TFile;
  inputs?: React.ReactNode;
  classes: ReturnType<typeof useFilesInputStyles>["classes"];
  defaultOpen?: boolean;
  onDelete: () => void;
}

export function FileInput<
  TProps extends Record<string, any> | undefined,
  TFile extends { file: File } & Omit<TProps, "file">
>({ file, inputs, classes, defaultOpen, onDelete }: FileInputProps<TFile>): React.ReactElement {
  const [opened, setOpened] = React.useState(defaultOpen);

  const toggleOpened = React.useCallback(() => setOpened((o) => !o), []);

  return (
    <Flex.Column gap={16} className={classes.file}>
      <Grid
        columns={inputs ? "min-content 1fr min-content" : "1fr min-content"}
        gap={8}
        px={8}
        alignItems="center"
        rows="1fr"
        className={classes.fileTitle}
        onClick={toggleOpened}
      >
        {inputs && (
          <RpiIconButton
            icon={IconV2.ArrowDown}
            size={11}
            color="currentColor"
            iconProps={{ style: { transform: opened ? "" : "rotate(-90deg)" } }}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              toggleOpened();
            }}
          />
        )}
        <RpiText component="span" type="p3" weight="regular" ellipsis pb={2} children={file.file.name} />
        <RpiIconButton
          icon={IconV2.X}
          size={11}
          color="currentColor"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            onDelete();
          }}
        />
      </Grid>

      {inputs && opened && <Flex.Column mb={8} gap={16} children={inputs} />}
    </Flex.Column>
  );
}
