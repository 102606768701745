import { Divider } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, Icon, IconFn, IconV2, RpiButton, RpiText } from "@rpi/openapi-core";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { NavLink } from "../../components/buttons/NavLink";
import { useAuthUser } from "../../hooks/useAuthUser";
import { RoutePathsKeys } from "../../hooks/useNavigateRoute";
import { Environment } from "../../env/Environment";

interface NavbarLink {
  name: string;
  route: RoutePathsKeys;
  icon: IconFn;
}

interface AdminNavbarLink extends NavbarLink {
  requiredRole?: GraphQL.DashboardUserRole | GraphQL.DashboardUserRole[];
}

const links: NavbarLink[] = [
  {
    name: "Dashboard",
    route: "home",
    icon: IconV2.Dashboard,
  },
  {
    name: "Products",
    route: "customer-products",
    icon: IconV2.Box,
  },
  {
    name: "Print assets",
    route: "print-assets",
    icon: IconV2.Printer,
  },
  {
    name: "Orders",
    route: "orders",
    icon: IconV2.Truck,
  },
  {
    name: "API credentials",
    route: "api-credentials",
    icon: IconV2.Key,
  },
  {
    name: "API examples",
    route: "api-examples",
    icon: IconV2.Code,
  },
];

const adminLinks: AdminNavbarLink[] = [
  {
    name: "Customers",
    route: "customers",
    icon: IconV2.Users,
    requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
  },
  {
    name: "Communications",
    route: "communications",
    icon: IconV2.Communications,
    requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
  },
  {
    name: "Open API products",
    route: "open-api-products",
    icon: IconV2.Box,
    requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
  },
  {
    name: "Dashboard users",
    route: "dashboard-users",
    icon: IconV2.Users,
  },
  {
    name: "Billing & payments",
    route: "billing",
    icon: IconV2.Wallet,
  },
  {
    name: "Uploads",
    route: "uploads",
    icon: IconV2.FileDownload,
    requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
  },
  {
    name: "Configuration",
    route: "configuration",
    icon: IconV2.LockOutline,
    requiredRole: GraphQL.DashboardUserRole.SuperAdmin,
  },
];

export const DashboardLayoutNavbar: React.FC = () => {
  const user = useAuthUser();
  const queryClient = useQueryClient();

  const navLinks = React.useMemo(
    () =>
      links.map(({ name, route, icon }, i) => (
        <NavLink
          key={`nav-link-${route}-${i}`}
          route={[route]}
          end={route === ("home" as RoutePathsKeys)}
          name={name}
          icon={icon}
        />
      )),
    []
  );

  const adminNavLinks = React.useMemo(() => {
    if (!user.is.CUSTOMER_ADMIN && !user.is.SUPER_ADMIN) return [];

    return adminLinks
      .filter(
        ({ requiredRole }) =>
          !requiredRole || (Array.isArray(requiredRole) ? requiredRole.every(user.hasRole) : user.hasRole(requiredRole))
      )
      .map(({ name, route, icon }, i) => (
        <NavLink key={`nav-link-admin-${route}-${i}`} route={[route]} name={name} icon={icon} />
      ));
  }, [user]);

  return (
    <Flex.Column
      py={24}
      gap={32}
      justify="space-between"
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[5],
        border: "none",
        width: 272,
        minWidth: 272,
        height: "100%",
        position: "relative",
      })}
    >
      <Flex.Column align="center" gap={32}>
        <Icon.Logo size={108} />

        <RpiButton
          component="a"
          href={
            Environment.isSandbox ? process.env.REACT_APP_DASHBOARD_URL_PROD : process.env.REACT_APP_DASHBOARD_URL_SAND
          }
          target="_blank"
          variant="brand"
          size="small"
          width="fit"
          trailingIcon
          children={Environment.isSandbox ? "Production" : "Sandbox"}
        />
      </Flex.Column>

      <Flex.Column
        justify="space-between"
        sx={(theme) => ({ flex: 1, overflowY: "auto", ...theme.other.scrollbars.light })}
      >
        <Flex.Column>
          <Flex.Column children={navLinks} />
          {adminNavLinks.length > 0 && (
            <>
              <Divider my={32} sx={(theme) => ({ borderTopColor: theme.colors.sky[8], width: 232, margin: "auto" })} />
              <RpiText
                type="p2"
                weight="light"
                px={32}
                pt={16}
                pb={8}
                sx={(theme) => ({ color: theme.colors.sky[5], letterSpacing: "1px" })}
                children="API Administration"
              />
              <Flex.Column children={adminNavLinks} />
            </>
          )}
        </Flex.Column>
      </Flex.Column>

      <Flex.Column>
        <NavLink route={["settings"]} name="Settings" icon={IconV2.Gear} />
        <NavLink
          to="/"
          end
          name="Log out"
          icon={IconV2.LogOut}
          onClick={() => {
            user.signOut();
            queryClient.clear();
          }}
        />
      </Flex.Column>
    </Flex.Column>
  );
};
