import { Accordion, Box, Input, Select, Stack, TextInput } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { GraphQL, Rest } from "@rpi/openapi-api";
import React from "react";
import ReactJson from "react-json-view";
import {
  ApiAccordion,
  ApiAccordionForm,
  ApiAccordionLabel,
  ApiAccordionLabelProps,
  ApiMethod,
} from "../components/accordions/ApiAccordion";
import { AppPage } from "../components/AppPage";
import { SelectApiCredential } from "../components/selects/SelectApiCredential";
import { SelectCustomerProduct } from "../components/selects/SelectCustomerProduct";
import { useLoadingCallback } from "../hooks/useLoadingCallback";
import { formatAuthHeader, generateJsonCurl, propPlaceholder } from "../utils/misc.util";
import { isEmptyString } from "../utils/type.util";
import { AxiosResponse, AxiosError } from "axios";
const requests: Array<ApiAccordionLabelProps & { element: React.FC<ApiRequestProps> }> = [
  {
    method: ApiMethod.GET,
    path: "/orders/:customerOrderId",
    description: "Get order by id",
    element: GetOrderById,
  },
  {
    method: ApiMethod.POST,
    path: "/orders/create",
    description: "Create an order",
    element: PostCreateOrder,
  },
];

export const ApiExamplesView: React.FC = () => {
  const apiCredentialsQuery = GraphQL.useListApiCredentialsQuery();
  const customerProductsQuery = GraphQL.useListCustomerProductsQuery();

  const error = React.useMemo(
    () => apiCredentialsQuery.error || customerProductsQuery.error,
    [apiCredentialsQuery, customerProductsQuery]
  );

  const isLoading = React.useMemo(
    () => apiCredentialsQuery.isLoading || customerProductsQuery.isLoading,
    [apiCredentialsQuery, customerProductsQuery]
  );

  const apiCredentials = React.useMemo(() => apiCredentialsQuery.data?.listApiCredentials, [apiCredentialsQuery]);

  const customerProducts = React.useMemo(
    () => customerProductsQuery.data?.listCustomerProducts,
    [customerProductsQuery]
  );

  const items = React.useMemo(
    () =>
      requests.map((request, i) => (
        <Accordion.Item key={`request-example-${i}`} value={i.toString()}>
          <Accordion.Control children={<ApiAccordionLabel {...request} />} />
          <Accordion.Panel
            children={React.createElement(request.element, { request, apiCredentials, customerProducts })}
          />
        </Accordion.Item>
      )),
    [apiCredentials, customerProducts]
  );

  return (
    <AppPage path={["API Examples"]} isLoading={isLoading || !!error}>
      <ApiAccordion chevronPosition="right" children={items} />
    </AppPage>
  );
};

interface ApiRequestProps {
  request: typeof requests[0];
  apiCredentials?: GraphQL.ApiCredentialsResponse[];
  customerProducts?: GraphQL.CustomerProductResponse[];
}

function GetOrderById({ request, apiCredentials }: ApiRequestProps) {
  const { isLoading, loadingCallback } = useLoadingCallback();
  const [response, setResponse] = React.useState<{
    data?: Awaited<ReturnType<typeof Rest.getOrderById>>["data"];
    error?: any;
  }>({});

  const [state, setState] = useSetState({ orderId: "" });
  const [authHeader, setAuthHeader] = React.useState<string>("");

  const curl = React.useMemo(() => {
    return generateJsonCurl({
      method: request.method,
      url: `${process.env.REACT_APP_PUBLIC_PRINTAPI_ENDPOINT_URL}/orders/${propPlaceholder(
        state.orderId,
        ":customerOrderId"
      )}`,
      headers: {
        accept: "application/json",
        Authorization: propPlaceholder(authHeader, "Basic base64(apiKey:sharedSecret)"),
      },
    });
  }, [request, authHeader, state]);

  const handleSubmit = React.useCallback(() => {
    loadingCallback(
      async () =>
        await Rest.getOrderById(state.orderId, authHeader)
          .then((res: any) => {
            showNotification({
              title: "Fetched order.",
              message: `Got order by id '${res.data?.order.customerOrderId}'.`,
              color: "green",
            });
            setResponse({ data: res.data });
          })
          .catch((error: AxiosError) => {
            console.error(error);
            showNotification({
              title: "Failed to fetch order.",
              message: (error?.response?.data as Record<string, any>)?.errorDescription ?? error.message,
              color: "red",
            });
            setResponse({ error: error?.response?.data ?? error });
          })
    );
  }, [state, authHeader, loadingCallback]);

  const selectApiCredential = React.useMemo(
    () => (
      <SelectApiCredential
        required
        data={apiCredentials}
        value={authHeader}
        onChange={(authHeader) => setAuthHeader(authHeader || "")}
        getValue={(apiCredential) => formatAuthHeader(apiCredential.apiKey, apiCredential.sharedSecret)}
      />
    ),
    [apiCredentials, authHeader]
  );

  return (
    <ApiAccordionForm
      curl={curl}
      response={response}
      buttonProps={{
        loading: isLoading,
        disabled: isEmptyString(authHeader) || isEmptyString(state.orderId),
        onClick: handleSubmit,
      }}
    >
      <TextInput
        label="Customer Order Id"
        value={state.orderId}
        onChange={(e) => setState({ orderId: e.currentTarget.value || "" })}
      />
      {selectApiCredential}
    </ApiAccordionForm>
  );
}

// TODO: TEMPORARY!
const pdfs = [
  {
    name: "8.5x11 Softcover, Gloss Laminate, 100# Gloss Text",
    openapiSku: "SC_8.5x11_GLS_GL100T",
    filename: "SC_8.5x11_GLS_GL100T_20pg",
  },
  {
    name: "8x10 Softcover, Gloss Laminate, 100# Gloss Text",
    openapiSku: "SC_8x10_GLS_GL100TSC_8x10_GLS_GL100T",
    filename: "SC_8x10_GLS_GL100T_20pg",
  },
  {
    name: "8x8 Softcover, Gloss Laminate, 100# Gloss Text",
    openapiSku: "SC_8x8_GLS_GL100T",
    filename: "SC_8x8_GLS_GL100T_20pg",
  },
  {
    name: "8x8 ImageWrap, Gloss Laminate, 100# Gloss Text",
    openapiSku: "HCIW_8x8_GLS_GL100T",
    filename: "HCIW_8x8_GLS_GL100T_20pg",
  },
  {
    name: "8x8 ImageWrap, Matte Laminate, 100# Gloss Text",
    openapiSku: "HCIW_8x8_MAT_GL100T",
    filename: "HCIW_8x8_MAT_GL100T_20pg",
  },
  {
    name: "8x10 ImageWrap, Gloss Laminate, 100# Gloss Text",
    openapiSku: "HCIW_8x10_GLS_GL100T",
    filename: "HCIW_8x10_GLS_GL100T_20pg",
  },
  {
    name: "8x10 ImageWrap, Matte Laminate, 100# Gloss Text",
    openapiSku: "HCIW_8x10_MAT_GL100T",
    filename: "HCIW_8x10_MAT_GL100T_20pg",
  },
  {
    name: "11x8.5 Imagewrap, Gloss Laminate, 100# Gloss Text",
    openapiSku: "HCIW_11x8.5_GLS_GL100T",
    filename: "HCIW_11x8.5_GLS_GL100T_20pg",
  },
  {
    name: "11x8.5 Imagewrap, Matte Laminate, 100# Gloss Text",
    openapiSku: "HCIW_11x8.5_MAT_GL100T",
    filename: "HCIW_11x8.5_MAT_GL100T_20pg",
  },
  {
    name: "11x8.5 Softcover, Gloss Laminate, 100# Gloss Text",
    openapiSku: "SC_11x8.5_GLS_GL100T",
    filename: "SC_11x8.5_GLS_GL100T_20pg",
  },
  {
    name: "8.5x11 ImageWrap, Gloss Laminate, 100# Gloss Text",
    openapiSku: "HCIW_8.5x11_GLS_GL100T",
    filename: "HCIW_8.5x11_GLS_GL100T_20pg",
  },
  {
    name: "8.5x11 ImageWrap, Matte Laminate, 100# Gloss Text",
    openapiSku: "HCIW_8.5x11_MAT_GL100T",
    filename: "HCIW_8.5x11_MAT_GL100T_20pg",
  },
  {
    name: "12x12 ImageWrap, Gloss Laminate, 100# Gloss Text",
    openapiSku: "HCIW_12x12_GLS_GL100T",
    filename: "HCIW_12x12_GLS_GL100T_20pg",
  },
  {
    name: "12x12 ImageWrap, Matte Laminate, 100# Gloss Text",
    openapiSku: "HCIW_12x12_MAT_GL100T",
    filename: "HCIW_12x12_MAT_GL100T_20pg",
  },
];

function PostCreateOrder({ request, apiCredentials, customerProducts }: ApiRequestProps) {
  const { isLoading, loadingCallback } = useLoadingCallback();
  const [response, setResponse] = React.useState<{
    data?: GraphQL.CreateOrderResponse;
    error?: any;
  }>({});

  const [authHeader, setAuthHeader] = React.useState<string>("");
  const [customerProduct, setCustomerProduct] = React.useState<string>("{sku}");
  const [coverUrl, setCoverUrl] = React.useState<string>("{cover_url}");
  const [gutsUrl, setGutsUrl] = React.useState<string>("{guts_url}");

  const [data, setData] = React.useState<any>({
    payload: "{custom_payload}",
    currency: "USD",
    shippingClassification: "priority",
    webhookUrl:
      "https://notification-testing-service.builder.blurb.com/notification/rpi-open-api-test/http-code-200/dummy",
    destination: {
      name: "John Doe",
      company: "RPI print",
      address1: "3325 S 116th St",
      city: "Tukwila",
      state: "Washington",
      postal: "98168",
      country: "US",
      phone: "(206) 905 5999",
      email: "shipping.dept@rpiprint.com",
    },
    orderItems: [
      {
        sku: "{sku}",
        quantity: 1,
        retailPrice: "12.3",
        itemDescription: "{item_description}",
        product: {
          coverUrl: "{cover_url}",
          gutsUrl: "{guts_url}",
        },
      },
    ],
  });

  React.useEffect(() => {
    setData((data: any) => {
      const copy = Object.assign({}, data);
      copy.orderItems = copy.orderItems.map((orderItem: any) => ({ ...orderItem, sku: customerProduct }));
      return copy;
    });
  }, [customerProduct]);

  React.useEffect(() => {
    setData((data: any) => {
      const copy = Object.assign({}, data);
      copy.orderItems = copy.orderItems.map((orderItem: any) => ({
        ...orderItem,
        product: { ...orderItem.product, coverUrl },
      }));
      return copy;
    });
  }, [coverUrl]);

  React.useEffect(() => {
    setData((data: any) => {
      const copy = Object.assign({}, data);
      copy.orderItems = copy.orderItems.map((orderItem: any) => ({
        ...orderItem,
        product: { ...orderItem.product, gutsUrl },
      }));
      return copy;
    });
  }, [gutsUrl]);

  const curl = React.useMemo(() => {
    return generateJsonCurl({
      method: request.method,
      url: `${process.env.REACT_APP_PUBLIC_PRINTAPI_ENDPOINT_URL}/orders/create`,
      headers: {
        accept: "application/json",
        Authorization: propPlaceholder(authHeader, "Basic base64(apiKey:sharedSecret)"),
      },
      data: data || {},
    });
  }, [request, authHeader, data]);

  const handleSubmit = React.useCallback(() => {
    loadingCallback(
      async () =>
        await Rest.createOrder(data, authHeader)
          .then((res: AxiosResponse<GraphQL.CreateOrderResponse>) => {
            showNotification({
              title: "New order.",
              message: `Created new order '${res.data.customerOrderId}'.`,
              color: "green",
            });
            setResponse({ data: res.data });
          })
          .catch((error: AxiosError) => {
            console.error(error);
            showNotification({
              title: "Failed to create order.",
              message: (error?.response?.data as Record<string, any>)?.errorDescription ?? error.message,
              color: "red",
            });
            setResponse({ error: error?.response?.data ?? error });
          })
    );
  }, [data, authHeader, loadingCallback]);

  const selectApiCredential = React.useMemo(
    () => (
      <SelectApiCredential
        required
        data={apiCredentials}
        value={authHeader}
        onChange={(authHeader) => setAuthHeader(authHeader || "")}
        getValue={(apiCredential) => formatAuthHeader(apiCredential.apiKey, apiCredential.sharedSecret)}
      />
    ),
    [apiCredentials, authHeader]
  );

  const selectCustomerProduct = React.useMemo(
    () => (
      <SelectCustomerProduct
        required
        data={customerProducts?.sort((cp1, cp2) => cp1.openapiProductId - cp2.openapiProductId)}
        value={customerProduct}
        onChange={(value) => {
          const customerProduct = value || "";
          setCustomerProduct(customerProduct);
          const fileWithProduct = pdfs.find(({ openapiSku }) => openapiSku.startsWith(customerProduct))!;
          const coverUrl = `${process.env.REACT_APP_DOCS_URL}${
            process.env.REACT_APP_DOCS_URL.endsWith("/") ? "" : "/"
          }pdfs/${fileWithProduct.filename}_cover.pdf`;

          const gutsUrl = `${process.env.REACT_APP_DOCS_URL}${
            process.env.REACT_APP_DOCS_URL.endsWith("/") ? "" : "/"
          }pdfs/${fileWithProduct.filename}_guts.pdf`;

          setCoverUrl(coverUrl);
          setGutsUrl(gutsUrl);
        }}
      />
    ),
    [customerProducts, customerProduct]
  );

  const selectCoverPdf = React.useMemo(
    () => (
      <Select
        label="Cover PDF"
        required
        disabled
        data={pdfs.map(({ name, filename }) => {
          return {
            value: `${process.env.REACT_APP_DOCS_URL}${
              process.env.REACT_APP_DOCS_URL.endsWith("/") ? "" : "/"
            }pdfs/${filename}_cover.pdf`,
            label: name,
          };
        })}
        value={coverUrl}
        onChange={(data) => {
          setCoverUrl(data || "{cover_url}");
        }}
      />
    ),
    [coverUrl]
  );

  const selectGutsPdf = React.useMemo(
    () => (
      <Select
        label="Guts PDF"
        required
        disabled
        data={pdfs.map(({ name, filename }) => {
          return {
            value: `${process.env.REACT_APP_DOCS_URL}${
              process.env.REACT_APP_DOCS_URL.endsWith("/") ? "" : "/"
            }pdfs/${filename}_guts.pdf`,
            label: name,
          };
        })}
        value={gutsUrl}
        onChange={(data) => setGutsUrl(data || "{guts_url}")}
      />
    ),
    [gutsUrl]
  );

  return (
    <ApiAccordionForm
      curl={curl}
      response={response}
      buttonProps={{
        loading: isLoading,
        disabled: isEmptyString(authHeader) || isEmptyString(customerProduct),
        onClick: handleSubmit,
      }}
      jsonInput={
        <Input.Wrapper label="Data" required>
          <Box p={8} sx={{ maxHeight: 400, overflowY: "auto", border: "1px solid #ced4da", borderRadius: 4 }}>
            <ReactJson
              src={data}
              onEdit={({ updated_src }) => setData(updated_src)}
              onAdd={({ updated_src }) => setData(updated_src)}
              onDelete={({ updated_src }) => setData(updated_src)}
              name={null}
              displayDataTypes={false}
              style={{ fontSize: 14 }}
            />
          </Box>
        </Input.Wrapper>
      }
    >
      <Stack spacing="sm">
        {selectApiCredential}
        {selectCustomerProduct}
      </Stack>
      <Stack spacing="sm">
        {selectCoverPdf}
        {selectGutsPdf}
      </Stack>
    </ApiAccordionForm>
  );
}
