import { createStyles } from "@mantine/core";
import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useAuthUser } from "../hooks/useAuthUser";

interface AdminOnlyProps extends React.ComponentProps<typeof Flex.Column> {
  title?: string;
  children?: React.ReactNode;
  render?: React.FC;
}

const useAdminOnlyStyles = createStyles(() => {
  return {
    title: {
      color: "#f55353e3",
      display: "flex",
      gap: 8,
      marginLeft: -5.2,
    },
    body: {
      outline: `4px solid #f55353e3`,
      outlineOffset: 0,
      position: "relative",
    },
  };
});

export const AdminOnly: React.FC<AdminOnlyProps> = (props) => {
  const user = useAuthUser();

  if (!user.is.SUPER_ADMIN) return <></>;

  return React.useMemo(() => <AdminOnlyRender {...props} />, [props]);
};

const AdminOnlyRender: React.FC<AdminOnlyProps> = ({ title, render, children, ...props }) => {
  const { classes } = useAdminOnlyStyles();

  return (
    <Flex.Column gap={16} fullWidth {...props}>
      <RpiText type="h2" weight="roman" className={classes.title}>
        <strong>[ADMIN]</strong> {title}
      </RpiText>
      <Flex.Column fullWidth className={classes.body} children={render ? React.createElement(render) : children} />
    </Flex.Column>
  );
};
