import { Box } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, IconV2, RpiIconButton, RpiText } from "@rpi/openapi-core";
import moment from "moment";
import React from "react";
import { ExtendedRpiSearchFilterInput } from "./RpiSearchFilter";

interface RpiSearchFilterDisplayProps<TField> {
  input: ExtendedRpiSearchFilterInput<TField>;
  onDelete: () => void;
}

export function RpiSearchFilterDisplay<TField>({
  input,
  onDelete,
}: RpiSearchFilterDisplayProps<TField>): React.ReactElement {
  const condition = React.useMemo(() => {
    switch (input.condition) {
      case GraphQL.SearchCondition.Contains:
        return "contains";
      case GraphQL.SearchCondition.Equals:
        return "equals";
      case GraphQL.SearchCondition.NotEquals:
        return "not equals";
      case GraphQL.SearchCondition.In:
        return "equals";
      case GraphQL.SearchCondition.Between:
        return "between";
    }
  }, [input.condition]);

  const value = React.useMemo(() => {
    const parseValue = (value?: string) => {
      if (input.type === "date") {
        return moment(value).format("MM/DD/YYYY");
      }

      return value;
    };

    switch (input.condition) {
      case GraphQL.SearchCondition.Contains:
      case GraphQL.SearchCondition.Equals:
      case GraphQL.SearchCondition.NotEquals:
        return parseValue(input.firstValue);
      case GraphQL.SearchCondition.In:
        if (!input.values || input.values.length === 0) return "null";
        return input.values.length === 1 ? (
          input.values[0]
        ) : (
          <>
            {parseValue(input.values[0])}{" "}
            <Box component="span" sx={(theme) => ({ fontWeight: theme.other.fontWeights.light })} children="or" />{" "}
            {input.values.length - 1} more
            <Box component="span" sx={(theme) => ({ fontWeight: theme.other.fontWeights.light })} children="..." />
          </>
        );
      case GraphQL.SearchCondition.Between:
        return (
          <>
            {parseValue(input.firstValue)}{" "}
            <Box component="span" sx={(theme) => ({ fontWeight: theme.other.fontWeights.light })} children="and" />{" "}
            {parseValue(input.secondValue)}
          </>
        );
    }
  }, [input]);

  return (
    <Flex.Row
      align="center"
      px={8}
      gap={4}
      sx={(theme) => ({
        borderRadius: 11,
        background: theme.colors.calm[5],
        maxWidth: "fit-content",
        height: 24,
        whiteSpace: "nowrap",
      })}
    >
      <RpiText
        component="span"
        type="p3"
        weight="bold"
        color={(theme) => theme.colors.text[5]}
        children={input.label}
      />
      <RpiText component="span" type="p3" weight="light" color={(theme) => theme.colors.text[5]} children={condition} />
      <RpiText
        component="span"
        type="p3"
        weight="bold"
        color={(theme) => theme.colors.text[5]}
        sx={{ whiteSpace: "nowrap" }}
        children={value}
      />
      <RpiIconButton
        ml={4}
        sx={(theme) => ({ color: theme.colors.text[5] })}
        color="currentColor"
        size={9}
        icon={IconV2.X}
        onClick={onDelete}
      />
    </Flex.Row>
  );
}
