import { CSSObject } from "@mantine/core";
import { PickEntityKeys } from "../../utils/type.util";
import { RpiTableColumn } from "./RpiTable";

export function createEntityColumn<T extends object, S = string>() {
  return <K extends keyof T = keyof T>(columnDef: RpiTableColumn<PickEntityKeys<T, K>, S>) => columnDef;
}

export function justifyFromAlign<T extends object = any>(
  align: Exclude<RpiTableColumn<T>["align"], undefined>
): CSSObject["justifyContent"] {
  switch (align) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    case "center":
      return "center";
  }
}
