import { RpiSingleTableRows } from "../../components/tables/RpiSingleTable";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { CellRoute } from "../../components/cells/CellLink";
import { CellDate } from "../../components/cells/CellDate";

export const customerCommunicationRows: RpiSingleTableRows<GraphQLQueryData<GraphQL.CustomerCommunicationQuery>> = (
  user
) => [
  {
    header: "Id",
    accessor: (data) => data?.id,
  },
  {
    header: "Customer",
    accessor: (data) => {
      if (!data?.customer?.publicId) return;
      return <CellRoute route={["customer", { publicId: data.customer.publicId }]} children={data.customer.publicId} />;
    },
  },
  {
    header: "Communication Type",
    accessor: (data) => data?.type,
  },
  {
    header: "Created",
    accessor: (data) => <CellDate date={data.createdAt} />,
  },
];
