import { GraphQL } from "@rpi/openapi-api";
import { Flex, IconV2, RpiText } from "@rpi/openapi-core";
import moment from "moment";
import React from "react";
import { AppPage } from "../components/AppPage";
import { DashboardCard } from "../components/dashboard-card/DashboardCard";
import { useDailyMetricsGraph } from "../components/graphs/useDailyMetricsGraph";
import { useDailyPaymentMetricsGraph } from "../components/graphs/useDailyPaymentMetricsGraph";
import { RpiSearchFilter } from "../components/search-filter/RpiSearchFilter";
import { dashboardOrderColumns } from "../configuration/columns/order.column";
import { useOrderFilters } from "../configuration/filters/order.filter";
import { useAuthUser } from "../hooks/useAuthUser";
import { useRpiPagination } from "../hooks/usePagination";
import { RpiTable } from "../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../utils/graphql.util";
import { PublicUrl } from "../utils/link.util";
import { getNDaysDateRange } from "../utils/misc.util";

const numberOfDays = 31;

export const HomeView: React.FC = () => {
  const user = useAuthUser();

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.OrdersQueryVariables>>({
    by: GraphQL.OrderSearchField.CreatedAt,
    sortDirection: GraphQL.SortDirection.Desc,
  });
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.OrdersQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.OrdersQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 5 });

  const orderFilters = useOrderFilters();

  const dateRange = React.useMemo(() => getNDaysDateRange(numberOfDays), []);

  const { data: ordersData, ...ordersQuery } = GraphQL.useOrdersQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: [
          {
            field: GraphQL.OrderSearchField.CreatedAt,
            condition: GraphQL.SearchCondition.Between,
            firstValue: dateRange.from,
            secondValue: dateRange.to,
          },
          ...(searchInputs || []),
        ],
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.orders.totalCount),
    }
  );

  const orders = React.useMemo(() => ordersData?.orders.data, [ordersData]);

  const { data: nextChargeTimeData, ...nextChargeTimeQuery } = GraphQL.useGetNextChargeTimeQuery({
    customerPublicId: user?.id as string,
  });
  const nextChargeTime = React.useMemo(() => nextChargeTimeData?.getNextChargeTime, [nextChargeTimeData]);

  const { data: metricsDataData, ...metricsDataQuery } = GraphQL.useOrdersByPublicStatusMetricsQuery({
    customerPublicId: user.id!,
    numberOfDays,
  });
  const metricsData = React.useMemo(() => metricsDataData?.ordersByPublicStatusMetrics, [metricsDataData]);

  const { graph: dailyMetricGraph } = useDailyMetricsGraph({
    customerPublicId: user.id!,
    numberOfDays,
  });

  const { graph: dailyPaymentMetricsGraph } = useDailyPaymentMetricsGraph({
    customerPublicId: user.id!,
    numberOfDays,
  });

  const itemsInError = React.useMemo(
    () =>
      metricsData?.ordersByPublicStatus
        .filter(
          ({ publicStatus }) =>
            publicStatus === GraphQL.PublicOrderStatus.Failed || publicStatus === GraphQL.PublicOrderStatus.Exception
        )
        .reduce((acc, item) => acc + item.numOrders, 0),
    [metricsData]
  );

  return (
    <AppPage background={(theme) => theme.colors.calm[4]}>
      <Flex.Column gap={28}>
        <Flex.Row
          fullWidth
          justify="space-between"
          sx={(theme) => ({ color: theme.colors.brand[5], position: "relative" })}
        >
          <RpiText type="h1" weight="roman" children={`Welcome back, ${user.cognitoUser?.username}`} />

          {/* TODO: Dark mode */}
          {/* <ColorSchemeToggle /> */}
        </Flex.Row>

        <Flex.Row gap={16} sx={{ flexWrap: "wrap" }}>
          <DashboardCard.Info
            title="Orders that need attention"
            children={itemsInError || 0}
            links={
              (itemsInError || 0) > 0
                ? {
                    showTrailingIcon: true,
                    children: "See your orders",
                    route: ["orders-attention"],
                  }
                : undefined
            }
            icon={IconV2.ErrorOutline}
            isLoading={metricsDataQuery.isLoading}
          />
          <DashboardCard.Info
            title="Next charge time"
            children={moment(nextChargeTime).fromNow()}
            description={moment(nextChargeTime).format("MM/DD/YYYY h:mm a")}
            links={
              user.is.CUSTOMER_ADMIN || user.is.USER
                ? { children: "Or pay now", route: ["orders:purchase"], showTrailingIcon: true }
                : undefined
            }
            icon={IconV2.CreditCard}
            isLoading={nextChargeTimeQuery.isLoading}
          />
          <DashboardCard.Info
            links={[
              {
                children: "Documentation",
                href: PublicUrl.DOCS_FIRST_REQUEST,
                showTrailingIcon: false,
                target: "_blank",
              },
              {
                children: "Frequently asked questions",
                showTrailingIcon: false,
                href: PublicUrl.DOCS_FAQS,
                target: "_blank",
              },
            ]}
            icon={IconV2.Document}
          >
            <RpiText
              type="h2"
              weight="bold"
              mt={"xl"}
              mb={"sm"}
              sx={{ lineHeight: "normal !important" }}
              children={"Helpful links"}
            />
          </DashboardCard.Info>
        </Flex.Row>

        <Flex.Row gap={16} sx={{ flexWrap: "wrap" }}>
          <DashboardCard.Graph
            title="Order Overview"
            description="Completed orders of the latest month"
            {...dailyMetricGraph}
          />

          <DashboardCard.Graph
            title="Payment Overview"
            description="Daily total of the latest month"
            {...dailyPaymentMetricsGraph}
          />
        </Flex.Row>

        <DashboardCard.Table
          title="Order status"
          description="Overview of the latest month"
          total={ordersData?.orders.totalCount || 0}
          dataSize={orders?.length}
          pagination={pagination}
          isLoading={ordersQuery.isLoading}
          filterComponent={
            <RpiSearchFilter
              placeholder="Search by Order ID"
              filters={orderFilters}
              dateRangeField={GraphQL.OrderSearchField.CreatedAt}
              onChange={setSearchInputs}
              onSearch={setSearchGeneral}
            />
          }
        >
          <RpiTable
            variant="dashboard"
            emptyMessage={
              searchInputs?.length || searchGeneral ? "No orders found." : "Your order history will appear here."
            }
            isLoading={ordersQuery.isLoading}
            error={ordersQuery.error}
            perPage={pagination.perPage}
            data={orders}
            columns={dashboardOrderColumns}
            withSortBy={{
              defaultValue: {
                by: GraphQL.OrderSearchField.CreatedAt,
                sortDirection: GraphQL.SortDirection.Desc,
              },
              onChange: setSortInput,
            }}
          />
        </DashboardCard.Table>
      </Flex.Column>
    </AppPage>
  );
};
