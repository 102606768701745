import { useForm } from "@mantine/form";
import { GraphQL } from "@rpi/openapi-api";
import { RpiDrawerTextInput, RpiText } from "@rpi/openapi-core";
import React from "react";
import { FilesInput } from "../../../components/FilesInput";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { RpiNotification, useRpiNotifications } from "../../../hooks/useRpiNotifications";
import {
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerOverlayProps,
  RpiDrawerProps,
  createRpiDrawerView,
} from "../../../rpi-core/drawer/RpiDrawer";
import { useUploadService } from "../../../services/useUploadService";

export const TaxFileUploadView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    const user = useAuthUser();

    return (
      <RpiDrawer title="Upload tax documents" opened={opened} onClose={handleClose} isLoading={!user.id}>
        {user.id && <CreateUpload customerPublicId={user.id} onClose={() => handleClose(true)} />}
      </RpiDrawer>
    );
  }
);

interface CreateUploadProps {
  customerPublicId: string;
  onClose: () => void;
}

const CreateUpload: React.FC<CreateUploadProps> = ({ customerPublicId, onClose }) => {
  const notifications = useRpiNotifications();

  const { upload, status, reset } = useUploadService({
    withCollectionPoll: true,
    onComplete: () => {
      notifications.refetch(RpiNotification.TAX_DOCUMENT_NOT_UPLOADED);
      onClose();
    },
    onError: onClose,
  });

  const form = useForm<
    Omit<NonNullable<GraphQL.CreateUploadMutationVariables["input"]>, "files"> & {
      files: Array<{
        file: File;
        description: string | undefined;
      }>;
    }
  >({
    initialValues: {
      customerPublicId,
      files: [],
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async ({ customerPublicId, files }: typeof form.values) => {
      const filesInput: GraphQL.UploadingFilesInput[] = [];
      const uploadFiles: File[] = [];

      for (const { file, description } of files) {
        filesInput.push({
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          taxDocument: true,
          ...(description && { description }),
        });
        uploadFiles.push(file);
      }

      return upload(
        {
          customerPublicId,
          files: filesInput,
        },
        uploadFiles
      );
    },
    [upload]
  );

  const overlay: RpiDrawerOverlayProps | undefined = React.useMemo(() => {
    if (!status) return;

    switch (status.status) {
      case "loading":
        return {
          type: "loading",
          message: status.message,
          description: status.description || "Please wait, do not close this screen.",
        };

      case "error":
        return {
          type: "error",
          message: status.message,
          description: status.description || "Please try again later.",
        };

      case "success":
        return {
          type: "success",
          message: status.message,
          description:
            status.description || "RPI staff has been notified and they will begin to review your tax documents.",
        };
    }
  }, [status]);

  return (
    <RpiDrawerForm
      button="Upload"
      overlay={overlay}
      disabled={!form.values.files.length}
      onSubmit={form.onSubmit(handleSubmit)}
    >
      <RpiText type="p3" color={(theme) => theme.colors.brand[5]} mb={24}>
        Our server receives your state-based reseller's certificate through a secure connection to protect your
        sensitive information. We accept PDF, JPG, and PNG formats. Size limit is 2G.
      </RpiText>

      <FilesInput
        defaultOpen
        defaultProps={{
          description: undefined as string | undefined,
        }}
        inputs={(setProps) => (
          <RpiDrawerTextInput
            label="Description"
            onChange={(e) => setProps({ description: e.target.value || undefined })}
          />
        )}
        onChange={(files) => form.setValues({ files })}
      />
    </RpiDrawerForm>
  );
};
