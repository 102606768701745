import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { RpiApiProvider } from "@rpi/openapi-api";
import { rpiTheme } from "@rpi/openapi-core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify, Auth } from "aws-amplify";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { $envRibbon } from ".";
import { RpiModalsProvider } from "./components/modals/modals";
import { useDevMode } from "./hooks/useDevMode";
import { RpiNotificationsProvider } from "./hooks/useRpiNotifications";
import { router } from "./router";
import { Error404View } from "./views/error/Error404View";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_COGNITO_CUSTOM_DOMAIN,
    scope: ["phone", "email", "profile", "openid"],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: "code",
  },
  Analytics: {
    disabled: true,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const { toggleDevMode } = useDevMode();
  const [colorScheme, setColorScheme] = React.useState<ColorScheme>("light");

  const toggleColorScheme = (value?: ColorScheme) => {
    const nextColorScheme = value || (colorScheme === "dark" ? "light" : "dark");
    setColorScheme(nextColorScheme);
    // setCookies("mantine-color-scheme", nextColorScheme, {
    //   maxAge: 60 * 60 * 24 * 30,
    // });
  };

  React.useEffect(() => {
    const handleClick = () => toggleDevMode();

    $envRibbon.addEventListener("click", handleClick);
    return () => {
      $envRibbon.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ colorScheme, ...rpiTheme }} withGlobalStyles withNormalizeCSS>
        <NotificationsProvider position="top-right">
          <Authenticator.Provider>
            <QueryClientProvider client={queryClient}>
              <RpiApiProvider
                value={{
                  headers: async () => {
                    // TODO: Cache?
                    const session = await Auth.currentSession();
                    const jwtToken = session.getAccessToken().getJwtToken();

                    return {
                      Authorization: jwtToken,
                    };
                  },
                }}
              >
                <RpiModalsProvider>
                  <RouterProvider router={router} fallbackElement={<Error404View />} />
                </RpiModalsProvider>
              </RpiApiProvider>
            </QueryClientProvider>
          </Authenticator.Provider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
