import { Box, Group, SimpleGrid } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import {
  Flex,
  IconV2,
  RpiButton,
  RpiCheckbox,
  RpiDrawerNumberInput,
  RpiDrawerPasswordInput,
  RpiDrawerSelectInput,
  RpiDrawerTextInput,
  RpiDropdown,
  RpiLink,
  RpiMultilineInput,
  RpiPasswordInput,
  RpiSlider,
  RpiText,
  RpiTextInput,
} from "@rpi/openapi-core";
import React from "react";
import { CellBoolean } from "../../components/cells/CellBoolean";
import { CellClipboard } from "../../components/cells/CellClipboard";
import { CellDate } from "../../components/cells/CellDate";
import { CellJson } from "../../components/cells/CellJson";
import { CellLink, CellRoute } from "../../components/cells/CellLink";
import { CellStatus } from "../../components/cells/CellStatus";
import { RpiPagination } from "../../components/RpiPagination";
import { RpiSearchFilter, RpiSearchFilterInput } from "../../components/search-filter/RpiSearchFilter";
import { RpiFilterV2 } from "../../components/search-filter/RpiSearchFilterTypes";
import { RpiSingleTable } from "../../components/tables/RpiSingleTable";
import { useRpiPagination } from "../../hooks/usePagination";
import { RpiPerPage, useRpiPerPage } from "../../hooks/useRpiPerPage";
import { CountField } from "../../rpi-core/CountField";
import { RpiTable } from "../../rpi-core/table/RpiTable";
import { countrySelectData } from "../../utils/country.util";
import { DesignSection } from "./DesignLayout";
import { DragAndDrop } from "../../components/DragAndDrop";
import { FilesInput } from "../../components/FilesInput";

const selectData = [
  {
    label: "8.5x11 inches Photobook with Matte ImageWrap Cover",
    value: "306",
    data: { 123: 123 },
  },
  {
    label: "12x12 inches Photobook with Gloss ImageWrap Cover",
    value: "307",
  },
  {
    label: "8.5x11 inches Photobook with Gloss Softcover Cover",
    value: "304",
  },
  {
    label: "8x10 inches Photobook with Gloss Softcover Cover",
    value: "298",
  },
  {
    label: "8x8 inches Photobook with Gloss Softcover Cover",
    value: "295",
  },
  {
    label: "8x8 inches Photobook with Gloss ImageWrap Cover",
    value: "296",
  },
  {
    label: "8x8 inches Photobook with Matte ImageWrap Cover",
    value: "297",
  },
  {
    label: "8x10 inches Photobook with Gloss ImageWrap Cover",
    value: "299",
  },
  {
    label: "8x10 inches Photobook with Matte ImageWrap Cover",
    value: "300",
  },
  {
    label: "11x8.5 inches Photobook with Gloss ImageWrap Cover",
    value: "301",
  },
  {
    label: "11x8.5 inches Photobook with Matte ImageWrap Cover",
    value: "302",
  },
  {
    label: "11x8.5 inches Photobook with Gloss Softcover Cover",
    value: "303",
  },
  {
    label: "8.5x11 inches Photobook with Gloss ImageWrap Cover",
    value: "305",
  },
  {
    label: "12x12 inches Photobook with Matte ImageWrap Cover",
    value: "308",
  },
];

export const DesignV2View: React.FC = () => {
  const rpiPerPage = useRpiPerPage({ id: "design-view-test", perPage: 12 });
  const pagination = useRpiPagination({ initialPage: 0, initialTotal: 100, perPage: 2 });

  const filters = RpiFilterV2.createConfigs<GraphQL.OrderSearchField>([
    {
      type: "text",
      label: "Text",
      field: GraphQL.OrderSearchField.CustomerOrderId,
    },
    {
      type: "select",
      label: "Select",
      field: GraphQL.OrderSearchField.Status,
      data: Object.values(GraphQL.OrderStatus),
    },
    {
      type: "dropdown",
      label: "Dropdown",
      field: GraphQL.OrderSearchField.SubmittedAt,
      data: Object.values(GraphQL.OrderStatus),
    },
    {
      type: "date",
      label: "Date",
      field: GraphQL.OrderSearchField.UpdatedAt,
    },
  ]);

  const DrawerInput: React.FC = () => {
    const [value, setValue] = React.useState("");
    const [numberValue, setNumberValue] = React.useState(0);

    React.useEffect(() => {
      console.log(`Value: "${value}"`);
    }, [value]);

    return (
      <DesignSection title="Drawer Input" id="drawer-input">
        <RpiText type="h3" weight="medium" children="Text" />
        <RpiDrawerTextInput label="Label" value={value} onChange={(e) => setValue(e.target.value)} />
        <RpiDrawerTextInput label="Label" value={value} onChange={(e) => setValue(e.target.value)} error="Error..." />

        <RpiText type="h3" weight="medium" children="Number" />
        <RpiDrawerNumberInput
          label="Label"
          value={numberValue}
          onChange={(e) => setNumberValue(Number.parseFloat(e.target.value))}
        />
        <RpiDrawerNumberInput
          label="Label"
          value={numberValue}
          onChange={(e) => setNumberValue(Number.parseFloat(e.target.value))}
          error="Error..."
        />

        <RpiText type="h3" weight="medium" children="Password" />
        <RpiDrawerPasswordInput label="Label" value={value} onChange={(e) => setValue(e.target.value)} />
        <RpiDrawerPasswordInput
          label="Label"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          error="Error..."
        />

        <RpiText type="h3" weight="medium" children="Select" />
        <RpiDrawerSelectInput
          label="Label"
          data={selectData}
          onChange={(value, element) => {
            setValue(value ? `${value}` : "");
            console.log(element);
          }}
        />
        <RpiDrawerSelectInput label="Label" data={selectData} error="Error..." />

        <RpiText type="h3" weight="medium" children="Select - search" />
        <RpiDrawerSelectInput
          label="Label"
          data={selectData}
          onChange={(value, element) => {
            setValue(value ? `${value}` : "");
            console.log(element);
          }}
          searchable
          onSearch={(value) => {
            console.log(`onSearch: "${value}"`);
          }}
        />
      </DesignSection>
    );
  };

  return (
    <>
      <DesignSection title="Icons V2">
        <SimpleGrid
          cols={5}
          spacing="lg"
          breakpoints={[
            { maxWidth: 1800, cols: 10, spacing: "md" },
            { maxWidth: 1500, cols: 8, spacing: "md" },
            { maxWidth: 1200, cols: 6, spacing: "md" },
            { maxWidth: 900, cols: 4, spacing: "md" },
            { maxWidth: 600, cols: 2, spacing: "sm" },
          ]}
        >
          {Object.keys(IconV2).map((key, i) => (
            <Flex.Column gap={16} align="center" key={`icon-${i}`}>
              <Flex.Row gap={8} align="center" sx={{ width: "100%" }}>
                {key} {React.createElement((IconV2 as any)[key], { height: 16, width: 16, color: "currentColor" })}
              </Flex.Row>
              <Flex.Row justify="center" py={8} sx={{ width: "100%", height: "100%", background: "#655c94" }}>
                <Box sx={{ background: "#e7d54f" }}>
                  {React.createElement((IconV2 as any)[key], {
                    height: 40,
                    color: "currentColor",
                  })}
                </Box>
              </Flex.Row>
            </Flex.Column>
          ))}
        </SimpleGrid>
      </DesignSection>

      <DesignSection title="Buttons V2">
        {["spark", "spark-outline", "spark-ghost", "mellow", "brand-outline"].map((variant: any) => {
          return ["small", "large"].map((size: any) => (
            <Flex.Column gap={8} key={`button-${variant}-${size}`}>
              <RpiText type="h3" weight="medium" children={`Button - ${variant} - ${size}`} />
              <Group>
                <RpiButton variant={variant} size={size} children="Button" />
                <RpiButton variant={variant} size={size} children="Button" trailingIcon />
                <RpiButton variant={variant} size={size} children="Button" disabled />
                <RpiButton variant={variant} size={size} children="Button" disabled trailingIcon />
              </Group>
              <Group>
                <RpiButton variant={variant} size={size} children="Button" isLoading />
                <RpiButton variant={variant} size={size} children="Button" isLoading trailingIcon />
                <RpiButton variant={variant} size={size} children="Button" isLoading disabled />
                <RpiButton variant={variant} size={size} children="Button" isLoading disabled trailingIcon />
              </Group>
            </Flex.Column>
          ));
        })}
      </DesignSection>

      <DesignSection title="Dropdown V2" description="[WIP]">
        <RpiDropdown
          placeholder="Book Size"
          data={[
            { label: "12x12in", value: "1" },
            { label: "8x8in", value: "2" },
            { label: "11x8.5in", value: "3" },
          ]}
        />

        <RpiDropdown initiallyOpened data={countrySelectData} placeholder="Country" />
      </DesignSection>

      <DesignSection title="Checkbox V2">
        <RpiCheckbox />
        <RpiCheckbox readOnly checked={false} label="Default checkbox" />
        <RpiCheckbox readOnly checked={false} indeterminate label="Indeterminate checkbox" />
        <RpiCheckbox readOnly checked indeterminate label="Indeterminate checked checkbox" />
        <RpiCheckbox readOnly checked label="Checked checkbox" />
        <RpiCheckbox readOnly disabled label="Disabled checkbox" />
        <RpiCheckbox readOnly disabled checked label="Disabled checked checkbox" />
        <RpiCheckbox readOnly disabled indeterminate label="Disabled indeterminate checkbox" />
      </DesignSection>

      <DesignSection title="Hyperlink V2">
        <RpiLink href="" children="Text" />
      </DesignSection>

      <DesignSection title="Text Input V2">
        <RpiTextInput label="Label" placeholder="Placeholder" />
        <RpiTextInput label="Label" placeholder="Placeholder" error="Error" />
        <RpiTextInput label="Label" placeholder="Placeholder" />
        <RpiTextInput label="Label" placeholder="Placeholder" disabled />
        <RpiTextInput label="Label" placeholder="Placeholder" disabled />
      </DesignSection>

      <DrawerInput />

      <DesignSection title="Password Input V2">
        <RpiPasswordInput label="Label" placeholder="Placeholder" />
        <RpiPasswordInput label="Label" placeholder="Placeholder" error="Error" />
        <RpiPasswordInput label="Label" placeholder="Placeholder" disabled />
      </DesignSection>

      <DesignSection title="Multiline Input V2">
        <RpiMultilineInput label="Label" placeholder="Placeholder" />
        <RpiMultilineInput label="Label" placeholder="Placeholder" error="Error" rows={3} />
        <RpiMultilineInput label="Label" placeholder="Placeholder" disabled />
      </DesignSection>

      <DesignSection title="Status">
        <Flex.Row gap={64} sx={{ flexWrap: "wrap" }}>
          <Flex.Column gap={8}>
            <RpiText mb={16} type="p2" weight="bold" children="Order Status" />
            {Object.values(GraphQL.OrderStatus).map((status, i) => (
              <CellStatus.Order key={`order-status-${i}`} status={status} />
            ))}
          </Flex.Column>
          <Flex.Column gap={8}>
            <RpiText mb={16} type="p2" weight="bold" children="Public Order Status" />
            {Object.values(GraphQL.PublicOrderStatus).map((status, i) => (
              <CellStatus.PublicOrder key={`public-order-status-${i}`} status={status} />
            ))}
          </Flex.Column>
          <Flex.Column gap={8}>
            <RpiText mb={16} type="p2" weight="bold" children="Print Asset Status" />
            {Object.values(GraphQL.PrintAssetStatus).map((status, i) => (
              <CellStatus.PrintAsset key={`print-asset-status-${i}`} status={status} />
            ))}
          </Flex.Column>
        </Flex.Row>
      </DesignSection>

      <DesignSection title="Table V2">
        <Flex.Column gap={32}>
          <RpiPerPage {...rpiPerPage} />
          <RpiTable<{
            name: string;
            id: number;
          }>
            getRowId={(row) => String(row.id)}
            withSortBy={{
              onChange: () => {},
            }}
            withRowSelection={{
              onChange: () => {},
            }}
            withSubRow={{
              onChange: () => {},
              renderSubRow: () => "Hwllo",
            }}
            perPage={rpiPerPage.perPage}
            columns={[
              {
                header: "ID",
                accessor: "id",
              },
              {
                header: "Name",
                accessor: "name",
                cell: ({ row }) => `${row.name} Novak`,
                size: 300,
                minSize: 100,
                maxSize: 400,
                align: "center",
                tooltip:
                  "This email is used for notifications and alerts. E.g. payment method problems, webhooks not being processed and API updates and maintenance.",
              },
            ]}
            data={Array(5)
              .fill(0)
              .map((_, i) => ({
                id: i,
                name: `Value ${i}`,
              }))}
          />

          <RpiTable<{
            name: string;
            id: number;
          }>
            variant="dashboard"
            columns={[
              {
                header: "ID",
                accessor: "id",
              },
              {
                header: "Name",
                accessor: "name",
                cell: ({ row }) => `${row.name} Novak`,
                align: "left",
                size: 200,
              },
              {
                header: "Name",
                accessor: "name",
                cell: ({ row }) => `${row.name} Novak`,
                align: "center",
                size: 200,
              },
              {
                header: "Name",
                accessor: "name",
                cell: ({ row }) => `${row.name} Novak`,
                align: "right",
                size: 200,
              },
            ]}
            data={Array(5)
              .fill(0)
              .map((_, i) => ({
                id: i,
                name: `Value ${i}`,
              }))}
          />
        </Flex.Column>
      </DesignSection>

      <DesignSection title="Pagination V2">
        <RpiPagination centered={false} pagination={pagination} />
        <RpiPagination centered={false} variant="dashboard" pagination={pagination} />
        <RpiPagination pagination={pagination} />
        <RpiPagination variant="dashboard" pagination={pagination} />
      </DesignSection>

      <DesignSection title="Search V2">
        <RpiSearchFilter
          filters={filters}
          dateRangeField={GraphQL.OrderSearchField.Status}
          onChange={(inputs) => console.log(inputs)}
          parser={({ query }) => {
            const searchInputs: RpiSearchFilterInput<GraphQL.OrderSearchField>[] = [];

            if (query.length > 0) {
              searchInputs.push({
                field: GraphQL.OrderSearchField.CustomerOrderId,
                condition: GraphQL.SearchCondition.Contains,
                firstValue: query.join(" "),
              });
            }

            return searchInputs;
          }}
        />
      </DesignSection>

      <DesignSection title="Slider">
        <RpiSlider
          mb={32}
          min={20}
          max={240}
          marks={[
            { value: 20, label: 20 },
            { value: 240, label: 240 },
          ]}
        />
      </DesignSection>

      <DesignSection title="Cell">
        <RpiTable<{ id: number }>
          columns={[
            {
              header: "Raw",
              accessor: "id",
            },
            {
              header: "Boolean",
              cell: () => <CellBoolean value={true} />,
            },
            {
              header: "Clipboard",
              cell: () => <CellClipboard data={{}} children="qypgj" />,
            },
            {
              header: "Date",
              cell: () => <CellDate date={new Date().toISOString()} />,
            },
            {
              header: "JSON",
              cell: () => <CellJson data={{ a: "b" }} />,
            },
            {
              header: "Link",
              cell: () => <CellLink to="./" children="qypgj" />,
            },
            {
              header: "Route",
              cell: () => <CellRoute route={["auth"]} children="qypgj" />,
            },
            {
              header: "Status",
              cell: () => <CellStatus.Order status={GraphQL.OrderStatus.AssetsProcessed} />,
            },
          ]}
          data={Array(1)
            .fill(0)
            .map((_, i) => ({
              id: i,
            }))}
        />

        <RpiSingleTable<{ id: number }>
          rows={[
            {
              header: "Raw",
              accessor: "id",
            },
            {
              header: "Boolean",
              accessor: () => <CellBoolean value={true} />,
            },
            {
              header: "Clipboard",
              accessor: () => <CellClipboard data={{}} children="qypgj" />,
            },
            {
              header: "Date",
              accessor: () => <CellDate date={new Date().toISOString()} />,
            },
            {
              header: "JSON",
              accessor: () => <CellJson data={{ a: "b" }} />,
            },
            {
              header: "Link",
              accessor: () => <CellLink to="./" children="qypgj" />,
            },
            {
              header: "Route",
              accessor: () => <CellRoute route={["auth"]} children="qypgj" />,
            },
            {
              header: "Status",
              accessor: () => <CellStatus.Order status={GraphQL.OrderStatus.AssetsProcessed} />,
            },
          ]}
          data={{ id: 1 }}
        />
      </DesignSection>

      <DesignSection
        title="Counter Field"
        render={() => {
          const [value, setValue] = React.useState<number | undefined>(52);

          return (
            <Flex.Column gap={16}>
              <CountField label="Daily Order Count" value={value} onChange={setValue} />
              <CountField
                label="Daily Order Count"
                value={value}
                onChange={setValue}
                error={"Over the daily order count"}
              />
              <CountField label="Daily Order Count" value={value} onChange={setValue} isLoading />
              <CountField label="Daily Order Count" value={value} disabled />
            </Flex.Column>
          );
        }}
      />

      <DesignSection title="Drag and Drop">
        <DragAndDrop onChange={(files) => console.log("files", files)} />
      </DesignSection>

      <DesignSection title="File Upload">
        <FilesInput
          defaultOpen={false}
          defaultProps={{
            description: undefined as string | undefined,
            isTaxFile: false,
          }}
          inputs={(setProps, props) => (
            <>
              <RpiCheckbox
                label="Tax document?"
                variant="default"
                defaultChecked={props?.isTaxFile}
                onChange={(e) => setProps({ isTaxFile: e.target.checked })}
              />
              <RpiDrawerTextInput
                label="Description"
                onChange={(e) => setProps({ description: e.target.value || undefined })}
              />
            </>
          )}
          onChange={(files) => console.log("files", files)}
        />
      </DesignSection>
    </>
  );
};
