import { GraphQL } from "@rpi/openapi-api";
import { Flex, RpiButton } from "@rpi/openapi-core";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { RpiPagination, RpiPaginationRow } from "../../../components/RpiPagination";
import { RpiSearchFilter } from "../../../components/search-filter/RpiSearchFilter";
import { uploadedFilesColumns } from "../../../configuration/columns/upload.column";
import { useUploadedFilesFilter } from "../../../configuration/filters/upload.filter";
import { useNavigateRoute } from "../../../hooks/useNavigateRoute";
import { useRpiPagination } from "../../../hooks/usePagination";
import { RpiPerPage } from "../../../hooks/useRpiPerPage";
import { RpiTable } from "../../../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../../../utils/graphql.util";
import { showNotification } from "@mantine/notifications";
import { createRpiTableRowMenu } from "../../../rpi-core/table/RpiTableRowMenu";
import { optArray } from "../../../utils/misc.util";
import { filterNullableArray } from "../../../utils/type.util";
import { Time } from "../../../utils/time.util";

export const UploadsView: React.FC = () => {
  const navigateRoute = useNavigateRoute();
  const outlet = useOutlet();

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.UploadedFilesQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.UploadedFilesQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.UploadedFilesQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const orderFilters = useUploadedFilesFilter();

  const { data, isLoading, error, refetch } = GraphQL.useUploadedFilesQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.uploadedFiles.totalCount),
      refetchInterval: (data) => {
        if (!data || !data.uploadedFiles?.data) return false;
        const uploadedFiles = filterNullableArray(data.uploadedFiles.data || []);

        if (!uploadedFiles.length) return false;
        const isPendingUpload = uploadedFiles.some((file) => file.status === GraphQL.UploadedFileStatus.Started);

        if (!isPendingUpload) return false;
        return Time.secondsToMilliseconds(5);
      },
    }
  );
  const uploadedFiles = React.useMemo(() => data?.uploadedFiles.data, [data]);

  const deleteMutation = GraphQL.useUploadedFileDeleteMutation<{ message?: string }>({
    onSuccess: () => refetch(),
    onError: (error) => {
      showNotification({
        title: "Failed delete file.",
        message: JSON.stringify(error),
        color: "red",
      });
    },
  });

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  return (
    <AppPage path={["Uploads"]} onReload={refetch}>
      {outlet}

      <AppPage.Section>
        <Flex.Row justify="space-between" align="flex-end">
          <RpiSearchFilter
            placeholder="Search by filename (or collection)"
            filters={orderFilters}
            onChange={setSearchInputs}
            onSearch={setSearchGeneral}
          />

          <RpiButton
            width="fit"
            variant="brand-outline"
            size="small"
            children="Upload files"
            trailingIcon
            onClick={() => navigateRoute(["uploads:upload"])}
          />
        </Flex.Row>

        <RpiTable
          emptyMessage={searchInputs?.length || searchGeneral ? "No files found." : "Your files will appear here."}
          isLoading={isLoading || deleteMutation.isLoading}
          error={error}
          perPage={pagination.perPage}
          data={uploadedFiles}
          columns={uploadedFilesColumns}
          withSortBy={{ onChange: setSortInput }}
          additionalColumns={[
            createRpiTableRowMenu<Pick<GraphQL.UploadedFileResponse, "id" | "url">>(({ row }) => [
              ...optArray(Boolean(row.url && window.open), [
                {
                  label: "View",
                  onClick: () => window?.open?.(row.url!, "_blank")?.focus(),
                },
              ]),
              {
                label: "Delete",
                onClick: () => row.id && deleteMutation.mutate({ id: row.id }),
                type: "error",
              },
            ]),
          ]}
        />

        <RpiPaginationRow>
          <RpiPagination pagination={pagination} />
          <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
        </RpiPaginationRow>
      </AppPage.Section>
    </AppPage>
  );
};
