import { GraphQL, GraphQLPagedQueryData } from "@rpi/openapi-api";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { CellDate } from "../../components/cells/CellDate";

const customerColumn = createEntityColumn<GraphQL.CustomerResponse, GraphQL.CustomerSearchFields>();

export const CustomerColumn = {
  publicId: customerColumn<"publicId">({
    header: "ID",
    accessor: "publicId",
    sortKey: GraphQL.CustomerSearchFields.PublicId,
    cell: ({ row }) => {
      return <CellRoute route={["customer", { publicId: row.publicId }]} children={row.publicId} />;
    },
    size: 300,
  }),
  email: customerColumn<"email">({
    header: "Notifications Email",
    accessor: "email",
    sortKey: GraphQL.CustomerSearchFields.Email,
    size: 300,
    tooltip:
      "This email is used for notifications and alerts. E.g. payment method problems, webhooks not being processed and API updates and maintenance.",
  }),
  createdAt: customerColumn<"createdAt">({
    header: "Created At",
    accessor: "createdAt",
    sortKey: GraphQL.CustomerSearchFields.CreatedAt,
    cell: ({ row }) => <CellDate date={row.createdAt} />,
    size: 170,
  }),
};

export const customerColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.AdminSearchCustomersQuery>> = [
  CustomerColumn.publicId,
  CustomerColumn.email,
  CustomerColumn.createdAt,
];
