import moment from "moment";
import React from "react";

interface CellDateProps {
  date: moment.MomentInput;
  format?: string;
}

export const CellDate: React.FC<CellDateProps> = ({ date, format }) => {
  const momentDate = React.useMemo(() => {
    if (!date) return "";
    return moment(date).format(format || "MM/DD/YYYY HH:mm:ss");
  }, [date]);
  return <>{momentDate}</>;
};
