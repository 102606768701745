import { Box, BoxProps, createStyles } from "@mantine/core";
import React from "react";

const useOverflowXStyles = createStyles(() => {
  return {
    root: {
      position: "relative",
      display: "flex",
      width: "100%",
      boxSizing: "border-box",
    },
    wrapper: {
      flex: 1,
      width: 0,
      boxSizing: "border-box",
    },
    scroll: {
      overflowX: "auto",
      flex: 1,
      boxSizing: "border-box",
    },
  };
});

interface OverflowXProps extends BoxProps {
  rootClassName?: string;
  rootChildren?: React.ReactNode;
}

export const OverflowX: React.FC<OverflowXProps> = ({ rootChildren, className, rootClassName, ...props }) => {
  const { classes, cx } = useOverflowXStyles();

  return (
    <div className={cx(classes.root, rootClassName)}>
      <div className={classes.wrapper}>
        <Box className={cx(classes.scroll, className)} {...props} />
      </div>
      {rootChildren}
    </div>
  );
};
