import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { RpiDrawerSelectInput, RpiDrawerTextInput } from "@rpi/openapi-core";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuthUser } from "../../../hooks/useAuthUser";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../../rpi-core/drawer/RpiDrawer";
import { dashboardUserRoleLabel } from "../../../utils/graphql.util";
import { shouldRetry } from "../../../utils/query.util";

export const DashboardUserEditView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const { id: stringId } = useParams();
    const id = React.useMemo(() => stringId && Number.parseInt(stringId), []);

    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    React.useEffect(() => {
      if (!stringId) handleClose();
    }, [stringId]);

    const { data, isLoading, error } = GraphQL.useDashboardUserQuery(
      { id: id || -1 },
      {
        enabled: !!stringId && opened,
        refetchOnMount: true,
        retry: shouldRetry(handleClose),
        cacheTime: 0,
      }
    );
    const dashboardUser = React.useMemo(() => data?.dashboardUser, [data]);

    return (
      <RpiDrawer title="Edit user" isLoading={isLoading} error={error} opened={opened} onClose={handleClose}>
        {dashboardUser && <EditUser dashboardUser={dashboardUser} onClose={() => handleClose(true)} />}
      </RpiDrawer>
    );
  }
);

interface EditUserProps {
  dashboardUser: GraphQLQueryData<GraphQL.DashboardUserQuery>;
  onClose: () => void;
}

const EditUser: React.FC<EditUserProps> = ({ dashboardUser, onClose }) => {
  const user = useAuthUser();

  const [customersSearch, setCustomersSearch] = React.useState(dashboardUser.customerPublicId || "");
  const [debouncedCustomersSearch] = useDebouncedValue(customersSearch, 250);

  const { data, ...customersQuery } = GraphQL.useAdminListCustomersQuery(
    { input: debouncedCustomersSearch },
    { enabled: user.is.SUPER_ADMIN }
  );
  const customers = React.useMemo(() => data?.adminListCustomers, [data]);

  const {
    mutateAsync: editDashboardUser,
    reset,
    ...mutation
  } = GraphQL.useEditDashboardUserMutation<{ message: string }>();

  const form = useForm<GraphQL.EditDashboardUserMutationVariables["input"]>({
    initialValues: {
      username: dashboardUser.username || "",
      firstName: dashboardUser.firstName || "",
      lastName: dashboardUser.lastName || "",
      customerPublicId: dashboardUser.customerPublicId || "",
      role: dashboardUser?.role || GraphQL.DashboardUserRole.User,
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      await editDashboardUser({ id: dashboardUser.id, input });
      onClose();
    },
    [editDashboardUser, dashboardUser, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Editing user...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to edit user...",
          description: "Please try again later.",
        },
        success: {
          message: "User edited!",
        },
      })(mutation),
    [mutation]
  );

  const customersSelectData = React.useMemo(
    () => (customers || []).map((customer) => ({ label: customer.publicId, value: customer.publicId })),
    [customers]
  );

  const roleSelectData = React.useMemo(() => {
    return [
      GraphQL.DashboardUserRole.User,
      GraphQL.DashboardUserRole.CustomerAdmin,
      ...(user.is.SUPER_ADMIN ? [GraphQL.DashboardUserRole.SuperAdmin] : []),
    ].map((value) => ({ label: dashboardUserRoleLabel(value), value }));
  }, [user]);

  return (
    <RpiDrawerForm button="Save changes" overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      {user.is.SUPER_ADMIN && (
        <RpiDrawerSelectInput
          required
          label="Customer"
          data={customersSelectData}
          {...form.getInputProps("customerPublicId")}
          searchable
          onSearch={setCustomersSearch}
          isLoading={customersQuery.isLoading}
        />
      )}

      <RpiDrawerTextInput disabled label="Username" {...form.getInputProps("username")} />
      <RpiDrawerTextInput label="First Name" {...form.getInputProps("firstName")} />
      <RpiDrawerTextInput label="Last Name" {...form.getInputProps("lastName")} />

      <RpiDrawerSelectInput required label="Role" data={roleSelectData} {...form.getInputProps("role")} />
    </RpiDrawerForm>
  );
};
