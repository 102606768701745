import { ContextModalProps } from "@mantine/modals";
import { Flex, RpiButton, RpiText } from "@rpi/openapi-core";

export const ConfirmationModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  message: React.ReactNode;
  onConfirm: () => void | Promise<void>;
}>) => (
  <Flex.Column gap={32} sx={(theme) => ({ color: theme.colors.brand[5] })}>
    <RpiText type="p2" weight="regular" children={innerProps.message} />
    <Flex.Row gap={16} justify="flex-end">
      <RpiButton variant="spark" width="full" onClick={() => context.closeModal(id)} children="Cancel" />
      <RpiButton
        variant="mellow"
        width="full"
        onClick={() => {
          context.closeModal(id);
          innerProps.onConfirm();
        }}
        children="Confirm"
      />
    </Flex.Row>
  </Flex.Column>
);
