import { useForm } from "@mantine/form";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { RpiDrawerTextInput } from "@rpi/openapi-core";
import React from "react";
import { useParams } from "react-router-dom";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../rpi-core/drawer/RpiDrawer";
import { shouldRetry } from "../../utils/query.util";

export const CustomerProductEditView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const { id: stringId } = useParams();
    const id = React.useMemo(() => stringId && Number.parseInt(stringId), []);

    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    React.useEffect(() => {
      if (!stringId) handleClose();
    }, [stringId]);

    const { data, isLoading, error } = GraphQL.useCustomerProductQuery(
      { id: id || -1 },
      {
        enabled: !!stringId && opened,
        refetchOnMount: true,
        retry: shouldRetry(handleClose),
        cacheTime: 0,
      }
    );
    const customerProduct = React.useMemo(() => data?.customerProduct, [data]);

    return (
      <RpiDrawer title="Edit product" isLoading={isLoading} error={error} opened={opened} onClose={handleClose}>
        {customerProduct && <EditProduct customerProduct={customerProduct} onClose={() => handleClose(true)} />}
      </RpiDrawer>
    );
  }
);

interface EditProductProps {
  customerProduct: GraphQLQueryData<GraphQL.CustomerProductQuery>;
  onClose: () => void;
}

const EditProduct: React.FC<EditProductProps> = ({ customerProduct, onClose }) => {
  const {
    mutateAsync: editCustomerProduct,
    reset,
    ...mutation
  } = GraphQL.useEditCustomerProductMutation<{ message: string }>();

  const form = useForm<GraphQL.EditCustomerProductMutationVariables["input"]>({
    initialValues: {
      newCustomerSku: customerProduct?.customerSku || "",
      name: customerProduct?.name || "",
      description: customerProduct?.description || "",
    },
    validate: {},
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      await editCustomerProduct({ id: customerProduct.id, input });
      onClose();
    },
    [editCustomerProduct, customerProduct, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Editing product...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to edit product...",
          description: "Please try again later.",
        },
        success: {
          message: "Product edited!",
        },
      })(mutation),
    [mutation]
  );

  return (
    <RpiDrawerForm button="Save changes" overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerTextInput required label="Your SKU" {...form.getInputProps("newCustomerSku")} />
      <RpiDrawerTextInput required label="Your Product Name" {...form.getInputProps("name")} />
      <RpiDrawerTextInput label="Description" {...form.getInputProps("description")} />
    </RpiDrawerForm>
  );
};
