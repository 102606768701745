import { useMantineTheme } from "@mantine/core";
import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";

export const DeliveryTimelinePage: React.FC = () => {
  const theme = useMantineTheme();

  const deliveryTimelines = React.useMemo(
    () => [
      {
        type: "Economy",
        description: "Mail Innovations BPM",
        duration: "5-7 days",
        width: 500,
        color: theme.colors.mellow[5],
      },
      {
        type: "Standard",
        description: "FedEx Home Delivery",
        duration: "5 days",
        width: 440,
        color: theme.colors.calm[5],
      },
      {
        type: "Express",
        description: "FedEx 2-day",
        duration: "2 days",
        width: 240,
        color: theme.colors.spark[5],
      },
      {
        type: "Priority",
        description: "FedEx Standard Overnight",
        duration: "1 day",
        width: 120,
        color: theme.colors.amethyst[5],
      },
    ],
    [theme]
  );

  return (
    <Flex.Column justify="center" align="center" fullWidth p={80} sx={(theme) => ({ background: theme.colors.sky[5] })}>
      <Flex.Column
        justify="center"
        align="center"
        fullWidth
        gap={24}
        sx={(theme) => ({
          flex: 1,
          background: theme.colors.sky[5],
          position: "relative",
          maxWidth: 760,
        })}
      >
        <RpiText type="d2" weight="bold" color={(theme) => theme.colors.brand[5]} children="Delivery timeline" />
        <RpiText
          type="p2"
          weight="regular"
          color={(theme) => theme.colors.brand[5]}
          children="RPI ships to the US only. Printing and binding take up to 4-5 business days, then your order ships."
        />

        <Flex.Column
          gap={32}
          sx={{ width: 714.5 }}
          children={deliveryTimelines.map((deliveryTimeline, i) => (
            <Flex.Row align="center" gap={32} sx={{ height: 40 }} key={`delivery-timeline-${i}`}>
              <Flex.Column sx={(theme) => ({ width: 182.5, color: theme.colors.brand[5] })}>
                <RpiText type="p3" weight="bold" children={deliveryTimeline.type} />
                <RpiText type="p3" weight="light" children={deliveryTimeline.description} />
              </Flex.Column>

              <Flex
                px={16}
                align="center"
                sx={(theme) => ({
                  width: deliveryTimeline.width,
                  background: deliveryTimeline.color,
                  color: theme.colors.text[5],
                  ...theme.other.fontTypes.p3,
                  fontWeight: theme.other.fontWeights.regular,
                  height: 32,
                  borderRadius: 16,
                })}
                children={deliveryTimeline.duration}
              />
            </Flex.Row>
          ))}
        />
      </Flex.Column>
    </Flex.Column>
  );
};
