import { Box, LoadingOverlay } from "@mantine/core";
import { PDFPageProxy } from "pdfjs-dist";
import React from "react";
import { useLoadingCallback } from "../../../hooks/useLoadingCallback";

interface PdfPagePreviewProps {
  page?: PDFPageProxy | null;
  bleed?: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };
  defaultDimensions?: {
    width: number;
    height: number;
  };
}

export const PdfPagePreview = React.forwardRef<HTMLDivElement, PdfPagePreviewProps>(
  ({ page, bleed, defaultDimensions }, ref) => {
    const { isLoading, loadingCallback } = useLoadingCallback();

    const canvasRef = React.useRef<HTMLCanvasElement>();

    const renderBleed = React.useCallback(
      (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
        if (!bleed) return;

        // ctx.globalCompositeOperation = "hard-light";
        // ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
        // ctx.strokeStyle = "rgba(255, 0, 0, 1)";

        // ctx.globalCompositeOperation = "luminosity";
        // ctx.fillStyle = "rgba(128, 128, 128, 0.5)";
        // ctx.strokeStyle = "rgba(128, 128, 128, 1)";

        ctx.globalCompositeOperation = "difference";
        ctx.fillStyle = "rgba(128, 128, 128, 0.5)";
        ctx.strokeStyle = "rgba(128, 128, 128, 1)";

        const bleedPath = new Path2D();
        bleedPath.moveTo(0, 0);
        bleedPath.lineTo(canvas.width, 0);
        bleedPath.lineTo(canvas.width, canvas.height);
        bleedPath.lineTo(0, canvas.height);
        bleedPath.lineTo(0, 0);
        bleedPath.lineTo(bleed.left, bleed.top);
        bleedPath.lineTo(canvas.width - bleed.right, bleed.top);
        bleedPath.lineTo(canvas.width - bleed.right, canvas.height - bleed.bottom);
        bleedPath.lineTo(bleed.left, canvas.height - bleed.bottom);
        bleedPath.lineTo(bleed.left, bleed.top);
        bleedPath.closePath();
        ctx.fill(bleedPath, "evenodd");

        const lineWidth = 2;
        const outlineBleed = { ...bleed };
        Object.keys(outlineBleed).forEach((key) => ((outlineBleed as any)[key] -= lineWidth / 2));

        const bleedLinePath = new Path2D();
        bleedLinePath.moveTo(outlineBleed.left, outlineBleed.top);
        bleedLinePath.lineTo(canvas.width - outlineBleed.right, outlineBleed.top);
        bleedLinePath.lineTo(canvas.width - outlineBleed.right, canvas.height - outlineBleed.bottom);
        bleedLinePath.lineTo(outlineBleed.left, canvas.height - outlineBleed.bottom);
        bleedLinePath.closePath();

        ctx.lineWidth = lineWidth;
        ctx.setLineDash([10, 5]);
        ctx.stroke(bleedLinePath);

        ctx.globalCompositeOperation = "source-over";
      },
      [bleed]
    );

    const clearCanvas = React.useCallback(() => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      if (!canvas || !ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }, []);

    const render = React.useCallback(() => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      if (!canvas || !ctx) return;

      if (page) {
        return loadingCallback(async () => {
          const viewport = page.getViewport({ scale: 1 });

          const width = viewport.width;
          const height = viewport.height;

          if (canvas.width !== width) canvas.width = width;
          if (canvas.height !== height) canvas.height = height;

          await page.render({ canvasContext: ctx, viewport }).promise;
          renderBleed(canvas, ctx);
        });
      }

      clearCanvas();
      if (defaultDimensions) {
        if (canvas.width !== defaultDimensions.width) canvas.width = defaultDimensions.width;
        if (canvas.height !== defaultDimensions.height) canvas.height = defaultDimensions.height;
      }
    }, [page, renderBleed, clearCanvas, loadingCallback, defaultDimensions]);

    React.useEffect(() => {
      render();
    }, [render]);

    return (
      <Box ref={ref} sx={{ position: "relative", boxSizing: "border-box", display: "inline-flex" }}>
        {isLoading && <LoadingOverlay visible={isLoading} overlayOpacity={1} />}
        <canvas ref={canvasRef as React.LegacyRef<HTMLCanvasElement>} style={{ objectFit: "contain", width: "100%" }} />
      </Box>
    );
  }
);
