import { GraphQL, GraphQLPagedQueryData } from "@rpi/openapi-api";
import { CellClipboard } from "../../components/cells/CellClipboard";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { formatAuthHeader } from "../../utils/misc.util";

const apiCredentialColumn = createEntityColumn<GraphQL.ApiCredentialsResponse, GraphQL.ApiCredentialSearchFields>();

export const ApiCredentialColumn = {
  description: apiCredentialColumn<"description">({
    header: "Description",
    accessor: "description",
    sortKey: GraphQL.ApiCredentialSearchFields.Description,
    size: 300,
  }),
  customer: apiCredentialColumn<"customerPublicId">({
    header: "Customer",
    accessor: "customerPublicId",
    sortKey: GraphQL.ApiCredentialSearchFields.CustomerPublicId,
    cell: ({ row }) => {
      if (!row.customerPublicId) return;
      return <CellRoute route={["customer", { publicId: row.customerPublicId }]} children={row.customerPublicId} />;
    },
    size: 200,
  }),
  apiKey: apiCredentialColumn<"apiKey">({
    header: "API Key",
    accessor: "apiKey",
    sortKey: GraphQL.ApiCredentialSearchFields.ApiKey,
    cell: ({ row }) => <CellClipboard children={row.apiKey} />,
    size: 200,
  }),
  sharedSecret: apiCredentialColumn<"sharedSecret">({
    header: "Shared Secret",
    accessor: "sharedSecret",
    sortKey: GraphQL.ApiCredentialSearchFields.SharedSecret,
    cell: ({ row }) => <CellClipboard children={row.sharedSecret} />,
    size: 200,
  }),
  authHeader: apiCredentialColumn<"apiKey" | "sharedSecret">({
    header: "Authorization Header",
    cell: ({ row }) => {
      return <CellClipboard children={formatAuthHeader(row.apiKey, row.sharedSecret)} />;
    },
    size: 250,
  }),
  webhookSecret: apiCredentialColumn<"webhookSecret">({
    header: "Webhook Secret",
    accessor: "webhookSecret",
    sortKey: GraphQL.ApiCredentialSearchFields.SharedSecret,
    cell: ({ row }) => <CellClipboard children={row.webhookSecret} />,
    size: 200,
  }),
};

export const apiCredentialColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.ApiCredentialsQuery>> = (user) => [
  user.is.SUPER_ADMIN && ApiCredentialColumn.customer,
  user.is.SUPER_ADMIN && ApiCredentialColumn.webhookSecret,
  ApiCredentialColumn.apiKey,
  ApiCredentialColumn.sharedSecret,
  ApiCredentialColumn.authHeader,
  ApiCredentialColumn.description,
];
