type CustomEventCallback<T> = (event: CustomEvent<T>) => void;

export class LocalState<T> {
  private privateValue!: T;
  private eventTarget = new EventTarget();

  constructor(private defaultValue: T) {
    this.privateValue = defaultValue;
  }

  /** Get value. */
  get value(): T {
    return this.privateValue;
  }

  /** Set value. Emits "change" event. */
  set value(value: T) {
    this.privateValue = value;
    this.eventTarget.dispatchEvent(new CustomEvent("change", { detail: this.privateValue }));
  }

  /** Reset to default value. */
  public reset() {
    this.value = this.defaultValue;
  }

  /** Add listener to "change" event. */
  public addListener(callback: CustomEventCallback<T>) {
    this.eventTarget.addEventListener("change", callback as any);
  }

  /** Remove listener from "change" event. */
  public removeListener(callback: CustomEventCallback<T>) {
    this.eventTarget.removeEventListener("change", callback as any);
  }
}
