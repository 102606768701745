import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";
import { Outlet } from "react-router-dom";

export const DesignSection: React.FC<{
  title: string;
  description?: string;
  children?: React.ReactNode;
  id?: string;
  render?: React.FC;
}> = ({ title, description, children, id, render }) => {
  return (
    <Flex.Column gap={16} sx={{ position: "relative", width: "100%" }} id={id}>
      <Flex.Row pb={8} gap={8} align="flex-end" sx={{ borderBottom: "1px solid black" }}>
        <RpiText type="h1" weight="bold" children={title} />
        <RpiText type="h3" weight="medium" sx={(theme) => ({ color: theme.colors.text[3] })} children={description} />
      </Flex.Row>

      <Flex.Column
        gap={16}
        p={16}
        sx={{ backgroundColor: "#f5f5f5" }}
        children={render ? React.createElement(render) : children}
      />
    </Flex.Column>
  );
};

export const DesignLayout: React.FC = () => {
  return (
    <Flex.Column gap={32} p={32} pb="50vh" sx={{ backgroundColor: "#e5e5e5", height: "100%" }}>
      <Outlet />
    </Flex.Column>
  );
};
