import { useSetState } from "@mantine/hooks";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { Time } from "../utils/time.util";

export enum RpiNotification {
  PAYMENT_METHOD = "paymentMethod",
  TAX_DOCUMENT_NOT_UPLOADED = "taxDocumentNotUploaded",
}

export interface RpiNotificationsContextProps {
  notifications: Partial<{
    [RpiNotification.PAYMENT_METHOD]: boolean;
    [RpiNotification.TAX_DOCUMENT_NOT_UPLOADED]: boolean;
  }>;
  refetch: (notification?: RpiNotification) => void;
}

export const RpiNotificationsContext = React.createContext<RpiNotificationsContextProps | null>(null);

export interface RpiNotificationsProviderProps {
  children: React.ReactNode;
}

export const RpiNotificationsProvider: React.FC<RpiNotificationsProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useSetState<RpiNotificationsContextProps["notifications"]>({});

  const bulletinQuery = GraphQL.useBulletinsQuery(
    {},
    {
      refetchInterval: Time.minutesToMilliseconds(5),
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      cacheTime: Time.minutesToMilliseconds(5),
      onSuccess: (data) => {
        const bulletins = data?.bulletins;
        if (!bulletins) return;

        setNotifications((notifications) => ({
          ...notifications,
          [RpiNotification.PAYMENT_METHOD]: Boolean(bulletins.paymentMethodMissing),
          [RpiNotification.TAX_DOCUMENT_NOT_UPLOADED]: Boolean(bulletins.taxDocumentNotUploaded),
        }));
      },
    }
  );

  const handleRefetch: RpiNotificationsContextProps["refetch"] = React.useCallback(
    (notification) => {
      switch (notification) {
        case RpiNotification.PAYMENT_METHOD:
          return bulletinQuery.refetch();
        case RpiNotification.TAX_DOCUMENT_NOT_UPLOADED:
          return bulletinQuery.refetch();
        default:
          // All
          return bulletinQuery.refetch();
      }
    },
    [bulletinQuery.refetch]
  );

  return <RpiNotificationsContext.Provider value={{ notifications, refetch: handleRefetch }} children={children} />;
};

export const useRpiNotifications = () => {
  const context = React.useContext(RpiNotificationsContext);
  if (!context) {
    throw new Error(`${useRpiNotifications.name} must be used within ${RpiNotificationsProvider.name}`);
  }

  return context;
};
