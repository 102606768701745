import { MultiSelect, MultiSelectProps, Select, SelectProps } from "@mantine/core";
import React from "react";

// Select Item
export interface CustomSelectItemProps<T = unknown> extends React.ComponentPropsWithoutRef<"div"> {
  value: string;
  label: string;
  element: T;
}

// Select
export type CustomSelectProps<T> = Omit<SelectProps, "data"> &
  React.RefAttributes<HTMLInputElement> & {
    data?: T[];
    getValue?: (element: T) => string;
    getItemProps: (element: T, index: number) => CustomSelectItemProps<T>;
  };

export type CustomSelectWrapper<T> = React.FC<Omit<CustomSelectProps<T>, "getItemProps" | "itemComponent">>;

export function CustomSelect<T>({ getItemProps, data, ...props }: CustomSelectProps<T>): JSX.Element {
  const selectValues: CustomSelectItemProps<T>[] = React.useMemo(
    () => (data || []).map(getItemProps),
    [data, getItemProps]
  );
  return <Select data={selectValues} {...props} />;
}

// Multi Select
export type CustomMultiSelectProps<T> = Omit<MultiSelectProps, "data"> &
  React.RefAttributes<HTMLInputElement> & {
    data?: T[];
    getValue?: (element: T) => string;
    getItemProps: (element: T, index: number) => CustomSelectItemProps<T>;
  };

export type CustomMultiSelectWrapper<T> = React.FC<Omit<CustomMultiSelectProps<T>, "getItemProps" | "itemComponent">>;

export function CustomMultiSelect<T>({ getItemProps, data, ...props }: CustomMultiSelectProps<T>): JSX.Element {
  const selectValues: CustomSelectItemProps<T>[] = React.useMemo(
    () => (data || []).map(getItemProps),
    [data, getItemProps]
  );
  return <MultiSelect data={selectValues} {...props} />;
}
