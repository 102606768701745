import { GraphQL, GraphQLPagedQueryData } from "@rpi/openapi-api";
import { CellClipboard } from "../../components/cells/CellClipboard";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";

const customerProductColumn = createEntityColumn<GraphQL.CustomerProductResponse, GraphQL.CustomerProductSearchField>();

export const CustomerProductColumn = {
  customerSku: customerProductColumn<"customerSku">({
    header: "Product SKU",
    accessor: "customerSku",
    sortKey: GraphQL.CustomerProductSearchField.CustomerSku,
    cell: ({ row }) => <CellClipboard children={row.customerSku} />,
    size: 300,
    align: "right",
  }),
  name: customerProductColumn<"name" | "id">({
    header: "Your Product Name",
    accessor: "name",
    sortKey: GraphQL.CustomerProductSearchField.Name,
    cell: ({ row }) => <CellRoute route={["customer-product", { id: row.id }]} children={row.name} />,
    size: 500,
  }),
  openapiProductId: customerProductColumn<"openapiProductId">({
    header: "Open Api Product",
    accessor: "openapiProductId",
    sortKey: GraphQL.CustomerProductSearchField.OpenapiProductId,
    cell: ({ row }) => (
      <CellRoute route={["open-api-product", { id: row.openapiProductId }]} children={row.openapiProductId} />
    ),
    size: 200,
  }),
  description: customerProductColumn<"description">({
    header: "Description",
    accessor: "description",
    sortKey: GraphQL.CustomerProductSearchField.Description,
    size: 300,
  }),
  customerPublicId: customerProductColumn<"customerPublicId">({
    header: "Customer",
    accessor: "customerPublicId",
    sortKey: GraphQL.CustomerProductSearchField.CustomerPublicId,
    cell: ({ row }) => (
      <CellRoute route={["customer", { publicId: row.customerPublicId }]} children={row.customerPublicId} />
    ),
    size: 200,
  }),
};

export const customerProductColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.CustomerProductsQuery>> = (user) => [
  CustomerProductColumn.name,
  user.is.SUPER_ADMIN && CustomerProductColumn.customerPublicId,
  user.is.SUPER_ADMIN && CustomerProductColumn.openapiProductId,
  CustomerProductColumn.customerSku,
];
