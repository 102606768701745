import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { RpiRouteLink } from "../../../hooks/useNavigateRoute";
import { createRpiDrawerView, RpiDrawer, RpiDrawerProps } from "../../../rpi-core/drawer/RpiDrawer";
import { RpiDrawerField } from "../../../rpi-core/drawer/RpiDrawerField";
import { dashboardUserRoleLabel } from "../../../utils/graphql.util";
import { shouldRetry } from "../../../utils/query.util";

export const DashboardUserView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const { id: stringId } = useParams();
    const id = React.useMemo(() => stringId && Number.parseInt(stringId), []);

    React.useEffect(() => {
      if (!stringId) onClose();
    }, [stringId]);

    const { data, isLoading, error } = GraphQL.useDashboardUserQuery(
      { id: id || -1 },
      {
        enabled: !!stringId && opened,
        refetchOnMount: true,
        retry: shouldRetry(onClose),
      }
    );
    const dashboardUser = React.useMemo(() => data?.dashboardUser, [data]);

    return (
      <RpiDrawer title="User details" isLoading={isLoading} error={error} opened={opened} onClose={onClose}>
        {dashboardUser && <UserInfo dashboardUser={dashboardUser} />}
      </RpiDrawer>
    );
  }
);

interface UserInfoProps {
  dashboardUser: GraphQLQueryData<GraphQL.DashboardUserQuery>;
}

const UserInfo: React.FC<UserInfoProps> = ({ dashboardUser }) => {
  const user = useAuthUser();

  return (
    <>
      <RpiDrawerField label="Username" children={dashboardUser.username} />

      <RpiDrawerField label="First Name" children={dashboardUser.firstName} />
      <RpiDrawerField label="Last Name" children={dashboardUser.lastName} />

      {user.is.SUPER_ADMIN && dashboardUser.customerPublicId && (
        <RpiDrawerField label="Customer">
          <RpiRouteLink
            variant="inherit"
            route={["customer", { publicId: dashboardUser.customerPublicId }]}
            children={dashboardUser.customerPublicId}
          />
        </RpiDrawerField>
      )}
      <RpiDrawerField label="Sub" children={dashboardUser.sub} />
      <RpiDrawerField label="Email" children={dashboardUser.email} />
      <RpiDrawerField label="Role" children={dashboardUserRoleLabel(dashboardUser.role)} />
    </>
  );
};
