import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { useParams } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { uploadedCollectionFilesColumns } from "../../../configuration/columns/upload.column";
import { getRoutePath } from "../../../hooks/useNavigateRoute";
import { RpiTable } from "../../../rpi-core/table/RpiTable";
import { filterNullableArray } from "../../../utils/type.util";
import { RpiSingleTable } from "../../../components/tables/RpiSingleTable";
import { formatCount } from "../../../utils/misc.util";
import { Time } from "../../../utils/time.util";

export const UploadsCollectionView: React.FC = () => {
  const { collectionId, customerPublicId } = useParams();

  const { data, isLoading, error, refetch } = GraphQL.useUploadFilesByPollCollectionQuery(
    {
      collectionId: collectionId!,
      customerPublicId: customerPublicId!,
    },
    {
      enabled: Boolean(collectionId) && Boolean(customerPublicId),
      refetchInterval: (data) => {
        if (!data || !data.uploadFilesPollByCollection) return false;
        const uploadedFiles = filterNullableArray(data.uploadFilesPollByCollection || []);

        if (!uploadedFiles.length) return false;
        const allFinished = uploadedFiles.every(
          (file) =>
            file.status === GraphQL.UploadedFileStatus.Finished || file.status === GraphQL.UploadedFileStatus.Failure
        );

        if (allFinished) return false;
        return Time.secondsToMilliseconds(5);
      },
    }
  );
  const uploadedFiles = React.useMemo(() => filterNullableArray(data?.uploadFilesPollByCollection || []), [data]);

  return (
    <AppPage path={[{ name: "Uploads", to: getRoutePath("uploads") }, "Collections", collectionId]} onReload={refetch}>
      <AppPage.Section title="Data" id="data">
        <RpiSingleTable
          isLoading={isLoading}
          error={error}
          data={{ collectionId, customerPublicId }}
          rows={(user) => [
            user.is.SUPER_ADMIN && { header: "Customer", accessor: "customerPublicId" },
            { header: "Collection ID", accessor: "collectionId" },
          ]}
        />
      </AppPage.Section>

      <AppPage.Section mt={32} title="Files" description={`(${formatCount(uploadedFiles.length, "file")})`} id="files">
        <RpiTable
          emptyMessage="No files found."
          isLoading={isLoading}
          error={error}
          data={uploadedFiles}
          columns={uploadedCollectionFilesColumns}
        />
      </AppPage.Section>
    </AppPage>
  );
};
