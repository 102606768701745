import { Box, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import {
  Flex,
  Grid,
  IconV2,
  RpiButton,
  RpiDrawerSelectInput,
  RpiIconButton,
  RpiLink,
  RpiText,
} from "@rpi/openapi-core";
import moment from "moment";
import React from "react";
import { Link, useOutlet } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { useAuthUser } from "../../hooks/useAuthUser";
import { getRoutePath, useNavigateRoute } from "../../hooks/useNavigateRoute";
import { formatCount, formatCurrency } from "../../utils/misc.util";

type SelectedOrder = Array<NonNullable<NonNullable<GraphQLQueryData<GraphQL.ListOrdersForPaymentQuery>>[number]>>;

export const PurchaseOrdersView: React.FC = () => {
  const user = useAuthUser();
  const outlet = useOutlet();
  const navigateRoute = useNavigateRoute();

  const [selectedOrders, setSelectedOrders] = React.useState<SelectedOrder>([]);
  const [removedOrders, setRemovedOrders] = React.useState<Set<GraphQL.OrderResponse["id"]>>(new Set());
  const [paymentMethod, setPaymentMethod] = React.useState<GraphQLQueryData<GraphQL.ListPaymentMethodsQuery>[number]>();

  const { isLoading, error, refetch } = GraphQL.useListOrdersForPaymentQuery(
    { customerPublicId: user.id! },
    {
      onSuccess: ({ listOrdersForPayment }) => {
        const newOrders = (listOrdersForPayment || [])?.filter((order) => order && !removedOrders.has(order.id));
        setSelectedOrders(newOrders);
      },
    }
  );

  const paymentMethodsQuery = GraphQL.useListPaymentMethodsQuery(
    { customerPublicId: user.id || "" },
    { enabled: !!user.id }
  );

  const subtotal = React.useMemo(
    () => ({
      value: selectedOrders.reduce((p, c) => p + (c.pricing?.orderTotal || 0), 0) || 0,
      currency: selectedOrders[0]?.pricing?.currency,
    }),
    [selectedOrders]
  );

  const maxHeight = React.useMemo(() => (152 + 32 + 1 + 32) * 3 - 32, []);

  const handleRemoveOrder = React.useCallback(
    (id: GraphQL.OrderResponse["id"]) => {
      if (removedOrders.has(id)) return;

      const newRemoveOrders = new Set(Array.from(removedOrders));
      newRemoveOrders.add(id);

      setRemovedOrders(newRemoveOrders);
      setSelectedOrders((orders) => orders.filter((order) => !newRemoveOrders.has(order.id)));
    },
    [removedOrders]
  );

  const { mutateAsync: createPayment, ...createPaymentMutation } = GraphQL.useCreateBraintreePaymentMutation({
    onSuccess: ({ braintreePaymentCreate }) => {
      navigateRoute([
        "orders:purchase:confirmation",
        { braintreeTransactionId: braintreePaymentCreate.braintreeTransactionId },
      ]);
    },
  });

  const handlePayOrders = React.useCallback(async () => {
    if (!paymentMethod || !selectedOrders || selectedOrders.length === 0) return;

    return await createPayment({
      input: {
        customerOrderIds: selectedOrders.map((order) => order.customerOrderId!),
        token: paymentMethod.token,
      },
    })
      .then(() => {
        showNotification({
          title: "Payment successful.",
          message: `Payment for ${formatCount(selectedOrders.length, "order")} was successful.`,
          color: "green",
        });
      })
      .catch((error) => {
        showNotification({
          title: "Payment failed.",
          message: error.message,
          color: "red",
        });
      });
  }, [selectedOrders, paymentMethod, refetch]);

  React.useEffect(() => {
    if (outlet === null) {
      refetch();
      paymentMethodsQuery.refetch();
    }
  }, [outlet]);

  return (
    <AppPage path={[{ name: "Orders", to: getRoutePath("orders") }, "Purchase"]} onReload={refetch}>
      {outlet}

      <Flex.Row gap={40} sx={(theme) => ({ color: theme.colors.brand[5] })}>
        <Flex.Column gap={32}>
          <Flex.Row align="baseline" gap={16}>
            <RpiText type="h3" weight="bold" children="Awaiting payment" />
            {!!selectedOrders?.length && (
              <RpiText type="p1" weight="regular" children={formatCount(selectedOrders.length, "order")} />
            )}
          </Flex.Row>

          <Flex.Column
            pr={selectedOrders.length > 3 ? 16 : 0}
            gap={32}
            sx={{ width: 463, maxHeight, overflowY: "auto" }}
          >
            {(isLoading || !!error) && (
              <Flex justify="center" align="center">
                <Loader variant="oval" size="lg" color={error ? "spark" : "brand"} />
              </Flex>
            )}
            {!isLoading && !error && selectedOrders && (
              <>
                {selectedOrders.length === 0 && (
                  <RpiText type="p2" weight="regular" children="There are no orders awaiting payment." />
                )}

                {selectedOrders.map((order, i) => {
                  return (
                    <React.Fragment key={`order-display-${order.id}`}>
                      <OrderCard key={`order-${i}`} order={order} onRemove={() => handleRemoveOrder(order.id)} />
                      <Box
                        sx={(theme) => ({ width: "100%", height: 1, background: theme.colors.brand[3], flexShrink: 0 })}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Flex.Column>

          {!!selectedOrders?.length && (
            <RpiText
              type="p1"
              weight="bold"
              color={(theme) => theme.colors.brand[4]}
              children={`Subtotal: ${formatCurrency(subtotal.currency, subtotal.value)}`}
            />
          )}
        </Flex.Column>

        <Flex.Column gap={32} sx={{ width: "fit-content" }}>
          <PaymentDetails
            paymentMethods={paymentMethodsQuery.data?.listPaymentMethods || []}
            isLoading={paymentMethodsQuery.isLoading}
            error={paymentMethodsQuery.error}
            onSelect={setPaymentMethod}
          />

          <PurchaseInfoCard title="Order Summary">
            {selectedOrders.length > 0 ? (
              <>
                <span children={formatCount(selectedOrders.length, "order")} />{" "}
                <Grid
                  columns="1fr min-content"
                  columnGap={32}
                  rowGap={16}
                  sx={{ maxHeight: (25 + 16) * 3 - 16, overflowY: "auto", paddingRight: 16, marginRight: -16 }}
                >
                  {selectedOrders.map((order) => (
                    <React.Fragment key={`order-summary-${order.id}`}>
                      <RpiText type="p2" weight="regular" ellipsis children={`Order ID ${order.customerOrderId}`} />
                      <RpiText
                        type="p2"
                        weight="bold"
                        children={formatCurrency(order.pricing?.currency, order.pricing?.orderTotal || 0)}
                      />
                    </React.Fragment>
                  ))}
                </Grid>
              </>
            ) : (
              "There are no orders"
            )}
          </PurchaseInfoCard>

          <Flex.Row justify="space-between" align="center">
            <RpiText
              type="p1"
              weight="bold"
              color={(theme) => theme.colors.brand[5]}
              sx={{ fontSize: 24 }}
              children={`Total: ${formatCurrency(subtotal.currency, subtotal.value)}`}
            />

            <RpiButton
              variant="mellow"
              width="fit"
              trailingIcon
              isLoading={createPaymentMutation.isLoading}
              disabled={!selectedOrders || selectedOrders.length === 0 || !paymentMethod}
              onClick={handlePayOrders}
              children="Pay now"
            />
          </Flex.Row>
        </Flex.Column>
      </Flex.Row>
    </AppPage>
  );
};

interface PaymentDetailsProps {
  paymentMethods: GraphQLQueryData<GraphQL.ListPaymentMethodsQuery>;
  isLoading?: boolean;
  error?: any;
  onSelect: (paymentMethod: GraphQLQueryData<GraphQL.ListPaymentMethodsQuery>[number] | undefined) => void;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({ paymentMethods, isLoading, error, onSelect }) => {
  const navigateRoute = useNavigateRoute();

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<GraphQLQueryData<GraphQL.ListPaymentMethodsQuery>[number]>();

  React.useEffect(() => {
    if (selectedPaymentMethod) return;

    setSelectedPaymentMethod(paymentMethods.find((pm) => pm.isDefault));
  }, [paymentMethods, selectedPaymentMethod]);

  React.useEffect(() => {
    onSelect(selectedPaymentMethod);
  }, [selectedPaymentMethod, onSelect]);

  return (
    <PurchaseInfoCard title="Payment details">
      {(isLoading || !!error) && (
        <Flex justify="center" align="center">
          <Loader variant="oval" size="lg" color={error ? "spark" : "brand"} />
        </Flex>
      )}

      {!isLoading && !error && (
        <>
          <Flex.Column
            gap={24}
            sx={(theme) => ({ ...theme.other.fontTypes.p2, fontWeight: theme.other.fontWeights.regular })}
          >
            <Flex.Column gap={8}>
              <Flex.Row justify="space-between">
                <RpiText type="p2" weight="bold" children="Selected payment method" />
                {selectedPaymentMethod && (
                  <RpiIconButton
                    icon={IconV2.Edit}
                    color="currentColor"
                    size={16}
                    sx={(theme) => ({ color: theme.colors.brand[5], "&:hover": { color: theme.colors.brand[4] } })}
                    onClick={() =>
                      navigateRoute(["orders:purchase:payment-method:edit", { token: selectedPaymentMethod.token }])
                    }
                  />
                )}
              </Flex.Row>

              {!selectedPaymentMethod &&
                "There is no selected payment method. Please select a different credit card or add a new one."}
              {selectedPaymentMethod && (
                <>
                  <Flex.Row justify="space-between">
                    <span>
                      {selectedPaymentMethod.name} ... {selectedPaymentMethod.last4Digits}
                    </span>
                    <span>{selectedPaymentMethod.expiryDate}</span>
                  </Flex.Row>

                  <Flex.Row justify="space-between">
                    <span>Billing address</span>
                    <span>{selectedPaymentMethod.address}</span>
                  </Flex.Row>
                </>
              )}
            </Flex.Column>

            {paymentMethods?.length > 1 && (
              <Flex.Column gap={8}>
                <RpiText type="p2" weight="bold" children="Saved cards" />

                <RpiDrawerSelectInput
                  label="Card"
                  placeholder="Select payment method"
                  value={String(selectedPaymentMethod?.token)}
                  onChange={(_, element) =>
                    setSelectedPaymentMethod(element?.data as GraphQL.BraintreePaymentMethodResponse)
                  }
                  data={paymentMethods.map((paymentMethod) => ({
                    label: `${paymentMethod.name} ... ${paymentMethod.last4Digits}`,
                    value: String(paymentMethod.token),
                    data: paymentMethod,
                    labelRender: (
                      <Flex.Row fullWidth justify="space-between">
                        <span children={`${paymentMethod.name} ... ${paymentMethod.last4Digits}`} />
                        <Box sx={{ textAlign: "right" }} children={`${paymentMethod.expiryDate}`} />
                      </Flex.Row>
                    ),
                  }))}
                />
              </Flex.Column>
            )}
          </Flex.Column>

          <RpiButton
            variant="brand-outline"
            width="fit"
            sx={{ borderWidth: 1 }}
            onClick={() => navigateRoute(["orders:purchase:payment-method:create"])}
            children="Add new card"
          />
        </>
      )}
    </PurchaseInfoCard>
  );
};

interface OrderCardProps {
  order: NonNullable<GraphQLQueryData<GraphQL.ListOrdersForPaymentQuery>>[number];
  onRemove: () => void;
}

const OrderCard: React.FC<OrderCardProps> = ({ order, onRemove }) => {
  return (
    <Flex.Column fullWidth gap={12}>
      <Flex.Row fullWidth justify="space-between">
        <RpiText type="p2" weight="regular">
          <Box component="span" sx={(theme) => ({ fontWeight: theme.other.fontWeights.bold })} children="Order ID" />{" "}
          <RpiLink
            variant="inherit"
            component={Link}
            to={getRoutePath("order", { id: order.id })}
            children={order.customerOrderId}
          />
        </RpiText>

        <RpiIconButton icon={IconV2.X} onClick={onRemove} />
      </Flex.Row>

      <Grid
        columns="1fr min-content min-content"
        columnGap={32}
        sx={(theme) => ({ ...theme.other.fontTypes.p3, fontWeight: theme.other.fontWeights.regular })}
      >
        <Box component="span" children={`Created ${moment(order.createdAt).format("MM/DD YYYY HH:mm:ss")}`} />
        <Box component="span" sx={{ textAlign: "right" }} children="Item" />
        <Box
          component="span"
          sx={{ textAlign: "right" }}
          children={formatCurrency(order.pricing?.currency, order.pricing?.itemsTotal || 0)}
        />

        <Box component="span" children={`Updated ${moment(order.updatedAt).format("MM/DD YYYY HH:mm:ss")}`} />
        <Box component="span" sx={{ textAlign: "right" }} children="Shipping" />
        <Box
          component="span"
          sx={{ textAlign: "right" }}
          children={formatCurrency(order.pricing?.currency, order.pricing?.shippingTotal || 0)}
        />

        <Box component="span" sx={{ textAlign: "right", gridColumn: 2 }} children="Tax" />
        <Box
          component="span"
          sx={{ textAlign: "right" }}
          children={formatCurrency(order.pricing?.currency, order.pricing?.taxesTotal || 0)}
        />
      </Grid>

      <RpiText
        type="p1"
        weight="bold"
        mt={20}
        sx={{ textAlign: "right", width: "100%" }}
        children={formatCurrency(order.pricing?.currency, order.pricing?.orderTotal || 0)}
      />
    </Flex.Column>
  );
};

interface PurchaseInfoCardProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
}

const PurchaseInfoCard: React.FC<PurchaseInfoCardProps> = ({ title, children }) => {
  return (
    <Flex.Column
      p={24}
      gap={24}
      sx={(theme) => ({
        color: theme.colors.brand[5],
        background: theme.colors.calm[4],
        borderRadius: 24,
        width: 449,
      })}
    >
      {title && <RpiText type="h3" weight="bold" children={title} />}
      {children}
    </Flex.Column>
  );
};
