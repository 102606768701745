import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { CellDate } from "../../components/cells/CellDate";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";

const orderEventColumn = createEntityColumn<GraphQL.OrderEventResponse>();

export const OrderEventColumn = {
  id: orderEventColumn<"id">({
    header: "ID",
    accessor: "id",
    minSize: 100,
    size: 150,
  }),
  eventTime: orderEventColumn<"eventTime">({
    header: "Time",
    accessor: "eventTime",
    cell: ({ row }) => <CellDate date={row.eventTime} />,
    size: 200,
  }),
  eventType: orderEventColumn<"eventType">({
    header: "Type",
    accessor: "eventType",
    minSize: 100,
    size: 300,
  }),
  shortDesc: orderEventColumn<"shortDesc">({
    header: "Description",
    accessor: "shortDesc",
    minSize: 100,
    size: 400,
  }),
  data: orderEventColumn<"data">({
    header: "Data",
    accessor: "data",
    cell: ({ row }) => (row.data ? "{...}" : ""),
    minSize: 200,
  }),
};

export const orderEventColumns: RpiTableColumns<
  NonNullable<GraphQLQueryData<GraphQL.OrderQuery>["orderEvents"]>[number]
> = [OrderEventColumn.id, OrderEventColumn.eventTime, OrderEventColumn.eventType, OrderEventColumn.shortDesc];
