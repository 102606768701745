// Partial
type FormValidateInput<Values> = (values: Values) => any;

export const validateEmail: FormValidateInput<string | undefined> = (value = "") => {
  return /^\S+@\S+$/.test(value) ? null : "Invalid email";
};

export const validateLength = (
  name: string,
  options: { min?: number; max?: number }
): FormValidateInput<string | undefined> => {
  return (value = "") => {
    if (options.min) {
      if (value.length < options.min) {
        return `${name} must be at least ${options.min} characters long`;
      }
    }

    if (options.max) {
      if (value.length > options.max) {
        return `${name} must be less than ${options.max} characters long`;
      }
    }

    return null;
  };
};

export const validateUrl: FormValidateInput<string | undefined> = (value = "") => {
  // eslint-disable-next-line no-useless-escape
  const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return value.match(regex) ? null : "Invalid url";
};

export const validateCustomer: FormValidateInput<string | undefined> = (value = "") => {
  return !value ? "Invalid customer" : null;
};
