import { Box } from "@mantine/core";
import { Flex, IconV2, RpiLink, RpiText } from "@rpi/openapi-core";
import React from "react";
import { Link, Outlet, useMatches } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";
import { getRoutePath } from "../../hooks/useNavigateRoute";
import { RpiNotification, useRpiNotifications } from "../../hooks/useRpiNotifications";
import { canViewPage } from "../../utils/auth.util";
import { Error401View } from "../../views/error/Error401View";
import { DashboardLayoutNavbar } from "./DashboardLayoutNavbar";
import { optObj } from "../../utils/misc.util";

export const DashboardLayout: React.FC = () => {
  const user = useAuthUser();
  const matches = useMatches();
  const { notifications } = useRpiNotifications();

  const hasPaymentNotification = React.useMemo(() => notifications[RpiNotification.PAYMENT_METHOD], [notifications]);
  const hasTaxNotification = React.useMemo(
    () => notifications[RpiNotification.TAX_DOCUMENT_NOT_UPLOADED],
    [notifications]
  );

  const children = React.useMemo(() => {
    if (!canViewPage(matches, user.cognitoUser)) return <Error401View root={false} hideButton />;
    return <Outlet />;
  }, [matches, user.cognitoUser]);

  return (
    <Flex.Column
      sx={(theme) => ({
        width: "100%",
        height: "100vh",
        position: "relative",
        overflow: "hidden",
        backgroundColor: theme.colors.sky[5],
      })}
    >
      <Banner active={hasPaymentNotification}>
        <IconV2.ErrorOutline color="currentColor" size={16} />
        <RpiText type="p3" weight="regular" children="Please submit a payment method before proceeding." />
        {user.is.CUSTOMER_ADMIN && (
          <RpiLink
            component={Link}
            to={getRoutePath("payment-method:create")}
            sx={{ color: "currentcolor" }}
            children="Add card details"
          />
        )}
      </Banner>

      <Banner active={hasTaxNotification && !hasPaymentNotification}>
        <IconV2.ErrorOutline color="currentColor" size={16} />
        <RpiText
          type="p3"
          weight="regular"
          children="With your state reseller's certificate, no sales tax is charged on your orders."
        />
        <RpiLink
          component={Link}
          to={getRoutePath("billing")}
          sx={{ color: "currentcolor" }}
          children="Upload it here"
        />
      </Banner>

      <Flex.Row sx={{ flex: 1, overflow: "hidden" }}>
        <DashboardLayoutNavbar />
        <Box sx={(theme) => ({ flex: 1, position: "relative", background: theme.colors.sky[5] })} children={children} />
      </Flex.Row>
    </Flex.Column>
  );
};

interface BannerProps {
  active?: boolean;
  border?: boolean;
  children?: React.ReactNode;
}

const Banner: React.FC<BannerProps> = ({ active, border, children }) => {
  return (
    <Flex.Row
      fullWidth
      justify="center"
      align="center"
      gap={16}
      sx={(theme) => ({
        background: theme.colors.spark[2],
        color: theme.colors.spark[6],
        height: 47,
        maxHeight: active ? 47 : 0,
        transition: "max-height ease-in-out 125ms",
        borderBottom: optObj(border, `1px solid ${theme.colors.spark[3]}`),
      })}
    >
      {active && children}
    </Flex.Row>
  );
};
