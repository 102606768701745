import { RpiCheckbox } from "@rpi/openapi-core";
import React from "react";
import { RpiTableColumn } from "../RpiTable";
import { useRpiTable } from "../RpiTableProvider";

export type RpiTableRowSelection<T> = Array<{
  id: string;
  value: T;
}>;

export interface RpiTableRowSelectionContextProps<T> {
  rowSelection?: RpiTableRowSelection<T>;
  setRowSelection: React.Dispatch<React.SetStateAction<RpiTableRowSelectionContextProps<T>["rowSelection"]>>;
}

export const RpiTableRowSelectionContext = React.createContext<RpiTableRowSelectionContextProps<any> | null>(null);

export function useRpiTableRowSelection<T>() {
  const context = React.useContext(RpiTableRowSelectionContext) as RpiTableRowSelectionContextProps<T> | null;
  if (!context) {
    throw new Error(`${useRpiTableRowSelection.name} must be used within ${RpiTableRowSelectionProvider.name}`);
  }

  return context;
}

export interface RpiTableRowSelectionProviderProps<T> {
  children: React.ReactNode;
  defaultValue?: RpiTableRowSelectionContextProps<T>["rowSelection"];
  onChange?: (rowSelection: Exclude<RpiTableRowSelectionContextProps<T>["rowSelection"], undefined>) => void;
}

export function RpiTableRowSelectionProvider<T>({
  children,
  defaultValue,
  onChange,
}: RpiTableRowSelectionProviderProps<T>) {
  const [rowSelection, setRowSelection] = React.useState<RpiTableRowSelectionContextProps<T>["rowSelection"]>(
    () => defaultValue
  );

  React.useEffect(() => {
    onChange && onChange(rowSelection || []);
  }, [rowSelection]);

  return <RpiTableRowSelectionContext.Provider value={{ rowSelection, setRowSelection }} children={children} />;
}

export function getRowSelectionColumn<T>(): RpiTableColumn<T> {
  return {
    id: "#row-select",
    header: () => {
      const { rowSelection, setRowSelection } = useRpiTableRowSelection<T>();

      return (
        <RpiCheckbox
          sx={{ input: { cursor: "pointer" } }}
          size="xs"
          checked={!!rowSelection && rowSelection.length > 0}
          indeterminate={!!rowSelection && rowSelection.length > 0}
          onChange={(e) => {
            const checked = e.currentTarget.checked;
            if (checked) return;
            setRowSelection([]);
          }}
        />
      );
    },
    cell: ({ row }) => {
      const { getRowId } = useRpiTable<T>();
      const { rowSelection, setRowSelection } = useRpiTableRowSelection<T>();

      if (!row || !getRowId) return <></>;
      const rowId = getRowId(row);

      return (
        <RpiCheckbox
          sx={{ input: { cursor: "pointer" } }}
          size="xs"
          checked={!!rowSelection?.some((el) => el.id === rowId)}
          onChange={(e) => {
            const checked = e.currentTarget.checked;
            setRowSelection((selection) => {
              let rowSelection = selection ? [...selection] : [];
              if (checked) {
                rowSelection.push({
                  id: rowId,
                  value: row,
                });
              } else {
                rowSelection = rowSelection.filter((el) => el.id !== rowId);
              }

              return rowSelection;
            });
          }}
        />
      );
    },
    maxSize: 34,
    minSize: 34,
    size: 34,
    align: "center",
    noPadding: true,
  };
}
