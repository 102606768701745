export interface MailTo {
  email: string;
  subject?: string;
  body?: string;
  cc?: string;
  bcc?: string;
}

export const MailTo = ({ email, ...props }: MailTo): string => {
  const params = Object.keys(props)
    .map((key) => `${key}=${props[key as keyof typeof props]}`)
    .join("&");

  return `mailto:${email}${params ? `?${params}` : ""}`;
};
