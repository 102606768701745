import { GraphQL } from "@rpi/openapi-api";
import { RpiFilterV2 } from "../../components/search-filter/RpiSearchFilterTypes";

export const customerFilters = RpiFilterV2.createConfigs<GraphQL.CustomerSearchFields>([
  {
    type: "text",
    label: "Public ID",
    field: GraphQL.CustomerSearchFields.PublicId,
  },
  {
    type: "text",
    label: "Email",
    field: GraphQL.CustomerSearchFields.Email,
  },
  {
    type: "date",
    label: "Created at",
    field: GraphQL.CustomerSearchFields.CreatedAt,
  },
]);
