import { GraphQL } from "@rpi/openapi-api";

export const dashboardUserRoleLabel = (role?: GraphQL.DashboardUserRole | null): string => {
  switch (role) {
    case GraphQL.DashboardUserRole.User:
      return "User";
    case GraphQL.DashboardUserRole.CustomerAdmin:
      return "Customer Admin";
    case GraphQL.DashboardUserRole.SuperAdmin:
      return "Super Admin";
    default:
      return "";
  }
};

export type SearchSort<T extends { search?: { sort?: any } | null }> = NonNullable<T["search"]>["sort"];

export type SearchInputs<T extends { search?: { inputs?: any } | null }> = NonNullable<T["search"]>["inputs"];

export type SearchGeneral<T extends { search?: { generalInput?: any } | null }> = NonNullable<
  T["search"]
>["generalInput"];
