import { Group, Text } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { CustomSelect, CustomSelectItemProps, CustomSelectWrapper } from "./SelectUtils";

type SelectPrintAssetType = Pick<GraphQL.PrintAssetResponse, "id" | "customer" | "printAssetUploadId">;

const SelectPrintAssetItem = React.forwardRef<HTMLDivElement, CustomSelectItemProps<SelectPrintAssetType>>(
  ({ element, label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap spacing="xs">
        <Text size="sm" children={`ID=${element.id} : ${element?.customer?.publicId}/${element.printAssetUploadId},`} />
      </Group>
    </div>
  )
);

export const SelectPrintAsset: CustomSelectWrapper<SelectPrintAssetType> = ({ getValue, ...props }) => {
  return (
    <CustomSelect
      label="Print asset Id"
      placeholder="Select print asset"
      nothingFound="No print assets"
      maxDropdownHeight={200}
      itemComponent={SelectPrintAssetItem}
      getItemProps={(printAsset) => ({
        label: `${printAsset.id}`,
        value: (getValue ? getValue(printAsset) : printAsset.printAssetUploadId) as string,
        element: printAsset,
      })}
      {...props}
    />
  );
};
