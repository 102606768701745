import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { AppPage } from "../../../components/AppPage";
import { JsonBox } from "../../../components/JsonBox";

export const ConfigurationView: React.FC = () => {
  const { data, isLoading, error, refetch } = GraphQL.useAdminConfigurationQuery();
  const configuration = React.useMemo(() => data?.adminConfiguration, [data]);

  return (
    <AppPage path={["Configuration"]} onReload={refetch}>
      <AppPage.Section title="Avalara Tax" isLoading={isLoading} error={error}>
        {configuration && <ConfigurationJson data={configuration.avalaraTaxConfiguration} />}
      </AppPage.Section>

      <AppPage.Section mt={32} title="Dynamo DB" isLoading={isLoading} error={error}>
        {configuration && <ConfigurationJson data={configuration.dynamoDbConfiguration} />}
      </AppPage.Section>

      <AppPage.Section mt={32} title="Tasks" isLoading={isLoading} error={error}>
        {configuration && <ConfigurationJson data={configuration.tasksConfiguration} />}
      </AppPage.Section>

      <AppPage.Section mt={32} title="UOS Client" isLoading={isLoading} error={error}>
        {configuration && <ConfigurationJson data={configuration.uosClientConfiguration} />}
      </AppPage.Section>

      <AppPage.Section mt={32} title="PDF Processing" isLoading={isLoading} error={error}>
        {configuration && <ConfigurationJson data={configuration.pdfProcessingConfiguration} />}
      </AppPage.Section>

      <AppPage.Section mt={32} title="Shipping" isLoading={isLoading} error={error}>
        {configuration && <ConfigurationJson data={configuration.shippingConfiguration} />}
      </AppPage.Section>

      <AppPage.Section mt={32} title="Git" isLoading={isLoading} error={error}>
        {configuration && <ConfigurationJson data={configuration.git} />}
      </AppPage.Section>

      <AppPage.Section mt={32} title="Other" isLoading={isLoading} error={error}>
        {configuration && (
          <ConfigurationJson
            data={{
              dataSourceUrl: configuration.dataSourceUrl,
              nextChargeTime: configuration.nextChargeTime,
              holdingBinReleaseInHours: configuration.holdingBinReleaseInHours,
            }}
          />
        )}
      </AppPage.Section>
    </AppPage>
  );
};

interface ConfigurationJsonProps {
  data?: object;
}

const ConfigurationJson: React.FC<ConfigurationJsonProps> = ({ data }) => {
  return (
    <JsonBox
      sx={(theme) => ({
        borderRadius: 6,
        background: theme.colors.sky[5],
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.colors.calm[5],
        padding: 8,
      })}
      data={data}
    />
  );
};
