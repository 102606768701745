import { Box } from "@mantine/core";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { Flex, Grid, Icon, IconV2, RpiIconButton, RpiText } from "@rpi/openapi-core";
import moment from "moment";
import React from "react";
import { HTTP_STATUS_CODE, formatCount } from "../utils/misc.util";
import { JsonBox } from "./JsonBox";
import { OverflowX } from "./OverflowX";

type CallbackData = NonNullable<GraphQLQueryData<GraphQL.OrderQuery>["callbacks"]>[number];

interface CallbacksListProps {
  callbacks?: CallbackData[] | null;
  webhookUrl?: string | null;
}

export const CallbacksList: React.FC<CallbacksListProps> = ({ callbacks = [], webhookUrl }) => {
  return (
    <Box
      px={8}
      py={16}
      sx={(theme) => ({
        borderRadius: 6,
        background: theme.colors.sky[5],
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.colors.calm[5],
      })}
    >
      <OverflowX>
        <Grid
          rowGap={12}
          columnGap={8}
          columns="min-content min-content 1fr min-content min-content min-content"
          sx={(theme) => ({ color: theme.colors.brand[5] })}
          py={8}
        >
          <div />

          <RpiText
            type="p3"
            weight="regular"
            color={(theme) => theme.colors.brand[5]}
            sx={{ flexShrink: 0 }}
            children="Webhook URL:"
          />

          <Flex.Row align="baseline" gap={8} pr={8} sx={{ gridColumn: "span 4", overflow: "hidden" }}>
            {webhookUrl && (
              <Box
                component="a"
                href={webhookUrl || ""}
                px={8}
                sx={(theme) => ({
                  ...theme.other.fontTypes.p3,
                  fontWeight: theme.other.fontWeights.regular,
                  fontFamily: "monospace",
                  background: theme.colors.calm[4],
                  borderRadius: 4,
                  color: theme.colors.text[5],
                  textDecoration: "none",
                  wordBreak: "break-all",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                })}
                children={webhookUrl}
              />
            )}
          </Flex.Row>

          {callbacks?.map((callback, i) => (
            <Callback key={`callback-${i}-row`} callback={callback} isLast={i === callbacks.length - 1} />
          ))}
        </Grid>
      </OverflowX>
    </Box>
  );
};

const CallbackSection: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <Flex.Column gap={8}>
    <RpiText type="h4" weight="roman" color={(theme) => theme.colors.brand[5]} children={title} />
    <Flex.Column
      p={8}
      sx={(theme) => ({
        background: theme.colors.sky[6],
        borderRadius: 4,
      })}
      children={children}
    />
  </Flex.Column>
);

interface CallbackProps {
  callback: CallbackData;
  isLast?: boolean;
}

const Callback: React.FC<CallbackProps> = ({ callback, isLast }) => {
  const [opened, setOpened] = React.useState(false);

  const lastTry =
    !callback.timeline || callback.timeline.length === 0
      ? undefined
      : moment(callback.timeline[callback.timeline.length - 1].timestamp).format("MM/DD/YYYY HH:mm:ss");

  const body = React.useMemo(() => callback.body, [callback.body]);

  return (
    <>
      <RpiText
        pl={8}
        type="p3"
        weight="bold"
        color={(theme) => (callback.webhookStatus === "done" ? theme.colors.brand[5] : theme.colors.spark[5])}
        sx={{ textTransform: "uppercase" }}
        children={callback.webhookStatus}
      />

      <RpiText
        type="p3"
        weight="regular"
        ellipsis
        color={(theme) => (callback.webhookStatus === "done" ? theme.colors.brand[5] : theme.colors.spark[5])}
        children={body?.source || ""}
      />

      <Flex.Row
        gap={8}
        px={8}
        align="center"
        sx={(theme) => ({
          flexWrap: "nowrap",
          background: theme.colors.calm[4],
          borderRadius: 4,
          color: theme.colors.text[5],
          height: "fit-content",
          maxWidth: "fit-content",
          overflow: "hidden",
          minWidth: 243,
        })}
      >
        <Icon.Menu.Box size={14} style={{ flexShrink: 0 }} />
        <RpiText type="p3" weight="regular" sx={{ fontFamily: "monospace" }} ellipsis children={callback.webhookId} />
      </Flex.Row>

      <RpiText type="p3" weight="regular" ellipsis children={lastTry} />

      <RpiText
        type="p3"
        weight="regular"
        ellipsis
        children={`[${formatCount(callback.attemptCount || 0, "attempt")}]`}
      />

      <RpiIconButton
        px={8}
        icon={IconV2.ArrowDown}
        size={14}
        color="currentColor"
        sx={{ color: "inherit" }}
        iconProps={{ style: { transform: opened ? "" : "rotate(90deg)" } }}
        onClick={() => setOpened((opened) => !opened)}
      />

      {opened && (
        <Flex.Column px={8} gap={16} sx={{ gridColumn: "span 6" }}>
          <CallbackSection title="Body">
            <JsonBox sx={{ background: "inherit" }} data={body} />
          </CallbackSection>

          <CallbackSection title="Timeline">
            {(callback.timeline || []).map((timeline: any, i: number) => {
              return (
                <Flex.Row
                  gap={8}
                  key={`callback-timeline-${callback.webhookId}-${i}`}
                  sx={(theme) => ({ color: theme.colors.text[5] })}
                >
                  <RpiText type="p3" weight="regular" children={`${i + 1}.`} />
                  <RpiText type="p3" weight="regular" children={`${moment(timeline.timestamp).format()}:`} />
                  {timeline.httpStatus && (
                    <RpiText
                      type="p3"
                      weight="bold"
                      children={
                        Number.isNaN(timeline.httpStatus)
                          ? timeline.httpStatus
                          : `${timeline.httpStatus} ${HTTP_STATUS_CODE[timeline.httpStatus]?.message}`
                      }
                    />
                  )}
                </Flex.Row>
              );
            })}
          </CallbackSection>
        </Flex.Column>
      )}

      {!isLast && (
        <Box
          sx={(theme) => ({
            width: "100%",
            height: 1,
            background: theme.colors.calm[5],
            gridColumn: "span 6",
          })}
        />
      )}
    </>
  );
};
