import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { RpiPagination, RpiPaginationRow } from "../../../components/RpiPagination";
import { RpiSearchFilter } from "../../../components/search-filter/RpiSearchFilter";
import { openApiProductColumns } from "../../../configuration/columns/openApiProduct.column";
import { openApiProductFilters } from "../../../configuration/filters/openApiProduct.filter";
import { useRpiPagination } from "../../../hooks/usePagination";
import { RpiPerPage } from "../../../hooks/useRpiPerPage";
import { RpiTable } from "../../../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../../../utils/graphql.util";

export const OpenApiProductsView: React.FC = () => {
  const outlet = useOutlet();

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.OpenApiProductsQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.OpenApiProductsQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.OpenApiProductsQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const { data, isLoading, error, refetch } = GraphQL.useOpenApiProductsQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.openapiProducts.totalCount),
    }
  );
  const openApiProducts = React.useMemo(() => data?.openapiProducts.data, [data]);

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  return (
    <AppPage path={["Open API Products"]} onReload={refetch}>
      {outlet}

      <AppPage.Section>
        <RpiSearchFilter
          placeholder="Search by Name"
          filters={openApiProductFilters}
          onChange={setSearchInputs}
          onSearch={setSearchGeneral}
        />

        <RpiTable
          emptyMessage={
            searchInputs?.length || searchGeneral
              ? "No open API products found."
              : "Open API products will appear here."
          }
          isLoading={isLoading}
          error={error}
          perPage={pagination.perPage}
          data={openApiProducts}
          columns={openApiProductColumns}
          withSortBy={{ onChange: setSortInput }}
        />

        <RpiPaginationRow>
          <RpiPagination pagination={pagination} />
          <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
        </RpiPaginationRow>
      </AppPage.Section>
    </AppPage>
  );
};
