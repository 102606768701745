import { useForm } from "@mantine/form";
import { GraphQL } from "@rpi/openapi-api";
import { RpiDrawerTextInput } from "@rpi/openapi-core";
import React from "react";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../rpi-core/drawer/RpiDrawer";

export const ApiCredentialCreateView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    return (
      <RpiDrawer title="Create credential" opened={opened} onClose={handleClose}>
        <CreateCredential onClose={() => handleClose(true)} />
      </RpiDrawer>
    );
  }
);

interface CreateCredentialProps {
  onClose: () => void;
}

const CreateCredential: React.FC<CreateCredentialProps> = ({ onClose }) => {
  const {
    mutateAsync: createApiCredential,
    reset,
    ...mutation
  } = GraphQL.useCreateApiCredentialMutation<{ message: string }>();

  const form = useForm<GraphQL.CreateApiCredentialMutationVariables["input"]>({
    initialValues: {
      description: "",
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      await createApiCredential({ input });
      onClose();
    },
    [createApiCredential, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Creating credential...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to create credential...",
          description: "Please try again later.",
        },
        success: {
          message: "Credential created!",
        },
      })(mutation),
    [mutation]
  );

  return (
    <RpiDrawerForm button="Save" overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerTextInput label="Description" {...form.getInputProps("description")} />
    </RpiDrawerForm>
  );
};
