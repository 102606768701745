import { Group, Switch } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import {
  Flex,
  RpiButton,
  RpiCheckbox,
  RpiDropdown,
  RpiLink,
  RpiPasswordInput,
  RpiText,
  RpiTextInput,
} from "@rpi/openapi-core";
import React from "react";
import { CellStatus } from "../../components/cells/CellStatus";
import { DesignSection } from "./DesignLayout";

export const DesignView: React.FC = () => {
  return (
    <>
      <DesignSection title="Buttons">
        <RpiButton children="Button" />

        <Group>
          <RpiButton size="small" children="Button" />
          <RpiButton size="small" children="Button" />
          <RpiButton size="small" children="Button" disabled />
        </Group>
        <Group>
          <RpiButton variant="mellow" size="small" children="Button" />
          <RpiButton variant="mellow" size="small" children="Button" />
          <RpiButton variant="mellow" size="small" children="Button" disabled />
        </Group>
      </DesignSection>

      <DesignSection title="Hyperlink">
        <RpiLink href="" children="Text" />
      </DesignSection>

      <DesignSection title="Checkbox">
        <RpiCheckbox />
        <RpiCheckbox label="Label" />
      </DesignSection>

      <DesignSection title="Dropdown" description="[WIP]">
        <RpiDropdown
          // initiallyOpened={true}
          placeholder="Pick one"
          data={[
            { value: "rick", label: "Rick" },
            { value: "morty", label: "Morty" },
            { value: "beth", label: "Beth" },
            { value: "summer", label: "Summer" },
          ]}
        />
      </DesignSection>

      <DesignSection title="Text Input">
        <RpiTextInput label="Label" placeholder="Placeholder" />
        <RpiTextInput label="Label" placeholder="Placeholder" error="Error" />

        <RpiTextInput label="Label" placeholder="Placeholder" />
        <RpiTextInput label="Label" placeholder="Placeholder" error="Error" />

        <RpiTextInput label="Label" placeholder="Placeholder" />
        <RpiTextInput label="Label" placeholder="Placeholder" error="Error" />
      </DesignSection>

      <DesignSection title="Password Input">
        <RpiPasswordInput label="Label" placeholder="Placeholder" />
        <RpiPasswordInput label="Label" placeholder="Placeholder" error="Error" />

        <RpiPasswordInput label="Label" placeholder="Placeholder" />
        <RpiPasswordInput label="Label" placeholder="Placeholder" error="Error" />

        <RpiPasswordInput label="Label" placeholder="Placeholder" />
        <RpiPasswordInput label="Label" placeholder="Placeholder" error="Error" />
      </DesignSection>

      <DesignSection title="Toggle" description="[WIP]">
        <Switch
          unstyled
          label="Label"
          styles={(theme) => ({
            label: {
              ...theme.other.fontTypes.p3,
              fontWeight: theme.other.fontWeights.regular,
              paddingLeft: 8,
            },
            input: {
              width: 38,
              height: 22,
              background: `${theme.colors.sky[6]} !important`,
              borderWidth: 1,
              borderColor: `${theme.colors.text[5]} !important`,
              cursor: "pointer",
              "&:checked": {
                background: `${theme.colors.green[5]} !important`,
              },
              "&::before": {
                width: 11,
                height: 11,
                borderColor: `${theme.colors.text[5]} !important`,
              },
            },
          })}
        />
      </DesignSection>
      <DesignSection title="Status">
        <Flex.Row gap={64} sx={{ flexWrap: "wrap" }}>
          <Flex.Column gap={8}>
            <RpiText mb={16} type="p2" weight="bold" children="Order Status" />
            {Object.values(GraphQL.OrderStatus).map((status) => (
              <CellStatus.Order status={status} />
            ))}
          </Flex.Column>
          <Flex.Column gap={8}>
            <RpiText mb={16} type="p2" weight="bold" children="Public Order Status" />
            {Object.values(GraphQL.PublicOrderStatus).map((status) => (
              <CellStatus.PublicOrder status={status} />
            ))}
          </Flex.Column>
          <Flex.Column gap={8}>
            <RpiText mb={16} type="p2" weight="bold" children="Print Asset Status" />
            {Object.values(GraphQL.PrintAssetStatus).map((status) => (
              <CellStatus.PrintAsset status={status} />
            ))}
          </Flex.Column>
        </Flex.Row>
      </DesignSection>
    </>
  );
};
