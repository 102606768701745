import { GraphQL } from "@rpi/openapi-api";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { Time } from "../utils/time.util";
import { filterNullableArray, isString } from "../utils/type.util";
import uploadService from "./upload.service";

type UploadStatus = {
  status: "loading" | "error" | "success";
  message: string;
  description?: string;
};

export function createStatus<
  T extends {
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
  }
>(data: Partial<Record<"loading" | "error" | "success", string | { message: string; description?: string }>>) {
  return (state: T): UploadStatus | undefined => {
    if (state.isLoading && data.loading) {
      if (isString(data.loading)) return { status: "loading", message: data.loading };
      return { status: "loading", message: data.loading.message, description: data.loading.description };
    }
    if (state.isError && data.error) {
      if (isString(data.error)) return { status: "error", message: data.error };
      return { status: "error", message: data.error.message, description: data.error.description };
    }
    if (state.isSuccess && data.success) {
      if (isString(data.success)) return { status: "success", message: data.success };
      return { status: "success", message: data.success.message, description: data.success.description };
    }
    return undefined;
  };
}

interface UploadServiceInternal {
  customerPublicId?: string;
  collectionId?: string;
  uploadCompleted?: boolean;
}

export interface UseUploadServiceProps {
  onComplete?: () => void;
  onError?: (error: any) => void;
  withCollectionPoll?: boolean;
}

export function useUploadService(props: UseUploadServiceProps) {
  const [files, setFiles] = React.useState<File[]>([]);
  const [progress, setProgress] = React.useState(0);
  const [internal, setInternal] = React.useState<UploadServiceInternal>({});

  const handleReset = React.useCallback(() => {
    setFiles([]);
    setProgress(0);
    setInternal({});
  }, []);

  const handleComplete = React.useCallback(() => {
    props.onComplete?.();
    handleReset();
  }, [props.onComplete, handleReset]);

  const handleError = React.useCallback(
    (error: any) => {
      props.onError?.(error);
      handleReset();
    },
    [props.onError, handleReset]
  );

  const uploadMutation = useMutation(
    ["upload-collection", files],
    async (collection: GraphQL.UploadServiceV2CollectionsStatusResponse) =>
      await uploadService.uploadCollection.observe({
        progress: setProgress,
      })(collection, files),
    {
      retry: false,
      onSuccess: () => {
        if (!props.withCollectionPoll) handleComplete();
        setInternal((internal) => ({ ...internal, uploadCompleted: true }));
      },
      onError: props.onError,
    }
  );

  const createMutation = GraphQL.useCreateUploadMutation<{ message: string }>({
    onSuccess: (data) => {
      if (!data.uploadCreate) return handleError(new Error("Missing `uploadCreate`."));
      if (props.withCollectionPoll) {
        setInternal((internal) => ({ ...internal, collectionId: data.uploadCreate!.collectionId }));
      }
      uploadMutation.mutate(data.uploadCreate!);
    },
    onError: handleError,
  });

  const checkCollectionFinished = React.useCallback((data?: GraphQL.UploadFilesByPollCollectionQuery) => {
    if (!data || !data.uploadFilesPollByCollection) return true;
    const uploadedFiles = filterNullableArray(data.uploadFilesPollByCollection || []);

    if (!uploadedFiles.length) return true;
    const allFinished = uploadedFiles.every(
      (file) =>
        file.status === GraphQL.UploadedFileStatus.Finished || file.status === GraphQL.UploadedFileStatus.Failure
    );

    if (allFinished) return true;

    return false;
  }, []);

  const collectionQueryEnabled = React.useMemo(
    () =>
      Boolean(props.withCollectionPoll) &&
      Boolean(internal.collectionId) &&
      Boolean(internal.customerPublicId) &&
      Boolean(internal.uploadCompleted),
    [props.withCollectionPoll, internal]
  );

  const collectionQuery = GraphQL.useUploadFilesByPollCollectionQuery(
    {
      collectionId: internal.collectionId!,
      customerPublicId: internal.customerPublicId!,
    },
    {
      enabled: collectionQueryEnabled,
      onSuccess: (data) => {
        if (checkCollectionFinished(data)) return handleComplete();
      },
      onError: handleError,
      refetchInterval: (data) => {
        if (checkCollectionFinished(data)) return false;
        return Time.secondsToMilliseconds(5);
      },
    }
  );

  const isCollectionFinished = React.useMemo(() => {
    return checkCollectionFinished(collectionQuery.data);
  }, [collectionQuery.data]);

  const status: UploadStatus | undefined = React.useMemo(() => {
    if (collectionQueryEnabled) {
      const collectionStatus = createStatus({
        loading: "Finishing upload",
        error: (collectionQuery as { error?: { message: any } }).error?.message || "Failed to fetch collection...",
        success: "Files uploaded!",
      })({
        isLoading: collectionQuery.isLoading || collectionQuery.isFetching || !isCollectionFinished,
        isError: collectionQuery.isError,
        isSuccess: isCollectionFinished,
      });

      if (collectionStatus) return collectionStatus;
    }

    const uploadStatus = createStatus({
      loading: `Uploading files... ${Math.round(progress * 100)}%`,
      error: (uploadMutation as { error?: { message: any } }).error?.message || "Failed to upload files...",
      success: "Files uploaded!",
    })(uploadMutation);

    if (uploadStatus) return uploadStatus;

    return createStatus({
      loading: "Creating upload...",
      error: createMutation.error?.message || "Failed to create upload...",
      success: "Upload created!",
    })(createMutation);
  }, [createMutation, uploadMutation, collectionQuery, collectionQueryEnabled, isCollectionFinished, progress]);

  return {
    upload: (input: GraphQL.CreateUploadInput, files: File[]) => {
      if (createMutation.isLoading || uploadMutation.isLoading) {
        return console.log("Upload already in progress.");
      }

      setFiles(files);
      setProgress(0);
      if (props.withCollectionPoll) setInternal({ customerPublicId: input.customerPublicId });
      createMutation.mutate({ input });
    },
    status,
    progress,
    reset: () => {
      handleReset();
      uploadMutation.reset();
      createMutation.reset();
    },
  };
}
