import { useMantineTheme } from "@mantine/core";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import React from "react";
import Chart from "react-apexcharts";
import { formatCurrency } from "../../utils/misc.util";

interface UseDailyPaymentMetricsGraphReturnType {
  graph: Omit<React.ComponentProps<typeof Chart>, "width" | "height">;
  data: GraphQLQueryData<GraphQL.DailyPaymentMetricsQuery>;
}

export const useDailyPaymentMetricsGraph = (
  variables: GraphQL.DailyPaymentMetricsQueryVariables
): UseDailyPaymentMetricsGraphReturnType => {
  const theme = useMantineTheme();

  const { data } = GraphQL.useDailyPaymentMetricsQuery(variables);
  const dailyPaymentMetrics = React.useMemo(() => data?.dailyPaymentMetrics, [data]);

  const series = React.useMemo(
    () => [
      {
        name: "Sale totals",
        type: "column",
        data: (dailyPaymentMetrics?.days || []).map(({ sumSaleTotals }) => sumSaleTotals),
        color: theme.colors.spark[5],
      },
    ],
    [theme, dailyPaymentMetrics?.days]
  );

  const options = React.useMemo(
    () => ({
      chart: {
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      stroke: {
        width: [0, 4],
      },
      fill: {
        opacity: 1,
      },
      grid: {
        borderColor: theme.colors.sky[8],
        padding: {
          top: -22,
          right: 8,
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: (dailyPaymentMetrics?.days || []).map(({ createdDay }) => createdDay),
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          offsetX: -8,
          style: {
            fontFamily: "sofia-pro",
            fontSize: "14px",
            fontWeight: 400,
            colors: [theme.colors.text[3]],
          },
          formatter: (value: number) => {
            return value > 1000 ? `${Math.round(value / 1000)}k` : value;
          },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          show: true,
          format: "MM/dd/yyyy",
        },
        y: {
          formatter: (value: number) => formatCurrency(GraphQL.SupportedCurrency.Usd, value),
        },
      },
    }),
    [theme, dailyPaymentMetrics?.days]
  );

  return React.useMemo(
    () => ({ graph: { series, options }, data: dailyPaymentMetrics }),
    [series, options, dailyPaymentMetrics]
  );
};
