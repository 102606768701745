import { Box } from "@mantine/core";
import { Flex, RpiButton } from "@rpi/openapi-core";
import React from "react";
import { usePdfPreview } from "../../../hooks/usePdfPreview";
import { AppPage } from "../../AppPage";
import { RpiPagination } from "../../RpiPagination";
import { PdfPagePreview } from "./PdfPagePreview";

interface PdfPreviewProps {
  coverUrl?: string | null;
  gutsUrl: string;
  gutsBleeds?: {
    top: number;
    bottom: number;
    inside: number;
    outside: number;
  };
}

export const PdfPreview: React.FC<PdfPreviewProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => () => setOpen(false), []);

  if (!open) return <RpiButton width="fit" onClick={() => setOpen(true)} children="Render PDF" />;
  return <PdfPreviewRender {...props} />;
};

export const PdfPreviewRender: React.FC<PdfPreviewProps> = ({ coverUrl, gutsUrl, gutsBleeds }) => {
  const { pages, pagination, error, isLoading } = usePdfPreview({ coverUrl, gutsUrl });

  const defaultGutsDimensions = React.useMemo(() => {
    if (pages.gutsFirst) return pages.gutsFirst.getViewport({ scale: 1 });
    if (pages.gutsSecond) return pages.gutsSecond.getViewport({ scale: 1 });
    return undefined;
  }, [pages.gutsFirst, pages.gutsSecond]);

  const coverRender = React.useMemo(() => {
    if (!coverUrl) return;

    return (
      <PdfPagePreview
        page={pages.cover}
        bleed={
          gutsBleeds && {
            top: gutsBleeds.top,
            bottom: gutsBleeds.bottom,
            left: gutsBleeds.outside,
            right: gutsBleeds.outside,
          }
        }
      />
    );
  }, [coverUrl, pages.cover, gutsBleeds]);

  return (
    <Flex.Row>
      <Flex.Column sx={{ maxWidth: 1280 }}>
        {coverUrl && (
          <AppPage.Section size="small" title="Cover Page" isLoading={isLoading} error={error}>
            <PdfPageWrapper children={coverRender} />
          </AppPage.Section>
        )}

        <AppPage.Section mt={24} size="small" title="Guts Pages" isLoading={isLoading} error={error}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <PdfPageWrapper zIndex={pages.gutsSecond ? 1 : 2}>
              <PdfPagePreview
                page={pages.gutsFirst}
                defaultDimensions={defaultGutsDimensions}
                bleed={
                  gutsBleeds && {
                    top: gutsBleeds.top,
                    bottom: gutsBleeds.bottom,
                    left: gutsBleeds.outside,
                    right: gutsBleeds.outside,
                  }
                }
              />
            </PdfPageWrapper>
            <PdfPageWrapper zIndex={pages.gutsFirst ? 1 : 2}>
              <PdfPagePreview
                page={pages.gutsSecond}
                defaultDimensions={defaultGutsDimensions}
                bleed={
                  gutsBleeds && {
                    top: gutsBleeds.top,
                    bottom: gutsBleeds.bottom,
                    left: gutsBleeds.outside,
                    right: gutsBleeds.outside,
                  }
                }
              />
            </PdfPageWrapper>
          </Box>

          <RpiPagination pagination={pagination} />
        </AppPage.Section>
      </Flex.Column>
    </Flex.Row>
  );
};

interface PdfPageWrapperProps {
  children: React.ReactNode;
  zIndex?: number;
}

const PdfPageWrapper: React.FC<PdfPageWrapperProps> = ({ children, zIndex }) => {
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        boxSizing: "border-box",
        display: "inline-flex",
        background: theme.colors.sky[5],
        boxShadow: theme.other.boxShadows.button,
        zIndex,
      })}
    >
      {children}
    </Box>
  );
};
