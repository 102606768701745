import { useDebouncedValue } from "@mantine/hooks";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { RpiFilterV2 } from "../../components/search-filter/RpiSearchFilterTypes";
import { useAuthUser } from "../../hooks/useAuthUser";
import { optArray } from "../../utils/misc.util";

export const useDashboardUserFilters = () => {
  const user = useAuthUser();

  const [customersSearch, setCustomersSearch] = React.useState("");
  const [debouncedCustomersSearch] = useDebouncedValue(customersSearch, 250);

  const { data, isLoading } = GraphQL.useAdminListCustomersQuery(
    { input: debouncedCustomersSearch },
    { enabled: user.is.SUPER_ADMIN }
  );
  const customers = React.useMemo(() => (data?.adminListCustomers || []).map((customer) => customer.publicId), [data]);

  return React.useMemo(() => {
    return RpiFilterV2.createConfigs<GraphQL.DashboardUserSearchFields>([
      ...optArray<RpiFilterV2.Config<GraphQL.DashboardUserSearchFields>>(user.is.SUPER_ADMIN, [
        {
          type: "dropdown",
          label: "Customer",
          field: GraphQL.DashboardUserSearchFields.CustomerPublicId,
          data: customers,
          onSearch: setCustomersSearch,
          isLoading: isLoading,
        },
      ]),
      {
        type: "text",
        label: "Username",
        field: GraphQL.DashboardUserSearchFields.Username,
      },
      {
        type: "text",
        label: "Email",
        field: GraphQL.DashboardUserSearchFields.Email,
      },
      {
        type: "select",
        label: "Role",
        field: GraphQL.DashboardUserSearchFields.Role,
        data: Object.values(GraphQL.DashboardUserRole),
      },
      {
        type: "date",
        label: "Created at",
        field: GraphQL.DashboardUserSearchFields.CreatedAt,
      },
    ]);
  }, [user.is, customers, isLoading]);
};
