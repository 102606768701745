import { Box, SimpleGrid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { GraphQL } from "@rpi/openapi-api";
import {
  Flex,
  IconV2,
  RpiButton,
  RpiCheckbox,
  RpiDrawerBraintreeInput,
  RpiDrawerSelectInput,
  RpiDrawerTextInput,
  RpiLink,
  RpiText,
} from "@rpi/openapi-core";
import React from "react";
import { BraintreeFields, useBraintree } from "../../../hooks/useBraintree";
import { RpiNotification, useRpiNotifications } from "../../../hooks/useRpiNotifications";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../../rpi-core/drawer/RpiDrawer";
import { countrySelectData } from "../../../utils/country.util";
import { PublicUrl } from "../../../utils/link.util";
import { isString } from "../../../utils/type.util";

export const PaymentMethodCreateView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    return (
      <RpiDrawer title="Add payment method" opened={opened} onClose={handleClose}>
        <CreatPaymentMethod onClose={() => handleClose(true)} />
      </RpiDrawer>
    );
  }
);

interface CreatPaymentMethodProps {
  onClose: () => void;
}

const CreatPaymentMethod: React.FC<CreatPaymentMethodProps> = ({ onClose }) => {
  const notifications = useRpiNotifications();

  const [isLoading, setIsLoading] = React.useState(false);
  const [braintreeErrors, setBraintreeErrors] = React.useState<Partial<Record<BraintreeFields, string>>>({});
  const [cardError, setCardError] = React.useState<string>();
  const [countrySearch, setCountrySearch] = React.useState("");
  const [debouncedCountrySearch] = useDebouncedValue(countrySearch, 250);

  const { fields, hostedFields, getPayload } = useBraintree();

  const formRef = React.useRef<HTMLFormElement>();

  const {
    mutateAsync: createPaymentMethod,
    reset,
    ...mutation
  } = GraphQL.useCreatePaymentMethodMutation<{ message: string }>();

  const form = useForm<GraphQL.CreatePaymentMethodMutationVariables["input"]>({
    initialValues: {
      paymentMethodNonce: "",
      cardholderName: "",
      streetAddress: "",
      city: "",
      state: "",
      country: "US",
      makeDefault: false,
      deviceDataFromClient: undefined,
    },

    validate: {
      paymentMethodNonce: (value) => (value.length > 0 ? null : "Invalid payment method"),
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      await createPaymentMethod({ input });
      onClose();
      if (notifications.notifications[RpiNotification.PAYMENT_METHOD]) {
        notifications.refetch(RpiNotification.PAYMENT_METHOD);
      }
    },
    [createPaymentMethod, onClose, notifications]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Adding payment method...",
          description: "Please wait, do not close this screen.",
        },
        success: {
          message: "Payment method added!",
        },
      })(mutation),
    [mutation]
  );

  const filteredCountrySelectData = React.useMemo(() => {
    if (!debouncedCountrySearch || debouncedCountrySearch.length === 0) return countrySelectData;
    const lowercaseDebounced = debouncedCountrySearch.toLowerCase();
    return countrySelectData.filter(({ label }) => label.toLowerCase().includes(lowercaseDebounced));
  }, [debouncedCountrySearch]);

  return (
    <RpiDrawerForm ref={formRef as any} overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerTextInput label="Name on card" {...form.getInputProps("cardholderName")} />
      <RpiDrawerTextInput required label="Address" {...form.getInputProps("streetAddress")} />
      <RpiDrawerTextInput label="Town/City" {...form.getInputProps("city")} />
      {form.values?.country === "US" && <RpiDrawerTextInput label="State" {...form.getInputProps("state")} />}
      <RpiDrawerSelectInput
        required
        label="Country"
        data={filteredCountrySelectData}
        {...form.getInputProps("country")}
        searchable
        onSearch={setCountrySearch}
      />

      <RpiDrawerBraintreeInput
        required
        label="Card number"
        isFocused={fields.number.isFocused}
        isEmpty={fields.number.isEmpty}
        ref={fields.number.ref as any}
        onFocus={() => hostedFields?.focus("number")}
        error={braintreeErrors.number}
      />

      <SimpleGrid cols={2} spacing={8}>
        <RpiDrawerBraintreeInput
          required
          label="MM/YY"
          isFocused={fields.expirationDate.isFocused}
          isEmpty={fields.expirationDate.isEmpty}
          ref={fields.expirationDate.ref as any}
          onFocus={() => hostedFields?.focus("expirationDate")}
          error={!!braintreeErrors.expirationDate}
        />

        <RpiDrawerBraintreeInput
          required
          label="CVC (back of card)"
          isFocused={fields.cvv.isFocused}
          isEmpty={fields.cvv.isEmpty}
          ref={fields.cvv.ref as any}
          onFocus={() => hostedFields?.focus("cvv")}
          error={!!braintreeErrors.cvv}
        />
      </SimpleGrid>

      {(braintreeErrors.expirationDate || braintreeErrors.cvv) && (
        <RpiText
          type="p3"
          weight="light"
          color={(theme) => theme.colors.spark[6]}
          children={braintreeErrors.expirationDate || braintreeErrors.cvv}
        />
      )}

      <RpiDrawerBraintreeInput
        required
        label="Postal Code"
        isFocused={fields.postalCode.isFocused}
        isEmpty={fields.postalCode.isEmpty}
        ref={fields.postalCode.ref as any}
        onFocus={() => hostedFields?.focus("postalCode")}
        error={braintreeErrors.postalCode}
      />

      <RpiText type="p3" weight="light" mt={24} color={(theme) => theme.colors.brand[5]}>
        By saving your payment details, you agree to be charged for your orders daily.
      </RpiText>

      <RpiText type="p3" weight="light" mt={8} color={(theme) => theme.colors.brand[5]}>
        The payment amount will be the sum of prices for your orders in the billing cycle, or in the case of per order
        billing, will be clearly defined on the order summary page.
      </RpiText>

      <RpiText type="p3" weight="light" mt={8} color={(theme) => theme.colors.brand[5]}>
        You can find additional information regarding payments in our{" "}
        <RpiLink
          href={PublicUrl.TERMS_AND_CONDITIONS}
          target="_blank"
          sx={{
            fontFamily: "inherit",
            fontWeight: "inherit",
            fontSize: "inherit",
            color: "inherit",
            textDecoration: "underline",
          }}
          children="terms & conditions"
        />
        .
      </RpiText>

      <RpiCheckbox label="Default payment method" mt={24} variant="radio" {...form.getInputProps("makeDefault")} />

      {cardError && (
        <Flex.Row
          align="flex-start"
          mt={24}
          p={16}
          gap={8}
          sx={(theme) => ({ background: theme.colors.spark[3], color: theme.colors.spark[6] })}
        >
          <Box mt={2} sx={{ flexShrink: 0 }}>
            <IconV2.CreditCard height={20} color="currentColor" />
          </Box>

          <RpiText type="p3" weight="light">
            The provided credit card was declined.
            <br />
            Please try paying with a different card
          </RpiText>
        </Flex.Row>
      )}

      {mutation.error && (
        <Flex.Row
          align="flex-start"
          mt={24}
          p={16}
          gap={8}
          sx={(theme) => ({ background: theme.colors.spark[3], color: theme.colors.spark[6] })}
        >
          <Box mt={2} sx={{ flexShrink: 0 }}>
            <IconV2.ErrorSolid height={20} color="currentColor" />
          </Box>

          <RpiText
            type="p3"
            weight="light"
            children="There was an error while processing your payment. Please try again later."
          />
        </Flex.Row>
      )}

      <RpiButton
        variant="mellow"
        width="full"
        mt={24}
        icon={IconV2.LockSolid}
        isLoading={isLoading}
        disabled={!!cardError}
        onClick={async () => {
          setIsLoading(true);

          try {
            const { payload, deviceData } = await getPayload();

            form.setValues({
              paymentMethodNonce: payload.nonce,
              deviceDataFromClient: String(deviceData),
            });

            // TODO: "form.setValues" needs delay?
            setTimeout(() => {
              setBraintreeErrors({});
              setCardError(undefined);
              formRef.current?.requestSubmit();
            }, 150);
          } catch (error) {
            console.log("braintreeErrors", error);

            if (isString(error)) {
              hostedFields?.clear("number");
              hostedFields?.clear("cvv");
              hostedFields?.clear("expirationDate");
              hostedFields?.clear("postalCode");
              form.reset();
              setCardError(error);
            } else {
              setBraintreeErrors(error as typeof braintreeErrors);
            }
          }

          setIsLoading(false);
        }}
        children="Save"
      />
    </RpiDrawerForm>
  );
};
