import { createStyles, CSSObject } from "@mantine/core";

export type RpiTableVariant = "normal" | "dashboard";

export const useRpiTableStyles = createStyles<"table" | "variant", { variant: RpiTableVariant }>(
  (theme, { variant }) => {
    const variantCss: Record<RpiTableVariant, CSSObject> = {
      normal: {
        border: `1px solid ${theme.colors.text[5]}`,
        backgroundColor: theme.colors.sky[5],
        "& > thead > tr": {
          height: 48,
          borderBottom: `1px solid ${theme.colors.text[5]}`,

          "& > th": {
            ...theme.other.fontTypes.p2,
            lineHeight: "normal",
            fontWeight: theme.other.fontWeights.bold,
            color: theme.colors.text[5],

            borderRight: `0.5px dashed ${theme.colors.sky[7]}`,

            padding: "12px 20px",

            "&:last-of-type": {
              border: "none",
            },

            "& > .resizer": {
              backgroundColor: theme.colors.sky[8],
              opacity: 0,

              "&:hover": {
                opacity: 0,
              },

              "&:active": {
                opacity: 0.2,
              },
            },
          },
        },

        "& > tbody > tr": {
          height: 48,
          borderBottom: `0.5px solid ${theme.colors.text[5]}`,

          "&:last-of-type": {
            borderBottom: "none",
          },

          "&:hover": {
            backgroundColor: theme.colors.calm[4],
          },

          "&[data-disabled]": {
            backgroundColor: theme.colors.text[2],
            color: theme.colors.text[4],
          },

          "& > td": {
            ...theme.other.fontTypes.p3,
            fontWeight: theme.other.fontWeights.regular,
            color: theme.colors.text[5],

            padding: "12px 20px",
          },
        },
      },
      dashboard: {
        borderCollapse: "separate",
        borderSpacing: 0,

        "& > thead > tr": {
          backgroundColor: theme.colors.brand[5],
          height: 48,

          "& > th": {
            ...theme.other.fontTypes.p2,
            lineHeight: "normal",
            fontWeight: theme.other.fontWeights.bold,
            color: theme.colors.sky[5],

            borderRight: `0.5px dashed ${theme.colors.brand[4]}`,

            padding: "12px 32px",

            "&:first-of-type": {
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            },

            "&:last-of-type": {
              border: "none",
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
            },

            "& > .resizer": {
              backgroundColor: theme.colors.brand[3],
              opacity: 0,

              "&:hover": {
                opacity: 0,
              },

              "&:active": {
                opacity: 0.2,
              },
            },
          },
        },

        "& > tbody > tr": {
          backgroundColor: theme.colors.sky[5],
          height: 50,

          "& > td": {
            ...theme.other.fontTypes.p3,
            fontWeight: theme.other.fontWeights.regular,
            color: theme.colors.text[5],

            padding: "12px 32px",
          },
        },
      },
    };

    return {
      table: {
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "collapse",

        "& .ellipsis": {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },

        "& > thead > tr": {
          userSelect: "none",

          "& > th": {
            position: "relative",
            textAlign: "left",
            userSelect: "none",

            "& > .resizer": {
              position: "absolute",
              top: 0,
              right: -4,
              width: 7,
              zIndex: 10,
              height: "100%",
              cursor: "col-resize",
              backgroundColor: "currentcolor",
              pointerEvents: "auto",
            },
          },
        },

        "& > tbody > tr > td": {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",

          "&.subRow": {
            whiteSpace: "normal",
            textOverflow: "unset",
            overflowX: "auto",
          },
        },
      },
      variant: variantCss[variant],
    };
  }
);
