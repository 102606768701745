import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Environment } from "../env/Environment";
import { NavigateRoute } from "../hooks/useNavigateRoute";
import { RpiNotificationsProvider } from "../hooks/useRpiNotifications";
import { RouteLoadingView } from "../views/error/RouteLoadingView";

type AuthRoute =
  | "authenticated"
  | "confirmResetPassword"
  | "confirmSignIn"
  | "confirmSignUp"
  | "confirmVerifyUser"
  | "forceNewPassword"
  | "idle"
  | "resetPassword"
  | "setup"
  | "setupTOTP"
  | "signIn"
  | "signOut"
  | "signUp"
  | "verifyUser";

type AuthStatus = "configuring" | "authenticated" | "unauthenticated";

const logAuthStatus = (isPrivate: boolean, status: string) => {
  if (!Environment.isProduction) {
    console.log(
      `%cStatus: ${status} (${isPrivate ? "PRIVATE" : "PUBLIC"})`,
      `color: ${isPrivate ? "#f7751d" : "#9ff71d"}`
    );
  }
};

const PublicLayout: React.FC = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { state } = useLocation();

  React.useEffect(() => logAuthStatus(false, authStatus), [authStatus]);

  const children = React.useMemo(() => {
    const from = state?.from as Location | undefined;

    switch (authStatus as AuthStatus) {
      case "authenticated":
        return <NavigateRoute {...(from?.pathname ? { to: from.pathname } : { route: ["home"] })} state={undefined} />;

      default:
        break;
    }

    return null;
  }, [authStatus, state]);

  return children || <Outlet />;
};

const PrivateLayout: React.FC = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();

  React.useEffect(() => logAuthStatus(true, route), [route]);

  const children = React.useMemo(() => {
    switch (route as AuthRoute) {
      case "authenticated":
        break;

      /**
       * Used as `unauthenticated`. Use cases:
       * - On load: `idle` -> `setup` | `authenticated`
       * - On sign out: `authenticated` -> `signOut` -> `setup`
       */
      case "setup":
      case "setupTOTP":
      case "signIn": {
        return <NavigateRoute route={["auth:sign-in"]} state={{ from: location }} />;
      }

      case "signUp": {
        return <NavigateRoute route={["auth:sign-in"]} state={{ from: location }} />;
      }

      case "signOut": {
        return <NavigateRoute to="/" state={{ from: location }} />;
      }

      case "resetPassword": {
        return <NavigateRoute route={["auth:reset-password"]} state={{ from: location }} />;
      }

      case "confirmSignIn":
      case "confirmSignUp":
      case "confirmResetPassword":
      case "confirmVerifyUser":
      case "forceNewPassword":
      case "verifyUser": {
        return <NavigateRoute route={["auth"]} state={{ from: location }} />;
      }

      default:
        return <RouteLoadingView />;
    }

    return null;
  }, [route, location]);

  return (
    children || (
      <RpiNotificationsProvider>
        <Outlet />
      </RpiNotificationsProvider>
    )
  );
};

export const AppLayout = {
  Public: PublicLayout,
  Private: PrivateLayout,
};
