import { showNotification } from "@mantine/notifications";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, RpiButton, RpiSimpleDivider, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { PaymentMethod } from "../../../components/PaymentMethod";
import { RpiPagination } from "../../../components/RpiPagination";
import { RpiSearchFilter } from "../../../components/search-filter/RpiSearchFilter";
import { paymentColumns } from "../../../configuration/columns/payment.column";
import { billingTaxDocumentsColumns } from "../../../configuration/columns/upload.column";
import { usePaymentFilters } from "../../../configuration/filters/payment.filter";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { useNavigateRoute } from "../../../hooks/useNavigateRoute";
import { useRpiPagination } from "../../../hooks/usePagination";
import { RpiNotification, useRpiNotifications } from "../../../hooks/useRpiNotifications";
import { RpiTable } from "../../../rpi-core/table/RpiTable";
import { createRpiTableRowMenu } from "../../../rpi-core/table/RpiTableRowMenu";
import { SearchGeneral, SearchInputs, SearchSort } from "../../../utils/graphql.util";
import { optArray } from "../../../utils/misc.util";
import { isUndefined } from "../../../utils/type.util";

export const BillingView: React.FC = () => {
  const user = useAuthUser();
  const outlet = useOutlet();

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.PaymentsQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.PaymentsQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.PaymentsQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 7 });

  const paymentFilters = usePaymentFilters();

  const { data: paymentsData, ...paymentsQuery } = GraphQL.usePaymentsQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.payments.totalCount),
    }
  );
  const payments = React.useMemo(() => paymentsData?.payments.data, [paymentsData]);

  return (
    <AppPage path={["Billing & Payments"]} onReload={paymentsQuery.refetch}>
      {outlet}

      <AppPage.Section title="Recent transactions" size="small">
        <RpiSearchFilter
          placeholder="Search by Transaction"
          filters={paymentFilters}
          dateRangeField={GraphQL.PaymentSearchFields.CreatedAt}
          onChange={setSearchInputs}
          onSearch={setSearchGeneral}
        />

        <RpiTable
          emptyMessage={
            searchInputs?.length || searchGeneral ? "No payments found." : "Your payments will appear here."
          }
          isLoading={paymentsQuery.isLoading}
          error={paymentsQuery.error}
          perPage={pagination.perPage}
          data={payments}
          columns={paymentColumns}
          withSortBy={{
            defaultValue: {
              by: GraphQL.PaymentSearchFields.CreatedAt,
              sortDirection: GraphQL.SortDirection.Desc,
            },
            onChange: setSortInput,
          }}
        />

        <RpiPagination isLoading={paymentsQuery.isLoading} pagination={pagination} />
      </AppPage.Section>

      {(user.is.USER || user.is.CUSTOMER_ADMIN) && <PaymentInformationSection refetchDep={outlet === null} />}

      {user.is.CUSTOMER_ADMIN && (
        <>
          <RpiSimpleDivider />
          <TaxExemptionSection refetchDep={outlet === null} />
        </>
      )}
    </AppPage>
  );
};

interface PaymentInformationSectionProps {
  refetchDep: boolean;
}

const PaymentInformationSection: React.FC<PaymentInformationSectionProps> = ({ refetchDep }) => {
  const user = useAuthUser();
  const navigateRoute = useNavigateRoute();
  const notifications = useRpiNotifications();

  const { data, isLoading, error, refetch } = GraphQL.useListPaymentMethodsQuery(
    {
      customerPublicId: user.id!,
    },
    {
      enabled: !!user.id,
    }
  );
  const paymentMethods = React.useMemo(() => data?.listPaymentMethods?.sort((a) => (a.isDefault ? -1 : 1)), [data]);

  React.useEffect(() => {
    if (refetchDep) {
      refetch();
    }
  }, [refetchDep]);

  return (
    <AppPage.Section mt={32} title="Payment information" size="small" error={error} isLoading={isLoading}>
      <Flex.Column mt={8} gap={8} fullWidth sx={(theme) => ({ color: theme.colors.brand[5] })}>
        {(!paymentMethods || paymentMethods.length === 0) && (
          <RpiText
            type="p3"
            weight="light"
            children="Currently, you have no saved payment info. Please provide a valid credit card to process your orders."
          />
        )}

        {paymentMethods && paymentMethods.length > 0 && (
          <>
            <RpiText type="p3" weight="light" children="Saved payment methods" />
            <Flex.Row fullWidth sx={{ flexWrap: "wrap", columnGap: 100, rowGap: 16 }}>
              {paymentMethods.map((paymentMethod) => (
                <PaymentMethod
                  onRefetch={() => {
                    refetch();
                    notifications.refetch(RpiNotification.PAYMENT_METHOD);
                  }}
                  key={`payment-method-${paymentMethod.token}`}
                  paymentMethod={paymentMethod}
                />
              ))}
            </Flex.Row>
          </>
        )}
      </Flex.Column>

      <RpiButton
        mt={32}
        width="fit"
        variant="brand-outline"
        size="small"
        children="Add payment method"
        trailingIcon
        onClick={() => navigateRoute(["payment-method:create"])}
      />
    </AppPage.Section>
  );
};

interface TaxExemptionSectionProps {
  refetchDep: boolean;
}

const TaxExemptionSection: React.FC<TaxExemptionSectionProps> = ({ refetchDep }) => {
  const user = useAuthUser();
  const navigateRoute = useNavigateRoute();

  const { data, isLoading, refetch } = GraphQL.useCustomerQuery(
    { customerPublicId: user.id! },
    {
      enabled: Boolean(user.id),
    }
  );
  const customer = React.useMemo(() => data?.customer, [data]);

  const deleteMutation = GraphQL.useUploadedFileDeleteMutation<{ message?: string }>({
    onSuccess: () => refetch(),
    onError: (error) => {
      showNotification({
        title: "Failed delete tax document.",
        message: JSON.stringify(error),
        color: "red",
      });
    },
  });

  React.useEffect(() => {
    if (refetchDep) {
      refetch();
    }
  }, [refetchDep]);

  return (
    <AppPage.Section title="Tax exemption" size="small" isLoading={isLoading}>
      <Flex.Column gap={32} fullWidth sx={(theme) => ({ color: theme.colors.brand[5] })}>
        <Flex.Row mt={16} gap={32} align="center">
          <RpiText weight="bold" children="Status" />
          {!isUndefined(customer?.chargeTax) && (
            <RpiText children={customer!.chargeTax ? "Not tax exempt" : "Tax exempt"} />
          )}
        </Flex.Row>

        <RpiText sx={{ maxWidth: 738 }}>
          Are you eligible for tax exemption as a small business? Make sure that your small business is not charged any
          unnecessary taxes by uploading your tax-exempt documents through our online portal. Once we receive them, an
          automated system will notify RPI staff who will immediately begin to review your tax documents.
        </RpiText>

        <RpiButton
          width="fit"
          variant="brand-outline"
          size="small"
          children="Upload Tax-Exempt Documents"
          trailingIcon
          onClick={() => navigateRoute(["billing:file-upload"])}
        />

        {Boolean(customer?.taxDocuments?.length) && (
          <RpiTable
            isLoading={isLoading || deleteMutation.isLoading}
            data={customer?.taxDocuments}
            columns={billingTaxDocumentsColumns}
            additionalColumns={[
              createRpiTableRowMenu<Pick<GraphQL.UploadedFileResponse, "id" | "url">>(({ row }) => [
                ...optArray(Boolean(row.url && window.open), [
                  {
                    label: "View",
                    onClick: () => window?.open?.(row.url!, "_blank")?.focus(),
                  },
                ]),
                {
                  label: "Delete",
                  onClick: () => row.id && deleteMutation.mutate({ id: row.id }),
                  type: "error",
                },
              ]),
            ]}
          />
        )}
      </Flex.Column>
    </AppPage.Section>
  );
};
