import { Badge, Box, Loader } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, Grid, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useParams } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { JsonBox } from "../../components/JsonBox";
import { CellLink } from "../../components/cells/CellLink";
import { CellStatus } from "../../components/cells/CellStatus";
import { blurbCorePrintFileColumns } from "../../configuration/columns/blurb-core-print-files.column";
import { useAuthUser } from "../../hooks/useAuthUser";
import { RpiRouteLink, getRoutePath, useNavigateRoute } from "../../hooks/useNavigateRoute";
import { RpiTable } from "../../rpi-core/table/RpiTable";
import { InternalUrl } from "../../utils/link.util";
import { shouldRetry } from "../../utils/query.util";

export const PrintAssetView: React.FC = () => {
  const { id } = useParams();
  const user = useAuthUser();

  const navigateRoute = useNavigateRoute();
  const handleBack = React.useCallback(() => navigateRoute(["print-assets"]), [navigateRoute]);

  const { data, isLoading, error, refetch } = GraphQL.usePrintAssetQuery(
    { id: Number.parseInt(id!) },
    {
      enabled: !!id,
      refetchOnMount: true,
      retry: shouldRetry(handleBack),
    }
  );
  const printAsset = React.useMemo(() => data?.printAsset, [data]);

  const collectionUrl = `${InternalUrl.PDF_PROCESSING_URL}/${printAsset?.customer?.pdfProcessingApiaccount}/collections/${printAsset?.printAssetUploadId}`;

  return (
    <AppPage path={[{ name: "Print Assets", to: getRoutePath("print-assets") }, id]} onReload={refetch}>
      <Flex.Column gap={24} sx={(theme) => ({ color: theme.colors.brand[5] })}>
        <RpiText type="h2" weight="bold" children="Upload details" />

        <Grid columns="repeat(2, 1fr)" rowGap={24} columnGap={24} fullWidth>
          <DetailItem
            title="Upload ID"
            info={
              user.is.SUPER_ADMIN &&
              printAsset?.customer?.publicId && (
                <Badge variant="outline" size="sm" sx={{ height: 20 }}>
                  Admin only - VPN REQUIRED
                </Badge>
              )
            }
            isLoading={isLoading}
            error={error}
          >
            {user.is.SUPER_ADMIN && printAsset?.customer?.publicId ? (
              <CellLink
                sx={{ wordWrap: "break-word" }}
                to={collectionUrl}
                external
                children={printAsset.printAssetUploadId}
              />
            ) : (
              printAsset?.printAssetUploadId
            )}
          </DetailItem>

          {user.is.SUPER_ADMIN && (
            <DetailItem title="Customer" isLoading={isLoading} error={error}>
              {printAsset?.customer?.publicId && (
                <RpiRouteLink
                  variant="inherit"
                  route={["customer", { publicId: printAsset.customer.publicId }]}
                  children={printAsset.customer.publicId}
                />
              )}
            </DetailItem>
          )}

          <DetailItem title="Status" isLoading={isLoading} error={error}>
            <CellStatus.PrintAsset status={printAsset?.status} />
          </DetailItem>
        </Grid>
      </Flex.Column>

      <Box
        sx={(theme) => ({
          width: "100%",
          height: 1,
          background: theme.colors.calm[5],
          margin: "24px 0px",
        })}
      />

      <Flex.Column gap={32}>
        <JsonSection title="Preview" isLoading={isLoading} error={error} data={printAsset?.previews} />
        <JsonSection
          title="Preflight output files"
          isLoading={isLoading}
          error={error}
          data={printAsset?.preflightOutputFiles}
        />
        {user.is.SUPER_ADMIN && user.id === "blurb" && (
          <>
            <RpiTable
              emptyMessage="BlurbCore Print Files will appear here"
              isLoading={isLoading}
              error={error}
              perPage={3}
              data={data?.printAsset?.blurbCorePrintFiles}
              columns={blurbCorePrintFileColumns}
              getRowId={user.is.SUPER_ADMIN ? (row) => String(row.id) : undefined}
            />
          </>
        )}

        <JsonSection title="Report" isLoading={isLoading} error={error} data={printAsset?.report} />
      </Flex.Column>
    </AppPage>
  );
};

interface DetailItemProps {
  title: string;
  info?: React.ReactNode;
  isLoading?: boolean;
  error?: any;
  children?: React.ReactNode;
}

const DetailItem: React.FC<DetailItemProps> = ({ title, info, isLoading, error, children }) => {
  const titleRender = React.useMemo(() => <RpiText type="h3" weight="bold" children={title} />, [title]);

  return (
    <Flex.Column gap={16}>
      {!info ? (
        titleRender
      ) : (
        <Flex.Row gap={24} align="center">
          {titleRender}
          {info}
        </Flex.Row>
      )}

      <RpiText type="p3" weight="regular">
        {(isLoading || error) && <Loader variant="dots" color={error ? "spark" : "brand"} size="md" />}
        {!isLoading && !error && children}
      </RpiText>
    </Flex.Column>
  );
};

interface JsonSectionProps {
  title?: string;
  isLoading?: boolean;
  error?: any;
  data?: object;
}

const JsonSection: React.FC<JsonSectionProps> = ({ title, isLoading, error, data }) => {
  return (
    <AppPage.Section title={title} size="small" isLoading={isLoading} error={error}>
      <JsonBox
        sx={(theme) => ({
          background: theme.colors.calm[4],
          borderRadius: 5,
          padding: "16px 24px",
          maxHeight: 720,
          maxWidth: "100%",
          overflow: "auto",
        })}
        data={data || {}}
      />
    </AppPage.Section>
  );
};
