import {
  Box,
  Center,
  CSSObject,
  Loader,
  LoadingOverlay,
  MantineTheme,
  StackProps,
  useMantineTheme,
} from "@mantine/core";
import { ExtendedFlexProps, Flex, IconV2, RpiIconButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { Link, To } from "react-router-dom";
import { isFunction, isObject } from "../utils/type.util";

interface AppPageProps {
  path?: Array<React.ReactNode | { name: React.ReactNode; to: To }>;
  children?: React.ReactNode;
  isLoading?: boolean;
  error?: any;
  onReload?: () => void;
  background?: CSSObject["backgroundColor"] | ((theme: MantineTheme) => CSSObject["backgroundColor"]);
}

export const AppPage: AppPageType = ({ path, children, isLoading, error, onReload, background }) => {
  const theme = useMantineTheme();

  const body = React.useMemo(() => {
    if (isLoading || error) {
      return (
        <Box sx={{ flex: 1, position: "relative" }}>
          <LoadingOverlay loader={<Loader color={error ? "spark" : "brand"} size="xl" />} visible={true} />
        </Box>
      );
    }

    return children;
  }, [isLoading, error, children]);

  return (
    <Flex.Column
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        ...(background && {
          backgroundColor: isFunction(background) ? background(theme) : background,
        }),
      }}
    >
      <Flex.Column
        sx={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Flex.Column px={32} py={48} sx={{ flex: 1 }}>
          {path && (
            <Flex.Row
              justify="space-between"
              align="center"
              mb={32}
              fullWidth
              sx={{ overflow: "hidden", minHeight: 36 }}
            >
              <Flex.Row align="center" gap={15} fullWidth sx={{ overflow: "hidden" }}>
                <Flex.Row gap={14} align="baseline">
                  <Flex.Row align="baseline" gap="0.5rem" fullWidth>
                    {path.map((value, i) => {
                      const isLast = path.length - 1 === i;

                      const hasNavigate = isObject(value) && "to" in value;

                      const textRender = (
                        <RpiText
                          type="h1"
                          weight="bold"
                          color={(theme) => (isLast ? theme.colors.brand[5] : theme.colors.brand[3])}
                          children={hasNavigate ? value.name : value || <Loader size="xs" />}
                          sx={
                            hasNavigate
                              ? (theme) => ({
                                  cursor: "pointer",
                                  transition: "color 125ms",
                                  "&:hover": { color: theme.colors.brand[5] },
                                })
                              : {
                                  cursor: "default",
                                }
                          }
                        />
                      );

                      return (
                        <React.Fragment key={`appage-path-${i}-${value}`}>
                          {hasNavigate ? (
                            <Link to={value.to} style={{ all: "unset" }} children={textRender} />
                          ) : (
                            textRender
                          )}

                          {!isLast && (
                            <RpiText type="h1" weight="bold" color={(theme) => theme.colors.brand[3]} children="/" />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Flex.Row>
                  {onReload && (
                    <RpiIconButton size={24} color={theme.colors.brand[5]} icon={IconV2.Refresh} onClick={onReload} />
                  )}
                </Flex.Row>
              </Flex.Row>
            </Flex.Row>
          )}
          {body}
        </Flex.Column>
      </Flex.Column>
    </Flex.Column>
  );
};

interface AppPageSectionProps
  extends Pick<ExtendedFlexProps, `m${"" | "b" | "t" | "l" | "r"}` | `p${"" | "b" | "t" | "l" | "r"}` | "sx"> {
  title?: string;
  actions?: React.ReactNode;
  description?: string;
  isLoading?: boolean;
  error?: any;
  children?: React.ReactNode;
  spacing?: StackProps["spacing"];
  size?: "small" | "large";
  id?: string;
}

export const AppPageSection: React.FC<AppPageSectionProps> = ({
  title,
  actions,
  description,
  isLoading,
  error,
  children,
  spacing,
  size,

  id,

  m,
  mb,
  mt,
  ml,
  mr,
  sx,
  ...props
}) => {
  const body = React.useMemo(() => {
    const bodyChildren =
      isLoading || error ? (
        <Center p={32}>
          <Loader size="xl" color={error ? "spark" : "brand"} />
        </Center>
      ) : (
        children
      );

    return <Flex.Column gap={16} sx={{ position: "relative" }} {...props} children={bodyChildren} />;
  }, [isLoading, error, children, props, spacing]);

  return (
    <Flex.Column {...{ m, mb, mt, ml, mr, sx, id }}>
      {(title || actions) && (
        <Flex.Row justify="space-between" align="center" mb={16}>
          <Flex.Row align="baseline" gap={8}>
            {title && (
              <RpiText
                type={size === "small" ? "h3" : "h2"}
                weight="bold"
                color={(theme) => theme.colors.brand[5]}
                children={title}
              />
            )}
            {description && (
              <RpiText
                type="h3"
                weight="roman"
                sx={(theme) => ({
                  color: theme.colors.text[3],
                })}
                children={description}
              />
            )}
          </Flex.Row>
          {actions}
        </Flex.Row>
      )}

      {body}
    </Flex.Column>
  );
};
AppPage.Section = AppPageSection;

type AppPageType = React.FC<AppPageProps> & {
  Section: typeof AppPageSection;
};
