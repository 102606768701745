import { Box } from "@mantine/core";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { Flex, Grid, RpiButton, RpiLink, RpiSimpleDivider, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useOutlet, useParams } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { DashboardCard } from "../../../components/dashboard-card/DashboardCard";
import { useDailyMetricsGraph } from "../../../components/graphs/useDailyMetricsGraph";
import { useDailyPaymentMetricsGraph } from "../../../components/graphs/useDailyPaymentMetricsGraph";
import { RpiPagination, RpiPaginationRow } from "../../../components/RpiPagination";
import { RpiSearchFilter } from "../../../components/search-filter/RpiSearchFilter";
import { customerDashboardUserColumns } from "../../../configuration/columns/dashboardUser.colum";
import { customerOrderColumns } from "../../../configuration/columns/order.column";
import { customerPaymentColumns } from "../../../configuration/columns/payment.column";
import { useDashboardUserFilters } from "../../../configuration/filters/dashboardUser.filter";
import { useOrderFilters } from "../../../configuration/filters/order.filter";
import { usePaymentFilters } from "../../../configuration/filters/payment.filter";
import { getRoutePath, useNavigateRoute } from "../../../hooks/useNavigateRoute";
import { useRpiPagination } from "../../../hooks/usePagination";
import { RpiPerPage } from "../../../hooks/useRpiPerPage";
import { CountField } from "../../../rpi-core/CountField";
import { RpiTable } from "../../../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../../../utils/graphql.util";
import { InternalUrl, PublicEmail } from "../../../utils/link.util";
import { MailTo } from "../../../utils/mail.util";
import { shouldRetry } from "../../../utils/query.util";
import { isNumber, isUndefined } from "../../../utils/type.util";
import { SettingsSection } from "../../settings/SettingsView";
import { useModals } from "@mantine/modals";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { TaxReviewModalProps } from "../../../components/modals/TaxReviewModal";

export const CustomerView: React.FC = () => {
  const { customerPublicId } = useParams();
  const outlet = useOutlet();
  const user = useAuthUser();
  const modals = useModals();

  const navigateRoute = useNavigateRoute();
  const handleBack = React.useCallback(() => navigateRoute(["customers"]), [navigateRoute]);

  React.useEffect(() => {
    if (!customerPublicId) handleBack();
  }, [customerPublicId]);

  const { data, isLoading, error, refetch } = GraphQL.useCustomerQuery(
    { customerPublicId: customerPublicId || "" },
    {
      enabled: !!customerPublicId,
      refetchOnMount: true,
      retry: shouldRetry(handleBack),
    }
  );
  const customer = React.useMemo(() => data?.customer, [data]);

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  return (
    <AppPage path={[{ name: "Customers", to: getRoutePath("customers") }, customerPublicId]} onReload={refetch}>
      {outlet}

      <Flex.Column gap={24}>
        <RpiText type="h2" weight="bold" color={(theme) => theme.colors.brand[5]} children="Customer details" />

        <SettingsSection
          title="Public ID and email"
          gap={16}
          onEdit={() => navigateRoute(["customer:edit", { publicId: customerPublicId! }])}
          isLoading={isLoading}
          error={error}
        >
          <RpiText type="p3" weight="regular" children={customer?.publicId || ""} />
          <RpiText type="p3" weight="regular" children={customer?.email || ""} />
        </SettingsSection>

        <SettingsSection title="Pdf processing account" gap={16} isLoading={isLoading} error={error}>
          <RpiLink
            variant="inherit"
            sx={(theme) => ({
              ...theme.other.fontTypes.p3,
              fontWeight: theme.other.fontWeights.regular,
            })}
            href={InternalUrl.PDF_PROCESSING_URL + `/${data?.customer.pdfProcessingApiaccount}/collections`}
            children={data?.customer.pdfProcessingApiaccount}
          />
        </SettingsSection>

        <SettingsSection title="Order settings" gap={16} isLoading={isLoading} error={error}>
          {customer && <CustomerOrderSettings customer={customer} />}
        </SettingsSection>

        {user.is.SUPER_ADMIN && Boolean(customer?.taxDocuments?.length) && (
          <Flex.Column gap={16}>
            <Flex.Row gap={32} align="center" sx={(theme) => ({ color: theme.colors.brand[5] })}>
              <RpiText weight="bold" children="Status" />
              {!isUndefined(customer?.chargeTax) && (
                <RpiText children={customer!.chargeTax ? "Not tax exempt" : "Tax exempt"} />
              )}
            </Flex.Row>

            <RpiButton
              width="fit"
              variant="spark-outline"
              size="small"
              children="Review tax documents"
              trailingIcon
              onClick={() =>
                modals.openContextModal<TaxReviewModalProps>("taxReview", {
                  title: "Tax Documents Review",
                  size: "60vw",
                  innerProps: {
                    customerPublicId: customerPublicId!,
                  },
                })
              }
            />
          </Flex.Column>
        )}
      </Flex.Column>

      <RpiSimpleDivider margin={24} />

      <Flex.Column gap={32}>
        <CustomerOverview customerPublicId={customerPublicId!} />
        <CustomerDashboardUsers customerPublicId={customerPublicId!} />
        <CustomerOrders customerPublicId={customerPublicId!} />
        <CustomerPayments customerPublicId={customerPublicId!} />
      </Flex.Column>
    </AppPage>
  );
};

interface CustomerOrderSettingsProps {
  customer: GraphQLQueryData<GraphQL.CustomerQuery>;
}

const CustomerOrderSettings: React.FC<CustomerOrderSettingsProps> = ({ customer }) => {
  const ordersLimit = React.useMemo(() => customer.settings?.ordersLimit, [customer.settings]);

  const { maxOrdersMessage, queueLimitMessage } = React.useMemo(() => {
    const maxOrders = ordersLimit?.maxOrders;
    const limitQueueSize = ordersLimit?.limitQueueSize;

    return {
      maxOrdersMessage: (
        <>
          {!isNumber(maxOrders)
            ? ""
            : maxOrders > 0
            ? `You can place a maximum number of ${maxOrders} orders within a 24-hour period. `
            : "You can place unlimited amount of orders within a 24-hour period. "}
          {!isNumber(maxOrders)
            ? "If you want to set order limit"
            : maxOrders > 0
            ? "If you want to increase this number"
            : "If you want to limit this number"}
          , please reach out to RPI CS team{" "}
          <RpiLink
            variant="inherit"
            href={MailTo({ email: PublicEmail.SUPPORT, subject: `Increase order limit for "${customer?.publicId}"` })}
            children={PublicEmail.SUPPORT}
          />
          .
        </>
      ),
      queueLimitMessage: `If your orders meet the maximum daily limit, they will be placed in an over-limit queue that can hold ${limitQueueSize} orders. If the number of orders surpasses the queue size, new orders will not be accepted.`,
    };
  }, [customer, ordersLimit]);

  return (
    <Grid columns="1fr 1fr" columnGap={32} rowGap={16} sx={{ maxWidth: 943 }}>
      <CountField
        label="Daily Order Count"
        value={ordersLimit?.maxOrders === 0 ? "Unlimited" : ordersLimit?.maxOrders}
        disabled
      />
      <CountField label="Over-Limit Order Count" value={ordersLimit?.limitQueueSize} disabled />
      <RpiText type="p3" weight="light" children={maxOrdersMessage} />
      <RpiText type="p3" weight="light" children={queueLimitMessage} />
    </Grid>
  );
};

interface CustomerOverviewProps {
  customerPublicId: string;
}

const CustomerOverview: React.FC<CustomerOverviewProps> = ({ customerPublicId }) => {
  const { graph: dailyMetricGraph } = useDailyMetricsGraph({
    customerPublicId,
    numberOfDays: 31,
  });

  const { graph: dailyPaymentMetricsGraph } = useDailyPaymentMetricsGraph({
    customerPublicId,
    numberOfDays: 31,
  });

  return (
    <AppPage.Section title="Overview" size="small">
      <Flex.Row gap={16} sx={{ flexWrap: "wrap" }}>
        <DashboardCard.Graph
          title="Order Overview"
          description="Completed orders of the latest month"
          {...dailyMetricGraph}
        />

        <DashboardCard.Graph
          title="Payment Overview"
          description="Daily total of the latest month"
          {...dailyPaymentMetricsGraph}
        />
      </Flex.Row>
    </AppPage.Section>
  );
};

interface CustomerDashboardUsersProps {
  customerPublicId: string;
}

const CustomerDashboardUsers: React.FC<CustomerDashboardUsersProps> = ({ customerPublicId }) => {
  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.DashboardUsersQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.DashboardUsersQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.DashboardUsersQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const dashboardUserFilters = useDashboardUserFilters();

  const { data, isLoading, error } = GraphQL.useDashboardUsersQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: [
          {
            condition: GraphQL.SearchCondition.Equals,
            field: GraphQL.DashboardUserSearchFields.CustomerPublicId,
            firstValue: customerPublicId,
          },
          ...(searchInputs || []),
        ],
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.dashboardUsers.totalCount),
    }
  );
  const dashboardUsers = React.useMemo(() => data?.dashboardUsers.data, [data]);

  return (
    <AppPage.Section title="Dashboard users" size="small">
      <RpiSearchFilter
        placeholder="Search by Username (or Email)"
        filters={dashboardUserFilters}
        onChange={setSearchInputs}
        onSearch={setSearchGeneral}
      />

      <RpiTable
        emptyMessage={
          searchInputs?.length || searchGeneral
            ? "No dashboard users found."
            : "Customer's dashboard users will appear here."
        }
        isLoading={isLoading}
        error={error}
        perPage={pagination.perPage}
        data={dashboardUsers}
        columns={customerDashboardUserColumns}
        withSortBy={{ onChange: setSortInput }}
      />

      <RpiPaginationRow>
        <RpiPagination pagination={pagination} />
        <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
      </RpiPaginationRow>
    </AppPage.Section>
  );
};

interface CustomerOrdersProps {
  customerPublicId: string;
}

const CustomerOrders: React.FC<CustomerOrdersProps> = ({ customerPublicId }) => {
  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.OrdersQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.OrdersQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.OrdersQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const orderFilters = useOrderFilters();

  const { data, isLoading, error } = GraphQL.useOrdersQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: [
          {
            condition: GraphQL.SearchCondition.Equals,
            field: GraphQL.OrderSearchField.CustomerPublicId,
            firstValue: customerPublicId,
          },
          ...(searchInputs || []),
        ],
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.orders.totalCount),
    }
  );
  const orders = React.useMemo(() => data?.orders.data, [data]);

  return (
    <AppPage.Section title="Orders" size="small">
      <RpiSearchFilter
        placeholder="Search by Order ID"
        filters={orderFilters}
        dateRangeField={GraphQL.OrderSearchField.CreatedAt}
        onChange={setSearchInputs}
        onSearch={setSearchGeneral}
      />

      <RpiTable
        emptyMessage={
          searchInputs?.length || searchGeneral ? "No orders found." : "Customer's orders will appear here."
        }
        isLoading={isLoading}
        error={error}
        perPage={pagination.perPage}
        data={orders}
        columns={customerOrderColumns}
        withSortBy={{
          defaultValue: {
            by: GraphQL.OrderSearchField.CreatedAt,
            sortDirection: GraphQL.SortDirection.Desc,
          },
          onChange: setSortInput,
        }}
      />

      <RpiPaginationRow>
        <RpiPagination pagination={pagination} />
        <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
      </RpiPaginationRow>
    </AppPage.Section>
  );
};

interface CustomerPaymentsProps {
  customerPublicId: string;
}

const CustomerPayments: React.FC<CustomerPaymentsProps> = ({ customerPublicId }) => {
  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.PaymentsQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.PaymentsQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.PaymentsQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const paymentFilters = usePaymentFilters();

  const { data, isLoading, error } = GraphQL.usePaymentsQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: [
          {
            condition: GraphQL.SearchCondition.Equals,
            field: GraphQL.PaymentSearchFields.CustomerPublicId,
            firstValue: customerPublicId,
          },
          ...(searchInputs || []),
        ],
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.payments.totalCount),
    }
  );
  const payments = React.useMemo(() => data?.payments.data, [data]);

  return (
    <AppPage.Section title="Payments" size="small">
      <RpiSearchFilter
        placeholder="Search by Transaction"
        filters={paymentFilters}
        dateRangeField={GraphQL.PaymentSearchFields.CreatedAt}
        onChange={setSearchInputs}
        onSearch={setSearchGeneral}
      />

      <RpiTable
        emptyMessage={
          searchInputs?.length || searchGeneral ? "No payments found." : "Customer's payments will appear here."
        }
        isLoading={isLoading}
        error={error}
        perPage={pagination.perPage}
        data={payments}
        columns={customerPaymentColumns}
        withSortBy={{
          onChange: setSortInput,
        }}
      />

      <RpiPaginationRow>
        <RpiPagination pagination={pagination} />
        <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
      </RpiPaginationRow>
    </AppPage.Section>
  );
};
