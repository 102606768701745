import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";

interface RpiDrawerFieldProps {
  label: string | React.ReactNode;
  children: React.ReactNode;
}

export const RpiDrawerField: React.FC<RpiDrawerFieldProps> = ({ label, children }) => {
  return (
    <Flex.Column
      fullWidth
      gap={8}
      sx={(theme) => ({
        borderRadius: 6,
        background: theme.colors.sky[5],
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.colors.calm[5],
        padding: 8,
        minHeight: 64,
        flexShrink: 0,
        overflowX: "auto",
      })}
    >
      <RpiText type="p3" weight="light" color={(theme) => theme.colors.brand[5]} children={label} />
      <RpiText
        type="p2"
        weight="regular"
        sx={(theme) => ({
          color: theme.colors.text[5],
          lineHeight: "19.2px",
          "& a": {
            color: theme.colors.brand[5],
            textDecoration: "underline",
          },
        })}
        children={children}
      />
    </Flex.Column>
  );
};
