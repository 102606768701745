import moment from "moment";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { DevModeProvider } from "./hooks/useDevMode";
import "./index.css";
import { Environment } from "./env/Environment";

export const $envRibbon = document.createElement("div");
fetch("/version.json")
  .then((res) => res.json())
  .then((version = {}) => {
    console.log(
      `%c${document.title}:%c\n• Branch: ${version.branch}\n• SHA: ${version.sha}\n• Build Date: ${version.buildDate}`,
      "font-weight: bold; font-size: 20px;",
      "font-weight: normal; font-size: 16px;"
    );

    if (!Environment.isProduction) {
      $envRibbon.id = "env-ribbon";
      $envRibbon.className = `env-ribbon env-${Environment.value}`;
      $envRibbon.innerHTML = `
        <div>
          <div>${Environment.name}</div>
          <div>${moment(version.buildDate).fromNow()}</div>
        </div>
      `;
      document.body.prepend($envRibbon);
    }
  });

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <DevModeProvider>
      <App />
    </DevModeProvider>
  </React.StrictMode>
);
