import { Box } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { Flex, RpiButton } from "@rpi/openapi-core";
import React, { useState } from "react";
import { useOutlet, useParams } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { CallbacksList } from "../../components/CallbacksList";
import { JsonBox } from "../../components/JsonBox";
import { RpiSingleTable } from "../../components/tables/RpiSingleTable";
import { orderEventColumns } from "../../configuration/columns/orderEvent.column";
import { orderItemColumns } from "../../configuration/columns/orderItem.column";
import { orderRefundColumns } from "../../configuration/columns/payment.column";
import { CustomShipmentResponse, shipmentColumns } from "../../configuration/columns/shipment.column";
import { orderDestinationAddressRows, orderDestinationRows, orderRows } from "../../configuration/rows/order.row";
import { useAuthUser } from "../../hooks/useAuthUser";
import { getRoutePath } from "../../hooks/useNavigateRoute";
import { RpiTable } from "../../rpi-core/table/RpiTable";
import { formatCurrency, safelyParseJson } from "../../utils/misc.util";
import { OrderRefundView } from "./OrderRefundView";
import { ReprintForm } from "./ReprintForm";

const cancelOrderStatuses: GraphQL.OrderStatus[] = [
  GraphQL.OrderStatus.Created, // -> CANCELLED (no cost)
  GraphQL.OrderStatus.PricingCompleted, // -> CANCELLED (no cost)
  GraphQL.OrderStatus.Received, // -> CANCELLED (no cost)
  GraphQL.OrderStatus.WaitingInHoldingBin, // -> CANCELLED (no cost)
  GraphQL.OrderStatus.WaitingForSubmission, // -> CANCELLED_BEFORE_SUBMISSION (no cost probably)
  GraphQL.OrderStatus.Submitting, // -> CANCELLED_AFTER_SUBMISSION (costs may occur)
  GraphQL.OrderStatus.Submitted, // -> CANCELLED_AFTER_SUBMISSION (costs may occur)
];

const invalidUpdateOrderStatuses: GraphQL.OrderStatus[] = [
  GraphQL.OrderStatus.Received,
  GraphQL.OrderStatus.Created,
  GraphQL.OrderStatus.AssetsProcessed,
  GraphQL.OrderStatus.WaitingForTaxes,
  GraphQL.OrderStatus.PricingCompleted,
  GraphQL.OrderStatus.WaitingForSubmission,
  GraphQL.OrderStatus.Submitting,
  GraphQL.OrderStatus.CancelledBeforeSubmission,
  GraphQL.OrderStatus.WaitingInHoldingBin,
  GraphQL.OrderStatus.Failed,
];

export const OrderView: React.FC = () => {
  const { id } = useParams();

  const user = useAuthUser();
  const outlet = useOutlet();

  const { data, isLoading, error, refetch } = GraphQL.useOrderQuery({
    id: Number.parseInt(id!),
  });
  const order = React.useMemo(() => data?.order, [data]);

  const customOrderShipments = React.useMemo(() => {
    if (!order || !order.shipments) return;

    return order.shipments.map((shipment) => {
      const parsed =
        typeof shipment.rawShipmentData === "string"
          ? safelyParseJson(shipment.rawShipmentData)
          : shipment.rawShipmentData;

      return {
        ...shipment,
        parsedRawShipmentData: data === null || typeof data !== "object" ? undefined : parsed,
      };
    }) as CustomShipmentResponse<NonNullable<GraphQLQueryData<GraphQL.OrderQuery>["shipments"]>[number]>[];
  }, [order?.shipments]);

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  return (
    <AppPage path={[{ name: "Orders", to: getRoutePath("orders") }, id]} onReload={refetch}>
      {outlet}

      <AppPage.Section title="Actions" id="actions" isLoading={isLoading} error={error}>
        {order && <OrderActions order={order} onRefetch={refetch} />}
      </AppPage.Section>

      <AppPage.Section mt={32} title="Data" id="data">
        <RpiSingleTable isLoading={isLoading} error={error} data={order} rows={orderRows} />
      </AppPage.Section>

      <AppPage.Section mt={32} title="Destination" id="destination">
        <RpiSingleTable isLoading={isLoading} error={error} data={order?.destination} rows={orderDestinationRows} />
      </AppPage.Section>

      <AppPage.Section mt={24} title="Address" size="small" id="destination-address">
        <RpiSingleTable
          isLoading={isLoading}
          error={error}
          data={order?.destination?.address}
          rows={orderDestinationAddressRows}
        />
      </AppPage.Section>

      <AppPage.Section mt={32} title="Shipments" id="shipments">
        <RpiTable
          emptyMessage="Shipments will appear here."
          isLoading={isLoading}
          error={error}
          perPage={3}
          data={customOrderShipments}
          columns={shipmentColumns}
          getRowId={user.is.SUPER_ADMIN ? (row) => String(row.id) : undefined}
          withSubRow={
            user.is.SUPER_ADMIN
              ? {
                  hasSubRow: ({ rawShipmentData }) => !!rawShipmentData,
                  renderSubRow: (row) => {
                    if (!row.parsedRawShipmentData) return String(row.rawShipmentData);
                    return <JsonBox sx={{ background: "inherit" }} data={row.parsedRawShipmentData} />;
                  },
                }
              : undefined
          }
        />
      </AppPage.Section>

      {order?.pricing && (
        <AppPage.Section mt={32} title="Pricing" id="pricing">
          <Flex.Column
            px={16}
            py={8}
            sx={(theme) => ({
              borderRadius: 6,
              background: theme.colors.sky[5],
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.colors.calm[5],
              padding: 8,
            })}
          >
            <Box
              component="table"
              sx={(theme) => ({
                tableLayout: "fixed",
                borderCollapse: "collapse",
                "& td": {
                  padding: "8px 0px",
                  "&:last-of-type": {
                    textAlign: "right",
                  },
                },
                "& > tbody > tr": {
                  borderBottom: `1px dashed ${theme.colors.calm[5]}`,
                  "&:last-of-type": {
                    borderBottom: `1px solid ${theme.colors.calm[5]}`,
                  },
                  "& > td": {
                    ...theme.other.fontTypes.p2,
                    fontWeight: theme.other.fontWeights.regular,

                    "&:first-of-type": {
                      color: theme.colors.brand[5],
                    },
                    "&:last-of-type": {
                      color: theme.colors.text[5],
                    },
                  },
                },
                "& > tfoot": {
                  "& > tr > td": {
                    ...theme.other.fontTypes.p2,
                    fontWeight: theme.other.fontWeights.bold,

                    "&:first-of-type": {
                      color: theme.colors.brand[5],
                    },
                    "&:last-of-type": {
                      color: theme.colors.text[5],
                    },
                  },
                },
              })}
            >
              <tbody>
                <tr>
                  <td>Items Total:</td>
                  <td>{formatCurrency(order.currency, order.pricing?.itemsTotal || 0)}</td>
                </tr>
                <tr>
                  <td>Shipping Total:</td>
                  <td>{formatCurrency(order.currency, order.pricing?.shippingTotal || 0)}</td>
                </tr>
                <tr>
                  <td>Taxes Total:</td>
                  <td>{formatCurrency(order.currency, order.pricing?.taxesTotal || 0)}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Order Total:</td>
                  <td>{formatCurrency(order.currency, order.pricing?.orderTotal || 0)}</td>
                </tr>
              </tfoot>
            </Box>
          </Flex.Column>
        </AppPage.Section>
      )}

      {order?.refunds && (
        <AppPage.Section mt={32} title="Refunds" id="refunds">
          <RpiTable
            emptyMessage="Order refunds will appear here."
            isLoading={isLoading}
            error={error}
            perPage={3}
            data={order?.refunds}
            columns={orderRefundColumns}
          />
        </AppPage.Section>
      )}

      <AppPage.Section mt={32} title="Items" id="items">
        <RpiTable
          emptyMessage="No order items."
          isLoading={isLoading}
          error={error}
          perPage={3}
          data={order?.orderItems}
          columns={orderItemColumns}
        />
      </AppPage.Section>

      <AppPage.Section mt={32} title="Events" id="events">
        <RpiTable
          emptyMessage="Order events will appear here."
          isLoading={isLoading}
          error={error}
          perPage={3}
          data={order?.orderEvents}
          columns={orderEventColumns}
          getRowId={(row) => String(row.id)}
          withSubRow={{
            hasSubRow: ({ data }) => !!data,
            renderSubRow: (row) => {
              const data = typeof row.data === "string" ? safelyParseJson(row.data) : row.data;
              if (data === null || typeof data !== "object") return String(row.data);
              return <JsonBox sx={{ background: "inherit" }} data={data} />;
            },
          }}
        />
      </AppPage.Section>

      {order?.callbacks && order?.callbacks.length > 0 && (
        <AppPage.Section mt={32} title="Callbacks" id="callbacks2">
          <CallbacksList callbacks={order.callbacks} webhookUrl={order.webhookUri} />
        </AppPage.Section>
      )}
    </AppPage>
  );
};

interface OrderActionsProps {
  order: GraphQLQueryData<GraphQL.OrderQuery>;
  onRefetch: () => void;
}

const OrderActions: React.FC<OrderActionsProps> = ({ order, onRefetch }) => {
  const user = useAuthUser();

  const [opened, setOpened] = useState(false);

  const { mutateAsync: cancelOrder, ...cancelOrderMutation } = GraphQL.useAdminCancelOrderMutation();
  const { mutateAsync: updateOrderStatus, ...updateOrderStatusMutation } = GraphQL.useAdminUpdateOrderStatusMutation();
  const { mutateAsync: releaseFromHoldingBin, ...releaseFromHoldingBinMutation } =
    GraphQL.useAdminReleaseFromHoldingBinMutation();

  const modals = useModals();

  const canCancelOrder = React.useMemo(
    () => (order.status ? cancelOrderStatuses.includes(order.status) : false),
    [order.status]
  );

  const handleCancelOrder = React.useCallback(() => {
    if (!canCancelOrder) {
      return showNotification({
        title: "Cancel order is disabled.",
        message: order.customerOrderId,
        color: "red",
      });
    }

    return modals.openContextModal("confirmation", {
      title: "Cancel order",
      innerProps: {
        message: (
          <>
            Are you sure you want to cancel order <strong>{order.id}</strong>?
          </>
        ),
        onConfirm: async () => {
          return cancelOrder({ id: order.id })
            .then(() => {
              showNotification({
                title: "Order cancellation attempted.",
                message: order.customerOrderId,
                color: "green",
              });
              onRefetch();
            })
            .catch((error) => {
              showNotification({
                title: "Failed to cancel order",
                message: error.message,
                color: "red",
              });
            });
        },
      },
    });
  }, [canCancelOrder, order, modals, cancelOrder, onRefetch]);

  const canRefundOrder = React.useMemo(
    () => order?.paymentStatus === GraphQL.PaymentStatus.Paid && (order?.remainingPossibleRefund as number) > 0,
    []
  );

  const handleRefundOrder = React.useCallback(() => {
    if (!canRefundOrder) {
      return showNotification({
        title: "Refund order is disabled.",
        message: order.customerOrderId,
        color: "red",
      });
    }

    return setOpened(true);
  }, [canRefundOrder, order]);

  const handleReprintOrder = React.useCallback(() => {
    if (!order.canReprint) {
      return showNotification({
        title: "Reprint order is disabled.",
        message: order.customerOrderId,
        color: "red",
      });
    }

    return modals.openModal({
      title: "Reprint form",
      size: "lg",
      children: (
        <>
          <ReprintForm order={order as GraphQL.OrderSingleResponse} />
        </>
      ),
    });
  }, [order, modals]);

  const canReleaseFromHoldingBin = React.useMemo(
    () =>
      order.publicOrderStatus === GraphQL.PublicOrderStatus.ValidHoldingBin &&
      (order.paymentStatus === GraphQL.PaymentStatus.Paid ||
        order.paymentStatus === GraphQL.PaymentStatus.ExternallyPaid),
    [order]
  );

  const handleReleaseFromHoldingBin = React.useCallback(() => {
    if (!canReleaseFromHoldingBin) {
      return showNotification({
        title: "Release from holding bin is disabled.",
        message: order.customerOrderId,
        color: "red",
      });
    }

    return modals.openContextModal("confirmation", {
      title: "Release from holding bin",
      innerProps: {
        message: (
          <>
            Are you sure you want to release order <strong>{order.id}</strong> from holding bin?
          </>
        ),
        onConfirm: async () => {
          return releaseFromHoldingBin({ id: order.id })
            .then(() => {
              showNotification({
                title: "Your order is scheduled for holding been release.",
                message: order.customerOrderId,
                color: "green",
              });
              onRefetch();
            })
            .catch((error) => {
              showNotification({
                title: "Failed to released order from holding bin.",
                message: error.message,
                color: "red",
              });
            });
        },
      },
    });
  }, [canReleaseFromHoldingBin, order, modals, releaseFromHoldingBin, onRefetch]);

  const canUpdateOrder = React.useMemo(
    () => (order.status ? !invalidUpdateOrderStatuses.includes(order.status) : false),
    [order.status]
  );

  const handleUpdateOrder = React.useCallback(() => {
    if (!canUpdateOrder) {
      return showNotification({
        title: "Request update from printer is disabled.",
        message: order.customerOrderId,
        color: "red",
      });
    }

    return modals.openContextModal("confirmation", {
      title: "Request update from printer",
      innerProps: {
        message: (
          <>
            Are you sure you want to request update from printer for order <strong>{order.id}</strong>?
          </>
        ),
        onConfirm: async () => {
          return updateOrderStatus({ id: order.id })
            .then(() => {
              showNotification({
                title: "Requested update from printer.",
                message: order.customerOrderId,
                color: "green",
              });
              onRefetch();
            })
            .catch((error) => {
              showNotification({
                title: "Failed to request update from printer.",
                message: error.message,
                color: "red",
              });
            });
        },
      },
    });
  }, [canUpdateOrder, order, modals, updateOrderStatus, onRefetch]);

  return (
    <Flex.Row gap={16} sx={{ flexWrap: "wrap" }}>
      <RpiButton
        variant="spark"
        size="small"
        width="fit"
        isLoading={cancelOrderMutation.isLoading}
        disabled={!canCancelOrder}
        onClick={handleCancelOrder}
        children="Cancel Order"
      />

      {user.is.SUPER_ADMIN && (
        <>
          <RpiButton
            variant="spark"
            size="small"
            width="fit"
            disabled={!canRefundOrder}
            onClick={handleRefundOrder}
            children="Refund Order"
          />

          <RpiButton
            variant="spark"
            size="small"
            width="fit"
            isLoading={cancelOrderMutation.isLoading}
            disabled={!order.canReprint}
            onClick={handleReprintOrder}
            children="Reprint"
          />

          <RpiButton
            variant="spark"
            size="small"
            width="fit"
            isLoading={releaseFromHoldingBinMutation.isLoading}
            disabled={!canReleaseFromHoldingBin}
            onClick={handleReleaseFromHoldingBin}
            children="Release from Holding Bin"
          />

          <RpiButton
            variant="spark"
            size="small"
            width="fit"
            isLoading={updateOrderStatusMutation.isLoading}
            disabled={!canUpdateOrder}
            onClick={handleUpdateOrder}
            children="Request Update from Printer"
          />
        </>
      )}

      <OrderRefundView opened={opened} onClose={() => setOpened(false)} />
    </Flex.Row>
  );
};
