import React from "react";
import ReactJson, { ReactJsonViewProps } from "react-json-view";
import { OverflowX } from "../components/OverflowX";
import { Environment } from "../env/Environment";

interface DevPreviewProps extends Omit<ReactJsonViewProps, "src"> {
  data?: any;
  fromChildren?: React.ReactNode;
}

const DevPreview: React.FC<DevPreviewProps> = ({ data = {}, fromChildren, ...props }) => {
  const obj = React.useMemo(() => {
    if (fromChildren) {
      if (Array.isArray(fromChildren)) {
        return fromChildren.map((child) => (React.isValidElement(child) ? child.props : {}));
      }
      return React.isValidElement(fromChildren) ? fromChildren.props : {};
    }

    return data;
  }, [data, fromChildren]);

  return (
    <OverflowX
      p={16}
      sx={(theme) => ({
        borderRadius: 6,
        background: theme.colors.sky[5],
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.colors.spark[5],
        position: "relative",

        "&::after": {
          ...theme.other.fontTypes.h4,
          fontWeight: theme.other.fontWeights.medium,
          content: '"DEV"',
          position: "absolute",
          top: 0,
          right: 6,
          color: theme.colors.spark[5],
        },
      })}
    >
      <ReactJson src={obj} name={null} collapsed={1} displayDataTypes={false} {...props} />
    </OverflowX>
  );
};

const DevModeContext = React.createContext<{
  isDevMode: boolean;
  DevPreview: typeof DevPreview;
  toggleDevMode: () => void;
} | null>(null);

export const useDevMode = () => {
  if (Environment.isProduction) {
    return {
      isDevMode: false,
      DevPreview: ({ children }: any) => children,
      toggleDevMode: () => {},
    };
  }

  const context = React.useContext(DevModeContext);
  if (!context) {
    throw new Error(`${useDevMode.name} must be used within ${DevModeProvider.name}`);
  }

  return context;
};

interface DevModeProviderProps {
  children: React.ReactNode;
}

export const DevModeProvider: React.FC<DevModeProviderProps> = ({ children }) => {
  const [devMode, setDevMode] = React.useState(false);

  if (Environment.isProduction) return <>{children}</>;

  const toggleDevMode = () => {
    setDevMode((devMode) => !devMode);
  };

  return <DevModeContext.Provider value={{ isDevMode: devMode, DevPreview, toggleDevMode }} children={children} />;
};
