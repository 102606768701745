import {
  Accordion,
  AccordionProps,
  Badge,
  Box,
  Button,
  ButtonProps,
  createStyles,
  Divider,
  Group,
  MantineColor,
  SimpleGrid,
  Stack,
  TabsValue,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Prism } from "@mantine/prism";
import { PolymorphicComponentProps } from "@mantine/utils";
import React from "react";
import { Plus } from "tabler-icons-react";
import { optObj } from "../../utils/misc.util";

const useStyles = createStyles((theme) => ({
  control: {
    "&:hover": {
      background: "transparent",
    },
  },

  panel: {
    borderTop: "1px solid",
    borderColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[3],
  },

  item: {
    borderBottom: 0,
    overflow: "hidden",
    transition: `box-shadow 150ms ${theme.transitionTimingFunction}`,
    border: "1px solid transparent",
    borderRadius: theme.radius.sm,
    boxShadow: theme.shadows.sm,
    marginBottom: theme.spacing.sm,
    borderColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[3],
    opacity: 0.7,

    "&:hover": {
      opacity: 1,
    },

    "&[data-active]": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,
      opacity: 1,
    },
  },

  chevron: {
    "&[data-rotate]": {
      transform: "rotate(-90deg)",
    },
  },
}));

export const ApiAccordion: React.FC<AccordionProps> = (props) => {
  const { classes } = useStyles();
  return <Accordion classNames={classes} chevron={<Plus size={16} />} {...props} />;
};

export enum ApiMethod {
  POST = "POST",
  DELETE = "DELETE",
  GET = "GET",
  PUT = "PUT",
}

const apiMethodsColor: { [key in ApiMethod]: MantineColor } = {
  [ApiMethod.POST]: "green",
  [ApiMethod.DELETE]: "red",
  [ApiMethod.GET]: "blue",
  [ApiMethod.PUT]: "orange",
};

export interface ApiAccordionLabelProps {
  method: ApiMethod;
  path: string;
  description: string;
}

export const ApiAccordionLabel: React.FC<ApiAccordionLabelProps> = ({ method, path, description }) => {
  return (
    <Group spacing="sm">
      <Badge radius="xs" size="lg" variant="filled" color={apiMethodsColor[method]} children={method} />
      <Text children={path} />
      <Text color="dimmed" children={description} />
    </Group>
  );
};

interface ApiAccordionFormProps {
  curl: string;
  response: { data?: any; error?: any };
  children: React.ReactNode;
  buttonProps?: PolymorphicComponentProps<"button", ButtonProps>;
  jsonInput?: React.ReactNode;
}

export const ApiAccordionForm: React.FC<ApiAccordionFormProps> = ({
  curl,
  response,
  children,
  buttonProps = {},
  jsonInput,
}) => {
  const theme = useMantineTheme();
  const [tab, setTab] = React.useState<TabsValue>("curl");

  React.useEffect(() => {
    if (response.error) {
      setTab("error");
    } else if (response.data) {
      setTab("response");
    }
  }, [response]);

  return (
    <Stack>
      <SimpleGrid
        spacing="lg"
        breakpoints={[
          {
            minWidth: 1256,
            cols: 2,
            spacing: "sm",
          },
          {
            maxWidth: 1256,
            cols: 1,
            spacing: "sm",
          },
        ]}
        children={children}
      />

      {jsonInput}

      <Group>
        <Button children="Submit" variant="outline" {...buttonProps} />
      </Group>

      <Divider />

      <Box sx={{ position: "relative", display: "flex" }}>
        <Box sx={{ flex: 1, width: 0 }}>
          <Prism.Tabs value={tab} onTabChange={setTab}>
            <Prism.TabsList>
              <Prism.Tab value="curl" children="Curl" />
              <Prism.Tab value="response" children="Response" />
              <Prism.Tab
                value="error"
                style={optObj(response.error, {
                  color: theme.colors.spark[5],
                  backgroundColor: theme.colors.spark[2],
                  borderColor: theme.colors.spark[2],
                })}
                children="Error"
              />
            </Prism.TabsList>

            <Prism.Panel value="curl" language="bash" children={curl} />
            <Prism.Panel value="response" language="json" children={JSON.stringify(response.data || {}, null, 2)} />
            <Prism.Panel value="error" language="json" children={JSON.stringify(response.error || {}, null, 2)} />
          </Prism.Tabs>
        </Box>
      </Box>
    </Stack>
  );
};
