import { Box } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { Flex, IconV2, RpiIconButton } from "@rpi/openapi-core";
import React from "react";

interface CellClipboardProps {
  children: string;
  data?: any;
}

export const CellClipboard: React.FC<CellClipboardProps> = ({ children, data }) => {
  const clipboard = useClipboard({ timeout: 250 });

  return (
    <Flex.Row
      gap={8}
      align="center"
      sx={(theme) => ({
        flexWrap: "nowrap",
        color: clipboard.copied
          ? theme.colors.green[5]
          : clipboard.error
          ? theme.colors.spark[5]
          : theme.colors.text[5],
      })}
    >
      <Box sx={{ font: "inherit", lineHeight: "inherit" }} className="text-ellipsis" children={children} />
      <RpiIconButton
        icon={IconV2.Copy}
        color="currentColor"
        size={{ height: 15 }}
        sx={{ color: "inherit" }}
        onClick={() => clipboard.copy(data || children)}
      />
    </Flex.Row>
  );
};
