import { isGraphQLError } from "@rpi/openapi-api";

export const shouldRetry = (callback: () => void) => {
  // TODO: Implement everywhere
  return (failureCount: number, error: any) => {
    const shouldRetry = failureCount > 3;
    if (!isGraphQLError(error)) return shouldRetry;
    if (error.data.extensions.code >= 400) {
      callback();
      return false;
    }
    return shouldRetry;
  };
};
