import { GraphQL, GraphQLPagedQueryData, GraphQLQueryData } from "@rpi/openapi-api";
import { CellLink, CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { CellStatus } from "../../components/cells/CellStatus";
import { CellDate } from "../../components/cells/CellDate";
import { CellBoolean } from "../../components/cells/CellBoolean";

const uploadedFileColumn = createEntityColumn<GraphQL.UploadedFileResponse, GraphQL.UploadedFileSearchFields>();

export const UploadedFileColumn = {
  id: uploadedFileColumn<"id">({
    header: "ID",
    accessor: "id",
    sortKey: GraphQL.UploadedFileSearchFields.Id,
    size: 200,
  }),
  customer: uploadedFileColumn<"customer">({
    header: "Customer",
    sortKey: GraphQL.UploadedFileSearchFields.CustomerPublicId,
    cell: ({ row }) => {
      if (!row.customer?.publicId) return;
      return <CellRoute route={["customer", { publicId: row.customer.publicId }]} children={row.customer.publicId} />;
    },
    size: 150,
  }),
  status: uploadedFileColumn<"status">({
    header: "Status",
    accessor: "status",
    sortKey: GraphQL.UploadedFileSearchFields.Status,
    cell: ({ row }) => <CellStatus.UploadedFile status={row.status} />,
    size: 171,
  }),
  createdAt: uploadedFileColumn<"createdAt">({
    header: "Created",
    accessor: "createdAt",
    sortKey: GraphQL.UploadedFileSearchFields.CreatedAt,
    cell: ({ row }) => <CellDate date={row.createdAt} />,
    size: 180,
  }),
  updatedAt: uploadedFileColumn<"updatedAt">({
    header: "Updated",
    accessor: "updatedAt",
    sortKey: GraphQL.UploadedFileSearchFields.UpdatedAt,
    cell: ({ row }) => <CellDate date={row.updatedAt} />,
    size: 180,
  }),
  collectionId: uploadedFileColumn<"collectionId" | "customer">({
    header: "Collection",
    sortKey: GraphQL.UploadedFileSearchFields.CollectionId,
    cell: ({ row }) => {
      if (!row.customer?.publicId) return row.collectionId;
      return (
        <CellRoute
          route={["uploads:collection", { collectionId: row.collectionId, customerPublicId: row.customer.publicId }]}
          children={row.collectionId}
        />
      );
    },
    size: 200,
  }),
  fileName: uploadedFileColumn<"fileName">({
    header: "Filename",
    accessor: "fileName",
    sortKey: GraphQL.UploadedFileSearchFields.FileName,
    size: 200,
  }),
  fileNameLink: uploadedFileColumn<"fileName" | "url">({
    header: "Filename",
    sortKey: GraphQL.UploadedFileSearchFields.FileName,
    cell: ({ row }) =>
      row.url ? <CellLink external to={row.url} target="_blank" children={row.fileName} /> : row.fileName,
    size: 200,
  }),
  contentType: uploadedFileColumn<"contentType">({
    header: "Content type",
    accessor: "contentType",
    sortKey: GraphQL.UploadedFileSearchFields.FileType,
    size: 150,
  }),
  url: uploadedFileColumn<"url">({
    header: "URL",
    accessor: "url",
    cell: ({ row }) => row.url && <CellLink external to={row.url} />,
    size: 200,
  }),
  description: uploadedFileColumn<"description">({
    header: "Description",
    accessor: "description",
    size: 200,
  }),
  isTaxDocument: uploadedFileColumn<"isTaxDocument">({
    header: "Tax document?",
    accessor: "isTaxDocument",
    sortKey: GraphQL.UploadedFileSearchFields.IsTaxDocument,
    cell: ({ row }) => <CellBoolean value={Boolean(row.isTaxDocument)} />,
    size: 153,
  }),
};

export const uploadedFilesColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.UploadedFilesQuery>> = (user) => [
  user.is.SUPER_ADMIN && UploadedFileColumn.customer,
  UploadedFileColumn.collectionId,
  UploadedFileColumn.fileName,
  UploadedFileColumn.contentType,
  UploadedFileColumn.status,
  UploadedFileColumn.url,
  UploadedFileColumn.isTaxDocument,
  UploadedFileColumn.description,
  UploadedFileColumn.createdAt,
  UploadedFileColumn.updatedAt,
];

export const uploadedCollectionFilesColumns: RpiTableColumns<
  NonNullable<NonNullable<GraphQLQueryData<GraphQL.UploadFilesByPollCollectionQuery>>[number]>
> = [
  UploadedFileColumn.status,
  UploadedFileColumn.fileName,
  UploadedFileColumn.contentType,
  UploadedFileColumn.url,
  UploadedFileColumn.isTaxDocument,
  UploadedFileColumn.description,
  UploadedFileColumn.createdAt,
  UploadedFileColumn.updatedAt,
];

export const customerTaxDocumentsColumns: RpiTableColumns<
  NonNullable<NonNullable<NonNullable<GraphQLQueryData<GraphQL.CustomerQuery>>["taxDocuments"]>[number]>
> = [
  UploadedFileColumn.fileNameLink,
  UploadedFileColumn.contentType,
  UploadedFileColumn.description,
  UploadedFileColumn.createdAt,
  UploadedFileColumn.updatedAt,
];

export const billingTaxDocumentsColumns: RpiTableColumns<
  NonNullable<NonNullable<NonNullable<GraphQLQueryData<GraphQL.CustomerQuery>>["taxDocuments"]>[number]>
> = [
  { ...UploadedFileColumn.fileNameLink, size: 300 },
  { ...UploadedFileColumn.description, size: 300 },
  UploadedFileColumn.createdAt,
];
