import { Box } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, Grid, RpiLink, RpiText } from "@rpi/openapi-core";
import moment from "moment";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { getRoutePath, useNavigateRoute } from "../../hooks/useNavigateRoute";
import { formatCurrency } from "../../utils/misc.util";
import { shouldRetry } from "../../utils/query.util";

export const PurchaseOrdersConfirmationView: React.FC = () => {
  const { braintreeTransactionId } = useParams();
  const navigateRoute = useNavigateRoute();

  const { data, isLoading, error, refetch } = GraphQL.usePaymentByTransactionIdQuery(
    { braintreeTransactionId },
    {
      enabled: !!braintreeTransactionId,
      retry: shouldRetry(() => navigateRoute(["orders"])),
    }
  );
  const payment = React.useMemo(() => data?.paymentByTransactionId, [data]);

  React.useEffect(() => {
    if (!braintreeTransactionId) {
      navigateRoute(["orders"]);
    }
  }, [braintreeTransactionId, navigateRoute]);

  const divider = React.useMemo(
    () => <Box sx={(theme) => ({ width: "100%", height: 1, background: theme.colors.brand[3] })} />,
    []
  );

  return (
    <AppPage
      path={[{ name: "Orders", to: getRoutePath("orders") }, "Purchase"]}
      onReload={refetch}
      isLoading={isLoading}
      error={error}
    >
      {!payment && <RpiText type="p2" weight="regular" children="Unable to find payment" />}

      {payment && (
        <Flex.Column mt={32} gap={32} sx={(theme) => ({ color: theme.colors.brand[5], maxWidth: 738 })}>
          <RpiText type="h1" weight="bold" children="Thank you for your payment" />
          <RpiText type="p2" weight="regular">
            An email confirmation has been sent to{" "}
            <Box
              component="span"
              sx={(theme) => ({ fontWeight: theme.other.fontWeights.bold })}
              children={payment.paymentInfo.notificationEmail}
            />
          </RpiText>

          <Flex.Column
            px={28}
            py={32}
            gap={24}
            sx={(theme) => ({
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.colors.brand[5],
              borderRadius: 24,
            })}
          >
            <div>
              <RpiText type="p3" weight="light" children="Confirmation number" />
              <RpiText type="p1" weight="regular" children={payment.braintreeTransactionId} />
            </div>

            <div>
              <RpiText type="p3" weight="light" children="Order total" />
              <RpiText type="p1" weight="regular" children={formatCurrency(payment.currency, payment.amount)} />
            </div>
          </Flex.Column>

          <RpiText type="h3" weight="bold" children="Order details" />

          <Flex.Column
            gap={32}
            pr={payment.paidOrders && payment.paidOrders.length > 3 ? 16 : 0}
            sx={{ maxHeight: 292, overflowY: "auto" }}
            children={payment.paidOrders?.map((order, i) => (
              <Grid
                key={`paid-order-${i}`}
                columns="1fr min-content"
                sx={(theme) => ({ ...theme.other.fontTypes.p3, fontWeight: theme.other.fontWeights.regular })}
              >
                <RpiText type="p2" weight="regular" sx={{ marginBottom: 12 }}>
                  <Box
                    component="span"
                    sx={(theme) => ({ fontWeight: theme.other.fontWeights.bold })}
                    children="Order ID"
                  />{" "}
                  <RpiLink
                    variant="inherit"
                    component={Link}
                    to={getRoutePath("order", { id: order.id })}
                    children={order.customerOrderId}
                  />
                </RpiText>
                <Box
                  component="span"
                  sx={(theme) => ({ textAlign: "right", fontWeight: theme.other.fontWeights.bold })}
                  children={formatCurrency(order.pricing?.currency, order.pricing?.orderTotal)}
                />

                <Box
                  component="span"
                  sx={{ gridColumn: 1 }}
                  children={`Created ${moment(order.createdAt).format("MM/DD YYYY HH:mm:ss")}`}
                />

                <Box
                  component="span"
                  sx={{ gridColumn: 1 }}
                  children={`Updated ${moment(order.updatedAt).format("MM/DD YYYY HH:mm:ss")}`}
                />
              </Grid>
            ))}
          />

          {divider}

          <Grid
            columns="1fr min-content"
            sx={(theme) => ({ ...theme.other.fontTypes.p3, fontWeight: theme.other.fontWeights.regular })}
          >
            <RpiText
              type="p2"
              weight="bold"
              sx={{ marginBottom: 12, gridColumn: "span 2" }}
              children="Checkout details"
            />

            <span children="Total" />
            <Box
              component="span"
              sx={(theme) => ({ textAlign: "right", fontWeight: theme.other.fontWeights.bold })}
              children={formatCurrency(payment.currency, payment.amount)}
            />
          </Grid>

          {divider}

          <Grid
            columns="1fr min-content"
            sx={(theme) => ({ ...theme.other.fontTypes.p3, fontWeight: theme.other.fontWeights.regular })}
          >
            <RpiText
              type="p2"
              weight="bold"
              sx={{ marginBottom: 12, gridColumn: "span 2" }}
              children="Payment details"
            />

            <Box
              component="span"
              sx={{
                ":first-letter": {
                  textTransform: "uppercase",
                },
              }}
              children={`${payment.paymentInfo?.paidWith?.replaceAll(/_/g, " ")} ... ${
                payment.paymentInfo.last4Digits
              }`}
            />
            <Box
              component="span"
              sx={(theme) => ({ textAlign: "right", fontWeight: theme.other.fontWeights.bold })}
              children={formatCurrency(payment.currency, payment.amount)}
            />
          </Grid>

          {divider}
        </Flex.Column>
      )}
    </AppPage>
  );
};
