import { Box, Loader } from "@mantine/core";
import { GraphQL, Rest } from "@rpi/openapi-api";
import { Flex, RpiText } from "@rpi/openapi-core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { formatCurrency } from "../../utils/misc.util";
import { LANDING_PAGE_MAX_WIDTH } from "./IndexView";

type ExtendedProduct = Rest.ProductListingResponse["pricelist_entries"][number] & {
  data?: Rest.PriceListInfo["products"][number];
};

interface PricingPageProps {
  products?: Rest.PriceListInfo["products"];
}

export const PricingPage: React.FC<PricingPageProps> = ({ products }) => {
  const { data: productListingData } = useQuery(["product-listing"], Rest.getProductListing);

  const extendedProducts: ExtendedProduct[] | undefined = React.useMemo(() => {
    const pricelistEntries = productListingData?.pricelist_entries;
    if (!pricelistEntries) return undefined;
    if (!products) return pricelistEntries;
    return pricelistEntries.map((entry) => ({
      ...entry,
      data: products.find((product) => product.openapiSku === entry.sku),
    }));
  }, [productListingData, products]);

  return (
    <Flex.Column
      justify="center"
      align="center"
      fullWidth
      p={80}
      sx={(theme) => ({ background: theme.colors.calm[5] })}
    >
      <Flex.Column
        justify="center"
        align="center"
        fullWidth
        p={50}
        sx={(theme) => ({
          flex: 1,
          background: theme.colors.sky[5],
          borderRadius: 24,
          position: "relative",
          maxWidth: LANDING_PAGE_MAX_WIDTH,
        })}
      >
        <Flex.Column align="center" fullWidth gap={24} sx={{ maxWidth: 749 }}>
          <RpiText type="d2" weight="bold" color={(theme) => theme.colors.brand[5]} children="Pricing" />
          <Box
            component="table"
            sx={(theme) => ({
              width: "100%",
              tableLayout: "fixed",
              borderCollapse: "collapse",
              color: theme.colors.text[5],

              "& > thead": {
                "& > tr": {
                  height: 48,
                  borderBottom: `1px solid ${theme.colors.text[5]}`,

                  "& > th": {
                    ...theme.other.fontTypes.p2,
                    fontWeight: theme.other.fontWeights.bold,
                    textAlign: "left",
                    padding: "0px 30px",
                    verticalAlign: "top",
                  },
                },
              },
              "& > tbody": {
                "& > tr": {
                  height: 48,
                  borderBottom: `1px solid ${theme.colors.text[5]}`,
                  "&:last-of-type": {
                    borderBottom: "none",
                  },

                  "& > td": {
                    ...theme.other.fontTypes.p3,
                    fontWeight: theme.other.fontWeights.regular,
                    padding: "0px 30px",
                    position: "relative",
                  },
                },
              },
            })}
          >
            <thead>
              <tr>
                <th>Product</th>
                <th style={{ width: "170px" }}>
                  <Flex.Column align="flex-end">
                    <span>Cover</span>
                    <RpiText type="p3" weight="light" color="inherit" children="incl 20 page" />
                  </Flex.Column>
                </th>
                <th style={{ width: "170px" }}>
                  <Flex.Column align="flex-end">
                    <span>Extra pages</span>
                    <RpiText type="p3" weight="light" color="inherit" children="per side" />
                  </Flex.Column>
                </th>
              </tr>
            </thead>
            <tbody>
              {extendedProducts?.map((product, i) => (
                <tr key={`product-row-${i}`}>
                  <td children={product.name} />
                  <Box
                    component="td"
                    sx={{ textAlign: "right" }}
                    children={formatCurrency(GraphQL.SupportedCurrency.Usd, product.data?.basePriceUsd)}
                  />
                  <Box
                    component="td"
                    sx={{ textAlign: "right" }}
                    children={formatCurrency(GraphQL.SupportedCurrency.Usd, product.data?.pageCostUsd)}
                  />
                </tr>
              ))}

              {!extendedProducts &&
                Array(9)
                  .fill(0)
                  .map((_, i) => (
                    <tr key={`loading-product-row-${i}`}>
                      <td children={<Loader variant="dots" />} />
                      <td children={<Loader variant="dots" />} />
                      <td children={<Loader variant="dots" />} />
                    </tr>
                  ))}
            </tbody>
          </Box>
          <RpiText
            type="p3"
            weight="regular"
            sx={{ lineHeight: "48px" }}
            color={(theme) => theme.colors.brand[5]}
            children="Prices are rounded up. It does not include taxes and shipping."
          />
        </Flex.Column>
      </Flex.Column>
    </Flex.Column>
  );
};
