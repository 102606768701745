import { useAuthenticator } from "@aws-amplify/ui-react";
import { Center, Loader } from "@mantine/core";
import React from "react";
import { RpiAuthenticator } from "../components/authenticator/RpiAuthenticator";

export interface AuthViewProps {
  initialState?: "signIn" | "signUp" | "resetPassword";
}

export const AuthView: React.FC<AuthViewProps> = ({ initialState }) => {
  const { toSignIn, toSignUp, toResetPassword } = useAuthenticator((context) => [
    context.toSignIn,
    context.toSignUp,
    context.toResetPassword,
  ]);

  React.useEffect(() => {
    switch (initialState) {
      case "signIn":
        toSignIn();
        break;

      case "signUp":
        toSignUp();
        break;

      case "resetPassword":
        toResetPassword();
        break;

      default:
        break;
    }
  }, [initialState]);

  return (
    <Center
      sx={(theme) => ({ width: "100%", height: "100vh", position: "relative", background: theme.colors.calm[5] })}
    >
      <RpiAuthenticator initialState={initialState} signUpAttributes={["email"]} children={<Loader size="xl" />} />
    </Center>
  );
};
