import { GraphQL } from "@rpi/openapi-api";
import axios from "axios";
import { retry } from "../utils/api.util";
import { filterNullableArray, isAxiosError } from "../utils/type.util";
import { ServiceObserver } from "./ServiceObserver";

interface UploadedPart {
  PartNumber: number;
  ETag: string;
}

interface UploadedFile {
  location: string;
  file_id: string;
  file_size: number;
  collection_id: string;
  upload_status: string;
  filename: string;
  file_md5: string | null;
}

export class UploadService {
  static CHUNK_SIZE = 5 * 1024 * 1024; // 5mb

  readonly uploadCollection = ServiceObserver.bind(this._uploadCollection.bind(this));
  public async _uploadCollection(
    observer: ServiceObserver<{
      progress: number;
      status: string;
    }>,
    collection: GraphQL.UploadServiceV2CollectionsStatusResponse,
    files: File[]
  ) {
    const uploadFiles = filterNullableArray(collection.uploadFiles || []);
    if (uploadFiles.length !== files.length) throw new Error(`${UploadService.name}: Not enough files.`);

    observer.emit("progress", 0);

    let uploadedFilesCount = 0;
    return await Promise.all(
      uploadFiles.map(async (uploadData, i) => {
        const response = await this.uploadFile(collection, uploadData, files[i]);
        observer.emit("progress", ++uploadedFilesCount / uploadFiles.length);
        return response.data;
      })
    );
  }

  private async uploadFile(
    collection: GraphQL.UploadServiceV2CollectionsStatusResponse,
    uploadData: GraphQL.UploadServiceV2FileStatusResponse,
    file: File
  ) {
    if (!uploadData.multipartUpload?.parts) throw new Error(`${UploadService.name}: Missing upload information.`);
    const parts = filterNullableArray(uploadData.multipartUpload.parts);

    const createChunk = (partData: NonNullable<GraphQL.UploadServiceV2UploadPart>): Blob => {
      const start = (partData.PartNumber! - 1) * UploadService.CHUNK_SIZE;
      const end = Math.min(start + UploadService.CHUNK_SIZE, file.size);

      return file.slice(start, end);
    };

    const uploadedParts: UploadedPart[] = await Promise.all(
      parts
        .sort((a, b) => a.PartNumber! - b.PartNumber!)
        .map(async (partData) => {
          const chunk = createChunk(partData!);
          const response = await this.uploadPart(chunk, partData);

          if (!response?.headers.etag) {
            throw new Error(`${UploadService.name}: Missing ETag for part ${partData.PartNumber}.`);
          }

          return {
            PartNumber: partData.PartNumber!,
            ETag: response.headers.etag,
          };
        })
    );

    return (await this.completeFile(collection, uploadData, uploadedParts))!;
  }

  private async uploadPart(chunk: Blob, partData: GraphQL.UploadServiceV2UploadPart) {
    const apiUploadPart = async () => {
      return await axios.put(partData.uploadUrl!, chunk, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };

    return await retry(apiUploadPart, {
      retry: (retryCount, error) => {
        if (retryCount <= 5) return true;
        if (isAxiosError(error) && typeof error.status === "number" && error.status > 407) return true;
        return false;
      },
    })();
  }

  private async completeFile(
    collection: GraphQL.UploadServiceV2CollectionsStatusResponse,
    uploadData: GraphQL.UploadServiceV2FileStatusResponse,
    uploadedParts: UploadedPart[]
  ) {
    const apiCompleteFile = async () => {
      return await axios.post<UploadedFile>(collection.finishFileUrl, {
        upload_id: uploadData.multipartUpload?.uploadId,
        file_id: uploadData?.fileId,
        parts: uploadedParts,
      });
    };

    return await retry(apiCompleteFile, {
      retry: (retryCount, error) => {
        if (retryCount <= 5) return true;
        if (isAxiosError(error) && typeof error.status === "number" && error.status > 407) return true;
        return false;
      },
    })();
  }
}

export default new UploadService();
