import { GraphQL } from "@rpi/openapi-api";
import React from "react";

export interface RpiTableSortByContextProps {
  sortBy?: GraphQL.SortInput;
  setSortBy: React.Dispatch<React.SetStateAction<RpiTableSortByContextProps["sortBy"]>>;
}

export const RpiTableSortByContext = React.createContext<RpiTableSortByContextProps | null>(null);

export const useRpiTableSortBy = () => {
  return React.useContext(RpiTableSortByContext);
};

export interface RpiTableSortByProviderProps {
  children: React.ReactNode;
  defaultValue?: RpiTableSortByContextProps["sortBy"];
  onChange?: (sortBy: RpiTableSortByContextProps["sortBy"]) => any;
}

export const RpiTableSortByProvider: React.FC<RpiTableSortByProviderProps> = ({ children, defaultValue, onChange }) => {
  const [sortBy, setSortBy] = React.useState<RpiTableSortByContextProps["sortBy"]>(() => defaultValue);

  React.useEffect(() => {
    onChange && onChange(sortBy);
  }, [sortBy]);

  return <RpiTableSortByContext.Provider value={{ sortBy, setSortBy }} children={children} />;
};
