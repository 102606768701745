import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import React from "react";
import { PDFJS } from "../utils/pdf.util";
import { useRpiPagination } from "./usePagination";

export interface UsePdfPreviewProps {
  coverUrl?: string | null;
  gutsUrl: string;
}

export const usePdfPreview = ({ coverUrl, gutsUrl }: UsePdfPreviewProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<any>();

  const [gutsDocument, setGutsDocument] = React.useState<PDFDocumentProxy | null>(null);

  const [coverPage, setCoverPage] = React.useState<PDFPageProxy | null>(null);
  const [gutsFirstPage, setGutsFirstPage] = React.useState<PDFPageProxy | null>(null);
  const [gutsSecondPage, setGutsSecondPage] = React.useState<PDFPageProxy | null>(null);

  const pagination = useRpiPagination({ perPage: 1 });

  const loadDocuments = React.useCallback(async () => {
    setIsLoading(true);

    try {
      const [coverDocument, gutsDocument] = await Promise.all([
        coverUrl ? PDFJS.getDocument(coverUrl).promise : null,
        PDFJS.getDocument(gutsUrl).promise,
      ]);

      setGutsDocument(gutsDocument);
      pagination.setTotalCount(Math.ceil(gutsDocument.numPages / 2));

      if (coverDocument) {
        const coverPage = await coverDocument.getPage(1);
        setCoverPage(coverPage);
      }
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  }, [coverUrl, gutsUrl]);

  const loadGutsPages = React.useCallback(
    async (page: number) => {
      setGutsFirstPage(null);
      setGutsSecondPage(null);

      if (!gutsDocument) return;

      const firstIndex = page * 2 + 1;
      const secondIndex = firstIndex + 1;

      const [firstPage, secondPage] = await Promise.all([
        firstIndex > 0 && gutsDocument.getPage(firstIndex),
        secondIndex <= gutsDocument.numPages && gutsDocument.getPage(secondIndex),
      ]);

      if (firstPage) setGutsFirstPage(firstPage);
      if (secondPage) setGutsSecondPage(secondPage);
    },
    [gutsDocument]
  );

  React.useEffect(() => {
    loadDocuments();
  }, []);

  React.useEffect(() => {
    loadGutsPages(pagination.page);
  }, [gutsDocument, pagination.page]);

  return {
    pages: {
      cover: coverPage,
      gutsFirst: gutsFirstPage,
      gutsSecond: gutsSecondPage,
    },
    pagination,
    error,
    isLoading,
  };
};
