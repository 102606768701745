import { RpiLink, RpiLinkProps } from "@rpi/openapi-core";
import React from "react";
import {
  Link,
  LinkProps,
  Navigate,
  NavigateOptions,
  NavigateProps,
  NavLink,
  NavLinkProps,
  To,
  useNavigate,
} from "react-router-dom";
import { routePaths } from "../utils/route.util";

type RoutePaths = typeof routePaths;
export type RoutePathsKeys = keyof RoutePaths;

type RoutePathProps<T extends RoutePathsKeys> = [
  route: T,
  ...values: RoutePaths[T] extends (...args: infer P) => string ? P : [],
  ...rest: never[]
];

export function getRoutePath<T extends RoutePathsKeys>(...args: RoutePathProps<T>): string {
  const [route, ...values] = args;

  const path = routePaths[route];
  if (typeof path === "string") return path;

  // TODO: Error: A spread argument must either have a tuple type or be passed to a rest parameter.
  return (path as any)(...values);
}

export type ExtendedLinkProps<TKey extends RoutePathsKeys, TProps extends { to: To }> = Omit<TProps, "to"> &
  ({ route: RoutePathProps<TKey> } | Pick<TProps, "to">);

export type NavigateRouteProps<T extends RoutePathsKeys> = ExtendedLinkProps<T, NavigateProps>;

export function NavigateRoute<T extends RoutePathsKeys>(props: NavigateRouteProps<T>): React.ReactElement {
  if ("route" in props) {
    const { route, ...rest } = props;
    return <Navigate to={getRoutePath(...route)} {...rest} />;
  }

  return <Navigate {...props} />;
}

export function useNavigateRoute() {
  const navigate = useNavigate();

  return <T extends RoutePathsKeys>(to: RoutePathProps<T> | To | number, options?: NavigateOptions) => {
    if (typeof to === "number") {
      return navigate(to);
    }

    if (Array.isArray(to)) {
      return navigate(getRoutePath(...to), options);
    }

    return navigate(to, options);
  };
}

export type RouteLinkProps<T extends RoutePathsKeys> = ExtendedLinkProps<T, LinkProps>;

export function RouteLink<T extends RoutePathsKeys>(props: RouteLinkProps<T>): React.ReactElement {
  if ("route" in props) {
    const { route, ...rest } = props;
    return <Link to={getRoutePath(...route)} {...rest} />;
  }

  return <Link {...props} />;
}

export type NavRouteLinkProps<T extends RoutePathsKeys> = ExtendedLinkProps<T, NavLinkProps>;

export function NavRouteLink<T extends RoutePathsKeys>(props: NavRouteLinkProps<T>): React.ReactElement {
  if ("route" in props) {
    const { route, ...rest } = props;
    return <NavLink to={getRoutePath(...route)} {...rest} />;
  }

  return <NavLink {...props} />;
}

export type RpiRouteLinkProps<T extends RoutePathsKeys> = ExtendedLinkProps<T, LinkProps> & RpiLinkProps;

export function RpiRouteLink<T extends RoutePathsKeys>(props: RpiRouteLinkProps<T>): React.ReactElement {
  if ("route" in props) {
    const { route, ...rest } = props;
    return <RpiLink component={Link} to={getRoutePath(...route)} {...rest} />;
  }

  return <RpiLink component={Link} {...props} />;
}
