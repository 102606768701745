import { Translations } from "@aws-amplify/ui-components";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { AuthenticatorProps } from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import { Box, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  Flex,
  Grid,
  Icon,
  RpiButton,
  RpiCheckbox,
  RpiDropdown,
  RpiLink,
  RpiText,
  RpiTextInput,
} from "@rpi/openapi-core";
import { Auth, I18n } from "aws-amplify";
import React from "react";
import { countrySelectData } from "../../utils/country.util";
import { PublicUrl } from "../../utils/link.util";
import { LocalState } from "../../utils/state.util";
import { validateLength, validateUrl } from "../../utils/validation.util";
import "./RpiAuthenticator.css";

const CustomAtrribute = {
  SIGN_UP_FOR_EMAIL_UPDATES: "custom:signupForEmailUpdates",
};

const components: AuthenticatorProps["components"] = {
  SignIn: {
    Header() {
      return (
        <Flex.Column align="center" gap={32} mb={32}>
          <Icon.Logo size={94} />

          <RpiText type="h1" weight="bold" color={(theme) => theme.colors.sky[5]} children="Sign in" />
        </Flex.Column>
      );
    },
    Footer() {
      const { toResetPassword, toSignUp } = useAuthenticator();

      return (
        <Flex.Column align="center" gap={32} mt={32}>
          <RpiText type="p2" weight="regular" color={(theme) => theme.colors.sky[5]}>
            Don't have an account?{" "}
            <RpiLink
              variant="inherit"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={toSignUp}
              children="Sign up"
            />
          </RpiText>

          <RpiLink
            variant="underline"
            sx={(theme) => ({
              ...theme.other.fontTypes.p3,
              fontWeight: theme.other.fontWeights.regular,
              color: theme.colors.sky[5],
              cursor: "pointer",
            })}
            onClick={toResetPassword}
            children="Forgot Password?"
          />
        </Flex.Column>
      );
    },
  },
  SignUp: {
    Header() {
      return (
        <Flex.Column align="center" gap={32} mb={32}>
          <Icon.Logo size={94} />

          <RpiText type="h1" weight="bold" color={(theme) => theme.colors.sky[5]} children="Create account" />
        </Flex.Column>
      );
    },
    FormFields() {
      const theme = useMantineTheme();
      const { validationErrors } = useAuthenticator();

      const checkboxColors = React.useMemo(
        () => ({
          primary: theme.colors.sky[5],
          icon: theme.colors.brand[5],
          disabledPrimary: theme.colors.sky[3],
          disabledLabel: theme.colors.sky[4],
        }),
        [theme]
      );

      return (
        <>
          <Authenticator.SignUp.FormFields />

          <Flex.Column mt={16} gap={8}>
            <RpiCheckbox
              name="acknowledgement"
              required
              colors={checkboxColors}
              label={
                <>
                  I agree to the{" "}
                  <Box
                    component="a"
                    href={PublicUrl.TERMS_AND_CONDITIONS}
                    target="_blank"
                    sx={{ color: "inherit" }}
                    children="Terms of Service"
                  />{" "}
                  and{" "}
                  <Box
                    component="a"
                    href={PublicUrl.PRIVACY_POLICY}
                    target="_blank"
                    sx={{ color: "inherit" }}
                    children="Privacy Policy"
                  />
                </>
              }
              error={validationErrors.acknowledgement}
            />

            {/* TODO */}
            <RpiCheckbox
              colors={checkboxColors}
              name={CustomAtrribute.SIGN_UP_FOR_EMAIL_UPDATES}
              label="Keep me updated via email"
            />
          </Flex.Column>
        </>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <Flex.Column align="center" gap={32} mt={32}>
          <RpiText type="p2" weight="regular" color={(theme) => theme.colors.sky[5]}>
            Already have an account?{" "}
            <RpiLink
              variant="inherit"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={toSignIn}
              children="Sign in"
            />
          </RpiText>
        </Flex.Column>
      );
    },
  },
  ResetPassword: {
    Header() {
      return (
        <Flex.Column align="center" gap={32} mb={16}>
          <Icon.Logo size={94} />

          <RpiText type="h1" weight="bold" color={(theme) => theme.colors.sky[5]} children="Reset your password" />
        </Flex.Column>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <Flex.Column align="center" gap={32} mb={16}>
          <Icon.Logo size={94} />

          <RpiText type="h1" weight="bold" color={(theme) => theme.colors.sky[5]} children="Reset your password" />
        </Flex.Column>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      return (
        <Flex.Column align="center" gap={32} mb={16}>
          <Icon.Logo size={94} />

          <RpiText type="h1" weight="bold" color={(theme) => theme.colors.sky[5]} children="Almost finished" />
        </Flex.Column>
      );
    },
  },
};

const formFields: AuthenticatorProps["formFields"] = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: "Username",
      isRequired: true,
    },
    password: {
      labelHidden: true,
      placeholder: "Password",
      isRequired: true,
    },
  },
  confirmResetPassword: {
    password: {
      labelHidden: true,
      placeholder: "New password",
      isRequired: true,
    },
    confirm_password: {
      labelHidden: true,
      placeholder: "Confirm password",
      isRequired: true,
    },
  },
  signUp: {
    username: {
      order: 1,
    },
    email: {
      order: 2,
    },
    password: {
      order: 3,
    },
    confirm_password: {
      order: 4,
    },
  },
};

interface PersonalizedSignUpData {
  businessType: string;
  firstName: string;
  lastName: string;
  company: string;
  website: string;
  printVolume: string;
  country: string;
  shipGlobally: string;
}

const localState = new LocalState<{
  username?: string;
  signupForEmailUpdates?: string;
  personalizedSignUpData?: PersonalizedSignUpData;
}>({});

const services: AuthenticatorProps["services"] = {
  async validateCustomSignUp(formData) {
    if (formData?.acknowledgement !== "on") {
      return {
        [!formData.acknowledgement ? "hiddenAcknowledgement" : "acknowledgement"]:
          "You must agree to the Terms & Conditions",
      };
    }
  },

  async handleSignUp(formData) {
    const { username, password } = formData;

    const { [CustomAtrribute.SIGN_UP_FOR_EMAIL_UPDATES]: signupForEmailUpdates, ...attributes } = formData.attributes;

    localState.value = {
      ...localState.value,
      username,
      signupForEmailUpdates: signupForEmailUpdates === "on" ? signupForEmailUpdates : "off",
    };

    return Auth.signUp({
      username,
      password,
      attributes,
      autoSignIn: {
        enabled: true,
      },
    });
  },

  async handleConfirmSignUp(formData) {
    const { username, code } = formData;

    // TODO: Better solution to get `clientMetadata` created in `handleSignUp`
    let options: { clientMetadata: Record<string, string> } | undefined;

    if (localState.value?.username === username) {
      const { signupForEmailUpdates, personalizedSignUpData } = localState.value;

      let clientMetadata: NonNullable<typeof options>["clientMetadata"] = {};
      if (signupForEmailUpdates) clientMetadata.signupForEmailUpdates = signupForEmailUpdates;
      if (personalizedSignUpData) clientMetadata = { ...clientMetadata, ...personalizedSignUpData };

      options = { clientMetadata };
    } else {
      localState.reset();
    }

    return Auth.confirmSignUp(username, code, options).then(() => localState.reset());
  },
};

I18n.putVocabulariesForLanguage("en", {
  [Translations.SIGN_IN_TEXT]: "Sign in",
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Sign up",
  [Translations.SEND_CODE]: "Send code to my email",
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Submit",
});

export const RpiAuthenticator: React.FC<Omit<AuthenticatorProps, "components" | "formFields" | "services">> = ({
  ...props
}) => {
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const { route } = useAuthenticator((context) => [context.route]);

  React.useEffect(() => {
    setDisabled((disabled) => (route === "confirmSignUp" && disabled ? true : false));
  }, [route]);

  React.useEffect(() => {
    localState.addListener((e) => setDisabled(!!e.detail?.personalizedSignUpData));
  }, []);

  return (
    <>
      <Authenticator
        initialState="signUp"
        components={components}
        formFields={formFields}
        services={services}
        {...props}
      />

      {route === "confirmSignUp" && !disabled && <ConfirmSignUpAdditional />}
    </>
  );
};

const ConfirmSignUpAdditional: React.FC = () => {
  const theme = useMantineTheme();

  const checkboxColors = React.useMemo(
    () => ({
      primary: theme.colors.sky[5],
      icon: theme.colors.brand[5],
      disabledPrimary: theme.colors.sky[3],
      disabledLabel: theme.colors.sky[4],
    }),
    [theme]
  );

  const form = useForm<PersonalizedSignUpData>({
    initialValues: {
      businessType: "startup",
      firstName: "",
      lastName: "",
      company: "",
      website: "",
      printVolume: "",
      country: "",
      shipGlobally: "",
    },
    validate: {
      businessType: (value) => (!value ? "Please select a business type." : null),
      country: (value) => (!value ? "Please select a country." : null),
      company: validateLength("Company", { min: 3 }),
      printVolume: (value) => (!value ? "Please select print volume." : null),
      shipGlobally: (value) => (!value ? "Please select a value." : null),
      website: validateUrl,
    },
  });

  const handleSubmit = React.useCallback(async (values: typeof form.values) => {
    localState.value = { ...localState.value, personalizedSignUpData: values };
  }, []);

  return (
    <Flex.Column
      justify="center"
      align="center"
      sx={{ top: 0, left: 0, right: 0, bottom: 0, position: "absolute", zIndex: 999, background: "inherit" }}
    >
      <div data-amplify-container="true">
        <div data-amplify-router="true">
          <Flex.Column align="center" gap={32} sx={(theme) => ({ color: theme.colors.sky[5] })}>
            <Icon.Logo size={94} />

            <RpiText type="h1" weight="bold" children="We emailed you" />

            <RpiText
              type="p2"
              weight="light"
              sx={{ textAlign: "center" }}
              children="Your code is on the way to your inbox. It may take a minute to arrive. As you wait, please fill in the form below so we can better personalize your experience."
            />

            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Grid columnGap={8} rowGap={16} columns="1fr 1fr" sx={(theme) => ({ color: theme.colors.sky[5] })}>
                <Flex.Row justify="center" gap={24} mb={16} sx={{ gridColumn: "span 2" }}>
                  <RpiCheckbox
                    variant="radio"
                    name="acknowledgement"
                    colors={checkboxColors}
                    label="Startup"
                    onChange={(e) => e.target.checked && form.setFieldValue("businessType", "startup")}
                    checked={form.values.businessType === "startup"}
                  />
                  <RpiCheckbox
                    variant="radio"
                    name="acknowledgement"
                    colors={checkboxColors}
                    label="Established Business"
                    onChange={(e) => e.target.checked && form.setFieldValue("businessType", "established")}
                    checked={form.values.businessType === "established"}
                  />
                </Flex.Row>

                <RpiTextInput required placeholder="First name" {...form.getInputProps("firstName")} />
                <RpiTextInput required placeholder="Last name" {...form.getInputProps("lastName")} />

                <RpiTextInput
                  required
                  styles={{ root: { gridColumn: "span 2" } }}
                  placeholder="Company name"
                  {...form.getInputProps("company")}
                />
                <RpiTextInput
                  required
                  styles={{ root: { gridColumn: "span 2" } }}
                  placeholder="Website"
                  {...form.getInputProps("website")}
                />
                <RpiDropdown
                  required
                  fullWidth
                  styles={{ root: { gridColumn: "span 2" } }}
                  data={[
                    { label: "Less than 250 units", value: "<250" },
                    { label: "251-500 units", value: "251-500" },
                    { label: "501-1,000 units", value: "501-1000" },
                    { label: "1,001-5000 units", value: "1001-5000" },
                    { label: "5,001-10,000 units", value: "5001-10000" },
                    { label: "10,001-20,000 units", value: "10000-20000" },
                    { label: "20,001+ units", value: ">20000" },
                  ]}
                  placeholder="Antipaced first 12-month print volume"
                  {...form.getInputProps("printVolume")}
                />

                <RpiDropdown
                  required
                  fullWidth
                  data={countrySelectData}
                  placeholder="Country"
                  {...form.getInputProps("country")}
                />

                <RpiDropdown
                  required
                  fullWidth
                  data={[
                    { label: "Yes", value: "on" },
                    { label: "No", value: "off" },
                  ]}
                  placeholder="Ship globally?"
                  {...form.getInputProps("shipGlobally")}
                />

                <RpiButton
                  type="submit"
                  variant="mellow"
                  width="full"
                  trailingIcon
                  mt={16}
                  sx={{ gridColumn: "span 2" }}
                  children="Submit"
                />
              </Grid>
            </form>
          </Flex.Column>
        </div>
      </div>
    </Flex.Column>
  );
};
