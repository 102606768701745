import { Box, createStyles, Loader } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";
import { Flex } from "@rpi/openapi-core";
import React, { useRef } from "react";

const useCounterFieldStyles = createStyles<"input" | "label" | "hidden" | "root" | "error", { isError?: boolean }>(
  (theme, { isError }) => {
    return {
      root: {
        padding: "8px 24px",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: isError ? theme.colors.spark[6] : theme.colors.brand[5],
        width: "fit-content",
        background: theme.colors.sky[5],
        borderRadius: 32,
        position: "relative",
        cursor: "default",
        height: 52,
      },
      label: {
        ...theme.other.fontTypes.p2,
        fontWeight: theme.other.fontWeights.light,
        color: theme.colors.brand[5],
        lineHeight: "19.6px",
      },
      input: {
        ...theme.other.fontTypes.p2,
        fontWeight: theme.other.fontWeights.bold,
        color: theme.colors.brand[5],
        lineHeight: "19.6px",

        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        MozAppearance: "textfield",
        outline: "none",
        border: "none",
        background: "transparent",
        padding: 0,
        minWidth: 18,
        width: 18,
        textAlign: "left",
      },
      hidden: {
        ...theme.other.fontTypes.p2,
        fontWeight: theme.other.fontWeights.bold,
        color: theme.colors.brand[5],
        lineHeight: "19.6px",
        position: "absolute",
        visibility: "hidden",
      },
      error: {
        ...theme.other.fontTypes.p3,
        fontWeight: theme.other.fontWeights.regular,
        color: theme.colors.spark[6],
      },
    };
  }
);

interface CountFieldProps {
  label: string;
  value: number | undefined | string;
  onChange?: (value: number | undefined) => void;
  isLoading?: boolean;
  error?: React.ReactNode;
  disabled?: boolean;
  min?: number;
  max?: number;
}

export const CountField = React.forwardRef<HTMLInputElement, CountFieldProps>(
  ({ label, value, onChange, isLoading, error, disabled, min, max }, ref) => {
    const { classes } = useCounterFieldStyles({ isError: !!error });

    const hiddenRef = useRef<HTMLSpanElement>();
    const inputRef = useRef<HTMLInputElement>();

    const refs = useMergedRef<HTMLInputElement>(ref, inputRef as React.Ref<HTMLInputElement>);

    React.useEffect(() => {
      if (!inputRef.current || !hiddenRef.current) return;
      inputRef.current.style.width = `${hiddenRef.current.scrollWidth}px`;
    }, [value]);

    React.useEffect(() => {
      if (value && onChange) {
        let newValue: number | undefined;
        if (min !== undefined && value < min) newValue = min;
        else if (max !== undefined && value > max) newValue = max;
        else if (!Number.isSafeInteger(value)) newValue = Number.MAX_SAFE_INTEGER;

        if (newValue !== undefined) {
          onChange(newValue);
        }
      }
    }, [value, min, max, onChange]);

    return (
      <Flex.Column gap={8} align="flex-start" sx={{ width: "fit-content" }}>
        <Flex.Row
          align="center"
          gap={8}
          className={classes.root}
          onClick={!disabled ? () => inputRef.current?.focus() : undefined}
        >
          <span className={classes.label} children={label} />
          {typeof value === "string" && (
            <Box component="span" className={classes.input} sx={{ width: "fit-content" }} children={value} />
          )}
          {typeof value !== "string" && (
            <input
              ref={refs}
              type="number"
              className={classes.input}
              style={{ width: hiddenRef.current?.clientWidth, display: isLoading ? "none" : undefined }}
              value={value === undefined ? "" : value}
              disabled={disabled}
              onChange={(e) => {
                if (!onChange) return;

                let value = isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber;

                if (value) {
                  if (min !== undefined && value < min) value = min;
                  else if (max !== undefined && value > max) value = max;
                  else if (!Number.isSafeInteger(value)) value = Number.MAX_SAFE_INTEGER;
                }

                onChange(value);
              }}
            />
          )}

          {isLoading && <Loader ml={8} size={16} />}
          <Box
            component="span"
            className={classes.hidden}
            ref={hiddenRef as any}
            children={value === undefined ? "" : value}
          />
        </Flex.Row>

        {error && <span className={classes.error} children={error} />}
      </Flex.Column>
    );
  }
);
