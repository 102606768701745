import { GraphQL, GraphQLPagedQueryData, GraphQLQueryData } from "@rpi/openapi-api";
import { CellDate } from "../../components/cells/CellDate";
import { CellRoute } from "../../components/cells/CellLink";
import { CellStatus } from "../../components/cells/CellStatus";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { formatCurrency } from "../../utils/misc.util";

const orderColumn = createEntityColumn<GraphQL.OrderResponse, GraphQL.OrderSearchField>();

export const OrderColumn = {
  customerOrderId: orderColumn<"customerOrderId" | "id">({
    header: "Order ID",
    accessor: "customerOrderId",
    sortKey: GraphQL.OrderSearchField.CustomerOrderId,
    cell: ({ row }) => {
      return <CellRoute route={["order", { id: row.id }]} children={row.customerOrderId} />;
    },
    size: 200,
  }),
  id: orderColumn<"id">({
    header: "ID",
    accessor: "id",
    size: 200,
  }),
  publicOrderStatus: orderColumn<"publicOrderStatus">({
    header: "Status",
    accessor: "publicOrderStatus",
    cell: ({ row }) => <CellStatus.PublicOrder status={row.publicOrderStatus} />,
    size: 171,
  }),
  customer: orderColumn<"customer">({
    header: "Customer",
    sortKey: GraphQL.OrderSearchField.CustomerPublicId,
    cell: ({ row }) => {
      if (!row.customer?.publicId) return;
      return <CellRoute route={["customer", { publicId: row.customer.publicId }]} children={row.customer.publicId} />;
    },
    size: 150,
  }),
  createdAt: orderColumn<"createdAt">({
    header: "Created",
    accessor: "createdAt",
    sortKey: GraphQL.OrderSearchField.CreatedAt,
    cell: ({ row }) => <CellDate date={row.createdAt} />,
    size: 170,
  }),
  updatedAt: orderColumn<"updatedAt">({
    header: "Updated",
    accessor: "updatedAt",
    sortKey: GraphQL.OrderSearchField.UpdatedAt,
    cell: ({ row }) => <CellDate date={row.updatedAt} />,
    size: 170,
  }),
  submittedAt: orderColumn<"submittedAt">({
    header: "Submitted",
    accessor: "submittedAt",
    sortKey: GraphQL.OrderSearchField.SubmittedAt,
    cell: ({ row }) => <CellDate date={row.submittedAt} />,
    size: 170,
  }),
  lastStatusMessage: orderColumn<"lastStatusMessage">({
    header: "Last Status Message",
    accessor: "lastStatusMessage",
    size: 200,
  }),
  pricingItemsTotal: orderColumn<"currency" | "pricing">({
    header: "Items Total",
    id: "pricingItemsTotal",
    cell: ({ row }) => formatCurrency(row.currency, row.pricing?.itemsTotal || 0),
    size: 150,
    align: "right",
  }),
  pricingShippingTotal: orderColumn<"currency" | "pricing">({
    header: "Shipping Total",
    id: "pricingShippingTotal",
    cell: ({ row }) => formatCurrency(row.currency, row.pricing?.shippingTotal || 0),
    size: 150,
    align: "right",
  }),
  pricingOrderTotal: orderColumn<"currency" | "pricing">({
    header: "Order Total",
    id: "pricingOrderTotal",
    cell: ({ row }) => formatCurrency(row.currency, row.pricing?.orderTotal || 0),
    size: 150,
    align: "right",
  }),
  paymentStatus: orderColumn<"paymentStatus">({
    header: "Payment Status",
    accessor: "paymentStatus",
    sortKey: GraphQL.OrderSearchField.PaymentStatus,
    cell: ({ row }) => <CellStatus.Payment status={row.paymentStatus} />,
    size: 158,
  }),
};

export const orderColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.OrdersQuery>> = (user) => [
  OrderColumn.customerOrderId,
  user.is.SUPER_ADMIN && OrderColumn.customer,
  OrderColumn.publicOrderStatus,
  user.is.SUPER_ADMIN && OrderColumn.paymentStatus,
  OrderColumn.lastStatusMessage,
  OrderColumn.createdAt,
  OrderColumn.updatedAt,
  OrderColumn.submittedAt,
];

export const dashboardOrderColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.OrdersQuery>> = (user) => [
  {
    header: "Date",
    accessor: "createdAt",
    sortKey: GraphQL.OrderSearchField.CreatedAt,
    cell: ({ row }) => <CellDate date={row.createdAt} format="DD/MM/YYYY" />,
    size: 140,
  },
  OrderColumn.customerOrderId,
  user.is.SUPER_ADMIN && OrderColumn.customer,
  {
    ...OrderColumn.pricingOrderTotal,
    header: "Price",
  },
  OrderColumn.publicOrderStatus,
];

export const purchaseOrderColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.OrdersQuery>> = [
  OrderColumn.customerOrderId,
  OrderColumn.pricingItemsTotal,
  OrderColumn.pricingShippingTotal,
  OrderColumn.pricingOrderTotal,
  OrderColumn.createdAt,
  OrderColumn.updatedAt,
  OrderColumn.lastStatusMessage,
];

export const paidOrderColumns: RpiTableColumns<
  NonNullable<NonNullable<GraphQLQueryData<GraphQL.PaymentQuery>["paidOrders"]>[number]>
> = (user) => [
  OrderColumn.customerOrderId,
  OrderColumn.publicOrderStatus,
  user.is.SUPER_ADMIN && OrderColumn.customer,
  OrderColumn.createdAt,
  OrderColumn.updatedAt,
  OrderColumn.submittedAt,
  OrderColumn.lastStatusMessage,
];

export const customerOrderColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.OrdersQuery>> = [
  OrderColumn.customerOrderId,
  OrderColumn.publicOrderStatus,
  OrderColumn.paymentStatus,
  OrderColumn.lastStatusMessage,
  OrderColumn.createdAt,
  OrderColumn.updatedAt,
  OrderColumn.submittedAt,
];
