import { Box, SimpleGrid, Skeleton } from "@mantine/core";
import { Flex, IconV2, RpiIconButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { UseRpiPaginationResult } from "../hooks/usePagination";
import { optObj } from "../utils/misc.util";

interface RpiPaginationProps {
  pagination: UseRpiPaginationResult;
  centered?: boolean;
  variant?: "default" | "dashboard";
  isLoading?: boolean;
}

export const RpiPagination: React.FC<RpiPaginationProps> = ({
  pagination,
  centered: fullWidth = true,
  variant = "default",
  isLoading,
}) => {
  if (pagination.pages === 0) {
    if (isLoading) {
      return <Skeleton width="100%" height={19.59} />;
    }

    return <Box sx={{ height: 19.59 }} />;
  }

  const dots = React.useMemo(
    () => <RpiText type="p3" weight="regular" sx={{ userSelect: "none" }} children=".." />,
    []
  );

  const PaginationButton: React.FC<{ v: number }> = React.useCallback(
    ({ v }) => {
      return (
        <RpiText
          component="button"
          type="p2"
          weight={v === pagination.page ? "bold" : "regular"}
          sx={(theme) => ({
            cursor: "pointer",
            color: "inherit",
            "&:active": {
              transform: "translateY(1px)",
            },
            "&:disabled": {
              color: theme.colors.spark[3],
              transform: "none !important",
            },
            textAlign: "center",
            userSelect: "none",
            border: "none",
            background: "none",
            outline: "none",
            padding: 0,
          })}
          onClick={() => pagination.setPage(v)}
          children={v + 1}
        />
      );
    },
    [pagination]
  );

  const render = React.useMemo(() => {
    if (pagination.pages < 8) {
      return Array(pagination.pages)
        .fill(0)
        .map((_, i) => <PaginationButton key={`pagination-button-${i}`} v={i} />);
    }

    const isRight = pagination.page >= 4;
    const isLeft = pagination.page <= pagination.pages - 5;
    const isMiddle = isRight && isLeft;

    if (isMiddle) {
      return (
        <>
          <PaginationButton v={0} />
          {dots}
          <PaginationButton v={pagination.page - 1} />
          <PaginationButton v={pagination.page} />
          <PaginationButton v={pagination.page + 1} />
          {dots}
          <PaginationButton v={pagination.pages - 1} />
        </>
      );
    }

    if (isLeft) {
      return (
        <>
          <PaginationButton v={0} />
          <PaginationButton v={1} />
          <PaginationButton v={2} />
          <PaginationButton v={3} />
          <PaginationButton v={4} />
          {dots}
          <PaginationButton v={pagination.pages - 1} />
        </>
      );
    }

    if (isRight) {
      return (
        <>
          <PaginationButton v={0} />
          {dots}
          <PaginationButton v={pagination.pages - 5} />
          <PaginationButton v={pagination.pages - 4} />
          <PaginationButton v={pagination.pages - 3} />
          <PaginationButton v={pagination.pages - 2} />
          <PaginationButton v={pagination.pages - 1} />
        </>
      );
    }
  }, [pagination]);

  return (
    <Flex.Row
      gap={variant === "default" ? 14 : 8}
      align="baseline"
      justify={fullWidth ? "center" : "flex-start"}
      sx={(theme) => ({
        width: fullWidth ? "100%" : "fit-content",
        color: theme.colors.spark[5],
        position: "relative",
      })}
    >
      <ChevronButton
        variant={variant}
        direction="left"
        disabled={pagination.page === 0}
        onClick={pagination.previousPage}
      />

      <Flex.Row align="center" gap={6}>
        {render}
      </Flex.Row>

      <ChevronButton
        variant={variant}
        disabled={pagination.page === pagination.pages - 1}
        onClick={pagination.nextPage}
      />
    </Flex.Row>
  );
};

interface ChevronButtonProps {
  direction?: "left" | "right";
  disabled?: boolean;
  onClick?: () => void;
  variant: Exclude<RpiPaginationProps["variant"], undefined>;
}

const ChevronButton: React.FC<ChevronButtonProps> = ({ direction = "right", variant, ...props }) => {
  return (
    <RpiIconButton
      icon={variant === "default" ? IconV2.Arrow : IconV2.ArrowDown}
      iconProps={{
        style:
          variant === "default"
            ? optObj(direction === "left", {
                transform: "rotate(180deg)",
              })
            : direction === "left"
            ? {
                transform: "rotate(90deg)",
              }
            : {
                transform: "rotate(-90deg)",
              },
      }}
      color="currentColor"
      size={{ height: 12 }}
      sx={(theme) => ({
        color: theme.colors.spark[5],
        "&:disabled": {
          color: theme.colors.spark[3],
          cursor: "default !important",
        },
      })}
      {...props}
    />
  );
};

interface RpiPaginationRowProps {
  children: [React.ReactNode, React.ReactNode];
}

export const RpiPaginationRow: React.FC<RpiPaginationRowProps> = ({ children }) => {
  return (
    <SimpleGrid cols={3}>
      <div />
      {children[0]}
      <Flex.Row justify="flex-end" children={children[1]} />
    </SimpleGrid>
  );
};
