import { Box, Loader } from "@mantine/core";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, IconV2, RpiIconButton, RpiLink, RpiSimpleDivider, RpiText } from "@rpi/openapi-core";
import React from "react";
import { Outlet, useOutlet } from "react-router-dom";
import { AdminOnly } from "../../components/AdminOnly";
import { AppPage } from "../../components/AppPage";
import { JsonBox } from "../../components/JsonBox";
import { useAuthUser } from "../../hooks/useAuthUser";
import { useNavigateRoute } from "../../hooks/useNavigateRoute";
import { fmtDashboardUserName } from "../../utils/format.util";
import { PublicEmail, PublicUrl } from "../../utils/link.util";
import { prettyUrl } from "../../utils/misc.util";

export const SettingsView: React.FC = () => {
  const user = useAuthUser();
  const navigateRoute = useNavigateRoute();
  const outlet = useOutlet();

  const { data, isLoading, error, refetch } = GraphQL.useDashboardUserCurrentQuery();
  const dashboardUser = React.useMemo(() => data?.dashboardUserCurrent, [data]);

  React.useEffect(() => {
    if (outlet === null) refetch();
  }, [outlet]);

  return (
    <AppPage path={["Settings"]}>
      <Outlet />

      <SettingsSection
        title="Account"
        onEdit={() => navigateRoute(["settings:edit-account"])}
        isLoading={isLoading}
        error={error}
      >
        <RpiText type="p3" weight="regular" children={fmtDashboardUserName(dashboardUser || {})} />
        <RpiText type="p3" weight="regular" children={dashboardUser?.email || "<Email>"} />
      </SettingsSection>

      <RpiSimpleDivider />

      <SettingsSection title="Password" onEdit={() => navigateRoute(["settings:edit-password"])}>
        <RpiText type="p3" weight="regular" children="************" />
      </SettingsSection>

      <RpiSimpleDivider />

      <SettingsSection title="Helpful links">
        {/* TODO: FAQ */}
        <RpiText type="p3" weight="regular">
          Frequently asked questions: &nbsp;
          <RpiLink
            href={PublicUrl.DOCS_FAQS}
            sx={(theme) => ({ ...theme.other.fontTypes.p3, color: "inherit" })}
            children={prettyUrl(PublicUrl.DOCS_FAQS)}
          />
        </RpiText>

        <RpiText type="p3" weight="regular">
          Developer support: &nbsp;
          <RpiLink
            href={`mailto: ${PublicEmail.SUPPORT}`}
            target="_blank"
            sx={(theme) => ({ ...theme.other.fontTypes.p3, color: "inherit" })}
            children={PublicEmail.SUPPORT}
          />
        </RpiText>
        <RpiText type="p3" weight="regular">
          Terms & conditions: &nbsp;
          <RpiLink
            href={PublicUrl.TERMS_AND_CONDITIONS}
            target="_blank"
            sx={(theme) => ({ ...theme.other.fontTypes.p3, color: "inherit" })}
            children={prettyUrl(PublicUrl.TERMS_AND_CONDITIONS)}
          />
        </RpiText>

        <RpiText type="p3" weight="regular">
          Privacy policy: &nbsp;
          <RpiLink
            href={PublicUrl.PRIVACY_POLICY}
            target="_blank"
            sx={(theme) => ({ ...theme.other.fontTypes.p3, color: "inherit" })}
            children={prettyUrl(PublicUrl.PRIVACY_POLICY)}
          />
        </RpiText>
      </SettingsSection>

      <AdminOnly mt={32} title="Cognito User">
        <JsonBox
          collapsed={0}
          data={{
            attributes: user.cognitoUser.attributes || {},
            payload: user.cognitoUser.getSignInUserSession()?.getAccessToken().payload || {},
          }}
        />
      </AdminOnly>

      <AdminOnly mt={32} title="Dashboard User">
        <JsonBox collapsed={0} data={dashboardUser || {}} />
      </AdminOnly>
    </AppPage>
  );
};

interface SettingsSectionProps {
  title: string;
  onEdit?: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
  error?: any;
  gap?: number;
}

export const SettingsSection: React.FC<SettingsSectionProps> = ({
  title,
  gap = 24,
  onEdit,
  isLoading,
  error,
  children,
}) => {
  const body = React.useMemo(() => {
    if (isLoading || error) {
      const loader = <Loader variant="dots" color={error ? "spark" : "brand"} size="md" />;
      if (Array.isArray(children)) {
        return children.map((_, i) => (
          <Flex key={`loader-${i}`} align="center" sx={{ height: 19.6 }} children={loader} />
        ));
      }

      return loader;
    }

    return children;
  }, [isLoading, error, children]);

  return (
    <Flex.Column gap={gap} sx={(theme) => ({ color: theme.colors.brand[5] })}>
      <Flex.Row gap={24} align="center">
        <RpiText type="h3" weight="bold" children={title} />
        {onEdit && (
          <RpiIconButton
            icon={IconV2.Edit}
            color="currentColor"
            size={16}
            sx={(theme) => ({ color: theme.colors.brand[5], "&:hover": { color: theme.colors.brand[4] } })}
            onClick={onEdit}
          />
        )}
      </Flex.Row>

      <Flex.Column gap={4} children={body} />
    </Flex.Column>
  );
};
