import { useForm } from "@mantine/form";
import { GraphQL } from "@rpi/openapi-api";
import { RpiCheckbox, RpiDrawerTextInput } from "@rpi/openapi-core";
import React from "react";
import {
  createDrawerOverlay,
  createRpiDrawerView,
  RpiDrawer,
  RpiDrawerForm,
  RpiDrawerProps,
} from "../../../rpi-core/drawer/RpiDrawer";
import { validateEmail } from "../../../utils/validation.util";

export const CustomerCreateView = createRpiDrawerView<Pick<RpiDrawerProps, "opened" | "onClose">>(
  ({ opened, onClose }) => {
    const [closing, setClosing] = React.useState(false);

    const handleClose = React.useCallback(
      (delay?: boolean) => {
        if (closing) return;

        setClosing(true);
        if (delay) {
          return setTimeout(onClose, 1000);
        }
        return onClose();
      },
      [onClose, closing]
    );

    return (
      <RpiDrawer title="Add new customer" opened={opened} onClose={handleClose}>
        <CreateCustomer onClose={() => handleClose(true)} />
      </RpiDrawer>
    );
  }
);

interface CreateCustomerProps {
  onClose: () => void;
}

const CreateCustomer: React.FC<CreateCustomerProps> = ({ onClose }) => {
  const {
    mutateAsync: adminCreateCustomer,
    reset,
    ...mutation
  } = GraphQL.useAdminCreateCustomerMutation<{ message: string }>();

  const form = useForm<GraphQL.AdminCreateCustomerMutationVariables["input"]>({
    initialValues: {
      publicId: "",
      email: "",
      emailUpdates: true,
    },
    validate: {
      email: validateEmail,
    },
  });

  React.useEffect(() => reset, []);

  const handleSubmit = React.useCallback(
    async (input: typeof form.values) => {
      await adminCreateCustomer({ input });
      onClose();
    },
    [adminCreateCustomer, onClose]
  );

  const overlay = React.useMemo(
    () =>
      createDrawerOverlay({
        loading: {
          message: "Creating customer...",
          description: "Please wait, do not close this screen.",
        },
        error: {
          message: mutation.error?.message || "Failed to create customer...",
          description: "Please try again later.",
        },
        success: {
          message: "Customer created!",
        },
      })(mutation),
    [mutation]
  );

  return (
    <RpiDrawerForm button="Save" overlay={overlay} onSubmit={form.onSubmit(handleSubmit)}>
      <RpiDrawerTextInput required label="Public ID" {...form.getInputProps("publicId")} />
      <RpiDrawerTextInput label="Email" {...form.getInputProps("email")} />
      <RpiCheckbox
        mt={8}
        defaultChecked={form.values?.emailUpdates}
        label="Email notifications"
        {...form.getInputProps("emailUpdates")}
      />
    </RpiDrawerForm>
  );
};
