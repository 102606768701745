import { GraphQL } from "@rpi/openapi-api";
import { Flex, RpiButton } from "@rpi/openapi-core";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { RpiPagination, RpiPaginationRow } from "../../components/RpiPagination";
import { RpiSearchFilter } from "../../components/search-filter/RpiSearchFilter";
import { orderColumns } from "../../configuration/columns/order.column";
import { useOrderFilters } from "../../configuration/filters/order.filter";
import { useAuthUser } from "../../hooks/useAuthUser";
import { useNavigateRoute } from "../../hooks/useNavigateRoute";
import { useRpiPagination } from "../../hooks/usePagination";
import { RpiPerPage } from "../../hooks/useRpiPerPage";
import { RpiTable } from "../../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../../utils/graphql.util";

export const OrdersView: React.FC = () => {
  const navigateRoute = useNavigateRoute();
  const user = useAuthUser();
  const [searchParams] = useSearchParams();
  const currentDate = new Date();
  const oneMonthBack: Date = new Date();
  oneMonthBack.setMonth(currentDate.getMonth() - 1);

  const initialOrderSearch =
    searchParams.get("attention") === "true"
      ? [
          {
            field: GraphQL.OrderSearchField.Status,
            condition: GraphQL.SearchCondition.In,
            values: [GraphQL.PublicOrderStatus.Exception, GraphQL.PublicOrderStatus.Failed],
          },
          {
            field: GraphQL.OrderSearchField.CreatedAt,
            condition: GraphQL.SearchCondition.Between,
            firstValue: oneMonthBack.toISOString(),
            secondValue: currentDate.toISOString(),
          },
        ]
      : [];

  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.OrdersQueryVariables>>({
    by: GraphQL.OrderSearchField.CreatedAt,
    sortDirection: GraphQL.SortDirection.Desc,
  });
  const [searchInputs, setSearchInputs] =
    React.useState<SearchInputs<GraphQL.OrdersQueryVariables>>(initialOrderSearch);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.OrdersQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const orderFilters = useOrderFilters();

  const { data, isLoading, error, refetch } = GraphQL.useOrdersQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.orders.totalCount),
    }
  );
  const orders = React.useMemo(() => data?.orders.data, [data]);

  return (
    <AppPage path={["Orders"]} onReload={refetch}>
      <AppPage.Section>
        <Flex.Row justify="space-between" align="flex-end">
          <RpiSearchFilter
            placeholder="Search by Order ID"
            filters={orderFilters}
            initialInputs={initialOrderSearch}
            dateRangeField={GraphQL.OrderSearchField.CreatedAt}
            onChange={setSearchInputs}
            onSearch={setSearchGeneral}
          />

          {(user.is.CUSTOMER_ADMIN || user.is.USER) && (
            <RpiButton
              width="fit"
              variant="brand-outline"
              size="small"
              children="Pay orders now"
              trailingIcon
              onClick={() => navigateRoute(["orders:purchase"])}
            />
          )}
        </Flex.Row>

        <RpiTable
          isLoading={isLoading}
          error={error}
          perPage={pagination.perPage}
          data={orders}
          columns={orderColumns}
          emptyMessage={
            searchInputs?.length || searchGeneral ? "No orders found" : "Your order history will appear here."
          }
          withSortBy={{
            defaultValue: {
              by: GraphQL.OrderSearchField.CreatedAt,
              sortDirection: GraphQL.SortDirection.Desc,
            },
            onChange: setSortInput,
          }}
        />

        <RpiPaginationRow>
          <RpiPagination pagination={pagination} />
          <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
        </RpiPaginationRow>
      </AppPage.Section>
    </AppPage>
  );
};
