import { Flex, RpiButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useNavigateRoute } from "../../hooks/useNavigateRoute";

interface Error401ViewProps {
  /** Is root page. */
  root?: boolean;
  /** Hide redirect button. */
  hideButton?: boolean;
}

export const Error401View: React.FC<Error401ViewProps> = ({ root = true, hideButton }) => {
  const navigateRoute = useNavigateRoute();

  return (
    <Flex.Column
      gap={16}
      justify="center"
      align="center"
      sx={(theme) => ({ width: "100%", height: root ? "100vh" : "100%", color: theme.colors.brand[5] })}
    >
      <RpiText type="d1" weight="bold" children="401" />
      <RpiText type="d2" weight="roman" children="Unauthorized" />
      {!hideButton && (
        <RpiButton
          variant="brand-outline"
          size="large"
          width="fit"
          mt={16}
          onClick={() => navigateRoute(["home"])}
          children="Go Home"
        />
      )}
    </Flex.Column>
  );
};
