import React from "react";
import { useRpiPagination, UseRpiPaginationProps } from "./usePagination";

interface UsePaginatedData<T> extends UseRpiPaginationProps {
  sortFn?: (a: T, b: T) => number;
}

export function usePaginatedData<T>(initialData: T[], { sortFn, ...paginationProps }: UsePaginatedData<T>) {
  const [data, setData] = React.useState<T[]>(() => initialData || []);

  const pagination = useRpiPagination({ ...paginationProps, initialTotal: initialData.length });

  const pageData = React.useMemo(() => {
    return data.slice(pagination.offset, pagination.offset + pagination.perPage);
  }, [data, pagination.offset, pagination.perPage]);

  const handleSetData = React.useCallback(
    (data: T[]) => {
      const sortedData = sortFn ? data.sort(sortFn) : data;
      setData(sortedData);
      pagination.setTotalCount(sortedData.length);
    },
    [pagination, sortFn]
  );

  return {
    pagination,
    setData: handleSetData,
    pageData,
  };
}
