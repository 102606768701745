import { useDebouncedValue } from "@mantine/hooks";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { RpiFilterV2 } from "../../components/search-filter/RpiSearchFilterTypes";
import { useAuthUser } from "../../hooks/useAuthUser";
import { optArray } from "../../utils/misc.util";

export const useApiCredentialFilters = () => {
  const user = useAuthUser();

  const [customersSearch, setCustomersSearch] = React.useState("");
  const [debouncedCustomersSearch] = useDebouncedValue(customersSearch, 250);

  const { data, isLoading } = GraphQL.useAdminListCustomersQuery(
    { input: debouncedCustomersSearch },
    { enabled: user.is.SUPER_ADMIN }
  );
  const customers = React.useMemo(() => (data?.adminListCustomers || []).map((customer) => customer.publicId), [data]);

  return React.useMemo(() => {
    return RpiFilterV2.createConfigs<GraphQL.ApiCredentialSearchFields>([
      ...optArray<RpiFilterV2.Config<GraphQL.ApiCredentialSearchFields>>(user.is.SUPER_ADMIN, [
        {
          type: "dropdown",
          label: "Customer",
          field: GraphQL.ApiCredentialSearchFields.CustomerPublicId,
          data: customers,
          onSearch: setCustomersSearch,
          isLoading: isLoading,
        },
      ]),
      {
        type: "text",
        label: "Api Key",
        field: GraphQL.ApiCredentialSearchFields.ApiKey,
      },
      {
        type: "text",
        label: "Shared Secret",
        field: GraphQL.ApiCredentialSearchFields.SharedSecret,
      },
      {
        type: "text",
        label: "Description",
        field: GraphQL.ApiCredentialSearchFields.Description,
      },
      {
        type: "text",
        label: "Webhook Secret",
        field: GraphQL.ApiCredentialSearchFields.WebhookSecret,
      },
    ]);
  }, [user.is, customers, isLoading]);
};
