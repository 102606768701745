import { Loader } from "@mantine/core";
import { Flex, RpiButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { OverflowX } from "../../components/OverflowX";
import { isString } from "../../utils/type.util";

export interface RpiTableWrapperProps {
  dataSize?: number;
  isLoading?: boolean;
  error?: any;
  emptyMessage?:
    | string
    | {
        title: string;
        button: string;
        onClick?: () => void;
      };
  children: React.ReactNode;
}

export const RpiTableWrapper: React.FC<RpiTableWrapperProps> = ({
  dataSize,
  isLoading,
  error,
  emptyMessage,
  children,
}) => {
  const loadingOverlay = React.useMemo(() => {
    if (!isLoading && !error && dataSize) return;

    const loader = (isLoading || error) && <Loader size="xl" color={error ? "spark" : "brand"} />;

    let message: React.ReactNode;
    if (!isLoading && !error && !dataSize && emptyMessage !== undefined) {
      message = isString(emptyMessage) ? (
        <RpiText type="p3" weight="regular" children={emptyMessage} />
      ) : (
        <Flex.Column gap={24} align="center">
          <RpiText type="p3" weight="regular" children={emptyMessage.title} />
          <RpiButton
            variant="brand-outline"
            width="fit"
            size="small"
            onClick={emptyMessage.onClick}
            children={emptyMessage.button}
          />
        </Flex.Column>
      );
    }

    return (
      <Flex.Column
        align="center"
        justify="center"
        gap={8}
        sx={(theme) => ({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          ...theme.other.fontTypes.p3,
          fontWeight: theme.other.fontWeights.regular,
          background: theme.colors.sky[5],
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.colors.text[5],
        })}
      >
        {loader}
        {message}
      </Flex.Column>
    );
  }, [dataSize, isLoading, error, emptyMessage]);

  return <OverflowX rootChildren={loadingOverlay} children={children} />;
};
