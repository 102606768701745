import { useDebouncedValue } from "@mantine/hooks";
import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { RpiFilterV2 } from "../../components/search-filter/RpiSearchFilterTypes";
import { useAuthUser } from "../../hooks/useAuthUser";
import { optArray } from "../../utils/misc.util";

export const usePrintAssetFilters = () => {
  const user = useAuthUser();

  const [customersSearch, setCustomersSearch] = React.useState("");
  const [debouncedCustomersSearch] = useDebouncedValue(customersSearch, 250);

  const { data, isLoading } = GraphQL.useAdminListCustomersQuery(
    { input: debouncedCustomersSearch },
    { enabled: user.is.SUPER_ADMIN }
  );
  const customers = React.useMemo(() => (data?.adminListCustomers || []).map((customer) => customer.publicId), [data]);

  return React.useMemo(() => {
    return RpiFilterV2.createConfigs<GraphQL.PrintAssetSearchFields>([
      ...optArray<RpiFilterV2.Config<GraphQL.PrintAssetSearchFields>>(user.is.SUPER_ADMIN, [
        {
          type: "dropdown",
          label: "Customer",
          field: GraphQL.PrintAssetSearchFields.CustomerPublicId,
          data: customers,
          onSearch: setCustomersSearch,
          isLoading: isLoading,
        },
      ]),
      {
        type: "text",
        label: "Upload ID",
        field: GraphQL.PrintAssetSearchFields.PrintAssetUploadId,
      },
      {
        type: "select",
        label: "Status",
        field: GraphQL.PrintAssetSearchFields.Status,
        data: Object.values(GraphQL.PrintAssetStatus),
      },
      {
        type: "date",
        label: "Updated at",
        field: GraphQL.PrintAssetSearchFields.UpdatedAt,
      },
    ]);
  }, [user.is, customers, isLoading]);
};
