import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { GraphQL, GraphQLPagedQueryData } from "@rpi/openapi-api";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { CellDate } from "../../components/cells/CellDate";
import { CellJson } from "../../components/cells/CellJson";

const customerCommunicationColumn = createEntityColumn<
  GraphQL.CustomerCommunicationResponse,
  GraphQL.CustomerCommunicationSearchFields
>();

export const CustomerCommunicationColumn = {
  id: customerCommunicationColumn<"id">({
    header: "ID",
    accessor: "id",
    sortKey: GraphQL.CustomerCommunicationSearchFields.Id,
    cell: ({ row }) => {
      return <CellRoute route={["communication", { id: row.id }]} children={row.id} />;
    },
    size: 100,
  }),
  email: customerCommunicationColumn<"email">({
    header: "Email",
    accessor: "email",
    sortKey: GraphQL.CustomerCommunicationSearchFields.Email,
    size: 250,
  }),
  customer: customerCommunicationColumn<"customer">({
    header: "Customer",
    sortKey: GraphQL.CustomerCommunicationSearchFields.CustomerPublicId,
    cell: ({ row }) => {
      if (!row.customer?.publicId) return;
      return <CellRoute route={["customer", { publicId: row.customer.publicId }]} children={row.customer.publicId} />;
    },
    size: 200,
  }),
  createdAt: customerCommunicationColumn<"createdAt">({
    header: "Created",
    accessor: "createdAt",
    sortKey: GraphQL.CustomerCommunicationSearchFields.CreatedAt,
    cell: ({ row }) => <CellDate date={row.createdAt} />,
    size: 200,
  }),
  type: customerCommunicationColumn<"type">({
    header: "Communication type",
    accessor: "type",
    sortKey: GraphQL.CustomerCommunicationSearchFields.Type,
    size: 250,
  }),
  contents: customerCommunicationColumn<"contents">({
    header: "Contents",
    accessor: "contents",
    cell: ({ row }) => <CellJson collapsed={0} data={row.contents} />,
    size: 500,
  }),
};

export const customerCommunicationColumns: RpiTableColumns<
  GraphQLPagedQueryData<GraphQL.AdminSearchCustomerCommunicationsQuery>
> = (user) => [
  CustomerCommunicationColumn.id,
  CustomerCommunicationColumn.email,
  CustomerCommunicationColumn.customer,
  CustomerCommunicationColumn.type,
  CustomerCommunicationColumn.createdAt,
  CustomerCommunicationColumn.contents,
];
