import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppPage } from "../../components/AppPage";
import { RpiPagination, RpiPaginationRow } from "../../components/RpiPagination";
import { RpiSearchFilter } from "../../components/search-filter/RpiSearchFilter";
import { printAssetColumns } from "../../configuration/columns/printAsset.column";
import { usePrintAssetFilters } from "../../configuration/filters/printAsset.filter";
import { useRpiPagination } from "../../hooks/usePagination";
import { RpiPerPage } from "../../hooks/useRpiPerPage";
import { RpiTable } from "../../rpi-core/table/RpiTable";
import { SearchGeneral, SearchInputs, SearchSort } from "../../utils/graphql.util";

export const PrintAssetsView: React.FC = () => {
  const [sortInput, setSortInput] = React.useState<SearchSort<GraphQL.PrintAssetsQueryVariables>>();
  const [searchInputs, setSearchInputs] = React.useState<SearchInputs<GraphQL.PrintAssetsQueryVariables>>([]);
  const [searchGeneral, setSearchGeneral] = React.useState<SearchGeneral<GraphQL.PrintAssetsQueryVariables>>();

  const pagination = useRpiPagination({ perPage: 10 });

  const printAssetFilters = usePrintAssetFilters();

  const { data, isLoading, error, refetch } = GraphQL.usePrintAssetsQuery(
    {
      limit: pagination.perPage,
      offset: pagination.offset,
      search: {
        inputs: searchInputs,
        sort: sortInput,
        generalInput: searchGeneral,
      },
    },
    {
      onSuccess: (data) => pagination.setTotalCount(data.printAssets.totalCount),
    }
  );
  const printAssets = React.useMemo(() => data?.printAssets.data, [data]);

  return (
    <AppPage path={["Print Assets"]} onReload={refetch}>
      <AppPage.Section>
        <RpiSearchFilter
          placeholder="Search by Upload ID"
          filters={printAssetFilters}
          dateRangeField={GraphQL.PrintAssetSearchFields.UpdatedAt}
          onChange={setSearchInputs}
          onSearch={setSearchGeneral}
        />

        <RpiTable
          emptyMessage={
            searchInputs?.length || searchGeneral ? "No print assets found." : "Your print assets will appear here."
          }
          isLoading={isLoading}
          error={error}
          perPage={pagination.perPage}
          data={printAssets}
          columns={printAssetColumns}
          withSortBy={{ onChange: setSortInput }}
        />

        <RpiPaginationRow>
          <RpiPagination pagination={pagination} />
          <RpiPerPage perPage={pagination.perPage} setPerPage={pagination.setPerPage} />
        </RpiPaginationRow>
      </AppPage.Section>
    </AppPage>
  );
};
