import { GraphQL, GraphQLPagedQueryData, GraphQLQueryData } from "@rpi/openapi-api";
import { CellDate } from "../../components/cells/CellDate";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiTableColumns } from "../../rpi-core/table/RpiTable";
import { createEntityColumn } from "../../rpi-core/table/RpiTableHelpers";
import { formatCurrency } from "../../utils/misc.util";
import { isNumber } from "../../utils/type.util";

const paymentColumn = createEntityColumn<GraphQL.PaymentResponse, GraphQL.PaymentSearchFields>();

export const PaymentColumn = {
  id: paymentColumn<"id">({
    header: "ID",
    accessor: "id",
    sortKey: GraphQL.PaymentSearchFields.Id,
    cell: ({ row }) => {
      return <CellRoute route={["payment", { id: row.id }]} children={row.id} />;
    },
  }),
  customer: paymentColumn<"customerPublicId">({
    header: "Customer",
    accessor: "customerPublicId",
    sortKey: GraphQL.PaymentSearchFields.CustomerPublicId,
    cell: ({ row }) => {
      if (!row.customerPublicId) return;
      return <CellRoute route={["customer", { publicId: row.customerPublicId }]} children={row.customerPublicId} />;
    },
    size: 200,
  }),
  braintreeTransactionId: paymentColumn<"braintreeTransactionId">({
    header: "Braintree transaction",
    accessor: "braintreeTransactionId",
    sortKey: GraphQL.PaymentSearchFields.BraintreeTransactionId,
    size: 200,
  }),
  transactionType: paymentColumn<"transactionType" | "saleParentPaymentId">({
    header: "Type",
    accessor: "transactionType",
    sortKey: GraphQL.PaymentSearchFields.TransactionType,
    cell: ({ row }) => {
      if (row.saleParentPaymentId) {
        return (
          <CellRoute
            route={["payment", { id: row.saleParentPaymentId }]}
            children={`${row.transactionType} of ${row.saleParentPaymentId}`}
          />
        );
      }

      return row.transactionType;
    },
    size: 150,
  }),
  amount: paymentColumn<"amount" | "currency">({
    header: "Total",
    accessor: "amount",
    sortKey: GraphQL.PaymentSearchFields.Amount,
    cell: ({ row }) => isNumber(row.amount) && formatCurrency(row.currency, row.amount),
    align: "right",
    size: 200,
  }),
  date: paymentColumn<"createdAt">({
    header: "Date",
    accessor: "createdAt",
    sortKey: GraphQL.PaymentSearchFields.CreatedAt,
    cell: ({ row }) => <CellDate date={row.createdAt} />,
    size: 200,
  }),
};

export const paymentColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.PaymentsQuery>> = (user) => [
  PaymentColumn.id,
  PaymentColumn.date,
  user.is.SUPER_ADMIN && PaymentColumn.customer,
  user.is.SUPER_ADMIN && PaymentColumn.braintreeTransactionId,
  PaymentColumn.transactionType,
  PaymentColumn.amount,
];

export const customerPaymentColumns: RpiTableColumns<GraphQLPagedQueryData<GraphQL.PaymentsQuery>> = [
  PaymentColumn.id,
  PaymentColumn.date,
  PaymentColumn.braintreeTransactionId,
  PaymentColumn.transactionType,
  PaymentColumn.amount,
];

export const orderRefundColumns: RpiTableColumns<NonNullable<GraphQLQueryData<GraphQL.OrderQuery>["refunds"]>[number]> =
  paymentColumns;
