import { Anchor, Box, Button, Checkbox, Collapse, Group, Tabs, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useSetState } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, Icon } from "@rpi/openapi-core";
import { useState } from "react";
import { SelectPrintAsset } from "../../components/selects/SelectPrintAsset";
import { SelectOpenapiProvidedShippingClassification } from "../../components/selects/SelectShippingClassification";

type ReprintReplacedProductFormValue = GraphQL.ReprintReplacedAssets & { needsReplacement?: boolean };

export const convertFormToReplacedAsset = (
  order: GraphQL.OrderSingleResponse,
  empty: boolean
): Array<ReprintReplacedProductFormValue> => {
  return order?.orderItems?.map((orderItem) => ({
    orderItemId: orderItem.id,
    needsReplacement: orderItem?.orderItemStatus === GraphQL.OrderItemStatus.ProcessingFailed,
    replacedProduct: {
      coverUrl: empty ? undefined : orderItem.coverUri,
      printAssetUploadId: empty ? undefined : orderItem?.printAsset?.printAssetUploadId,
      gutsUrl: empty ? undefined : orderItem?.gutsUri,
      imageUrl: empty ? undefined : orderItem?.imageUri,
    },
  })) as Array<ReprintReplacedProductFormValue>;
};

function checkReplacedProductsErrors(
  replacedPrintAssetsForOrderItems: Array<ReprintReplacedProductFormValue>,
  printAssetOnlyCheck: boolean
) {
  const errors: Record<string, string> = {};

  replacedPrintAssetsForOrderItems.forEach((asset, index) => {
    const { replacedProduct } = asset;
    const coverUrl = replacedProduct?.coverUrl || "";
    const gutsUrl = replacedProduct?.gutsUrl || "";
    const printAssetId = replacedProduct?.printAssetUploadId || "";
    if (printAssetOnlyCheck) {
      if (!replacedProduct || printAssetId === "") {
        errors[`replacedPrintAssetsForOrderItems.${index}.replacedProduct.printAssetUploadId`] =
          "Existing printAssetUploadId must be selected";
      }
    } else {
      if (!replacedProduct || coverUrl === "" || gutsUrl === "") {
        errors[`replacedPrintAssetsForOrderItems.${index}.replacedProduct.coverUrl`] = "Cover url must be present";
        errors[`replacedPrintAssetsForOrderItems.${index}.replacedProduct.gutsUrl`] = "Guts url must be present";
      }
    }
  });

  return errors;
}

export const ReprintForm = ({ order }: { order: GraphQL.OrderSingleResponse }) => {
  const [replaceAssets, setReplaceAssets] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string | null>("files_tab");

  const form = useForm<
    Omit<GraphQL.CreateReprintInput, "replacedPrintAssetsForOrderItems"> & {
      replacedPrintAssetsForOrderItems: Array<ReprintReplacedProductFormValue>;
    }
  >({
    initialValues: {
      newShippingClassification: undefined,
      isFreeOfChange: false,
      orderId: order?.id,
      reason: "",
      replacedPrintAssetsForOrderItems: convertFormToReplacedAsset(order, true),
    },
    validate: (values) => {
      if (replaceAssets) {
        return checkReplacedProductsErrors(values.replacedPrintAssetsForOrderItems, activeTab === "print_assets_tab");
      }
      return {};
    },
  });
  const theme = useMantineTheme();
  const modals = useModals();

  const [newShippingClassificationChecked, setNewShippingClassificationChecked] = useState<boolean>(false);
  const oldPrintAssetOrderItems = convertFormToReplacedAsset(order, false);
  const [existing, setExisting] = useSetState<
    Record<number, Partial<{ coverUrl: boolean; gutsUrl: boolean; imageUrl: boolean }>>
  >({});

  const { mutateAsync: createReprint, ...createReprintMutation } = GraphQL.useReprintOrderMutation({
    onError: (error) => {
      showNotification({
        title: "Failed to create reprint order.",
        message: JSON.stringify(error),
        color: "red",
      });
    },
    onSuccess: (e) => {
      showNotification({
        title: "Reprint creation was a success",
        message: `Successfully created reprint'${order?.customer?.publicId}/${e.orderReprintCreate.customerOrderId}'.`,
        color: "green",
      });
      modals.closeAll();
    },
  });

  const [currentPrintAssetSearch, setCurrentPrintAssetSearch] = useState("");
  const { data } = GraphQL.usePrintAssetsQuery({
    limit: 10,
    offset: 0,
    search: {
      inputs: [
        {
          condition: GraphQL.SearchCondition.Contains,
          field: GraphQL.PrintAssetSearchFields.Id,
          firstValue: currentPrintAssetSearch,
        },
        {
          condition: GraphQL.SearchCondition.Contains,
          field: GraphQL.PrintAssetSearchFields.CustomerPublicId,
          firstValue: order?.customer?.publicId,
        },
      ],
      sort: { by: GraphQL.PrintAssetSearchFields.Id, sortDirection: GraphQL.SortDirection.Asc },
    },
  });

  const fields = oldPrintAssetOrderItems?.map((item, index) => {
    const isImageUrl = item?.replacedProduct?.imageUrl?.length ?? 0 > 0;
    return (
      <Box key={item?.orderItemId} mt="xs">
        <Tabs
          value={activeTab}
          onTabChange={(newTab) => {
            const currentItem = form.values.replacedPrintAssetsForOrderItems?.[index];
            const replacedItem = structuredClone(currentItem);
            if (activeTab === "files_tab") {
              replacedItem.replacedProduct.coverUrl = undefined;
              replacedItem.replacedProduct.gutsUrl = undefined;
              replacedItem.replacedProduct.imageUrl = undefined;
            } else {
              replacedItem.replacedProduct.coverUrl = existing?.[index]?.coverUrl
                ? oldPrintAssetOrderItems[index].replacedProduct?.coverUrl
                : undefined;
              replacedItem.replacedProduct.gutsUrl = existing?.[index]?.gutsUrl
                ? oldPrintAssetOrderItems[index].replacedProduct?.gutsUrl
                : undefined;
              replacedItem.replacedProduct.imageUrl = existing?.[index]?.imageUrl
                ? oldPrintAssetOrderItems[index].replacedProduct?.imageUrl
                : undefined;
              replacedItem.replacedProduct.printAssetUploadId = undefined;
            }
            form.removeListItem("replacedPrintAssetsForOrderItems", index);
            form.insertListItem(`replacedPrintAssetsForOrderItems`, replacedItem, index);
            setActiveTab(newTab);
          }}
        >
          <Text size={"sm"} px={5} color={"brand"} weight={700}>
            Order item : {item?.orderItemId}
          </Text>

          <Tabs.List grow>
            <Tabs.Tab value="files_tab">
              <Text weight={600} color={activeTab === "files_tab" ? "brand" : "gray"}>
                Replace with new files 🔗
              </Text>
            </Tabs.Tab>
            <Tabs.Tab value="print_assets_tab" color={activeTab === "print_assets_tab" ? "brand" : "gray"}>
              <Text weight={600} color={activeTab === "print_assets_tab" ? "brand" : "gray"}>
                <Flex>
                  <Box>Replace with existing PDF asset</Box>
                  <Box pt={4} pl={5}>
                    {" "}
                    <Icon.Menu.Pdf color={theme.colors.gray[6]} size={{ height: 14 }} />
                  </Box>
                </Flex>
              </Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="files_tab" py={5}>
            {isImageUrl ? (
              <TextInput
                placeholder={
                  existing?.[index]?.imageUrl
                    ? oldPrintAssetOrderItems[index].replacedProduct?.imageUrl
                    : "Insert new cover url"
                }
                disabled={existing?.[index]?.imageUrl}
                label={
                  <Checkbox
                    py={5}
                    disabled={oldPrintAssetOrderItems[index].needsReplacement}
                    checked={existing?.[index]?.imageUrl}
                    onChange={(event) => {
                      const shouldUseExistingItem: boolean = event.currentTarget.checked;
                      setExisting((current) => ({
                        [index]: {
                          ...current[index],
                          imageUrl: shouldUseExistingItem,
                        },
                      }));
                      const currentItem = form.values.replacedPrintAssetsForOrderItems?.[index];
                      const replacedItem = structuredClone(currentItem);

                      if (shouldUseExistingItem) {
                        replacedItem.replacedProduct.imageUrl =
                          oldPrintAssetOrderItems[index]?.replacedProduct?.imageUrl;
                        form.removeListItem("replacedPrintAssetsForOrderItems", index);
                        form.insertListItem(`replacedPrintAssetsForOrderItems`, replacedItem, index);
                      } else {
                        replacedItem.replacedProduct.imageUrl = undefined;
                        form.removeListItem("replacedPrintAssetsForOrderItems", index);
                        form.insertListItem(`replacedPrintAssetsForOrderItems`, replacedItem, index);
                      }
                    }}
                    label={
                      <>
                        <Text component={"span"} size={"sm"}>
                          Use existing{" "}
                        </Text>

                        <Anchor size="sm" weight={700} href={item?.replacedProduct?.imageUrl as string} target="_blank">
                          image url.
                        </Anchor>
                      </>
                    }
                  />
                }
                sx={{ flex: 1 }}
                {...form.getInputProps(`replacedPrintAssetsForOrderItems.${index}.replacedProduct.imageUrl`)}
              />
            ) : (
              <>
                <TextInput
                  placeholder={
                    existing?.[index]?.coverUrl
                      ? oldPrintAssetOrderItems[index].replacedProduct?.coverUrl
                      : "Insert new cover url"
                  }
                  disabled={existing?.[index]?.coverUrl}
                  {...form.getInputProps(`replacedPrintAssetsForOrderItems.${index}.replacedProduct.coverUrl`)}
                  label={
                    <Checkbox
                      py={5}
                      disabled={oldPrintAssetOrderItems[index].needsReplacement}
                      checked={existing?.[index]?.coverUrl}
                      onChange={(event) => {
                        const shouldUseExistingItem: boolean = event.currentTarget.checked;

                        setExisting((current) => ({
                          [index]: {
                            ...current[index],
                            coverUrl: shouldUseExistingItem,
                          },
                        }));
                        const currentItem = form.values.replacedPrintAssetsForOrderItems?.[index];
                        const replacedItem = structuredClone(currentItem);

                        if (shouldUseExistingItem) {
                          replacedItem.replacedProduct.coverUrl =
                            oldPrintAssetOrderItems[index]?.replacedProduct?.coverUrl;

                          form.removeListItem("replacedPrintAssetsForOrderItems", index);
                          form.insertListItem(`replacedPrintAssetsForOrderItems`, replacedItem, index);
                        } else {
                          replacedItem.replacedProduct.coverUrl = undefined;
                          form.removeListItem("replacedPrintAssetsForOrderItems", index);
                          form.insertListItem(`replacedPrintAssetsForOrderItems`, replacedItem, index);
                        }
                      }}
                      label={
                        <>
                          <Text component={"span"} size={"sm"}>
                            Use existing{" "}
                          </Text>

                          <Anchor
                            size="sm"
                            weight={700}
                            href={item?.replacedProduct?.coverUrl as string}
                            target="_blank"
                          >
                            cover url.
                          </Anchor>
                        </>
                      }
                    />
                  }
                  sx={{ flex: 1 }}
                  {...form.getInputProps(`replacedPrintAssetsForOrderItems.${index}.replacedProduct.coverUrl`)}
                />

                <TextInput
                  placeholder={
                    existing?.[index]?.gutsUrl
                      ? oldPrintAssetOrderItems[index].replacedProduct?.gutsUrl
                      : "Insert new guts url"
                  }
                  disabled={existing?.[index]?.gutsUrl}
                  label={
                    <Checkbox
                      py={5}
                      disabled={oldPrintAssetOrderItems[index].needsReplacement}
                      checked={existing?.[index]?.gutsUrl}
                      onChange={(event) => {
                        const shouldUseExistingItem: boolean = event.currentTarget.checked;
                        setExisting((current) => ({
                          [index]: {
                            ...current[index],
                            gutsUrl: shouldUseExistingItem,
                          },
                        }));
                        const currentItem = form.values.replacedPrintAssetsForOrderItems?.[index];
                        const replacedItem = structuredClone(currentItem);

                        if (shouldUseExistingItem) {
                          replacedItem.replacedProduct.gutsUrl =
                            oldPrintAssetOrderItems[index]?.replacedProduct?.gutsUrl;
                          form.removeListItem("replacedPrintAssetsForOrderItems", index);
                          form.insertListItem(`replacedPrintAssetsForOrderItems`, replacedItem, index);
                        } else {
                          replacedItem.replacedProduct.gutsUrl = undefined;
                          form.removeListItem("replacedPrintAssetsForOrderItems", index);
                          form.insertListItem(`replacedPrintAssetsForOrderItems`, replacedItem, index);
                        }
                      }}
                      label={
                        <>
                          <Text component={"span"} size={"sm"}>
                            Use existing{" "}
                          </Text>

                          <Anchor
                            size="sm"
                            weight={700}
                            href={item?.replacedProduct?.gutsUrl as string}
                            target="_blank"
                          >
                            guts url.
                          </Anchor>
                        </>
                      }
                    />
                  }
                  sx={{ flex: 1 }}
                  {...form.getInputProps(`replacedPrintAssetsForOrderItems.${index}.replacedProduct.gutsUrl`)}
                />
              </>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="print_assets_tab">
            <Text size={"sm"} py={5} color={"brand"} weight={700}>
              Current print asset :{" "}
              {item?.replacedProduct?.printAssetUploadId ? (
                <Anchor href={`/dashboard/print-assets/${item?.replacedProduct?.printAssetUploadId}`} target="_blank">
                  {`${order?.customer?.publicId} / ${item?.replacedProduct?.printAssetUploadId}`}
                </Anchor>
              ) : (
                "/"
              )}
            </Text>
            {activeTab === "print_assets_tab" && (
              <SelectPrintAsset
                data={data?.printAssets?.data ?? []}
                searchable
                required
                searchValue={currentPrintAssetSearch}
                onSearchChange={setCurrentPrintAssetSearch}
                filter={(value, item) =>
                  item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                  item?.description?.toLowerCase().includes(value.toLowerCase().trim())
                }
                {...form.getInputProps(`replacedPrintAssetsForOrderItems.${index}.replacedProduct.printAssetUploadId`)}
              />
            )}
          </Tabs.Panel>
        </Tabs>
      </Box>
    );
  });

  return (
    <div>
      <Box mx="auto">
        <form
          onSubmit={form.onSubmit(async (values) => {
            const reformattedProducts = values.replacedPrintAssetsForOrderItems.map((v) => {
              const { needsReplacement, ...rest } = v;
              return rest;
            });
            const input: GraphQL.CreateReprintInput = {
              ...values,
              replacedPrintAssetsForOrderItems: reformattedProducts,
            };
            await createReprint({
              input: input,
            });
          })}
        >
          <TextInput
            withAsterisk
            label="Order"
            disabled={true}
            placeholder="your@email.com"
            value={order?.customer?.publicId + "/" + order?.customerOrderId}
          />

          <TextInput
            withAsterisk
            label="Reason"
            placeholder="Enter reason for reprint"
            {...form.getInputProps("reason")}
          />

          <SelectOpenapiProvidedShippingClassification
            disabled={!newShippingClassificationChecked}
            label={
              <Flex justify={"space-between"} mb={5}>
                <Checkbox
                  mt={1}
                  checked={newShippingClassificationChecked}
                  onChange={(event) => {
                    const newValue: boolean = event?.target.checked;

                    setNewShippingClassificationChecked(event.currentTarget.checked);
                    if (!newValue) {
                      form.setFieldValue("newShippingClassification", undefined);
                    }
                  }}
                />

                <Box ml={12}>Change Shipping</Box>
              </Flex>
            }
            pt={"sm"}
            data={Object.values(GraphQL.OpenapiProvidedShippingClassification)}
            {...form.getInputProps("newShippingClassification")}
          />

          <Checkbox
            pt={"sm"}
            label="Reprint is free of charge"
            {...form.getInputProps("isFreeOfChange", { type: "checkbox" })}
          />

          <Checkbox
            pt={"sm"}
            label="Replace print assets"
            checked={replaceAssets}
            onChange={(event) => setReplaceAssets(event.currentTarget.checked)}
          />

          <Collapse in={replaceAssets}>{fields}</Collapse>

          <Group position="right" mt="md">
            <Button type="submit" loading={createReprintMutation.isLoading}>
              Submit
            </Button>
          </Group>
        </form>
      </Box>
    </div>
  );
};
