import { Box, Popover } from "@mantine/core";
import { Flex, IconV2, RpiText } from "@rpi/openapi-core";
import React from "react";
import { getStorageItem, setStorageItem, StorageKey } from "../utils/localStorage.util";

export interface UseRpiPerPageProps {
  id?: string;
  perPage: number;
}

export interface UseRpiPerPageResult {
  perPage: number;
  setPerPage: (perPage: number) => void;
}

export function useRpiPerPage({ id, perPage: initialPerPage }: UseRpiPerPageProps): UseRpiPerPageResult {
  const [perPage, setPerPage] = React.useState<number>(() => {
    const initialValue = initialPerPage || 10;
    if (!id) return initialValue;

    const storage = getStorageItem<Record<string, number>>(StorageKey.RPI_PER_PAGE);
    if (!storage || !storage[id]) return initialValue;

    return storage[id];
  });

  const handleSetPerPage: UseRpiPerPageResult["setPerPage"] = React.useCallback(
    (newPerPage) => {
      setPerPage(newPerPage);

      if (!id) return;

      const storage = getStorageItem<Record<string, number>>(StorageKey.RPI_PER_PAGE) || {};
      storage[id] = newPerPage;

      setStorageItem(StorageKey.RPI_PER_PAGE, storage);
    },
    [id]
  );

  return {
    perPage,
    setPerPage: handleSetPerPage,
  };
}

const rpiPerPageValues = [10, 15, 20, 25, 30, 35, 40, 45];

export const RpiPerPage: React.FC<UseRpiPerPageResult> = ({ perPage, setPerPage }) => {
  const [opened, setOpened] = React.useState<boolean>(false);

  const PopoverTarget = React.forwardRef<HTMLDivElement, any>(({ ...props }, ref) => {
    return (
      <Box
        ref={ref}
        onClick={() => setOpened(!opened)}
        sx={(theme) => ({
          color: theme.colors.brand[5],
          userSelect: "none",
          cursor: "pointer",
          width: "fit-content",
        })}
        {...props}
      >
        <Flex.Row align="baseline" gap={8}>
          <RpiText type="p3" weight="regular" color="inherit" children={`Show ${perPage} rows`} />

          <IconV2.ArrowDown
            color="currentColor"
            size={{ width: 10 }}
            style={{ transform: opened ? "rotate(180deg)" : "" }}
          />
        </Flex.Row>
      </Box>
    );
  });

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      offset={4}
      width={64}
      position="bottom-end"
      styles={(theme) => ({
        dropdown: {
          borderRadius: 8,
          border: "none",
          boxShadow: theme.other.boxShadows.large,
          padding: 3,
        },
      })}
    >
      <Popover.Target>
        <PopoverTarget />
      </Popover.Target>
      <Popover.Dropdown>
        <Flex.Column
          sx={(theme) => ({
            width: "100%",
            height: "100%",
            overflow: "hidden",
            color: theme.colors.brand[5],
            transition: "color 250ms",
          })}
        >
          {rpiPerPageValues.map((value, i) => {
            return (
              <Flex.Row
                key={`select-element-${i}`}
                align="center"
                justify="center"
                onClick={() => {
                  setOpened(false);
                  setPerPage(value);
                }}
                sx={(theme) => ({
                  borderRadius: 8,
                  height: 32,
                  cursor: "pointer",
                  color: "inherit",
                  flexShrink: 0,
                  "&:hover": {
                    background: theme.colors.calm[4],
                  },
                })}
              >
                <RpiText type="p3" weight="light" color="inherit" children={value} />
              </Flex.Row>
            );
          })}
        </Flex.Column>
      </Popover.Dropdown>
    </Popover>
  );
};
