import { Box, Sx } from "@mantine/core";
import React from "react";
import ReactJson, { ReactJsonViewProps } from "react-json-view";

interface JsonBoxProps extends Omit<ReactJsonViewProps, "src"> {
  data?: object;
  sx?: Sx;
}

export const JsonBox: React.FC<JsonBoxProps> = ({ data, sx, ...props }) => {
  if (!data) return <></>;
  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: theme.colors.sky[5],
          borderRadius: 1,
          width: "100%",
          padding: 12,
        }),
        sx,
      ]}
    >
      <ReactJson src={data} name={null} collapsed={1} displayDataTypes={false} {...props} />
    </Box>
  );
};
