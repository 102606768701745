import { Box, Loader } from "@mantine/core";
import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";
import { UseRpiPaginationResult } from "../../hooks/usePagination";
import { RpiPagination } from "../RpiPagination";
import { DashboardCardBase } from "./DashboardCard";

interface DashboardTableCardProps {
  title: string;
  description?: string;
  total: number;
  dataSize?: number;
  pagination: UseRpiPaginationResult;
  isLoading?: boolean;
  filterComponent?: React.ReactNode;
  children: React.ReactNode;
}

export const DashboardTableCard: React.FC<DashboardTableCardProps> = ({
  title,
  description,
  total,
  dataSize,
  pagination,
  isLoading,
  filterComponent,
  children,
}) => {
  return (
    <DashboardCardBase size="large">
      <Flex.Column gap={8}>
        <Flex.Column pl={24} gap={8} sx={(theme) => ({ color: theme.colors.brand[5] })}>
          <RpiText type="p2" weight="bold" children={title} />
          {description && <RpiText type="p3" weight="light" children={description} />}
        </Flex.Column>

        {filterComponent && <Flex.Row fullWidth justify="flex-end" sx={{ minHeight: 48 }} children={filterComponent} />}

        {children}

        <Flex.Row justify="space-between" fullWidth mt={8} px={24}>
          <Box sx={{ minHeight: 20 }}>
            {!isLoading && (
              <RpiText
                type="p3"
                weight="light"
                color={(theme) => theme.colors.brand[5]}
                children={`Showing ${dataSize || 0} of ${total} results`}
              />
            )}
            {isLoading && (
              <Flex.Row align="center" sx={{ height: 20 }}>
                <Loader variant="dots" height={24} />
              </Flex.Row>
            )}
          </Box>

          <RpiPagination variant="dashboard" pagination={pagination} centered={false} />
        </Flex.Row>
      </Flex.Column>
    </DashboardCardBase>
  );
};
