var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/graphql/export.ts
var export_exports = {};
__export(export_exports, {
  AdminCancelOrderDocument: () => AdminCancelOrderDocument,
  AdminConfigurationDocument: () => AdminConfigurationDocument,
  AdminCreateCustomerDocument: () => AdminCreateCustomerDocument,
  AdminEditCustomerDocument: () => AdminEditCustomerDocument,
  AdminEditCustomerLimitsDocument: () => AdminEditCustomerLimitsDocument,
  AdminListCustomersDocument: () => AdminListCustomersDocument,
  AdminRefundOrderDocument: () => AdminRefundOrderDocument,
  AdminReleaseFromHoldingBinDocument: () => AdminReleaseFromHoldingBinDocument,
  AdminSearchCustomerCommunicationsDocument: () => AdminSearchCustomerCommunicationsDocument,
  AdminSearchCustomersDocument: () => AdminSearchCustomersDocument,
  AdminTaxReviewDocument: () => AdminTaxReviewDocument,
  AdminUpdateOrderStatusDocument: () => AdminUpdateOrderStatusDocument,
  ApiCredentialSearchFields: () => ApiCredentialSearchFields,
  ApiCredentialsDocument: () => ApiCredentialsDocument,
  BillingCustomerDocument: () => BillingCustomerDocument,
  BlurbCorePrintFileStatus: () => BlurbCorePrintFileStatus,
  BraintreeRefundStrategy: () => BraintreeRefundStrategy,
  BraintreeTransactionStatus: () => BraintreeTransactionStatus,
  BulletinsDocument: () => BulletinsDocument,
  ChangeDashboardUserPasswordDocument: () => ChangeDashboardUserPasswordDocument,
  CreateApiCredentialDocument: () => CreateApiCredentialDocument,
  CreateBraintreePaymentDocument: () => CreateBraintreePaymentDocument,
  CreateCustomerProductDocument: () => CreateCustomerProductDocument,
  CreateDashboardUserDocument: () => CreateDashboardUserDocument,
  CreatePaymentMethodDocument: () => CreatePaymentMethodDocument,
  CreateUploadDocument: () => CreateUploadDocument,
  CustomerCommunicationDocument: () => CustomerCommunicationDocument,
  CustomerCommunicationSearchFields: () => CustomerCommunicationSearchFields,
  CustomerCommunicationType: () => CustomerCommunicationType,
  CustomerDocument: () => CustomerDocument,
  CustomerProductDocument: () => CustomerProductDocument,
  CustomerProductSearchField: () => CustomerProductSearchField,
  CustomerProductsDocument: () => CustomerProductsDocument,
  CustomerSearchFields: () => CustomerSearchFields,
  CustomerSettingsPayments: () => CustomerSettingsPayments,
  CustomerSettingsPricing: () => CustomerSettingsPricing,
  CustomerSettingsShipping: () => CustomerSettingsShipping,
  DailyMetricsDocument: () => DailyMetricsDocument,
  DailyPaymentMetricsDocument: () => DailyPaymentMetricsDocument,
  DashboardUserCurrentDocument: () => DashboardUserCurrentDocument,
  DashboardUserDocument: () => DashboardUserDocument,
  DashboardUserRole: () => DashboardUserRole,
  DashboardUserSearchFields: () => DashboardUserSearchFields,
  DashboardUsersDocument: () => DashboardUsersDocument,
  DeleteDashboardUserDocument: () => DeleteDashboardUserDocument,
  DeletePaymentMethodDocument: () => DeletePaymentMethodDocument,
  EditCustomerProductDocument: () => EditCustomerProductDocument,
  EditDashboardUserDocument: () => EditDashboardUserDocument,
  EditPaymentMethodDocument: () => EditPaymentMethodDocument,
  GetNextChargeTimeDocument: () => GetNextChargeTimeDocument,
  ListApiCredentialsDocument: () => ListApiCredentialsDocument,
  ListCustomerProductsDocument: () => ListCustomerProductsDocument,
  ListOpenApiProductsDocument: () => ListOpenApiProductsDocument,
  ListOrdersForPaymentDocument: () => ListOrdersForPaymentDocument,
  ListPaymentMethodsDocument: () => ListPaymentMethodsDocument,
  OpenApiProductDocument: () => OpenApiProductDocument,
  OpenApiProductsDocument: () => OpenApiProductsDocument,
  OpenapiProductSearchFields: () => OpenapiProductSearchFields,
  OpenapiProvidedShippingClassification: () => OpenapiProvidedShippingClassification,
  OrderDocument: () => OrderDocument,
  OrderItemDocument: () => OrderItemDocument,
  OrderItemStatus: () => OrderItemStatus,
  OrderLimiterStatus: () => OrderLimiterStatus,
  OrderSearchField: () => OrderSearchField,
  OrderStatus: () => OrderStatus,
  OrdersByPublicStatusMetricsDocument: () => OrdersByPublicStatusMetricsDocument,
  OrdersDocument: () => OrdersDocument,
  PaymentByTransactionIdDocument: () => PaymentByTransactionIdDocument,
  PaymentDocument: () => PaymentDocument,
  PaymentMode: () => PaymentMode,
  PaymentRefundStatus: () => PaymentRefundStatus,
  PaymentSearchFields: () => PaymentSearchFields,
  PaymentStatus: () => PaymentStatus,
  PaymentsDocument: () => PaymentsDocument,
  PrintAssetDocument: () => PrintAssetDocument,
  PrintAssetSearchFields: () => PrintAssetSearchFields,
  PrintAssetStatus: () => PrintAssetStatus,
  PrintAssetsDocument: () => PrintAssetsDocument,
  PublicOrderStatus: () => PublicOrderStatus,
  ReprintOrderDocument: () => ReprintOrderDocument,
  SearchCondition: () => SearchCondition,
  SortDirection: () => SortDirection,
  SupportedCurrency: () => SupportedCurrency,
  TransactionType: () => TransactionType,
  UploadFilesByPollCollectionDocument: () => UploadFilesByPollCollectionDocument,
  UploadedFileDeleteDocument: () => UploadedFileDeleteDocument,
  UploadedFileDocument: () => UploadedFileDocument,
  UploadedFileSearchFields: () => UploadedFileSearchFields,
  UploadedFileStatus: () => UploadedFileStatus,
  UploadedFilesDocument: () => UploadedFilesDocument,
  useAdminCancelOrderMutation: () => useAdminCancelOrderMutation,
  useAdminConfigurationQuery: () => useAdminConfigurationQuery,
  useAdminCreateCustomerMutation: () => useAdminCreateCustomerMutation,
  useAdminEditCustomerLimitsMutation: () => useAdminEditCustomerLimitsMutation,
  useAdminEditCustomerMutation: () => useAdminEditCustomerMutation,
  useAdminListCustomersQuery: () => useAdminListCustomersQuery,
  useAdminRefundOrderMutation: () => useAdminRefundOrderMutation,
  useAdminReleaseFromHoldingBinMutation: () => useAdminReleaseFromHoldingBinMutation,
  useAdminSearchCustomerCommunicationsQuery: () => useAdminSearchCustomerCommunicationsQuery,
  useAdminSearchCustomersQuery: () => useAdminSearchCustomersQuery,
  useAdminTaxReviewMutation: () => useAdminTaxReviewMutation,
  useAdminUpdateOrderStatusMutation: () => useAdminUpdateOrderStatusMutation,
  useApiCredentialsQuery: () => useApiCredentialsQuery,
  useBillingCustomerQuery: () => useBillingCustomerQuery,
  useBulletinsQuery: () => useBulletinsQuery,
  useChangeDashboardUserPasswordMutation: () => useChangeDashboardUserPasswordMutation,
  useCreateApiCredentialMutation: () => useCreateApiCredentialMutation,
  useCreateBraintreePaymentMutation: () => useCreateBraintreePaymentMutation,
  useCreateCustomerProductMutation: () => useCreateCustomerProductMutation,
  useCreateDashboardUserMutation: () => useCreateDashboardUserMutation,
  useCreatePaymentMethodMutation: () => useCreatePaymentMethodMutation,
  useCreateUploadMutation: () => useCreateUploadMutation,
  useCustomerCommunicationQuery: () => useCustomerCommunicationQuery,
  useCustomerProductQuery: () => useCustomerProductQuery,
  useCustomerProductsQuery: () => useCustomerProductsQuery,
  useCustomerQuery: () => useCustomerQuery,
  useDailyMetricsQuery: () => useDailyMetricsQuery,
  useDailyPaymentMetricsQuery: () => useDailyPaymentMetricsQuery,
  useDashboardUserCurrentQuery: () => useDashboardUserCurrentQuery,
  useDashboardUserQuery: () => useDashboardUserQuery,
  useDashboardUsersQuery: () => useDashboardUsersQuery,
  useDeleteDashboardUserMutation: () => useDeleteDashboardUserMutation,
  useDeletePaymentMethodMutation: () => useDeletePaymentMethodMutation,
  useEditCustomerProductMutation: () => useEditCustomerProductMutation,
  useEditDashboardUserMutation: () => useEditDashboardUserMutation,
  useEditPaymentMethodMutation: () => useEditPaymentMethodMutation,
  useGetNextChargeTimeQuery: () => useGetNextChargeTimeQuery,
  useListApiCredentialsQuery: () => useListApiCredentialsQuery,
  useListCustomerProductsQuery: () => useListCustomerProductsQuery,
  useListOpenApiProductsQuery: () => useListOpenApiProductsQuery,
  useListOrdersForPaymentQuery: () => useListOrdersForPaymentQuery,
  useListPaymentMethodsQuery: () => useListPaymentMethodsQuery,
  useOpenApiProductQuery: () => useOpenApiProductQuery,
  useOpenApiProductsQuery: () => useOpenApiProductsQuery,
  useOrderItemQuery: () => useOrderItemQuery,
  useOrderQuery: () => useOrderQuery,
  useOrdersByPublicStatusMetricsQuery: () => useOrdersByPublicStatusMetricsQuery,
  useOrdersQuery: () => useOrdersQuery,
  usePaymentByTransactionIdQuery: () => usePaymentByTransactionIdQuery,
  usePaymentQuery: () => usePaymentQuery,
  usePaymentsQuery: () => usePaymentsQuery,
  usePrintAssetQuery: () => usePrintAssetQuery,
  usePrintAssetsQuery: () => usePrintAssetsQuery,
  useReprintOrderMutation: () => useReprintOrderMutation,
  useUploadFilesByPollCollectionQuery: () => useUploadFilesByPollCollectionQuery,
  useUploadedFileDeleteMutation: () => useUploadedFileDeleteMutation,
  useUploadedFileQuery: () => useUploadedFileQuery,
  useUploadedFilesQuery: () => useUploadedFilesQuery
});

// src/graphql/generated.ts
import { useMutation, useQuery } from "@tanstack/react-query";

// src/client.tsx
import axios from "axios";
import React2 from "react";

// src/provider.tsx
import React from "react";
var RpiApiContext = React.createContext(null);
var RpiApiProvider = RpiApiContext.Provider;

// src/client.tsx
var restClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_PRINTAPI_ENDPOINT_URL,
  headers: {
    "Content-Type": "application/json"
  }
});
var publicRestClient = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});
var graphqlClient = axios.create({
  baseURL: process.env.REACT_APP_GRAPHQL_CLIENT_URL,
  headers: {
    "Content-Type": "application/json"
  }
});
async function rpiGraphqlRequest(query, variables, headers) {
  return graphqlClient.post(
    "",
    { query, variables },
    {
      headers
    }
  ).then((res) => {
    var _a;
    if (res.data.errors) {
      const errors = res.data.errors.map(
        (error) => new CustomGraphQLError(query, variables, error)
      );
      throw errors[0];
    }
    return (_a = res.data) == null ? void 0 : _a.data;
  });
}
var useRpiGraphqlRequest = (query, options) => {
  const rpiApiContext = React2.useContext(RpiApiContext);
  if (!rpiApiContext) {
    throw new Error("useRpiGraphqlRequest must be used within a RpiApiProvider");
  }
  return async (variables) => {
    let contextHeaders;
    if (rpiApiContext.headers) {
      if (typeof rpiApiContext.headers === "function") {
        contextHeaders = await Promise.resolve(rpiApiContext.headers());
      } else {
        contextHeaders = rpiApiContext.headers;
      }
    }
    return rpiGraphqlRequest(query, variables, { ...contextHeaders, ...options });
  };
};
var isGraphQLError = (error) => {
  return error instanceof CustomGraphQLError;
};
var CustomGraphQLError = class extends Error {
  constructor(query, variables, data) {
    super(data.message);
    this.name = "GraphQLError";
    this.query = query.trim();
    this.variables = variables;
    this.data = data;
    if (process.env.NODE_ENV === "development") {
      this.print();
    }
  }
  print() {
    console.error(
      "Dev error:\n`" + this.message + "`\n```\n" + this.query + "\n```\n```\n" + JSON.stringify(this.variables || {}, null, 2) + "\n```"
    );
  }
};

// src/graphql/generated.ts
var ApiCredentialSearchFields = /* @__PURE__ */ ((ApiCredentialSearchFields2) => {
  ApiCredentialSearchFields2["ApiKey"] = "apiKey";
  ApiCredentialSearchFields2["CustomerPublicId"] = "customerPublicId";
  ApiCredentialSearchFields2["Description"] = "description";
  ApiCredentialSearchFields2["SharedSecret"] = "sharedSecret";
  ApiCredentialSearchFields2["WebhookSecret"] = "webhookSecret";
  return ApiCredentialSearchFields2;
})(ApiCredentialSearchFields || {});
var BlurbCorePrintFileStatus = /* @__PURE__ */ ((BlurbCorePrintFileStatus2) => {
  BlurbCorePrintFileStatus2["Failure"] = "FAILURE";
  BlurbCorePrintFileStatus2["Processing"] = "PROCESSING";
  BlurbCorePrintFileStatus2["Success"] = "SUCCESS";
  BlurbCorePrintFileStatus2["Waiting"] = "WAITING";
  return BlurbCorePrintFileStatus2;
})(BlurbCorePrintFileStatus || {});
var BraintreeRefundStrategy = /* @__PURE__ */ ((BraintreeRefundStrategy2) => {
  BraintreeRefundStrategy2["OrderFullValue"] = "ORDER_FULL_VALUE";
  BraintreeRefundStrategy2["OrderPartialValue"] = "ORDER_PARTIAL_VALUE";
  BraintreeRefundStrategy2["OrderPercentageZeroToOne"] = "ORDER_PERCENTAGE_ZERO_TO_ONE";
  BraintreeRefundStrategy2["ShippingOnly"] = "SHIPPING_ONLY";
  return BraintreeRefundStrategy2;
})(BraintreeRefundStrategy || {});
var BraintreeTransactionStatus = /* @__PURE__ */ ((BraintreeTransactionStatus2) => {
  BraintreeTransactionStatus2["AuthorizationExpired"] = "AUTHORIZATION_EXPIRED";
  BraintreeTransactionStatus2["Authorized"] = "AUTHORIZED";
  BraintreeTransactionStatus2["Authorizing"] = "AUTHORIZING";
  BraintreeTransactionStatus2["Failed"] = "FAILED";
  BraintreeTransactionStatus2["GatewayRejected"] = "GATEWAY_REJECTED";
  BraintreeTransactionStatus2["ProcessorDeclined"] = "PROCESSOR_DECLINED";
  BraintreeTransactionStatus2["Settled"] = "SETTLED";
  BraintreeTransactionStatus2["SettlementConfirmed"] = "SETTLEMENT_CONFIRMED";
  BraintreeTransactionStatus2["SettlementDeclined"] = "SETTLEMENT_DECLINED";
  BraintreeTransactionStatus2["SettlementPending"] = "SETTLEMENT_PENDING";
  BraintreeTransactionStatus2["Settling"] = "SETTLING";
  BraintreeTransactionStatus2["SubmittedForSettlement"] = "SUBMITTED_FOR_SETTLEMENT";
  BraintreeTransactionStatus2["Unrecognized"] = "UNRECOGNIZED";
  BraintreeTransactionStatus2["Voided"] = "VOIDED";
  return BraintreeTransactionStatus2;
})(BraintreeTransactionStatus || {});
var CustomerCommunicationSearchFields = /* @__PURE__ */ ((CustomerCommunicationSearchFields2) => {
  CustomerCommunicationSearchFields2["CreatedAt"] = "createdAt";
  CustomerCommunicationSearchFields2["CustomerPublicId"] = "customerPublicId";
  CustomerCommunicationSearchFields2["Email"] = "email";
  CustomerCommunicationSearchFields2["Id"] = "id";
  CustomerCommunicationSearchFields2["Type"] = "type";
  return CustomerCommunicationSearchFields2;
})(CustomerCommunicationSearchFields || {});
var CustomerCommunicationType = /* @__PURE__ */ ((CustomerCommunicationType2) => {
  CustomerCommunicationType2["SharedIdea"] = "SHARED_IDEA";
  CustomerCommunicationType2["SignupSurvey"] = "SIGNUP_SURVEY";
  return CustomerCommunicationType2;
})(CustomerCommunicationType || {});
var CustomerProductSearchField = /* @__PURE__ */ ((CustomerProductSearchField2) => {
  CustomerProductSearchField2["CustomerPublicId"] = "customerPublicId";
  CustomerProductSearchField2["CustomerSku"] = "customerSku";
  CustomerProductSearchField2["Description"] = "description";
  CustomerProductSearchField2["Id"] = "id";
  CustomerProductSearchField2["Name"] = "name";
  CustomerProductSearchField2["OpenapiProductId"] = "openapiProductId";
  return CustomerProductSearchField2;
})(CustomerProductSearchField || {});
var CustomerSearchFields = /* @__PURE__ */ ((CustomerSearchFields2) => {
  CustomerSearchFields2["CreatedAt"] = "createdAt";
  CustomerSearchFields2["Email"] = "email";
  CustomerSearchFields2["Id"] = "id";
  CustomerSearchFields2["PublicId"] = "publicId";
  return CustomerSearchFields2;
})(CustomerSearchFields || {});
var CustomerSettingsPayments = /* @__PURE__ */ ((CustomerSettingsPayments2) => {
  CustomerSettingsPayments2["External"] = "EXTERNAL";
  CustomerSettingsPayments2["Scheduled"] = "SCHEDULED";
  return CustomerSettingsPayments2;
})(CustomerSettingsPayments || {});
var CustomerSettingsPricing = /* @__PURE__ */ ((CustomerSettingsPricing2) => {
  CustomerSettingsPricing2["External"] = "EXTERNAL";
  CustomerSettingsPricing2["Openapi"] = "OPENAPI";
  return CustomerSettingsPricing2;
})(CustomerSettingsPricing || {});
var CustomerSettingsShipping = /* @__PURE__ */ ((CustomerSettingsShipping2) => {
  CustomerSettingsShipping2["Flat"] = "FLAT";
  CustomerSettingsShipping2["ShippingService"] = "SHIPPING_SERVICE";
  return CustomerSettingsShipping2;
})(CustomerSettingsShipping || {});
var DashboardUserRole = /* @__PURE__ */ ((DashboardUserRole2) => {
  DashboardUserRole2["CustomerAdmin"] = "CUSTOMER_ADMIN";
  DashboardUserRole2["SuperAdmin"] = "SUPER_ADMIN";
  DashboardUserRole2["User"] = "USER";
  return DashboardUserRole2;
})(DashboardUserRole || {});
var DashboardUserSearchFields = /* @__PURE__ */ ((DashboardUserSearchFields2) => {
  DashboardUserSearchFields2["CreatedAt"] = "createdAt";
  DashboardUserSearchFields2["CustomerPublicId"] = "customerPublicId";
  DashboardUserSearchFields2["Disabled"] = "disabled";
  DashboardUserSearchFields2["Email"] = "email";
  DashboardUserSearchFields2["Id"] = "id";
  DashboardUserSearchFields2["Role"] = "role";
  DashboardUserSearchFields2["Username"] = "username";
  return DashboardUserSearchFields2;
})(DashboardUserSearchFields || {});
var OpenapiProductSearchFields = /* @__PURE__ */ ((OpenapiProductSearchFields2) => {
  OpenapiProductSearchFields2["Description"] = "description";
  OpenapiProductSearchFields2["Id"] = "id";
  OpenapiProductSearchFields2["Name"] = "name";
  OpenapiProductSearchFields2["OpenapiSku"] = "openapiSku";
  OpenapiProductSearchFields2["UosMediaOption"] = "uosMediaOption";
  OpenapiProductSearchFields2["UosOrientation"] = "uosOrientation";
  OpenapiProductSearchFields2["UosSku"] = "uosSku";
  return OpenapiProductSearchFields2;
})(OpenapiProductSearchFields || {});
var OpenapiProvidedShippingClassification = /* @__PURE__ */ ((OpenapiProvidedShippingClassification2) => {
  OpenapiProvidedShippingClassification2["Economy"] = "ECONOMY";
  OpenapiProvidedShippingClassification2["Express"] = "EXPRESS";
  OpenapiProvidedShippingClassification2["Priority"] = "PRIORITY";
  OpenapiProvidedShippingClassification2["Standard"] = "STANDARD";
  return OpenapiProvidedShippingClassification2;
})(OpenapiProvidedShippingClassification || {});
var OrderItemStatus = /* @__PURE__ */ ((OrderItemStatus2) => {
  OrderItemStatus2["AwaitingProcessing"] = "AWAITING_PROCESSING";
  OrderItemStatus2["PreflightSuccess"] = "PREFLIGHT_SUCCESS";
  OrderItemStatus2["Processing"] = "PROCESSING";
  OrderItemStatus2["ProcessingFailed"] = "PROCESSING_FAILED";
  OrderItemStatus2["ProcessingSuccess"] = "PROCESSING_SUCCESS";
  return OrderItemStatus2;
})(OrderItemStatus || {});
var OrderLimiterStatus = /* @__PURE__ */ ((OrderLimiterStatus2) => {
  OrderLimiterStatus2["LimitApplies"] = "LIMIT_APPLIES";
  OrderLimiterStatus2["NotLimitedReprint"] = "NOT_LIMITED_REPRINT";
  OrderLimiterStatus2["NotLimitedStaff"] = "NOT_LIMITED_STAFF";
  OrderLimiterStatus2["NoLimit"] = "NO_LIMIT";
  return OrderLimiterStatus2;
})(OrderLimiterStatus || {});
var OrderSearchField = /* @__PURE__ */ ((OrderSearchField2) => {
  OrderSearchField2["CreatedAt"] = "createdAt";
  OrderSearchField2["CustomerOrderId"] = "customerOrderId";
  OrderSearchField2["CustomerPublicId"] = "customerPublicId";
  OrderSearchField2["Id"] = "id";
  OrderSearchField2["PaymentStatus"] = "paymentStatus";
  OrderSearchField2["Status"] = "status";
  OrderSearchField2["SubmittedAt"] = "submittedAt";
  OrderSearchField2["UpdatedAt"] = "updatedAt";
  return OrderSearchField2;
})(OrderSearchField || {});
var OrderStatus = /* @__PURE__ */ ((OrderStatus2) => {
  OrderStatus2["AssetsProcessed"] = "ASSETS_PROCESSED";
  OrderStatus2["CancellationAttempted"] = "CANCELLATION_ATTEMPTED";
  OrderStatus2["CancellationRequestSentToPrinter"] = "CANCELLATION_REQUEST_SENT_TO_PRINTER";
  OrderStatus2["Cancelled"] = "CANCELLED";
  OrderStatus2["CancelledBeforeSubmission"] = "CANCELLED_BEFORE_SUBMISSION";
  OrderStatus2["Created"] = "CREATED";
  OrderStatus2["Exception"] = "EXCEPTION";
  OrderStatus2["Failed"] = "FAILED";
  OrderStatus2["PricingCompleted"] = "PRICING_COMPLETED";
  OrderStatus2["Received"] = "RECEIVED";
  OrderStatus2["ReceivedOverLimit"] = "RECEIVED_OVER_LIMIT";
  OrderStatus2["Rejected"] = "REJECTED";
  OrderStatus2["Shipped"] = "SHIPPED";
  OrderStatus2["Submitted"] = "SUBMITTED";
  OrderStatus2["Submitting"] = "SUBMITTING";
  OrderStatus2["WaitingForSubmission"] = "WAITING_FOR_SUBMISSION";
  OrderStatus2["WaitingForTaxes"] = "WAITING_FOR_TAXES";
  OrderStatus2["WaitingInHoldingBin"] = "WAITING_IN_HOLDING_BIN";
  return OrderStatus2;
})(OrderStatus || {});
var PaymentMode = /* @__PURE__ */ ((PaymentMode2) => {
  PaymentMode2["BlurbCoreInternal"] = "BLURB_CORE_INTERNAL";
  PaymentMode2["DailyCharge"] = "DAILY_CHARGE";
  PaymentMode2["ExternallyPaid"] = "EXTERNALLY_PAID";
  PaymentMode2["Immediate"] = "IMMEDIATE";
  return PaymentMode2;
})(PaymentMode || {});
var PaymentRefundStatus = /* @__PURE__ */ ((PaymentRefundStatus2) => {
  PaymentRefundStatus2["Failed"] = "FAILED";
  PaymentRefundStatus2["Pending"] = "PENDING";
  PaymentRefundStatus2["SuccessfulRefund"] = "SUCCESSFUL_REFUND";
  PaymentRefundStatus2["SuccessfulVoid"] = "SUCCESSFUL_VOID";
  return PaymentRefundStatus2;
})(PaymentRefundStatus || {});
var PaymentSearchFields = /* @__PURE__ */ ((PaymentSearchFields2) => {
  PaymentSearchFields2["Amount"] = "amount";
  PaymentSearchFields2["BraintreeTransactionId"] = "braintreeTransactionId";
  PaymentSearchFields2["CreatedAt"] = "createdAt";
  PaymentSearchFields2["CustomerPublicId"] = "customerPublicId";
  PaymentSearchFields2["Id"] = "id";
  PaymentSearchFields2["TransactionType"] = "transactionType";
  PaymentSearchFields2["UpdatedAt"] = "updatedAt";
  return PaymentSearchFields2;
})(PaymentSearchFields || {});
var PaymentStatus = /* @__PURE__ */ ((PaymentStatus2) => {
  PaymentStatus2["ExternallyPaid"] = "EXTERNALLY_PAID";
  PaymentStatus2["NoPaymentStaffOrder"] = "NO_PAYMENT_STAFF_ORDER";
  PaymentStatus2["Paid"] = "PAID";
  PaymentStatus2["Unpaid"] = "UNPAID";
  return PaymentStatus2;
})(PaymentStatus || {});
var PrintAssetSearchFields = /* @__PURE__ */ ((PrintAssetSearchFields2) => {
  PrintAssetSearchFields2["CustomerPublicId"] = "customerPublicId";
  PrintAssetSearchFields2["Id"] = "id";
  PrintAssetSearchFields2["PrintAssetUploadId"] = "printAssetUploadId";
  PrintAssetSearchFields2["Status"] = "status";
  PrintAssetSearchFields2["UpdatedAt"] = "updatedAt";
  return PrintAssetSearchFields2;
})(PrintAssetSearchFields || {});
var PrintAssetStatus = /* @__PURE__ */ ((PrintAssetStatus2) => {
  PrintAssetStatus2["AwaitingInput"] = "AWAITING_INPUT";
  PrintAssetStatus2["Enqueued"] = "ENQUEUED";
  PrintAssetStatus2["Failure"] = "FAILURE";
  PrintAssetStatus2["Invalid"] = "INVALID";
  PrintAssetStatus2["Processing"] = "PROCESSING";
  PrintAssetStatus2["Purged"] = "PURGED";
  PrintAssetStatus2["Success"] = "SUCCESS";
  return PrintAssetStatus2;
})(PrintAssetStatus || {});
var PublicOrderStatus = /* @__PURE__ */ ((PublicOrderStatus2) => {
  PublicOrderStatus2["CancellationRequested"] = "CANCELLATION_REQUESTED";
  PublicOrderStatus2["Cancelled"] = "CANCELLED";
  PublicOrderStatus2["Exception"] = "EXCEPTION";
  PublicOrderStatus2["Failed"] = "FAILED";
  PublicOrderStatus2["Printing"] = "PRINTING";
  PublicOrderStatus2["Queued"] = "QUEUED";
  PublicOrderStatus2["Received"] = "RECEIVED";
  PublicOrderStatus2["Rejected"] = "REJECTED";
  PublicOrderStatus2["Shipped"] = "SHIPPED";
  PublicOrderStatus2["Validating"] = "VALIDATING";
  PublicOrderStatus2["ValidAwaitingPayment"] = "VALID_AWAITING_PAYMENT";
  PublicOrderStatus2["ValidHoldingBin"] = "VALID_HOLDING_BIN";
  return PublicOrderStatus2;
})(PublicOrderStatus || {});
var SearchCondition = /* @__PURE__ */ ((SearchCondition2) => {
  SearchCondition2["Between"] = "BETWEEN";
  SearchCondition2["Contains"] = "CONTAINS";
  SearchCondition2["Equals"] = "EQUALS";
  SearchCondition2["In"] = "IN";
  SearchCondition2["NotEquals"] = "NOT_EQUALS";
  return SearchCondition2;
})(SearchCondition || {});
var SortDirection = /* @__PURE__ */ ((SortDirection2) => {
  SortDirection2["Asc"] = "ASC";
  SortDirection2["Desc"] = "DESC";
  return SortDirection2;
})(SortDirection || {});
var SupportedCurrency = /* @__PURE__ */ ((SupportedCurrency2) => {
  SupportedCurrency2["Aud"] = "AUD";
  SupportedCurrency2["Cad"] = "CAD";
  SupportedCurrency2["Eur"] = "EUR";
  SupportedCurrency2["Gbp"] = "GBP";
  SupportedCurrency2["Usd"] = "USD";
  return SupportedCurrency2;
})(SupportedCurrency || {});
var TransactionType = /* @__PURE__ */ ((TransactionType2) => {
  TransactionType2["Refund"] = "REFUND";
  TransactionType2["Sale"] = "SALE";
  TransactionType2["Void"] = "VOID";
  return TransactionType2;
})(TransactionType || {});
var UploadedFileSearchFields = /* @__PURE__ */ ((UploadedFileSearchFields2) => {
  UploadedFileSearchFields2["CollectionId"] = "collectionId";
  UploadedFileSearchFields2["CreatedAt"] = "createdAt";
  UploadedFileSearchFields2["CustomerPublicId"] = "customerPublicId";
  UploadedFileSearchFields2["FileName"] = "fileName";
  UploadedFileSearchFields2["FileType"] = "fileType";
  UploadedFileSearchFields2["Id"] = "id";
  UploadedFileSearchFields2["IsTaxDocument"] = "isTaxDocument";
  UploadedFileSearchFields2["Status"] = "status";
  UploadedFileSearchFields2["UpdatedAt"] = "updatedAt";
  return UploadedFileSearchFields2;
})(UploadedFileSearchFields || {});
var UploadedFileStatus = /* @__PURE__ */ ((UploadedFileStatus2) => {
  UploadedFileStatus2["Failure"] = "FAILURE";
  UploadedFileStatus2["Finished"] = "FINISHED";
  UploadedFileStatus2["Started"] = "STARTED";
  return UploadedFileStatus2;
})(UploadedFileStatus || {});
var CreateApiCredentialDocument = `
    mutation CreateApiCredential($input: ApiCredentialsInput) {
  apiCredentialsCreate(apiCredentialsInput: $input) {
    id
  }
}
    `;
var useCreateApiCredentialMutation = (options) => useMutation(
  ["CreateApiCredential"],
  useRpiGraphqlRequest(
    CreateApiCredentialDocument
  ),
  options
);
var AdminRefundOrderDocument = `
    mutation AdminRefundOrder($input: BraintreeRefundInput, $customerPublicId: String!) {
  adminRefundOrder(
    braintreeRefundInput: $input
    customerPublicId: $customerPublicId
  ) {
    message
  }
}
    `;
var useAdminRefundOrderMutation = (options) => useMutation(
  ["AdminRefundOrder"],
  useRpiGraphqlRequest(AdminRefundOrderDocument),
  options
);
var CreateBraintreePaymentDocument = `
    mutation CreateBraintreePayment($input: BraintreeCheckoutInput) {
  braintreePaymentCreate(braintreeCheckoutInput: $input) {
    message
    braintreeTransactionId
  }
}
    `;
var useCreateBraintreePaymentMutation = (options) => useMutation(
  ["CreateBraintreePayment"],
  useRpiGraphqlRequest(
    CreateBraintreePaymentDocument
  ),
  options
);
var CreateCustomerProductDocument = `
    mutation CreateCustomerProduct($input: CreateCustomerProductInput) {
  customerProductCreate(createCustomerProductInput: $input) {
    id
  }
}
    `;
var useCreateCustomerProductMutation = (options) => useMutation(
  ["CreateCustomerProduct"],
  useRpiGraphqlRequest(
    CreateCustomerProductDocument
  ),
  options
);
var EditCustomerProductDocument = `
    mutation EditCustomerProduct($id: Int!, $input: EditCustomerProductInput) {
  customerProductEdit(id: $id, editCustomerProductInput: $input) {
    id
  }
}
    `;
var useEditCustomerProductMutation = (options) => useMutation(
  ["EditCustomerProduct"],
  useRpiGraphqlRequest(
    EditCustomerProductDocument
  ),
  options
);
var AdminCreateCustomerDocument = `
    mutation AdminCreateCustomer($input: CustomerInput) {
  adminCreateCustomer(createCustomerInput: $input) {
    id
  }
}
    `;
var useAdminCreateCustomerMutation = (options) => useMutation(
  ["AdminCreateCustomer"],
  useRpiGraphqlRequest(
    AdminCreateCustomerDocument
  ),
  options
);
var AdminEditCustomerDocument = `
    mutation AdminEditCustomer($input: EditCustomerInput) {
  adminEditCustomer(editCustomerInput: $input) {
    id
  }
}
    `;
var useAdminEditCustomerMutation = (options) => useMutation(
  ["AdminEditCustomer"],
  useRpiGraphqlRequest(AdminEditCustomerDocument),
  options
);
var AdminEditCustomerLimitsDocument = `
    mutation AdminEditCustomerLimits($customerPublicId: String!, $input: CustomerSettingsOrdersLimit) {
  adminEditCustomerLimits(
    customerPublicId: $customerPublicId
    customerSettingsOrdersLimit: $input
  ) {
    id
  }
}
    `;
var useAdminEditCustomerLimitsMutation = (options) => useMutation(
  ["AdminEditCustomerLimits"],
  useRpiGraphqlRequest(
    AdminEditCustomerLimitsDocument
  ),
  options
);
var ChangeDashboardUserPasswordDocument = `
    mutation ChangeDashboardUserPassword($id: Int!, $input: ChangePasswordDashboardUserInput) {
  dashboardUserChangePassword(id: $id, changePasswordDashboardUserInput: $input) {
    id
  }
}
    `;
var useChangeDashboardUserPasswordMutation = (options) => useMutation(
  ["ChangeDashboardUserPassword"],
  useRpiGraphqlRequest(
    ChangeDashboardUserPasswordDocument
  ),
  options
);
var CreateDashboardUserDocument = `
    mutation CreateDashboardUser($input: DashboardUserInput) {
  dashboardUserCreate(createDashboardUserInput: $input) {
    id
  }
}
    `;
var useCreateDashboardUserMutation = (options) => useMutation(
  ["CreateDashboardUser"],
  useRpiGraphqlRequest(
    CreateDashboardUserDocument
  ),
  options
);
var DeleteDashboardUserDocument = `
    mutation DeleteDashboardUser($id: Int!) {
  dashboardUserDelete(id: $id)
}
    `;
var useDeleteDashboardUserMutation = (options) => useMutation(
  ["DeleteDashboardUser"],
  useRpiGraphqlRequest(
    DeleteDashboardUserDocument
  ),
  options
);
var EditDashboardUserDocument = `
    mutation EditDashboardUser($id: Int!, $input: EditDashboardUserInput) {
  dashboardUserEdit(id: $id, editDashboardUserInput: $input) {
    id
  }
}
    `;
var useEditDashboardUserMutation = (options) => useMutation(
  ["EditDashboardUser"],
  useRpiGraphqlRequest(EditDashboardUserDocument),
  options
);
var AdminCancelOrderDocument = `
    mutation AdminCancelOrder($id: Int!) {
  adminCancelOrder(id: $id) {
    id
  }
}
    `;
var useAdminCancelOrderMutation = (options) => useMutation(
  ["AdminCancelOrder"],
  useRpiGraphqlRequest(AdminCancelOrderDocument),
  options
);
var AdminReleaseFromHoldingBinDocument = `
    mutation AdminReleaseFromHoldingBin($id: Int!) {
  adminReleaseFromHoldingBin(id: $id) {
    id
  }
}
    `;
var useAdminReleaseFromHoldingBinMutation = (options) => useMutation(
  ["AdminReleaseFromHoldingBin"],
  useRpiGraphqlRequest(
    AdminReleaseFromHoldingBinDocument
  ),
  options
);
var AdminUpdateOrderStatusDocument = `
    mutation AdminUpdateOrderStatus($id: Int!) {
  adminUpdateOrderStatus(id: $id) {
    id
  }
}
    `;
var useAdminUpdateOrderStatusMutation = (options) => useMutation(
  ["AdminUpdateOrderStatus"],
  useRpiGraphqlRequest(
    AdminUpdateOrderStatusDocument
  ),
  options
);
var ReprintOrderDocument = `
    mutation ReprintOrder($input: CreateReprintInput) {
  orderReprintCreate(createReprintInput: $input) {
    customerOrderId
    validations
  }
}
    `;
var useReprintOrderMutation = (options) => useMutation(
  ["ReprintOrder"],
  useRpiGraphqlRequest(ReprintOrderDocument),
  options
);
var CreatePaymentMethodDocument = `
    mutation CreatePaymentMethod($input: BraintreePaymentMethodInput) {
  paymentMethodCreate(braintreePaymentMethodInput: $input) {
    token
  }
}
    `;
var useCreatePaymentMethodMutation = (options) => useMutation(
  ["CreatePaymentMethod"],
  useRpiGraphqlRequest(
    CreatePaymentMethodDocument
  ),
  options
);
var DeletePaymentMethodDocument = `
    mutation DeletePaymentMethod($paymentMethodToken: String!, $customerPublicId: String!) {
  paymentMethodDelete(
    paymentMethodToken: $paymentMethodToken
    customerPublicId: $customerPublicId
  ) {
    message
  }
}
    `;
var useDeletePaymentMethodMutation = (options) => useMutation(
  ["DeletePaymentMethod"],
  useRpiGraphqlRequest(
    DeletePaymentMethodDocument
  ),
  options
);
var EditPaymentMethodDocument = `
    mutation EditPaymentMethod($input: BraintreePaymentMethodEditInput) {
  paymentMethodEdit(braintreePaymentMethodEditInput: $input) {
    token
  }
}
    `;
var useEditPaymentMethodMutation = (options) => useMutation(
  ["EditPaymentMethod"],
  useRpiGraphqlRequest(EditPaymentMethodDocument),
  options
);
var AdminTaxReviewDocument = `
    mutation AdminTaxReview($input: TaxReviewInput) {
  adminTaxReview(taxReviewInput: $input) {
    message
    taxApprovalChanged
  }
}
    `;
var useAdminTaxReviewMutation = (options) => useMutation(
  ["AdminTaxReview"],
  useRpiGraphqlRequest(AdminTaxReviewDocument),
  options
);
var UploadedFileDeleteDocument = `
    mutation UploadedFileDelete($id: Int!) {
  uploadedFileDelete(id: $id) {
    message
  }
}
    `;
var useUploadedFileDeleteMutation = (options) => useMutation(
  ["UploadedFileDelete"],
  useRpiGraphqlRequest(UploadedFileDeleteDocument),
  options
);
var CreateUploadDocument = `
    mutation CreateUpload($input: CreateUploadInput) {
  uploadCreate(createUploadInput: $input) {
    collectionId
    fileCount
    collectionType
    uploadExpiration
    finishFileUrl
    uploadFiles {
      fileName
      fileSize
      collectionId
      contentType
      fileId
      externalFileId
      multipartUpload {
        key
        uploadId
        parts {
          PartNumber
          uploadUrl
        }
      }
    }
  }
}
    `;
var useCreateUploadMutation = (options) => useMutation(
  ["CreateUpload"],
  useRpiGraphqlRequest(CreateUploadDocument),
  options
);
var ApiCredentialsDocument = `
    query ApiCredentials($limit: Int!, $offset: Int!, $search: ApiCredentialSearch) {
  apiCredentials(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      apiKey
      customerPublicId
      sharedSecret
      description
      webhookSecret
    }
  }
}
    `;
var useApiCredentialsQuery = (variables, options) => useQuery(
  ["ApiCredentials", variables],
  useRpiGraphqlRequest(ApiCredentialsDocument).bind(
    null,
    variables
  ),
  options
);
var ListApiCredentialsDocument = `
    query ListApiCredentials {
  listApiCredentials {
    id
    apiKey
    customerPublicId
    sharedSecret
    description
    settings
    webhookSecret
  }
}
    `;
var useListApiCredentialsQuery = (variables, options) => useQuery(
  variables === void 0 ? ["ListApiCredentials"] : ["ListApiCredentials", variables],
  useRpiGraphqlRequest(ListApiCredentialsDocument).bind(
    null,
    variables
  ),
  options
);
var BulletinsDocument = `
    query Bulletins {
  bulletins {
    paymentMethodMissing
    taxDocumentNotUploaded
    ordersLimit {
      isOrdersLimitActive
      configuredMaxOrdersWithin24h
      numOrdersReceivedUnderLimitLast24h
      configuredLimitQueueSize
      numOrdersInLimitQueue
      numRejectedOrdersQueueFullLast7Days
      isLimitQueueFull
    }
  }
}
    `;
var useBulletinsQuery = (variables, options) => useQuery(
  variables === void 0 ? ["Bulletins"] : ["Bulletins", variables],
  useRpiGraphqlRequest(BulletinsDocument).bind(null, variables),
  options
);
var AdminConfigurationDocument = `
    query AdminConfiguration {
  adminConfiguration {
    avalaraTaxConfiguration
    dynamoDbConfiguration
    tasksConfiguration
    uosClientConfiguration
    pdfProcessingConfiguration
    shippingConfiguration
    dataSourceUrl
    nextChargeTime
    git
    holdingBinReleaseInHours
  }
}
    `;
var useAdminConfigurationQuery = (variables, options) => useQuery(
  variables === void 0 ? ["AdminConfiguration"] : ["AdminConfiguration", variables],
  useRpiGraphqlRequest(AdminConfigurationDocument).bind(
    null,
    variables
  ),
  options
);
var GetNextChargeTimeDocument = `
    query GetNextChargeTime($customerPublicId: String!) {
  getNextChargeTime(customerPublicId: $customerPublicId)
}
    `;
var useGetNextChargeTimeQuery = (variables, options) => useQuery(
  ["GetNextChargeTime", variables],
  useRpiGraphqlRequest(GetNextChargeTimeDocument).bind(
    null,
    variables
  ),
  options
);
var AdminSearchCustomerCommunicationsDocument = `
    query AdminSearchCustomerCommunications($limit: Int!, $offset: Int!, $search: CustomerCommunicationSearch) {
  adminSearchCustomerCommunications(
    limit: $limit
    offset: $offset
    search: $search
  ) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      email
      customer {
        publicId
      }
      type
      createdAt
      contents
    }
  }
}
    `;
var useAdminSearchCustomerCommunicationsQuery = (variables, options) => useQuery(
  ["AdminSearchCustomerCommunications", variables],
  useRpiGraphqlRequest(
    AdminSearchCustomerCommunicationsDocument
  ).bind(null, variables),
  options
);
var CustomerCommunicationDocument = `
    query CustomerCommunication($id: Int!) {
  customerCommunication(id: $id) {
    id
    email
    customer {
      publicId
    }
    type
    createdAt
    contents
  }
}
    `;
var useCustomerCommunicationQuery = (variables, options) => useQuery(
  ["CustomerCommunication", variables],
  useRpiGraphqlRequest(
    CustomerCommunicationDocument
  ).bind(null, variables),
  options
);
var CustomerProductDocument = `
    query CustomerProduct($id: Int!) {
  customerProduct(id: $id) {
    id
    customerPublicId
    customerSku
    name
    description
    openapiProductId
  }
}
    `;
var useCustomerProductQuery = (variables, options) => useQuery(
  ["CustomerProduct", variables],
  useRpiGraphqlRequest(CustomerProductDocument).bind(
    null,
    variables
  ),
  options
);
var CustomerProductsDocument = `
    query CustomerProducts($limit: Int!, $offset: Int!, $search: CustomerProductSearch) {
  customerProducts(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      customerPublicId
      customerSku
      name
      description
      openapiProductId
    }
  }
}
    `;
var useCustomerProductsQuery = (variables, options) => useQuery(
  ["CustomerProducts", variables],
  useRpiGraphqlRequest(CustomerProductsDocument).bind(
    null,
    variables
  ),
  options
);
var ListCustomerProductsDocument = `
    query ListCustomerProducts {
  listCustomerProducts {
    id
    customerPublicId
    customerSku
    name
    description
    openapiProductId
  }
}
    `;
var useListCustomerProductsQuery = (variables, options) => useQuery(
  variables === void 0 ? ["ListCustomerProducts"] : ["ListCustomerProducts", variables],
  useRpiGraphqlRequest(
    ListCustomerProductsDocument
  ).bind(null, variables),
  options
);
var AdminListCustomersDocument = `
    query AdminListCustomers($input: String) {
  adminListCustomers(generalInput: $input) {
    id
    publicId
    email
    createdAt
  }
}
    `;
var useAdminListCustomersQuery = (variables, options) => useQuery(
  variables === void 0 ? ["AdminListCustomers"] : ["AdminListCustomers", variables],
  useRpiGraphqlRequest(AdminListCustomersDocument).bind(
    null,
    variables
  ),
  options
);
var AdminSearchCustomersDocument = `
    query AdminSearchCustomers($limit: Int!, $offset: Int!, $search: CustomerSearch) {
  adminSearchCustomers(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      publicId
      email
      pdfProcessingApiaccount
      createdAt
    }
  }
}
    `;
var useAdminSearchCustomersQuery = (variables, options) => useQuery(
  ["AdminSearchCustomers", variables],
  useRpiGraphqlRequest(
    AdminSearchCustomersDocument
  ).bind(null, variables),
  options
);
var BillingCustomerDocument = `
    query BillingCustomer($customerPublicId: String!) {
  customer(customerPublicId: $customerPublicId) {
    id
    publicId
    chargeTax
    taxDocuments {
      id
      collectionId
      status
      fileName
      contentType
      url
      description
      createdAt
      updatedAt
    }
  }
}
    `;
var useBillingCustomerQuery = (variables, options) => useQuery(
  ["BillingCustomer", variables],
  useRpiGraphqlRequest(BillingCustomerDocument).bind(
    null,
    variables
  ),
  options
);
var CustomerDocument = `
    query Customer($customerPublicId: String!) {
  customer(customerPublicId: $customerPublicId) {
    id
    publicId
    pdfProcessingApiaccount
    email
    emailUpdates
    settings {
      shipping
      payments
      pricing
      ordersLimit {
        limitQueueSize
        maxOrders
      }
      allowPrintAssetCreation
      allowCreateOrderFromPrintAsset
      allowInstantPayment
      allowMultipleCurrencies
      vaultCustomerId
    }
    dashboardUsers {
      id
      username
      sub
      email
      disabled
      role
      createdAt
    }
    chargeTax
    createdAt
    taxDocuments {
      id
      collectionId
      status
      fileName
      contentType
      url
      description
      createdAt
      updatedAt
    }
  }
}
    `;
var useCustomerQuery = (variables, options) => useQuery(
  ["Customer", variables],
  useRpiGraphqlRequest(CustomerDocument).bind(null, variables),
  options
);
var DashboardUserDocument = `
    query DashboardUser($id: Int!) {
  dashboardUser(id: $id) {
    id
    username
    sub
    email
    disabled
    customerPublicId
    role
    firstName
    lastName
    createdAt
  }
}
    `;
var useDashboardUserQuery = (variables, options) => useQuery(
  ["DashboardUser", variables],
  useRpiGraphqlRequest(DashboardUserDocument).bind(null, variables),
  options
);
var DashboardUserCurrentDocument = `
    query DashboardUserCurrent {
  dashboardUserCurrent {
    id
    username
    sub
    email
    disabled
    customerPublicId
    role
    firstName
    lastName
    createdAt
  }
}
    `;
var useDashboardUserCurrentQuery = (variables, options) => useQuery(
  variables === void 0 ? ["DashboardUserCurrent"] : ["DashboardUserCurrent", variables],
  useRpiGraphqlRequest(
    DashboardUserCurrentDocument
  ).bind(null, variables),
  options
);
var DashboardUsersDocument = `
    query DashboardUsers($limit: Int!, $offset: Int!, $search: DashboardUserSearch) {
  dashboardUsers(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      username
      sub
      email
      disabled
      customerPublicId
      role
      firstName
      lastName
      createdAt
    }
  }
}
    `;
var useDashboardUsersQuery = (variables, options) => useQuery(
  ["DashboardUsers", variables],
  useRpiGraphqlRequest(DashboardUsersDocument).bind(
    null,
    variables
  ),
  options
);
var DailyMetricsDocument = `
    query DailyMetrics($customerPublicId: String!, $numberOfDays: Int!) {
  dailyMetrics(customerPublicId: $customerPublicId, numberOfDays: $numberOfDays) {
    customerPublicId
    numberOfDays
    days {
      createdDay
      sumOrderTotals
      numCreatedOrders
    }
  }
}
    `;
var useDailyMetricsQuery = (variables, options) => useQuery(
  ["DailyMetrics", variables],
  useRpiGraphqlRequest(DailyMetricsDocument).bind(null, variables),
  options
);
var DailyPaymentMetricsDocument = `
    query DailyPaymentMetrics($customerPublicId: String!, $numberOfDays: Int!) {
  dailyPaymentMetrics(
    customerPublicId: $customerPublicId
    numberOfDays: $numberOfDays
  ) {
    customerPublicId
    numberOfDays
    days {
      createdDay
      sumSaleTotals
      sumRefundTotals
    }
  }
}
    `;
var useDailyPaymentMetricsQuery = (variables, options) => useQuery(
  ["DailyPaymentMetrics", variables],
  useRpiGraphqlRequest(DailyPaymentMetricsDocument).bind(
    null,
    variables
  ),
  options
);
var OrdersByPublicStatusMetricsDocument = `
    query OrdersByPublicStatusMetrics($customerPublicId: String!, $numberOfDays: Int!) {
  ordersByPublicStatusMetrics(
    customerPublicId: $customerPublicId
    numberOfDays: $numberOfDays
  ) {
    customerPublicId
    numberOfDays
    ordersByPublicStatus {
      publicStatus
      numOrders
    }
  }
}
    `;
var useOrdersByPublicStatusMetricsQuery = (variables, options) => useQuery(
  ["OrdersByPublicStatusMetrics", variables],
  useRpiGraphqlRequest(
    OrdersByPublicStatusMetricsDocument
  ).bind(null, variables),
  options
);
var ListOpenApiProductsDocument = `
    query ListOpenApiProducts($hideAlreadyAssociatedProducts: Boolean) {
  listOpenApiProducts(
    hideAlreadyAssociatedProducts: $hideAlreadyAssociatedProducts
  ) {
    id
    name
    openapiSku
  }
}
    `;
var useListOpenApiProductsQuery = (variables, options) => useQuery(
  variables === void 0 ? ["ListOpenApiProducts"] : ["ListOpenApiProducts", variables],
  useRpiGraphqlRequest(ListOpenApiProductsDocument).bind(
    null,
    variables
  ),
  options
);
var OpenApiProductDocument = `
    query OpenApiProduct($id: Int!) {
  openapiProduct(id: $id) {
    id
    uosSku
    openapiSku
    uosMediaOption
    uosOrientation
    name
    description
    productPricing {
      currency
      basePrice
      perPageCost
    }
  }
}
    `;
var useOpenApiProductQuery = (variables, options) => useQuery(
  ["OpenApiProduct", variables],
  useRpiGraphqlRequest(OpenApiProductDocument).bind(
    null,
    variables
  ),
  options
);
var OpenApiProductsDocument = `
    query OpenApiProducts($limit: Int!, $offset: Int!, $search: OpenapiProductSearch) {
  openapiProducts(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      uosSku
      openapiSku
      uosMediaOption
      uosOrientation
      name
      description
    }
  }
}
    `;
var useOpenApiProductsQuery = (variables, options) => useQuery(
  ["OpenApiProducts", variables],
  useRpiGraphqlRequest(OpenApiProductsDocument).bind(
    null,
    variables
  ),
  options
);
var ListOrdersForPaymentDocument = `
    query ListOrdersForPayment($customerPublicId: String!) {
  listOrdersForPayment(customerPublicId: $customerPublicId) {
    id
    customerOrderId
    createdAt
    updatedAt
    pricing {
      orderTotal
      itemsTotal
      taxesTotal
      shippingTotal
      currency
    }
  }
}
    `;
var useListOrdersForPaymentQuery = (variables, options) => useQuery(
  ["ListOrdersForPayment", variables],
  useRpiGraphqlRequest(
    ListOrdersForPaymentDocument
  ).bind(null, variables),
  options
);
var OrderDocument = `
    query Order($id: Int!) {
  order(id: $id) {
    id
    customer {
      id
      publicId
      email
    }
    reprintParentOrderId
    customerOrderId
    paymentStatus
    publicOrderStatus
    status
    canReprint
    createdAt
    updatedAt
    submittedAt
    paymentMode
    paymentStatus
    currency
    webhookUri
    lastStatusMessage
    destination {
      id
      shippingMethodCode
      shippingDetails
      address {
        id
        createdAt
        updatedAt
        name
        company
        address1
        address2
        address3
        postalCode
        city
        regionIso
        countryIso
        phone
        email
      }
    }
    orderEvents {
      id
      orderId
      customerOrderId
      eventTime
      eventType
      shortDesc
      data
    }
    validationResult
    additionalPayload
    callbacks {
      urlEndpoint
      attemptCount
      webhookId
      webhookStatus
      timeline
      body
    }
    orderItems {
      id
      orderId
      openapiProduct {
        id
        uosSku
        openapiSku
        uosMediaOption
        uosOrientation
        name
        description
        productSpecification {
          id
          name
          type
          version
          md5Hash
          processingTemplate
          processingSettings
        }
      }
      customerProduct {
        id
        customerPublicId
        customerSku
        name
        description
        openapiProductId
      }
      printAsset {
        id
        customer {
          id
          publicId
          email
        }
        printAssetUploadId
        status
        previews
        preflightOutputFiles
      }
      orderItemStatus
      pagecount
      coverUri
      gutsUri
      imageUri
      validationResult
      pricingAlgorithm
      pricingDetails
      quantity
    }
    pricing {
      currency
      itemsTotal
      orderTotal
      shippingTotal
      taxesTotal
    }
    canDoFullRefund
    remainingPossibleRefund
    shipments {
      id
      destinationId
      status
      isFinalShipment
      shippedDate
      shippingMethodCode
      trackingNumber
      masterTrackingNumber
      secondaryTrackingNumber
      rawShipmentData
    }
    salePayment {
      id
    }
    refunds {
      id
      saleParentPaymentId
      customerPublicId
      braintreeTransactionId
      amount
      currency
      transactionType
      createdAt
    }
  }
}
    `;
var useOrderQuery = (variables, options) => useQuery(
  ["Order", variables],
  useRpiGraphqlRequest(OrderDocument).bind(null, variables),
  options
);
var OrderItemDocument = `
    query OrderItem($id: Int!) {
  orderItem(id: $id) {
    id
    orderId
    openapiProduct {
      id
      uosSku
      openapiSku
      uosMediaOption
      uosOrientation
      name
      description
      productSpecification {
        id
        name
        type
        version
        md5Hash
        processingTemplate
        processingSettings
      }
    }
    customerProduct {
      id
      customerPublicId
      customerSku
      name
      description
      openapiProductId
    }
    printAsset {
      id
      customer {
        id
        publicId
        email
      }
      printAssetUploadId
      status
      previews
      preflightOutputFiles
    }
    orderItemStatus
    pagecount
    coverUri
    gutsUri
    imageUri
    validationResult
    pricingAlgorithm
    pricingDetails
    quantity
  }
}
    `;
var useOrderItemQuery = (variables, options) => useQuery(
  ["OrderItem", variables],
  useRpiGraphqlRequest(OrderItemDocument).bind(null, variables),
  options
);
var OrdersDocument = `
    query Orders($limit: Int!, $offset: Int!, $search: OrderSearch) {
  orders(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      customer {
        publicId
      }
      customerOrderId
      publicOrderStatus
      createdAt
      updatedAt
      submittedAt
      paymentStatus
      pricing {
        currency
        itemsTotal
        orderTotal
        shippingTotal
        taxesTotal
      }
      currency
      lastStatusMessage
    }
  }
}
    `;
var useOrdersQuery = (variables, options) => useQuery(
  ["Orders", variables],
  useRpiGraphqlRequest(OrdersDocument).bind(null, variables),
  options
);
var ListPaymentMethodsDocument = `
    query ListPaymentMethods($customerPublicId: String!) {
  listPaymentMethods(customerPublicId: $customerPublicId) {
    cardholderName
    address
    city
    state
    countryIso2
    countryName
    name
    imageUrl
    customerId
    message
    token
    isDefault
    last4Digits
    maskedCCNumber
    expiryDate
  }
}
    `;
var useListPaymentMethodsQuery = (variables, options) => useQuery(
  ["ListPaymentMethods", variables],
  useRpiGraphqlRequest(ListPaymentMethodsDocument).bind(
    null,
    variables
  ),
  options
);
var PaymentDocument = `
    query Payment($id: Int) {
  payment(id: $id) {
    id
    saleParentPaymentId
    customerPublicId
    braintreeTransactionId
    amount
    currency
    transactionType
    notes
    createdAt
    updatedAt
    paymentInfo
    paidOrders {
      id
      customerOrderId
      customer {
        publicId
      }
      status
      publicOrderStatus
      createdAt
      updatedAt
      submittedAt
      lastStatusMessage
    }
    refundedOrder {
      id
      customerOrderId
      currency
      canDoFullRefund
      remainingPossibleRefund
      pricing {
        currency
        itemsTotal
        orderTotal
        shippingTotal
        taxesTotal
      }
      customer {
        publicId
      }
      status
      publicOrderStatus
      createdAt
      updatedAt
      submittedAt
      lastStatusMessage
    }
  }
}
    `;
var usePaymentQuery = (variables, options) => useQuery(
  variables === void 0 ? ["Payment"] : ["Payment", variables],
  useRpiGraphqlRequest(PaymentDocument).bind(null, variables),
  options
);
var PaymentByTransactionIdDocument = `
    query PaymentByTransactionId($braintreeTransactionId: String) {
  paymentByTransactionId(braintreeTransactionId: $braintreeTransactionId) {
    id
    customerPublicId
    braintreeTransactionId
    amount
    currency
    paymentInfo
    paidOrders {
      id
      customerOrderId
      createdAt
      updatedAt
      pricing {
        currency
        itemsTotal
        orderTotal
        shippingTotal
        taxesTotal
      }
    }
  }
}
    `;
var usePaymentByTransactionIdQuery = (variables, options) => useQuery(
  variables === void 0 ? ["PaymentByTransactionId"] : ["PaymentByTransactionId", variables],
  useRpiGraphqlRequest(
    PaymentByTransactionIdDocument
  ).bind(null, variables),
  options
);
var PaymentsDocument = `
    query Payments($limit: Int!, $offset: Int!, $search: PaymentSearch) {
  payments(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      saleParentPaymentId
      customerPublicId
      braintreeTransactionId
      amount
      currency
      transactionType
      createdAt
    }
  }
}
    `;
var usePaymentsQuery = (variables, options) => useQuery(
  ["Payments", variables],
  useRpiGraphqlRequest(PaymentsDocument).bind(null, variables),
  options
);
var PrintAssetDocument = `
    query PrintAsset($id: Int!) {
  printAsset(id: $id) {
    id
    customer {
      id
      publicId
      pdfProcessingApiaccount
      email
    }
    printAssetUploadId
    status
    previews
    preflightOutputFiles
    blurbCorePrintFiles {
      id
      preprintOperationKey
      status
      startedAt
      completedAt
      printReadyFiles
      blurbLogo
      isbn
      renderSpread
      disableCropMarks
      renderAsIngram
    }
    report
  }
}
    `;
var usePrintAssetQuery = (variables, options) => useQuery(
  ["PrintAsset", variables],
  useRpiGraphqlRequest(PrintAssetDocument).bind(null, variables),
  options
);
var PrintAssetsDocument = `
    query PrintAssets($limit: Int!, $offset: Int!, $search: PrintAssetSearch) {
  printAssets(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      customer {
        publicId
      }
      printAssetUploadId
      status
      updatedAt
    }
  }
}
    `;
var usePrintAssetsQuery = (variables, options) => useQuery(
  ["PrintAssets", variables],
  useRpiGraphqlRequest(PrintAssetsDocument).bind(null, variables),
  options
);
var UploadFilesByPollCollectionDocument = `
    query UploadFilesByPollCollection($collectionId: String!, $customerPublicId: String!) {
  uploadFilesPollByCollection(
    collectionId: $collectionId
    customerPublicId: $customerPublicId
  ) {
    id
    customer {
      id
      publicId
    }
    collectionId
    status
    fileName
    contentType
    url
    description
    createdAt
    updatedAt
    isTaxDocument
  }
}
    `;
var useUploadFilesByPollCollectionQuery = (variables, options) => useQuery(
  ["UploadFilesByPollCollection", variables],
  useRpiGraphqlRequest(
    UploadFilesByPollCollectionDocument
  ).bind(null, variables),
  options
);
var UploadedFileDocument = `
    query UploadedFile($id: Int!) {
  uploadedFile(id: $id) {
    id
    customer {
      id
      publicId
    }
    collectionId
    status
    fileName
    contentType
    url
    description
    createdAt
    updatedAt
  }
}
    `;
var useUploadedFileQuery = (variables, options) => useQuery(
  ["UploadedFile", variables],
  useRpiGraphqlRequest(UploadedFileDocument).bind(null, variables),
  options
);
var UploadedFilesDocument = `
    query UploadedFiles($limit: Int!, $offset: Int!, $search: UploadedFileSearch) {
  uploadedFiles(limit: $limit, offset: $offset, search: $search) {
    totalCount
    offset
    nextOffset
    prevOffset
    data {
      id
      customer {
        id
        publicId
      }
      collectionId
      status
      fileName
      contentType
      url
      description
      createdAt
      updatedAt
      isTaxDocument
    }
  }
}
    `;
var useUploadedFilesQuery = (variables, options) => useQuery(
  ["UploadedFiles", variables],
  useRpiGraphqlRequest(UploadedFilesDocument).bind(null, variables),
  options
);

// src/rest/export.ts
var export_exports2 = {};
__export(export_exports2, {
  createOrder: () => createOrder,
  createShareIdea: () => createShareIdea,
  exportCommunicationsCSV: () => exportCommunicationsCSV,
  getOrderById: () => getOrderById,
  getPriceListInfo: () => getPriceListInfo,
  getProductListing: () => getProductListing
});

// src/rest/requests.tsx
var exportCommunicationsCSV = async (body, authHeader) => {
  return publicRestClient.post("/internal/openapi-backend/admin/export/customerCommunications", body, {
    headers: {
      Authorization: authHeader
    },
    responseType: "blob"
  });
};
var createOrder = async (body, authHeader) => {
  return restClient.post("/orders/create", body, {
    headers: {
      Authorization: authHeader
    }
  });
};
var getOrderById = async (customerOrderId, authHeader) => {
  return restClient.get("/orders/" + customerOrderId, {
    headers: {
      Authorization: authHeader
    }
  });
};
var getPriceListInfo = async () => {
  return publicRestClient.get("/pricelistInfo");
};
var createShareIdea = async (request) => {
  return publicRestClient.post("/shareAnIdea", request);
};
var productListing = {
  sizes: {
    square: [
      { index: 1, inches: "12x12in", centimeters: "30.4x30.4 cm", price_key: "12x12" },
      { index: 2, inches: "8x8in", centimeters: "20x20 cm", price_key: "8x8" }
    ],
    portrait_only: [{ index: 1, inches: "8x10in", centimeters: "20x25 cm", price_key: "8x10" }],
    portrait_and_landscape: [
      { index: 1, inches: "8.5x11in", centimeters: "21.6x28 cm", price_key: "8.5x11" },
      { index: 2, inches: "11x8.5in", centimeters: "28x21.6 cm", price_key: "11x8.5" }
    ]
  },
  covers: [
    {
      index: 1,
      name: "Matte ImageWrap cover",
      description_lines: ["Durable matte finish", "Wrap an image across the front cover, back cover, and spine"],
      price_key: "MAT"
    },
    {
      index: 2,
      name: "Gloss ImageWrap cover",
      description_lines: ["Durable gloss finish", "Wrap an image across the front cover, back cover, and spine"],
      price_key: "GLS"
    },
    {
      index: 3,
      name: "Gloss Soft cover",
      description_lines: ["Gloss finish", "Print on the front cover, back cover, and spine"],
      price_key: "SC"
    }
  ],
  papers: [
    {
      index: 1,
      name: "Premium Gloss",
      description_lines: ["Smooth gloss finish", "100# (148 gsm)", "Up to 380 pages"],
      price_key: "GL100T"
    }
  ],
  pricing_sku_mapping: [
    { size: "11x8.5", cover: "MAT", paper: "GL100T", sku: "HCIW_11x8.5_MAT_GL100T" },
    { size: "12x12", cover: "MAT", paper: "GL100T", sku: "HCIW_12x12_MAT_GL100T" },
    { size: "8.5x11", cover: "MAT", paper: "GL100T", sku: "HCIW_8.5x11_MAT_GL100T" },
    { size: "8x10", cover: "MAT", paper: "GL100T", sku: "HCIW_8x10_MAT_GL100T" },
    { size: "8x8", cover: "MAT", paper: "GL100T", sku: "HCIW_8x8_MAT_GL100T" },
    { size: "11x8.5", cover: "GLS", paper: "GL100T", sku: "HCIW_11x8.5_GLS_GL100T" },
    { size: "12x12", cover: "GLS", paper: "GL100T", sku: "HCIW_12x12_GLS_GL100T" },
    { size: "8.5x11", cover: "GLS", paper: "GL100T", sku: "HCIW_8.5x11_GLS_GL100T" },
    { size: "8x10", cover: "GLS", paper: "GL100T", sku: "HCIW_8x10_GLS_GL100T" },
    { size: "8x8", cover: "GLS", paper: "GL100T", sku: "HCIW_8x8_GLS_GL100T" },
    { size: "11x8.5", cover: "SC", paper: "GL100T", sku: "SC_11x8.5_GLS_GL100T" },
    { size: "8.5x11", cover: "SC", paper: "GL100T", sku: "SC_8.5x11_GLS_GL100T" },
    { size: "8x10", cover: "SC", paper: "GL100T", sku: "SC_8x10_GLS_GL100T" },
    { size: "8x8", cover: "SC", paper: "GL100T", sku: "SC_8x8_GLS_GL100T" }
  ],
  pricelist_entries: [
    { index: 1, name: "8x8 inches Photobook with Soft Cover", sku: "SC_8x8_GLS_GL100T" },
    { index: 2, name: "8x8 inches Photobook with ImageWrap Cover", sku: "HCIW_8x8_GLS_GL100T" },
    { index: 3, name: "8x10 inches Photobook with Soft Cover", sku: "SC_8x10_GLS_GL100T" },
    { index: 4, name: "8x10 inches Photobook with ImageWrap Cover", sku: "HCIW_8x10_GLS_GL100T" },
    { index: 5, name: "8.5x11 inches Photobook with Soft Cover", sku: "SC_8.5x11_GLS_GL100T" },
    { index: 6, name: "8.5x11 inches Photobook with ImageWrap Cover", sku: "HCIW_8.5x11_GLS_GL100T" },
    { index: 7, name: "11x8.5 inches Photobook with Soft Cover", sku: "SC_11x8.5_GLS_GL100T" },
    { index: 8, name: "11x8.5 inches Photobook with ImageWrap Cover", sku: "HCIW_11x8.5_GLS_GL100T" },
    { index: 9, name: "12x12 inches Photobook with ImageWrap Cover", sku: "HCIW_12x12_GLS_GL100T" }
  ]
};
var getProductListing = async () => {
  return productListing;
};
export {
  CustomGraphQLError,
  export_exports as GraphQL,
  export_exports2 as Rest,
  RpiApiContext,
  RpiApiProvider,
  graphqlClient,
  isGraphQLError,
  publicRestClient,
  restClient,
  rpiGraphqlRequest,
  useRpiGraphqlRequest
};
