import { createStyles, Loader, SimpleGrid } from "@mantine/core";
import { Flex, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useAuthUser, UseAuthUserResult } from "../../hooks/useAuthUser";
import { useDevMode } from "../../hooks/useDevMode";
import { isFunction } from "../../utils/type.util";

export interface RpiSingleTableRow<T> {
  header: React.ReactNode;
  accessor: keyof T | ((data: T, index: number) => React.ReactNode);
}

export type RpiSingleTableRows<T> =
  | RpiSingleTableRow<T>[]
  | ((user: UseAuthUserResult) => Array<RpiSingleTableRow<T> | false>);

interface RpiSingleTableProps<T> {
  data?: T | null;
  rows: RpiSingleTableRows<T>;
  isLoading?: boolean;
  error?: any;
}

export const useRpiSingleTableStyles = createStyles((theme) => {
  return {
    root: {
      borderRadius: 6,
      background: theme.colors.sky[5],
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.colors.calm[5],
      padding: 8,
      minHeight: 64,
      flexShrink: 0,
      overflow: "hidden",
      position: "relative",
      height: "fit-content",
    },
    itemTitle: {
      color: theme.colors.brand[5],
    },
    itemBody: {
      ...theme.other.fontTypes.p2,
      fontWeight: theme.other.fontWeights.regular,
      color: theme.colors.text[5],
      lineHeight: "19.2px",
      overflowX: "auto",
      overflowY: "hidden",
      wordBreak: "break-all",
    },
  };
});

export function RpiSingleTable<T>({ data, rows: initialRows, error, isLoading }: RpiSingleTableProps<T>) {
  const user = useAuthUser();
  const { isDevMode, DevPreview } = useDevMode();
  const { classes } = useRpiSingleTableStyles();

  const rows = React.useMemo(() => {
    if (isFunction<[user: UseAuthUserResult], Array<RpiSingleTableRow<T> | false>>(initialRows)) {
      return initialRows(user).filter((row) => !!row) as RpiSingleTableRow<T>[];
    }

    return initialRows;
  }, [initialRows, user]);

  const getRowContent = React.useCallback(
    (row: RpiSingleTableRow<T>, i: number): React.ReactNode => {
      if (!data || !row) return "";
      if (typeof row.accessor === "function") return row.accessor(data, i);
      return data[row.accessor] as unknown as React.ReactNode;
    },
    [data]
  );

  const items = React.useMemo(() => {
    return rows.map((row, i) => {
      return (
        <Flex.Column key={`rpi-single-table-item-${i}`} gap={8} className={classes.root}>
          <RpiText type="p3" weight="light" className={classes.itemTitle} children={row.header} />
          {(isLoading || error) && (
            <Flex.Column justify="center" sx={{ flex: 1 }}>
              <Loader size="md" variant="dots" color={error ? "spark" : "brand"} />
            </Flex.Column>
          )}

          {!isLoading && !error && (
            <RpiText type="p2" weight="regular" className={classes.itemBody} children={getRowContent(row, i)} />
          )}
        </Flex.Column>
      );
    });
  }, [rows, getRowContent, isLoading, error]);

  if (isDevMode) return <DevPreview data={data} />;

  return <SimpleGrid cols={3} spacing={16} children={items} />;
}
