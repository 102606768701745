import { GraphQL, GraphQLQueryData } from "@rpi/openapi-api";
import { CellDate } from "../../components/cells/CellDate";
import { CellRoute } from "../../components/cells/CellLink";
import { RpiSingleTableRows } from "../../components/tables/RpiSingleTable";
import { formatCurrency } from "../../utils/misc.util";
import { isNumber } from "../../utils/type.util";

export const paymentRows: RpiSingleTableRows<GraphQLQueryData<GraphQL.PaymentQuery>> = (user) => [
  {
    header: "Amount",
    accessor: ({ amount, currency }) => isNumber(amount) && formatCurrency(currency, amount),
  },
  user.is.SUPER_ADMIN && {
    header: "Customer Public ID",
    accessor: ({ customerPublicId }) =>
      customerPublicId && (
        <CellRoute route={["customer", { publicId: customerPublicId }]} children={customerPublicId} />
      ),
  },
  user.is.SUPER_ADMIN && {
    header: "Braintree Transaction ID",
    accessor: "braintreeTransactionId",
  },
  {
    header: "Type",
    accessor: (data) => {
      if (data.saleParentPaymentId) {
        return (
          <CellRoute
            route={["payment", { id: data.saleParentPaymentId }]}
            children={`${data.transactionType} of ${data.saleParentPaymentId}`}
          />
        );
      }

      return data.transactionType;
    },
  },
  {
    header: "Payment method",
    accessor: (data) => data?.paymentInfo?.paidWith,
  },
  {
    header: "CC Number",
    accessor: (data) => data?.paymentInfo?.maskedCCNumber,
  },
  {
    header: "Expiry Date",
    accessor: (data) => data?.paymentInfo?.expiryDate,
  },
  {
    header: "Created",
    accessor: (data) => <CellDate date={data.createdAt} />,
  },
  {
    header: "Updated",
    accessor: (data) => <CellDate date={data.updatedAt} />,
  },
];
