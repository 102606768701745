import { Box } from "@mantine/core";
import { Flex, Icon, RpiButton, RpiLink, RpiText } from "@rpi/openapi-core";
import React from "react";
import { Link } from "react-router-dom";
import { getRoutePath, useNavigateRoute } from "../../hooks/useNavigateRoute";
import { PublicUrl } from "../../utils/link.util";
import { LANDING_PAGE_MAX_WIDTH } from "./IndexView";

export const LandingPage: React.FC = () => {
  const navigate = useNavigateRoute();

  return (
    <Flex.Column fullWidth pb={40}>
      <Flex.Row px={80} py={16} justify="space-between" fullWidth>
        <Icon.Logo size={62} style={{ flexShrink: 0 }} />
        <Flex.Row gap={32} align="center">
          <RpiLink
            component={Link}
            to={getRoutePath("auth:sign-in")}
            sx={(theme) => ({ color: theme.colors.brand[5] })}
            children="Login"
          />
          <RpiButton variant="spark" trailingIcon onClick={() => navigate(["auth:sign-up"])} children="Sign Up" />
        </Flex.Row>
      </Flex.Row>
      <Flex.Column gap={32} sx={{ flex: 1, overflow: "hidden" }}>
        <Box
          component="img"
          src="/assets/images/landing_page.png"
          sx={{
            objectFit: "cover",
            width: "100%",
            maxHeight: 480,
            minHeight: 480,
            flexShrink: 0,
          }}
        />

        <Flex.Column px={80} align="center" justify="center">
          <Flex.Row fullWidth gap={64} sx={{ maxWidth: LANDING_PAGE_MAX_WIDTH }}>
            <Flex.Column gap={32} fullWidth sx={{ minWidth: "fit-content", width: "fit-content" }}>
              <RpiText type="d1" weight="bold" color={(theme) => theme.colors.brand[5]} children="RPI Print API" />
              <Flex.Row gap={16} align="center">
                <RpiButton
                  size="large"
                  variant="spark"
                  trailingIcon
                  width="fit"
                  onClick={() => navigate(["auth:sign-up"])}
                  children="Create Account"
                />
                <RpiButton
                  component="a"
                  target="_blank"
                  href={PublicUrl.DOCS_FIRST_REQUEST}
                  size="large"
                  variant="spark-outline"
                  width="fit"
                  children="View documentation"
                />
              </Flex.Row>
            </Flex.Column>
            <Flex.Row fullWidth align="center" sx={{ height: "fit-content", minHeight: 64, maxWidth: 569 }}>
              <RpiText type="p2" weight="regular" color={(theme) => theme.colors.brand[5]}>
                Connect your projects to our world-class print-on-demand and shipping services with RPI's Print API.
              </RpiText>
            </Flex.Row>
          </Flex.Row>
        </Flex.Column>
      </Flex.Column>
    </Flex.Column>
  );
};
