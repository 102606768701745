import { createStyles } from "@mantine/core";
import { Flex, IconV2, RpiButton, RpiIconButton, RpiText } from "@rpi/openapi-core";
import React from "react";

const useDragAndDropStyles = createStyles<"input" | "root" | "files" | "file", { activeDrag?: boolean }>(
  (theme, { activeDrag }) => {
    return {
      root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        alignItems: "center",
        width: "100%",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.colors.calm[activeDrag ? 6 : 5],
        padding: "16px 8px",
        borderRadius: 6,
        background: theme.colors.calm[activeDrag ? 5 : 4],
        color: theme.colors.brand[5],
        overflowX: "hidden",
      },
      input: {
        display: "none",
      },
      files: {
        position: "relative",
        maxWidth: "100%",
        width: "100%",
      },
      file: {
        height: 30,
        borderRadius: 11,
        background: theme.colors.brand[5],
        color: theme.colors.sky[5],
        maxWidth: "100%",

        "& > *": {
          color: `${theme.colors.sky[5]} !important`,
        },
      },
    };
  }
);

interface DragAndDropProps {
  onChange: (files: File[]) => void;
}

export const DragAndDrop: React.FC<DragAndDropProps> = ({ onChange }) => {
  const [activeDrag, setActiveDrag] = React.useState(false);
  const [files, setFiles] = React.useState<File[]>([]);

  const { classes } = useDragAndDropStyles({ activeDrag });

  const inputRef = React.useRef<HTMLInputElement>();

  const handleFiles = React.useCallback(
    (fileList: FileList) => {
      setFiles([...files, ...Array.from(fileList)]);
    },
    [files]
  );

  const handleDeleteFile = React.useCallback((index: number) => {
    setFiles((files) => {
      if (index < 0 || index >= files.length) return files;

      const clone = [...files];
      clone.splice(index, 1);
      return clone;
    });
  }, []);

  const handleDrag = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setActiveDrag(true);
    } else if (e.type === "dragleave") {
      setActiveDrag(false);
    }
  }, []);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (e.target.files) handleFiles(e.target.files);

      if (inputRef.current) inputRef.current.value = "";
    },
    [handleFiles]
  );

  const handleDrop = React.useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setActiveDrag(false);

      if (e.dataTransfer.files) {
        handleFiles(e.dataTransfer.files);
      }
    },
    [handleFiles]
  );

  React.useEffect(() => onChange(files), [onChange, files]);

  return (
    <div
      className={classes.root}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input ref={inputRef as any} className={classes.input} type="file" multiple onChange={handleChange} />

      <RpiText type="p3" weight="bold" children="Drag files here" />

      {Boolean(files?.length) && (
        <Flex.Column
          align="center"
          gap={8}
          className={classes.files}
          children={files?.map((file, i) => (
            <Flex.Row key={`file-${i}-${file.name}`} align="center" px={8} gap={4} className={classes.file}>
              <RpiText component="span" type="p3" weight="regular" ellipsis children={file.name} />
              <RpiIconButton ml={4} size={9} color="currentColor" icon={IconV2.X} onClick={() => handleDeleteFile(i)} />
            </Flex.Row>
          ))}
        />
      )}

      <RpiButton
        variant="brand-outline"
        width="fit"
        onClick={() => inputRef.current?.click()}
        children="Browse files"
      />
    </div>
  );
};
