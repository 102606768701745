import { Box, Loader } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { GraphQL } from "@rpi/openapi-api";
import { Flex, IconV2, RpiIconButton, RpiText } from "@rpi/openapi-core";
import React from "react";
import { useAuthUser } from "../hooks/useAuthUser";
import { useNavigateRoute } from "../hooks/useNavigateRoute";
import { optObj } from "../utils/misc.util";

interface PaymentMethodProps {
  paymentMethod: GraphQL.BraintreePaymentMethodResponse;
  onRefetch: () => void;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({ paymentMethod, onRefetch }) => {
  const user = useAuthUser();
  const navigateRoute = useNavigateRoute();

  const [isDeleted, setIsDeleted] = React.useState(false);

  const { mutateAsync: deletePaymentMethod, ...deletePaymentMethodMutation } = GraphQL.useDeletePaymentMethodMutation();

  const modals = useModals();

  const handleDeletePaymentMethod = React.useCallback(
    (paymentMethod: GraphQL.BraintreePaymentMethodResponse) => {
      return modals.openConfirmModal({
        title: "Delete payment method",
        centered: true,
        children: (
          <RpiText
            type="p2"
            children={`Are you sure you want to delete ${paymentMethod.name} ${paymentMethod.maskedCCNumber}?`}
          />
        ),
        labels: { confirm: "Delete", cancel: "Cancel" },
        confirmProps: { color: "red" },
        onConfirm: async () => {
          return await deletePaymentMethod({
            paymentMethodToken: paymentMethod.token!,
            customerPublicId: user.id!,
          })
            .then(() => {
              showNotification({
                title: `Removed ${paymentMethod.name} ${paymentMethod.maskedCCNumber}.`,
                message: "",
                color: "green",
              });
              setIsDeleted(true);
              onRefetch();
            })
            .catch((error) => {
              showNotification({
                title: "Failed to delete payment method.",
                message: error.message,
                color: "red",
              });
            });
        },
      });
    },
    [modals]
  );

  const isLoading = React.useMemo(
    () => deletePaymentMethodMutation.isLoading || isDeleted,
    [deletePaymentMethodMutation.isLoading, isDeleted]
  );

  return (
    <>
      <Flex.Column sx={{ width: 196, position: "relative" }}>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              rigth: 0,
              width: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader size="md" sx={{ zIndex: 5 }} />
          </Box>
        )}

        <Flex.Column gap={24} fullWidth sx={optObj(isLoading, { filter: "blur(4px)" })}>
          <Flex.Row fullWidth justify="space-between">
            <Flex.Column sx={{ minHeight: 59 }}>
              {paymentMethod.cardholderName && (
                <RpiText type="p3" weight="bold" ellipsis children={paymentMethod.cardholderName} />
              )}
              <RpiText
                type="p3"
                weight="bold"
                ellipsis
                children={`${paymentMethod.name} ending with ${paymentMethod.last4Digits} `}
              />
              <RpiText type="p3" weight="bold" ellipsis children={`Exp ${paymentMethod.expiryDate}`} />
            </Flex.Column>

            <Flex.Row gap={8}>
              <RpiIconButton
                icon={IconV2.Edit}
                pl={4}
                color="currentColor"
                size={16}
                sx={(theme) => ({ color: theme.colors.brand[5], "&:hover": { color: theme.colors.brand[4] } })}
                onClick={() => navigateRoute(["payment-method:edit", { token: paymentMethod.token }])}
              />
              <RpiIconButton
                icon={IconV2.Delete}
                color="currentColor"
                size={16}
                sx={(theme) => ({ color: theme.colors.brand[5], "&:hover": { color: theme.colors.brand[4] } })}
                onClick={() => !deletePaymentMethodMutation.isLoading && handleDeletePaymentMethod(paymentMethod)}
              />
            </Flex.Row>
          </Flex.Row>

          <Flex.Column sx={{ minHeight: 79 }}>
            <RpiText type="p3" weight="light" children="Billing address" />
            {paymentMethod.address && <RpiText type="p3" weight="bold" ellipsis children={paymentMethod.address} />}
            {paymentMethod.city && (
              <RpiText
                type="p3"
                weight="bold"
                ellipsis
                children={`${paymentMethod.city}${paymentMethod.state ? `, ${paymentMethod.state}` : ""}`}
              />
            )}
            {paymentMethod.countryName && (
              <RpiText type="p3" weight="bold" ellipsis children={paymentMethod.countryName} />
            )}
          </Flex.Column>

          {paymentMethod.isDefault && (
            <RpiText
              type="p3"
              weight="regular"
              color={(theme) => theme.colors.spark[5]}
              children="Default payment method"
            />
          )}
        </Flex.Column>
      </Flex.Column>
    </>
  );
};
