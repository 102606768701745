export enum StorageKey {
  RPI_PER_PAGE = "rpi-per-page",
}

export const getStorageItem = <T>(key: StorageKey): T | null => {
  const stringStorage = localStorage.getItem(key);
  if (!stringStorage) return null;

  try {
    return JSON.parse(stringStorage);
  } catch (error) {
    console.error(`Failed to parse "${key}" local storage`, error);
  }

  return null;
};

export const setStorageItem = <T>(key: StorageKey, value: T) => {
  return localStorage.setItem(key, JSON.stringify(value));
};
