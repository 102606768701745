import { Loader, SimpleGrid, useMantineTheme } from "@mantine/core";
import { GraphQL, Rest } from "@rpi/openapi-api";
import { Flex, IconV2, RpiText } from "@rpi/openapi-core";
import React from "react";
import { formatCurrency } from "../../utils/misc.util";
import { LANDING_PAGE_MAX_WIDTH } from "./IndexView";

const shippingIcons = [IconV2.Box, IconV2.Boxes, IconV2.Truck];

interface ShippingRatesPageProps {
  shipping?: Rest.PriceListInfo["shipping"];
}

export const ShippingRatesPage: React.FC<ShippingRatesPageProps> = ({ shipping }) => {
  const theme = useMantineTheme();

  return (
    <Flex.Column
      justify="center"
      align="center"
      fullWidth
      p={80}
      sx={(theme) => ({ background: theme.colors.spark[5] })}
    >
      <Flex.Column
        justify="center"
        align="center"
        fullWidth
        gap={24}
        sx={(theme) => ({
          flex: 1,
          background: theme.colors.spark[5],
          position: "relative",
          overflowX: "auto",
          maxWidth: LANDING_PAGE_MAX_WIDTH,
        })}
      >
        <Flex.Row gap={16} fullWidth>
          {shipping?.map(({ quantityTierName, shippingOptions }, i) => (
            <Flex.Column
              key={`shipping-tier-${i}`}
              align="flex-start"
              p={24}
              gap={24}
              sx={(theme) => ({
                background: theme.colors.sky[5],
                color: theme.colors.brand[5],
                width: 362.67,
                borderRadius: 24,
                flexShrink: 0,
              })}
            >
              {React.createElement(shippingIcons[i], {
                height: 36,
                color: theme.colors.spark[5],
              })}

              <RpiText type="h2" weight="bold" children={quantityTierName} />

              <SimpleGrid cols={2} spacing={32} verticalSpacing={2}>
                {shippingOptions.map(({ classification, price }, j) => (
                  <React.Fragment key={`shipping-tier-option-${i}-${j}`}>
                    <div children={classification} />
                    <div children={formatCurrency(GraphQL.SupportedCurrency.Usd, price)} />
                  </React.Fragment>
                ))}
              </SimpleGrid>
            </Flex.Column>
          ))}

          {!shipping &&
            Array(3)
              .fill(0)
              .map((_, i) => (
                <Flex.Column
                  key={`shipping-tier-${i}`}
                  align="flex-start"
                  p={24}
                  gap={24}
                  sx={(theme) => ({
                    background: theme.colors.sky[5],
                    color: theme.colors.brand[5],
                    width: 362.67,
                    borderRadius: 24,
                    flexShrink: 0,
                  })}
                >
                  {React.createElement(shippingIcons[i], {
                    height: 36,
                    color: theme.colors.spark[5],
                  })}

                  <RpiText type="h2" weight="bold" children={<Loader variant="dots" />} />

                  <SimpleGrid cols={2} spacing={32} verticalSpacing={2}>
                    {Array(4)
                      .fill(0)
                      .map((_, j) => (
                        <React.Fragment key={`shipping-tier-option-${i}-${j}`}>
                          <div children={<Loader variant="dots" />} />
                          <div children={<Loader variant="dots" />} />
                        </React.Fragment>
                      ))}
                  </SimpleGrid>
                </Flex.Column>
              ))}
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};
