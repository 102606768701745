import { GraphQL } from "@rpi/openapi-api";
import React from "react";
import { useParams } from "react-router-dom";
import { AppPage } from "../../../components/AppPage";
import { RpiPagination } from "../../../components/RpiPagination";
import { RpiSingleTable } from "../../../components/tables/RpiSingleTable";
import { paidOrderColumns } from "../../../configuration/columns/order.column";
import { refundOrderRows } from "../../../configuration/rows/order.row";
import { paymentRows } from "../../../configuration/rows/payment.row";
import { getRoutePath } from "../../../hooks/useNavigateRoute";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { RpiTable } from "../../../rpi-core/table/RpiTable";

export const PaymentView: React.FC = () => {
  const { id } = useParams();

  const paidOrdersPaged = usePaginatedData<
    NonNullable<NonNullable<NonNullable<typeof data>["payment"]["paidOrders"]>[number]>
  >([], { perPage: 5, sortFn: (a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime() });

  const { data, isLoading, error, refetch } = GraphQL.usePaymentQuery(
    {
      id: Number.parseInt(id!),
    },
    {
      onSuccess: (data) => {
        paidOrdersPaged.setData((data.payment.paidOrders as any) || []);
      },
    }
  );

  const payment = React.useMemo(() => data?.payment, [data]);

  return (
    <AppPage
      path={[
        {
          name: "Payments",
          to: getRoutePath("billing"),
        },
        id,
      ]}
      onReload={refetch}
    >
      <AppPage.Section title="Data">
        <RpiSingleTable data={payment} rows={paymentRows} isLoading={isLoading} error={error} />
      </AppPage.Section>

      {payment?.refundedOrder && (
        <AppPage.Section mt={32} title="Refunded Order">
          <RpiSingleTable data={payment.refundedOrder} rows={refundOrderRows} isLoading={isLoading} error={error} />
        </AppPage.Section>
      )}

      {payment?.transactionType === GraphQL.TransactionType.Sale && (
        <AppPage.Section mt={32} title="Paid Orders">
          <RpiTable
            emptyMessage="No paid orders."
            isLoading={isLoading}
            error={error}
            perPage={paidOrdersPaged.pagination.perPage}
            data={paidOrdersPaged.pageData}
            columns={paidOrderColumns}
          />

          {paidOrdersPaged.pagination.pages > 1 && <RpiPagination pagination={paidOrdersPaged.pagination} />}
        </AppPage.Section>
      )}
    </AppPage>
  );
};
